import { ClientDetailsProps } from "features/client/types";
import ClientDetail from "./clientDetail";

/**
 * Component used in the Client Summary Page for External Investment Informatio
 * @param param0
 * @returns
 */
function ClientExternalInvestmentDetails({ selectedClientData }: ClientDetailsProps) {
  const externalInvestmentDetails = selectedClientData.externalInvestmentDetails;

  return (
    <div className="client-details" id="client-external-investment-details">
      <ClientDetail
        label="Equities Value"
        value={externalInvestmentDetails.equitiesValue}
        isDollarAmount={true}
        isCommmas={true}
      />
      <ClientDetail
        label="Fixed Income Value"
        value={externalInvestmentDetails.fixedIncomeValue}
        isDollarAmount={true}
        isCommmas={true}
      />
      <ClientDetail
        label="Cash or Equiv. Value"
        value={externalInvestmentDetails.cashEquivalentValue}
        isDollarAmount={true}
        isCommmas={true}
      />
      <ClientDetail
        label="Real Estate Value"
        value={externalInvestmentDetails.realEstateValue}
        isDollarAmount={true}
        isCommmas={true}
      />
      <ClientDetail
        label="Commodities Value"
        value={externalInvestmentDetails.commoditiesValue}
        isDollarAmount={true}
        isCommmas={true}
      />
      <ClientDetail
        label="Others Value"
        value={externalInvestmentDetails.otherValue}
        isDollarAmount={true}
        isCommmas={true}
      />
      {externalInvestmentDetails.otherValue > 0 && (
        <ClientDetail
          label="Other Description"
          value={externalInvestmentDetails.otherDescription}
        />
      )}
      <ClientDetail
        label="Annual Income From Investments"
        value={externalInvestmentDetails.annualIncome}
        isDollarAmount={true}
        isCommmas={true}
      />
    </div>
  );
}

export default ClientExternalInvestmentDetails;
