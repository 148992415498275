import { InsiderDetail } from "../../../../types/Participant/Participant";
import { EditClientBoolean } from "../Helpers/Inputs";
import {
  Is10PercentShareholderDetails,
  Is20PercentShareholderDetails,
  IsAssociateDomesticPEP,
  IsAssociateForeignPEP,
  IsAssociateHIODetails,
  IsBrokerDealerDetails,
  IsForeignPEPDetails,
  IsHIODetails,
  IsInsiderDetails,
} from "../Helpers/RegulatoryDetailsInputs";

import { EditClientWizardItemProps } from "./EditClientWizardItemProps";
import "../styles/CreateClientRegulatoryDetails.scss";
import IsIIROCMemberDetails from "../Helpers/RegulatoryDetailsInputs/IsIIROCMemberDetails";
import IsDomesticPEPDetails from "../Helpers/RegulatoryDetailsInputs/IsDomesticPEPDetails";
import IsSecurityFirmDetails from "../Helpers/RegulatoryDetailsInputs/IsSecurityFirmDetails";

// Used for Saving and Verifying the Section
const SECTION_ID = 7;

const emptyInsiderDetail: InsiderDetail = {
  symbol: "",
  companyName: "",
};

/**
 * Component handle the regulatory details of a client, lots of moving parts to this as you can add and remove
 * items from different attributes
 * @todo Please move all of the add, delete, and remove functions to a helper file. Clean up the code.
 * @param param0
 * @returns
 */
const CreateClientRegulatoryDetails = ({
  selectedClientData,
  handleDataChange,
  handleSaveClientDetails,
  handleGoBack,
}: EditClientWizardItemProps) => {
  /**
   * Handles the change of the employment details given the value, attribute name and chekc if its a boolean
   * @param val The value of the change
   * @param attrName The attribute name of the change
   * @param check The boolean value of the change if it is a boolean
   */
  const handleRegulatoryDetailChange = (
    val: number | string | boolean,
    attrName: string | undefined,
    check?: boolean
  ) => {
    let value: any = "";
    if (check !== undefined) {
      value = check;
    } else {
      value = val;
    }

    if (selectedClientData && attrName) {
      let tempData = { ...selectedClientData.regulatoryDetails };
      tempData[attrName] = value;
      handleDataChange({ regulatoryDetails: tempData });
    }
  };

  return (
    <div className="edit-client-wizard-item" id="edit-client-regulatory-details">
      <div className="edit-client-wizard-item-header">
        <button
          className="edit-client-wizard-item-back"
          onClick={handleGoBack}
          style={{ fontSize: "1rem" }}
        >
          <i className="bi bi-arrow-left-circle-fill"></i>
        </button>
        <p>Regulatory Details</p>
      </div>
      <div className="edit-client-wizard-item-body">
        <div className="regulatory-list-section-header">Power of Attorney</div>
        <EditClientBoolean
          label="Has Power of Attorney?"
          value={selectedClientData?.regulatoryDetails?.hasPowerOfAttorney}
          inputName="hasPowerOfAttorney"
          attrName="hasPowerOfAttorney"
          handleChange={handleRegulatoryDetailChange}
        />
        <IsInsiderDetails
          selectedClientData={selectedClientData}
          handleDataChange={handleDataChange}
          handleRegulatoryDetailChange={handleRegulatoryDetailChange}
        />
        <Is10PercentShareholderDetails
          selectedClientData={selectedClientData}
          handleDataChange={handleDataChange}
          handleRegulatoryDetailChange={handleRegulatoryDetailChange}
        />

        <Is20PercentShareholderDetails
          selectedClientData={selectedClientData}
          handleDataChange={handleDataChange}
          handleRegulatoryDetailChange={handleRegulatoryDetailChange}
        />
        <div className="regulatory-list-section-header">
          Investment Industry Regulatory Organization of Canada (IIROC) Details
        </div>
        <IsIIROCMemberDetails
          selectedClientData={selectedClientData}
          handleDataChange={handleDataChange}
          handleRegulatoryDetailChange={handleRegulatoryDetailChange}
        />
        <div className="regulatory-list-section-header">
          Foreign Politically Exposed Person (PEP) Details
        </div>
        <IsForeignPEPDetails
          selectedClientData={selectedClientData}
          handleDataChange={handleDataChange}
          handleRegulatoryDetailChange={handleRegulatoryDetailChange}
        />
        <div className="breakline"></div>
        <IsAssociateForeignPEP
          selectedClientData={selectedClientData}
          handleDataChange={handleDataChange}
          handleRegulatoryDetailChange={handleRegulatoryDetailChange}
        />
        <div className="regulatory-list-section-header">
          Domestic Politically Exposed Person (PEP) Details
        </div>
        <IsDomesticPEPDetails
          selectedClientData={selectedClientData}
          handleDataChange={handleDataChange}
          handleRegulatoryDetailChange={handleRegulatoryDetailChange}
        />
        <div className="breakline"></div>
        <IsAssociateDomesticPEP
          selectedClientData={selectedClientData}
          handleDataChange={handleDataChange}
          handleRegulatoryDetailChange={handleRegulatoryDetailChange}
        />
        <div className="regulatory-list-section-header">
          Head of Internal Organization (HIO) Details
        </div>
        <IsHIODetails
          selectedClientData={selectedClientData}
          handleDataChange={handleDataChange}
          handleRegulatoryDetailChange={handleRegulatoryDetailChange}
        />
        <div className="breakline"></div>
        <IsAssociateHIODetails
          selectedClientData={selectedClientData}
          handleDataChange={handleDataChange}
          handleRegulatoryDetailChange={handleRegulatoryDetailChange}
        />
        <div className="regulatory-list-section-header">Security Firm Details</div>
        <IsSecurityFirmDetails
          selectedClientData={selectedClientData}
          handleDataChange={handleDataChange}
          handleRegulatoryDetailChange={handleRegulatoryDetailChange}
        />
        <div className="regulatory-list-section-header">Broker / Dealer Details</div>
        <IsBrokerDealerDetails
          selectedClientData={selectedClientData}
          handleDataChange={handleDataChange}
          handleRegulatoryDetailChange={handleRegulatoryDetailChange}
        />
        <button
          className="btn-nice-purple btn-save-client-details"
          onClick={() => handleSaveClientDetails(SECTION_ID)}
        >
          Save and Continue
        </button>
      </div>
    </div>
  );
};

export default CreateClientRegulatoryDetails;
