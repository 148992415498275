interface WizardListItemProps {
  target: string;
  label: string;
  isActive?: boolean;
  handleStepChange: (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    target: string
  ) => void;
}

const WizardListItem = ({
  target,
  label,
  isActive,
  handleStepChange,
}: WizardListItemProps) => {
  return (
    <li
      className={`edit-client-progress-item ${isActive ? "active" : ""}`}
      onClick={(e) => handleStepChange(e, target)}
    >
      <p>{label}</p>
      <div className="edit-client-progress-item-active-line"></div>
    </li>
  );
};

export default WizardListItem;
