import React, { useEffect, useState } from "react";
import { InvestmentAccountDetailsProps } from "./CreateInvestmentAccountGeneralDetails";
import "../styles/jointDetails.scss";
import maskString from "../../../utils/maskString";
import { HouseholdState } from "stores/Redux/householdSlice";
import { RootState } from "stores/store";
import { useSelector } from "react-redux";
import { SelectItem } from "types/Shared";
import { EditClientSelect } from "Components/Pages/CreateFullClient/Helpers/Inputs";
import { checkEmptyValue, notify } from "utils";
import { CreateInvestmentAccountDto } from "types/InvestmentAccount";
/**
 * Part of the Create Investment Account Process where a client can specify if the account is joint or not
 *
 * This was already specified in the general details, but this is a more detailed view where the user
 * can ensure that their spouse is selected the joint name on the account.
 *
 * Since the spouse details are required in the general details of the client, this will really be
 * just a view-only mode where a client can ensure that the spouse entered is correct.
 *
 * CHANGE: A joint account holder must have their entire KYC workup done. This means that you can only
 * select those clients for whom you have done the KYC workup for. Even if you have spousal information,
 * if they don't have a KYC workup, then they don't count as a joint account holder. A joint account holder
 * can also come from a seperate household that the advisor can manage. This means that the advisor can
 * select a client from a different household as a joint account holder.
 *
 * @param param0
 * @returns
 */
function CreateInvestmentAccountJointDetails({
  participant: client,
  account,
  handleGoNext,
  setNewInvestmentAccount,
}: InvestmentAccountDetailsProps) {
  const [isAddingHolder, setIsAddingHolder] = useState(false);
  const [validJointParticipants, setValidJointParticipants] = useState<SelectItem[]>([]);
  const advisorHouseholds: HouseholdState = useSelector((state: RootState) => state.householdStore);

  useEffect(() => {
    let validParticipants: SelectItem[] = [];
    // first find the clients in the same household as the client with a completed KYC
    const clientsWithSameHousehold = advisorHouseholds.households.find(
      (household) => household.id === client?.householdId
    )?.newClients;

    if (clientsWithSameHousehold && clientsWithSameHousehold.length > 0) {
      clientsWithSameHousehold.forEach((householdClient) => {
        if (householdClient.completedKYC && client?.id !== householdClient?.id) {
          validParticipants.push({
            label: `${householdClient.generalDetails.firstName} ${householdClient.generalDetails.lastName}`,
            value: householdClient.id,
          });
        }
      });
    }

    // now find the clients in other households with a completed KYC
    const clientsInOtherHouseholds = advisorHouseholds.households.find(
      (household) => household.id !== client?.householdId
    )?.newClients;

    if (clientsInOtherHouseholds && clientsInOtherHouseholds.length > 0) {
      clientsInOtherHouseholds.forEach((client) => {
        if (client.completedKYC) {
          validParticipants.push({
            label: `${client.generalDetails.firstName} ${client.generalDetails.lastName}`,
            value: client.id,
          });
        }
      });
    }

    setValidJointParticipants(validParticipants);
  }, [advisorHouseholds]);

  return (
    <div className="edit-client-wizard-item" id="edit-invest-account-joint-details">
      <div className="edit-client-wizard-item-header">
        <p>Account Holder Details</p>
      </div>
      <div className="edit-client-wizard-item-body">
        <div className="account-holders-container">
          {account.accountHolders &&
            account.accountHolders.length > 0 &&
            account.accountHolders.map((accountHolder) => {
              return (
                <div
                  className="account-holder-card"
                  style={{ border: "1px solid black", marginBottom: "1rem", padding: "0.5rem" }}
                >
                  <p>Account Holder Id: {accountHolder}</p>
                </div>
              );
            })}
        </div>
        {!account.accountHolders ||
          (account.accountHolders && account.accountHolders.length < 2 && (
            <div className="add-detail-btn-container">
              {isAddingHolder ? (
                <button
                  className="btn btn-danger"
                  onClick={() => setIsAddingHolder(!isAddingHolder)}
                >
                  Cancel
                </button>
              ) : (
                <button
                  className="btn btn-success"
                  onClick={() => setIsAddingHolder(!isAddingHolder)}
                >
                  Add Joint Account Holder
                </button>
              )}
            </div>
          ))}
        {isAddingHolder && (
          <JointAccountHolderForm
            validJointParticipants={validJointParticipants}
            account={account}
            setAccount={setNewInvestmentAccount}
            setIsAddingHolder={setIsAddingHolder}
          />
        )}

        {handleGoNext && (
          <button className="btn-nice-purple btn-save-client-details" onClick={handleGoNext}>
            Continue
          </button>
        )}
      </div>
    </div>
  );
}

interface JointAccountHolderFormProps {
  validJointParticipants: SelectItem[];
  account: CreateInvestmentAccountDto;
  setAccount: React.Dispatch<React.SetStateAction<CreateInvestmentAccountDto>>;
  setIsAddingHolder: React.Dispatch<React.SetStateAction<boolean>>;
}

const JointAccountHolderForm = ({
  validJointParticipants,
  account,
  setAccount,
  setIsAddingHolder,
}: JointAccountHolderFormProps) => {
  const [accountHolder, setAccountHolder] = useState("");

  /**
   * Handles the joint account holder form submission.
   * Adds an account holder to the account
   * @param e
   * @returns
   */
  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (checkEmptyValue(accountHolder)) {
      notify("Please select a valid account holder", "error");
      return;
    }
    const tempAccount = { ...account };
    if (tempAccount.accountHolders) {
      if (tempAccount.accountHolders.includes(accountHolder)) {
        notify("This account holder is already added", "error");
        return;
      }
      setAccount({
        ...tempAccount,
        accountHolders: [...tempAccount.accountHolders, accountHolder],
      });
    } else {
      setAccount({ ...tempAccount, accountHolders: [accountHolder] });
    }

    setAccountHolder("");
    setIsAddingHolder(false);
  };

  /**
   * Handles the select of the account holder to buy
   * @param e
   * @param attrName
   */
  const handleHolderChange = (e: string | number | boolean, attrName: string | undefined) => {
    setAccountHolder(e as string);
  };

  return (
    <form onSubmit={handleFormSubmit} style={{ marginTop: "1rem" }}>
      <EditClientSelect
        label="Account Holder"
        value={accountHolder}
        selectItems={validJointParticipants}
        handleChange={handleHolderChange}
      />
      <button className="btn btn-success">Add Holder</button>
    </form>
  );
};

export default CreateInvestmentAccountJointDetails;
