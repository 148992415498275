import React from "react";
import { RiskToleranceFormData } from "../../../types";
import "../styles/specialNotes.scss";

interface RiskWizardGroupProps {
  propData: RiskToleranceFormData;
  onChange: (data: Partial<RiskToleranceFormData>) => void;
}

function SpecialNotesSection(props: RiskWizardGroupProps) {
  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onChange({ specialNotes: e.target.value });
  };

  return (
    <div className="risk-special-notes">
      <p className="risk-special-notes-header">Special Notes</p>
      <p className="risk-special-notes-subheader">Anything else that you would like to add?</p>
      <textarea
        className="risk-special-notes-text-area"
        onChange={handleNotesChange}
        value={props.propData.specialNotes}
      />
    </div>
  );
}

export default SpecialNotesSection;
