export enum ContributionFrequency {
  WEEKLY = 1,
  BI_WEEKLY = 2,
  MONTHLY = 3,
  BI_MONTHLY = 4,
  QUARTERLY = 5,
  SEMI_ANNUALLY = 6,
  ANNUAL = 7,
  OTHER = 8,
}
