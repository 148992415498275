import { notify } from "utils";
import { CreateIndividualPolicyStatementDto } from "../types";

const validateTimeHorizon = (formData: CreateIndividualPolicyStatementDto) => {
  if (formData.timeHorizion > 0) {
    return true;
  }

  notify("Please provide a valid time horizon", "error");
  return false;
};

export default validateTimeHorizon;
