import { NewParticipantWithDOBDto } from "types/Participant";

const EmptyNewParticipant: NewParticipantWithDOBDto = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  householdId: "",
  dateOfBirth: "",
  sin: "",
  roles: [],
};

export default EmptyNewParticipant;
