import { Participant } from "../../../../../types";
import { InsiderDetail } from "../../../../../types/Participant/Participant";
import EditClientBoolean from "../Inputs/EditClientBoolean";
import EditClientInputWithArray from "../Inputs/EditClientInputWithArray";
import RegulatoryDataProps from "./RegulatoryDataProps";

const emptyInsiderDetail: InsiderDetail = {
  symbol: "",
  companyName: "",
};

const IsInsiderDetails = ({
  selectedClientData,
  handleDataChange,
  handleRegulatoryDetailChange,
}: RegulatoryDataProps) => {
  const handleInsiderDetailAdd = () => {
    let tempData = { ...selectedClientData?.regulatoryDetails! };
    if (!tempData.insiderDetails) {
      tempData.insiderDetails = [];
    }

    tempData.insiderDetails = [...tempData.insiderDetails, { ...emptyInsiderDetail }];

    handleDataChange({ regulatoryDetails: tempData });
  };

  const handleInsiderDetailChange = (
    val: number | string | boolean,
    attrName: string | undefined,
    index: number | undefined
  ) => {
    if (selectedClientData && attrName && index !== undefined) {
      let tempData = { ...selectedClientData?.regulatoryDetails! };
      if (!tempData.insiderDetails) {
        tempData.insiderDetails = [];
      }

      // Using spread operator to update an asset
      const updatedObject = {
        ...tempData.insiderDetails[index],
        [attrName]: val,
      };

      tempData.insiderDetails = [
        ...tempData.insiderDetails.slice(0, index),
        updatedObject,
        ...tempData.insiderDetails.slice(index + 1),
      ];

      handleDataChange({ regulatoryDetails: tempData });
    }
  };

  const handleInsiderDetailDelete = (index: number) => {
    if (selectedClientData) {
      let tempData = { ...selectedClientData?.regulatoryDetails! };
      if (!tempData.insiderDetails) {
        tempData.insiderDetails = [];
      }

      tempData.insiderDetails = [
        ...tempData.insiderDetails.slice(0, index),
        ...tempData.insiderDetails.slice(index + 1),
      ];

      handleDataChange({ regulatoryDetails: tempData });
    }
  };

  return (
    <>
      <div className="regulatory-list-section-header">Insider Details</div>
      <EditClientBoolean
        label="Is Insider?"
        value={selectedClientData?.regulatoryDetails?.isInsider}
        inputName="isInsider"
        attrName="isInsider"
        handleChange={handleRegulatoryDetailChange}
      />
      {selectedClientData?.regulatoryDetails?.isInsider && (
        <div className="regulatory-list-container">
          {selectedClientData?.regulatoryDetails?.insiderDetails?.map((detail, index) => {
            return (
              <div className="regulatory-list-item insider-detail">
                <EditClientInputWithArray
                  label={`Insider Symbol ${index + 1}`}
                  value={detail.symbol}
                  attrName="symbol"
                  handleDataChange={handleInsiderDetailChange}
                  index={index}
                />
                <EditClientInputWithArray
                  label={`Insider Company ${index + 1}`}
                  value={detail.companyName}
                  attrName="companyName"
                  handleDataChange={handleInsiderDetailChange}
                  index={index}
                />
                <div>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleInsiderDetailDelete(index)}
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {selectedClientData?.regulatoryDetails?.isInsider && (
        <div className="add-regulatory-detail-btn-cont">
          <button onClick={handleInsiderDetailAdd}>Add Insider Detail</button>
        </div>
      )}
    </>
  );
};

export default IsInsiderDetails;
