import { useEffect, useState } from "react";

/**
 * Interface used for the Edit Client Input
 * @attr label - The label for the input
 * @attr value - The value of the input
 * @attr attrName - The attribute name of the input
 * @attr placeholder - The placeholder for the input
 * @attr isNumber - Is the input a number?
 * @attr isDate - Is the input a date?
 * @attr handleChange - The function to handle the change of the input, set this to null if using handleChangeWithIndex
 * @attr handleChangeWithIndex - For attributes with arrays, we need to provide the index of the element we are changing. Not always used
 */
interface EditClientInputProps {
  label: string;
  value: any;
  attrName?: string;
  placeholder?: string;
  isNumber?: boolean;
  isDate?: boolean;
  isRequired?: boolean;
  isDollarAmount?: boolean;
  //index?: number;
  validateValue?: (value: any) => boolean | undefined | null | void;
  handleChange: (
    e: string | number | boolean,
    attrName: string | undefined
  ) => void;
  // handleChangeWithIndex?: (
  //   e: string | number | boolean,
  //   attrName: string | undefined,
  //   index: number | undefined
  // ) => void;
}

/**
 * An input component used to deal with Editing A Client's Information
 * @param label - The label for the input
 * @param value - The value of the input
 * @param attrName - The attribute name of the input
 * @param placeholder - The placeholder for the input
 * @param isNumber - Is the input a number?
 * @param isDate - Is the input a date?
 * @param handleChange - The function to handle the change of the input
 * @returns
 */
const EditClientInput = ({
  label,
  value,
  attrName,
  placeholder,
  isNumber,
  isDate,
  isRequired,
  isDollarAmount,
  validateValue,
  handleChange,
}: EditClientInputProps) => {
  const [isValid, setIsValid] = useState<boolean>(false);
  let inputType = "text";

  // If the input is a number, then set the input type to number
  // If the number is a date, then set the input type to date and check for null values
  if (isNumber) {
    inputType = "number";
    if (!value) {
      value = "0";
    }
  } else if (isDate) {
    if (value) {
      value = new Date(value).toISOString().split("T")[0];
      if (value == "0001-01-01") {
        value = new Date().toISOString().split("T")[0];
      }
    }

    inputType = "date";
  }

  // Set Index to -1 if not defined
  // if (!index) {
  //   index = -1;
  // }

  // Setting the isValid state based ont the validateValue function
  useEffect(() => {
    if (validateValue && value && validateValue(value) == true) {
      setIsValid(true);
    } else if (!validateValue && value) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [value]);

  return (
    <div className="edit-client-wizard-item-input-control">
      <label>
        {label}
        {isRequired && "*"}
      </label>
      {isDollarAmount ? "$" : ""}
      <input
        type={inputType}
        value={value ? value : ""}
        // Either handle the change normally, or use the index to handle the change with an array
        onChange={(e) => handleChange(e.target.value, attrName)}
        placeholder={placeholder}
        pattern={isNumber ? "[0-9]*" : undefined}
      />
    </div>
  );
};

export default EditClientInput;
