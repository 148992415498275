import { SelectItem } from "types/Shared";
import { CitizenshipType } from "../enums";

/**
 * Citizenship Options for a Client
 * Can really only be Canadian or American to open up an account with SIA for now
 *
 * @author Teddy K.
 */
export const ClientCitizenshipOptions: SelectItem[] = [
  {
    label: "Canada",
    value: CitizenshipType.CANADA,
  },
  {
    label: "United States",
    value: CitizenshipType.UNITED_STATES,
  },
  {
    label: "Other",
    value: CitizenshipType.OTHER,
  },
];
