import ClientDetail from "./clientDetail";
import { ClientDetailsProps } from "features/client/types";
import { EmploymentStatusMappingById } from "constants/mappings/EmploymentStatusMappingById";

function ClientEmploymentDetails({ selectedClientData }: ClientDetailsProps) {
  return (
    <div className="client-details" id="client-employment-details">
      {selectedClientData.employmentDetails ? (
        <>
          <ClientDetail
            label={"Status"}
            value={EmploymentStatusMappingById[selectedClientData.employmentDetails.status]}
          />
          <ClientDetail
            label={"Occupation"}
            value={selectedClientData.employmentDetails.occupation}
          />
          <ClientDetail label={"Business Name"} value={selectedClientData.employmentDetails.name} />
          <ClientDetail
            label={"Length (In Years)"}
            value={selectedClientData.employmentDetails.lengthInYears}
          />
          <ClientDetail
            label={"Employer Address"}
            value={`${selectedClientData.employmentDetails.address}, 
                    ${selectedClientData.employmentDetails.city}, 
                    ${selectedClientData.employmentDetails.region}, 
                    ${selectedClientData.employmentDetails.country}, 
                    ${selectedClientData.employmentDetails.postalCode}`}
          />
        </>
      ) : (
        <>
          <p>Employment Information Not Found. Please Complete KYC.</p>
        </>
      )}
    </div>
  );
}

export default ClientEmploymentDetails;
