import React, { useState } from "react";
import "../styles/stepFormWizard.scss";
interface StepFormWizardProps {
  startIndex: number;
  steps: WizardStep[];
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  handleStepChange: any;
  submitForm: any;
  // isCompleted: boolean;
  // CompletedComponent: React.ComponentType<any>;
}

interface WizardStep {
  label: string;
  subtitle: string;
  content: React.ComponentType<WizardStepProps>;
  validator?: any;
}

interface WizardStepProps {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
}

function StepFormWizard({
  startIndex,
  steps,
  formData,
  setFormData,
  handleStepChange,
  submitForm,
}: StepFormWizardProps) {
  const [currentStep, setCurrentStep] = useState(startIndex);
  const CurrentStepComponent = steps[currentStep].content;
  const currentStepValidator = steps[currentStep].validator;

  const handleNextStepChange = () => {
    if (currentStepValidator) {
      const isValid = currentStepValidator(formData);
      if (!isValid) {
        return;
      }
    }
    setCurrentStep(currentStep + 1);
  };

  return (
    <div>
      <div className="form-progress-bar">
        {steps.map((step, index) => {
          return (
            <>
              <div
                className={`form-progress-bar-step ${index == currentStep ? "active" : ""}`}
                key={index}
                onClick={() => setCurrentStep(index)}
              >
                <div
                  className={`form-progress-bar-step-number ${
                    currentStep > index ? "complete" : ""
                  }`}
                >
                  {index + 1}
                </div>
                <div className="form-progress-bar-step-label">{step.label}</div>
                <div className="form-progress-bar-step-subtitle">{step.subtitle}</div>
              </div>
              {index != steps.length - 1 && (
                <div className="form-progress-bar-outerline">
                  <div
                    className="form-progress-bar-innerline"
                    style={currentStep > index ? { width: "100%" } : {}}
                  ></div>
                </div>
              )}
            </>
          );
        })}
      </div>
      <div className="form-progress-container">
        <CurrentStepComponent formData={formData} setFormData={setFormData} />
        <div className="form-progress-btns-container">
          <div className="form-progress-btn-container">
            <button onClick={() => setCurrentStep(currentStep - 1)} disabled={currentStep == 0}>
              Prev
            </button>
          </div>

          {currentStep != steps.length - 1 ? (
            <div className="form-progress-btn-container">
              <button onClick={handleNextStepChange}>Next</button>
            </div>
          ) : (
            <div className="form-progress-btn-container">
              <button className="btn btn-success" onClick={submitForm}>
                Submit
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StepFormWizard;
