// Function to validate the
// GUID
function isValidGUID(str: string) {
  // Regex to check valid
  // GUID
  let regex = new RegExp(
    /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/
  );

  // if str
  // is empty return false
  if (str == null) {
    return "false";
  }

  // Return true if the str
  // matched the ReGex
  return regex.test(str) == true;
}

export default isValidGUID;
