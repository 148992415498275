import { AssetLiquidity } from "../../../../types/Participant/Participant";
import { EditClientWizardItemProps } from "./EditClientWizardItemProps";

const SECTION_ID = 5;

const emptyAsset = {
  type: "",
  amount: 0,
};
/**
 * Components that manages the change of employment details for a client
 * @param param0
 * @returns
 */
const CreateClientNetWorthDetails = ({
  selectedClientData,
  handleDataChange,
  handleSaveClientDetails,
  handleGoBack,
}: EditClientWizardItemProps) => {
  /**
   * Handles the change of the employment details given the value, attribute name and chekc if its a boolean
   * @param val The value of the change
   * @param attrName The attribute name of the change
   * @param check The boolean value of the change if it is a boolean
   */

  /**
   * Adds a New Liquid Asset to the clients net worth details
   */
  const handleAddLiquidAsset = () => {
    if (selectedClientData) {
      let tempData = { ...selectedClientData.netWorthDetails };
      if (!tempData.liquidAssets) {
        tempData.liquidAssets = [];
      }

      // Using spread operator to add an empty asset
      tempData.liquidAssets = [...tempData.liquidAssets, { ...emptyAsset }];

      handleDataChange({ netWorthDetails: tempData });
    }
  };

  /**
   * Adds a New Fixed Asset to the clients net worth details
   */
  const handleAddFixedAsset = () => {
    if (selectedClientData) {
      let tempData = { ...selectedClientData.netWorthDetails };
      if (!tempData.fixedAssets) {
        tempData.fixedAssets = [];
      }

      // Using spread operator to add an empty asset
      tempData.fixedAssets = [...tempData.fixedAssets, { ...emptyAsset }];

      handleDataChange({ netWorthDetails: tempData });
    }
  };

  /**
   * Adds a New Liquid Asset to the clients net worth details
   */
  const handleAddLiability = () => {
    if (selectedClientData) {
      let tempData = { ...selectedClientData.netWorthDetails };
      if (!tempData.liabilities) {
        tempData.liabilities = [];
      }

      tempData.liabilities = [...tempData.liabilities, { ...emptyAsset }];

      handleDataChange({ netWorthDetails: tempData });
    }
  };

  /**
   * Removes a Liquid Asset from the clients net worth details
   * @param index
   * @returns
   */
  const handleRemoveLiquidAsset = (index: number) => {
    if (selectedClientData) {
      let tempData = { ...selectedClientData.netWorthDetails };
      if (!tempData.liquidAssets) {
        return;
      }

      tempData.liquidAssets = [
        ...tempData.liquidAssets.slice(0, index),
        ...tempData.liquidAssets.slice(index + 1),
      ];

      handleDataChange({ netWorthDetails: tempData });
    }
  };

  /**
   * Removes a Liquid Asset from the clients net worth details
   * @param index
   * @returns
   */
  const handleRemoveFixedAsset = (index: number) => {
    if (selectedClientData) {
      let tempData = { ...selectedClientData.netWorthDetails };
      if (!tempData.fixedAssets) {
        return;
      }

      tempData.fixedAssets = [
        ...tempData.fixedAssets.slice(0, index),
        ...tempData.fixedAssets.slice(index + 1),
      ];

      handleDataChange({ netWorthDetails: tempData });
    }
  };

  /**
   * Removes a Liquid Asset from the clients net worth details
   * @param index
   * @returns
   */
  const handleRemoveLiability = (index: number) => {
    if (selectedClientData) {
      let tempData = { ...selectedClientData.netWorthDetails };
      if (!tempData.liabilities) {
        return;
      }

      tempData.liabilities = [
        ...tempData.liabilities.slice(0, index),
        ...tempData.liabilities.slice(index + 1),
      ];

      handleDataChange({ netWorthDetails: tempData });
    }
  };

  /**
   * Handling a liquid asset change
   * @param val
   * @param attrName
   * @param index
   */
  const handleNetWorthDetailsLiquidAssetChange = (
    val: number | string | boolean,
    attrName: string | undefined,
    index: number
  ) => {
    if (selectedClientData && attrName) {
      let tempData = { ...selectedClientData.netWorthDetails };
      if (!tempData.liquidAssets) {
        tempData.liquidAssets = [];
      }

      // Using spread operator to update an asset
      const updatedObject = {
        ...tempData.liquidAssets[index],
        [attrName]: val,
      };

      tempData.liquidAssets = [
        ...tempData.liquidAssets.slice(0, index),
        updatedObject,
        ...tempData.liquidAssets.slice(index + 1),
      ];

      handleDataChange({ netWorthDetails: tempData });
    }
  };

  /**
   * handling a fixed asset change
   * @param val
   * @param attrName
   * @param index
   */
  const handleNetWorthDetailsFixedAssetChange = (
    val: number | string | boolean,
    attrName: string | undefined,
    index: number
  ) => {
    if (selectedClientData && attrName) {
      let tempData = { ...selectedClientData.netWorthDetails };
      if (!tempData.fixedAssets) {
        tempData.fixedAssets = [];
      }

      // Using spread operator to update an asset
      const updatedObject = {
        ...tempData.fixedAssets[index],
        [attrName]: val,
      };

      tempData.fixedAssets = [
        ...tempData.fixedAssets.slice(0, index),
        updatedObject,
        ...tempData.fixedAssets.slice(index + 1),
      ];

      handleDataChange({ netWorthDetails: tempData });
    }
  };

  /**
   * handling a liability change
   * @param val
   * @param attrName
   * @param index
   */
  const handleNetWorthDetailsLiabilityChange = (
    val: number | string | boolean,
    attrName: string | undefined,
    index: number
  ) => {
    if (selectedClientData && attrName) {
      let tempData = { ...selectedClientData.netWorthDetails };
      if (!tempData.fixedAssets) {
        tempData.fixedAssets = [];
      }

      // Using spread operator to update an asset
      const updatedObject = {
        ...tempData.liabilities[index],
        [attrName]: val,
      };

      tempData.liabilities = [
        ...tempData.liabilities.slice(0, index),
        updatedObject,
        ...tempData.liabilities.slice(index + 1),
      ];

      handleDataChange({ netWorthDetails: tempData });
    }
  };

  return (
    <div className="edit-client-wizard-item" id="edit-client-net-worth-details">
      <div className="edit-client-wizard-item-header">
        <button
          className="edit-client-wizard-item-back"
          onClick={handleGoBack}
          style={{ fontSize: "1rem" }}
        >
          <i className="bi bi-arrow-left-circle-fill"></i>
        </button>
        <p>Net Worth Details</p>
      </div>
      <div className="edit-client-wizard-item-sub-header">
        <p className="special-message">Do not add any if not specified</p>
      </div>
      <div className="edit-client-wizard-item-body">
        <div className="edit-client-net-worth-asset-liability-container">
          <div className="edit-client-net-worth-asset-liability">
            <button
              className="edit-client-net-worth-asset-liability-button-add"
              onClick={handleAddLiquidAsset}
            >
              Add Liquid Asset
            </button>
            {selectedClientData?.netWorthDetails?.liquidAssets?.map((asset, index) => {
              return (
                <CreateNetWorthDetail
                  label="Liquid Asset"
                  index={index}
                  asset={asset}
                  handleRemoveAsset={handleRemoveLiquidAsset}
                  handleNetWorthChange={handleNetWorthDetailsLiquidAssetChange}
                />
              );
            })}
          </div>
          <div className="edit-client-net-worth-asset-liability">
            <button
              className="edit-client-net-worth-asset-liability-button-add"
              onClick={handleAddFixedAsset}
            >
              Add Fixed Asset
            </button>
            {selectedClientData?.netWorthDetails?.fixedAssets?.map((asset, index) => {
              return (
                <CreateNetWorthDetail
                  label="Fixed Asset"
                  index={index}
                  asset={asset}
                  handleRemoveAsset={handleRemoveFixedAsset}
                  handleNetWorthChange={handleNetWorthDetailsFixedAssetChange}
                />
              );
            })}
          </div>
          <div className="edit-client-net-worth-asset-liability">
            <button
              className="edit-client-net-worth-asset-liability-button-add"
              onClick={handleAddLiability}
            >
              Add Liability
            </button>
            {selectedClientData?.netWorthDetails?.liabilities?.map((asset, index) => {
              return (
                <CreateNetWorthDetail
                  label="Liability"
                  index={index}
                  asset={asset}
                  handleRemoveAsset={handleRemoveLiability}
                  handleNetWorthChange={handleNetWorthDetailsLiabilityChange}
                />
              );
            })}
          </div>
        </div>
      </div>
      <button
        className="btn-nice-purple btn-save-client-details"
        onClick={() => handleSaveClientDetails(SECTION_ID)}
      >
        Save and Continue
      </button>
    </div>
  );
};

interface CreateNetWorthDetailProps {
  label: string;
  index: number;
  asset: AssetLiquidity;
  handleRemoveAsset: (index: number) => void;
  handleNetWorthChange: (
    val: number | string | boolean,
    attrName: string | undefined,
    index: number
  ) => void;
}

const CreateNetWorthDetail = ({
  label,
  index,
  asset,
  handleRemoveAsset,
  handleNetWorthChange,
}: CreateNetWorthDetailProps) => {
  return (
    <div className="edit-client-list-item-container">
      <div className="edit-client-list-item-container-header">
        <p>
          {label} {index + 1}
        </p>
        <button className="remove-item-btn" onClick={() => handleRemoveAsset(index)}>
          <i className="bi bi-file-earmark-x-fill"></i>
        </button>
      </div>

      <div className="container-row">
        <div className="edit-client-wizard-item-input-control">
          <label>Type</label>
          <input
            type="text"
            value={asset.type ? asset.type : ""}
            onChange={(e) => handleNetWorthChange(e.target.value, "type", index)}
          />
        </div>
        <div className="edit-client-wizard-item-input-control">
          <label>Value</label>
          $
          <input
            type="number"
            value={asset.amount ? asset.amount : ""}
            onChange={(e) => handleNetWorthChange(e.target.value, "amount", index)}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateClientNetWorthDetails;
