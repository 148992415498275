import React from "react";
import "./styles/Footer.scss";
function Footer() {
  let currentYear: number = new Date().getFullYear();
  return (
    <div className="sia-footer">
      Copyright &copy; SIA Wealth Management Inc. {currentYear}, All Rights Reserved
    </div>
  );
}

export default Footer;
