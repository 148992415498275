import React, { useState } from "react";
import "../styles/LoginForm.css";
import { inputHelper } from "../../../utils";
import { useLoginUserMutation } from "../../../Apis/authApi";
import { ApiResponseModel } from "../../../types";
import { AuthUser } from "../types";
import notify from "../../../utils/toastNotify";

import { useNavigate } from "react-router-dom";
import { MiniLoader } from "../../../Components/Shared";

/**
 * This components manages the Login State of the user
 * Will capture the user's inputs to the Login Form and attempt to
 * authenticate the user. If the user is successfully authenticated, they will be
 * re-directed to the main application.
 * @returns
 */

function LoginForm() {
  //console.log(process.env.REACT_APP_API_URL);
  const navigate = useNavigate();

  // Loading used for when a user logs in
  const [loading, setLoading] = useState(false);
  // Logs in the user by checking credentials
  const [loginUser] = useLoginUserMutation();

  // Username and Password Inputs

  const [userInput, setUserInput] = useState({
    userName: "",
    password: "",
  });

  // Input Event Handlers
  const handleUserInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const tempData = inputHelper(e, userInput);
    setUserInput(tempData);
  };

  // Submission of the login form
  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const loginResponse: ApiResponseModel = await loginUser(userInput);
    if (!loginResponse.data?.isSuccess && loginResponse.data?.errorMessages) {
      // on error of login
      notify(loginResponse.data?.errorMessages[0], "error");
    } else if (loginResponse.data?.isSuccess) {
      // Navigate to the home page
      navigate("/");
    }

    setLoading(false);
  };

  return (
    <div className="login-card">
      <div className="login-card-header-cont">
        <p className="login-card-header">Welcome</p>
      </div>
      <form className="login-form" onSubmit={handleFormSubmit}>
        <div className="login-form-input-container">
          <input
            type="text"
            placeholder="Enter Username..."
            required
            name="userName"
            value={userInput.userName}
            onChange={handleUserInput}
          />
        </div>
        <div className="login-form-input-container">
          <input
            type="password"
            placeholder="Enter Password..."
            required
            name="password"
            value={userInput.password}
            onChange={handleUserInput}
          />
        </div>
        <div className="login-form-input-container">
          {loading ? <MiniLoader /> : <button>{"Log in"}</button>}
        </div>
        <div className="login-form-input-container">
          <p className="no-account-link">Don't have an account?</p>
        </div>
      </form>
    </div>
  );
}

export default LoginForm;

// import { skipToken } from "@reduxjs/toolkit/dist/query";
// import { setLoggedInUser } from "../../../Storage/Redux/userAuthSlice";
// import { useGetAuthorizedUserQuery } from "../../../Apis/userApi";
