import { numberWithCommas } from "utils";
import { IPSSectionComponentProps } from "../types";

const IPSReviewDetails = ({ formData }: IPSSectionComponentProps) => {
  return (
    <div className="ips-section-container">
      <div className="ips-section-header-container">
        <p className="ips-section-header">Review Details</p>
      </div>
      <div className="ips-section-body-container">
        <div className="review-details-section">
          <p className="label">Client Summary</p>
          <p className="value">{formData.clientSummary}</p>
        </div>
        <div className="review-details-section">
          <p className="label">Time Horizion</p>
          <p className="value">{formData.timeHorizion} Years</p>
        </div>
        <div className="review-details-section">
          <p className="label">Income Required From Investments?</p>
          <p className="value">{formData.incomeRequirements ? "Yes" : "No"}</p>
        </div>
        <div className="review-details-section">
          <p className="label">Maximum % of Single Commerical Paper</p>
          <p className="value">{formData.maxCommericialPaperPercentage}%</p>
        </div>
        <div className="review-details-section">
          <p className="label">Maximum % of Single Security</p>
          <p className="value">{formData.maxSecurityIssuerPercentage}%</p>
        </div>
        <div className="review-details-section">
          <p className="label">Legal and Regulatory Requirements</p>
          <p className="value">{formData.legalRegulatoryConsiderations}</p>
        </div>
        <div className="review-details-section">
          <p className="label">Unique Circumstances</p>
          <p className="value">{formData.uniqueCircumstances}</p>
        </div>
        <div className="review-details-section section-row">
          <div>
            <p className="label">Cash or Equivalent Objectives</p>
            <p className="value">
              Upper Limit: {formData.cashAndEquivalentsObjectives.upperLimit}%
            </p>
            <p className="value">
              Lower Limit: {formData.cashAndEquivalentsObjectives.lowerLimit}%
            </p>
            <p className="value">Target: {formData.cashAndEquivalentsObjectives.target}%</p>
          </div>
          <div>
            <p className="label">Fixed Income Objectives</p>
            <p className="value">Upper Limit: {formData.fixedIncomeObjectives.upperLimit}%</p>
            <p className="value">Lower Limit: {formData.fixedIncomeObjectives.lowerLimit}%</p>
            <p className="value">Target: {formData.fixedIncomeObjectives.target}%</p>
          </div>
          <div>
            <p className="label">Equity Objectives</p>
            <p className="value">Upper Limit: {formData.equityObjectives.upperLimit}%</p>
            <p className="value">Lower Limit: {formData.equityObjectives.lowerLimit}%</p>
            <p className="value">Target: {formData.equityObjectives.target}%</p>
          </div>
          <div>
            <p className="label">Alternate Objectives</p>
            <p className="value">
              Upper Limit: {formData.alternativeInvestmentsObjectives.upperLimit}%
            </p>
            <p className="value">
              Lower Limit: {formData.alternativeInvestmentsObjectives.lowerLimit}%
            </p>
            <p className="value">Target: {formData.alternativeInvestmentsObjectives.target}%</p>
          </div>
        </div>
        <div className="review-details-section">
          <p className="label">Account Fee Descriptions</p>
          <div className="account-descriptions-review">
            {formData.accountFeeDescriptions.map((fee, index) => (
              <div key={index} className="account-description-section">
                <p className="value">
                  {fee.accountDescription} - {fee.accountNumber}
                </p>
                <p className="value">{fee.clientName}</p>
                <p className="value">
                  Approximate Value: ${numberWithCommas(fee.approximateValue)}
                </p>
                <p className="value">Management Fee: {fee.managementFee}%</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IPSReviewDetails;
