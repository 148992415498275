export const ContributionFrequencyMapping: Record<string | number, string> = {
  "1": "Weekly",
  "2": "Bi-Weekly",
  "3": "Monthly",
  "4": "Bi-Monthly",
  "5": "Quarterly",
  "6": "Semi-Annually",
  "7": "Annually",
  "8": "Other",
};
