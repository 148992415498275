export const InvestmentAccountTypeMapping: Record<string | number, string> = {
  "1": "Cash",
  "2": "In Trust For",
  "3": "Cash Leveraged",
  "4": "Margin",
  "5": "Tax-Free Savings Account",
  "6": "Registered Education Savings Plan",
  "7": "Registered Savings Account",
  "8": "Registered Income Fund",
};
