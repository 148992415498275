import { useEffect, useState } from "react";
import withClientRiskCode from "../../../HOC/withClientRiskCode";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";
import { ApiResponseModel, RiskToleranceFormData, RiskToleranceWizardStep } from "types";
import { GuidValues } from "../../../constants/enums";
import riskSections from "../../../assets/StaticData/RiskToleranceSections";
import RiskWizardSections from "./riskWizardSections";
import SpecialNotesSection from "./specialNotesSection";
import RiskToleranceWizard from "./riskToleranceWizard";
import ClientIntroRiskStep from "./clientIntroRiskStep";
import { notify, sanitizeString } from "../../../utils";
import { useUseMagicLinkInfoMutation } from "../../../Apis/riskToleranceApi";

import { useCreateRiskToleranceResponseMutation } from "../../../Apis/mainApi";

import ClientCompletedWizard from "./clientCompletedWizard";
import { useSendForSignatureMutation } from "../../../Apis/riskToleranceApi";
import { useParams } from "react-router-dom";

const steps: RiskToleranceWizardStep[] = [
  {
    component: ClientIntroRiskStep,
  },
  {
    component: RiskWizardSections,
    startIndex: 0,
    endIndex: 3,
  },
  {
    component: RiskWizardSections,
    startIndex: 3,
    endIndex: 6,
  },
  {
    component: RiskWizardSections,
    startIndex: 6,
    endIndex: 7,
  },
  {
    component: RiskWizardSections,
    startIndex: 7,
    endIndex: 8,
  },
  {
    component: RiskWizardSections,
    startIndex: 8,
    endIndex: 12,
  },
  {
    component: RiskWizardSections,
    startIndex: 12,
    endIndex: 15,
  },
  {
    component: SpecialNotesSection,
  },
];

/**
 * This Page is a Client-Facing Page that allows Anonymous users to create their own risk tolerance responses
 * Prior to loading this, it used the withClientRiskCode Higher Order Component to check the magic link code
 * as well as populate the data
 * @returns
 */
function ClientCreateRiskTolerance() {
  const [currentRiskScore, setCurrentRiskScore] = useState(0);
  const [submitRisk] = useCreateRiskToleranceResponseMutation();
  const [submitMagicLink] = useUseMagicLinkInfoMutation(); // Used to set the link to used
  const [sendOneSpanForm] = useSendForSignatureMutation();
  const [isCompleted, setIsCompleted] = useState(false);
  const { code } = useParams();

  // This state is populated from the previous
  const riskToleranceData = useSelector((state: RootState) => state.newClientRiskStore);

  const [formData, setFormData] = useState<RiskToleranceFormData>({
    foClientId: riskToleranceData.clientId ? riskToleranceData.clientId : GuidValues.EMPTY_GUID,
    responseId: undefined,
    // All of the risk form answers
    riskScoreSections: riskSections.map((section) => {
      return {
        sectionId: section.id,
        answerScore: 0,
      };
    }),
    specialNotes: "",
  });

  // If there is risk tolerance data, then we load into the form
  useEffect(() => {
    if (riskToleranceData.clientId && riskToleranceData.responseId) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        foClientId: riskToleranceData.clientId,
        responseId: riskToleranceData.responseId,
      }));
    }

    if (riskToleranceData.riskScoreSections.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        riskScoreSections: riskToleranceData.riskScoreSections,
      }));
    }
  }, [riskToleranceData]);

  // Anytime an input changes or something on the step changes, pass this in
  const handleStepChange = async (data: Partial<RiskToleranceFormData>): Promise<void> => {
    setFormData((prevFormData) => ({ ...prevFormData, ...data }));

    if (data.riskScoreSections) {
      // If we are setting the risk tolerance score
      var tempScore = 0;
      data.riskScoreSections.map((answer) => {
        tempScore += answer.answerScore;
      });

      setCurrentRiskScore(tempScore);
    }
  };

  // The goal here is to update the database everytime the Form Data object is being updated
  useEffect(() => {
    const updateRiskForm = async () => {
      if (formData.foClientId != GuidValues.EMPTY_GUID && formData.responseId != undefined) {
        // Submit the Risk Response
        const riskSubmissionResponse: ApiResponseModel = await submitRisk(formData);

        // Change the Response ID in state to the current response ID if it is empty
        if (
          formData.responseId == GuidValues.EMPTY_GUID &&
          riskSubmissionResponse.data?.isSuccess
        ) {
          handleStepChange({
            responseId: riskSubmissionResponse.data?.result.id,
          });
        }

        if (riskSubmissionResponse.error) {
          console.log("error");
          console.log(riskSubmissionResponse.error);
        }
      }
    };

    updateRiskForm();
  }, [formData]);

  /**
   * Form Submission Handler
   */
  const handleRiskFormSubmit = async () => {
    // 1. See if any form submission values are 0
    const formAnswers = formData.riskScoreSections;
    const isZeroAnswers = formAnswers.filter((answer) => answer.answerScore == 0);

    if (isZeroAnswers.length > 0) {
      notify("Please Answer All Risk Tolerance Questions", "error");
      return;
    }
    // 2. Sanitize the Special Notes section text area
    formData.specialNotes = sanitizeString(formData.specialNotes);
    // 2.5 Set the status to 2
    // formData.status = SD_DocumentStatus.UNSIGNED;
    // 3. Submit it to the API
    const riskSubmissionResponse: ApiResponseModel = await submitRisk(formData);

    if (riskSubmissionResponse.error) {
      notify("An error has occured", "error");
    }
    if (riskSubmissionResponse.data && riskSubmissionResponse.data.isSuccess) {
      notify("Successfully Submitted Risk Response", "success");
      setIsCompleted(true);

      // Send the Email With a Signature Request
      const sendFormResult: ApiResponseModel = await sendOneSpanForm(formData.responseId);

      if (sendFormResult.error) {
        console.log("error");
        return;
      }

      // Set the Flag to Used for the Magic Link
      const setUseLinkResult: ApiResponseModel = await submitMagicLink(code);
      if (setUseLinkResult.error) {
        return;
      }
    }
  };

  return (
    <div className="main-container">
      <h1 className="main-container-header">Client Risk Tolerance Questionaire</h1>
      <RiskToleranceWizard
        steps={steps}
        formData={formData}
        setFormData={setFormData}
        handleStepChange={handleStepChange}
        currentRiskScore={currentRiskScore}
        handleRiskFormSubmit={handleRiskFormSubmit}
        isCompleted={isCompleted}
        CompletedComponent={ClientCompletedWizard}
        showRiskScore={false}
      />
    </div>
  );
}

export default withClientRiskCode(ClientCreateRiskTolerance);
