export enum ParticipantRole {
  ACCOUNT_HOLDER = "56B8BBCE-4D6C-4AF8-BF3D-40974C11AF8D",
  BENEFICIARY = "6F3EE629-152A-45C9-81E4-1728E624BF69",
  DIRECTOR = "8fabf529-f2a3-4d27-a386-621e9e77a8ae",
  THIRD_PARTY = "15293291-F7C8-4C03-801B-7CFD595238AA",
  TRUSTEE = "ceb8a815-1fe9-4bd3-ac5c-9d849e5b4cd8",
  POWER_OF_ATTORNEY = "59bfb474-617e-4ec6-b0c7-79d3f40b5495",
  IN_TRUST_FOR_BENEFICIARY = "7c5eb5fd-64bc-4cc6-b5c2-2993a5913876",
  RESP_BENEFICIARY = "587b86a3-76f2-4e8e-b2ba-915d8b9e6ae5",
  SPOUSE_CONTRIBUTER = "0C41E07D-BC9F-49C3-8E5E-32C7F9A2C054",
}
