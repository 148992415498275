import { SelectItem } from "../../../types/Shared";
import { BankInstitution } from "../../../constants/enums";

const DefaultBankInstitutions: SelectItem[] = [
  {
    label: "Bank of Montreal",
    value: BankInstitution.BMO,
  },
  {
    label: "Scotia Bank",
    value: BankInstitution.SCOTIA_BANK,
  },
  {
    label: "Royal Bank of Canada",
    value: BankInstitution.RBC,
  },
  {
    label: "Toronto Dominion",
    value: BankInstitution.TD,
  },
  {
    label: "National Bank",
    value: BankInstitution.NATIONAL_BANK,
  },
  {
    label: "Canadian Imperial Bank of Commerce",
    value: BankInstitution.CIBC,
  },
  {
    label: "HSBC Canada",
    value: BankInstitution.HSBC,
  },
  {
    label: "Canadian Western Bank",
    value: BankInstitution.CANADIAN_WESTERN_BANK,
  },
  {
    label: "Laurentian Bank",
    value: BankInstitution.LAURENTIAN_BANK,
  },
  {
    label: "Manulife Bank",
    value: BankInstitution.MANULIFE_BANK,
  },
  {
    label: "ATB Financial",
    value: BankInstitution.ATB_FINANCIAL,
  },
  {
    label: "Alberta Credit Union",
    value: BankInstitution.ALBERTA_CREDIT_UNION,
  },
  {
    label: "Other",
    value: BankInstitution.OTHER,
  },
];

export default DefaultBankInstitutions;
