import ClientNetWorthTable from "./clientNetWorthTable";
import { ClientDetail } from "features/client";
import { ClientDetailsProps } from "features/client/types";

function ClientNetWorthDetails({ selectedClientData }: ClientDetailsProps) {
  const liquidAssets = selectedClientData.netWorthDetails?.liquidAssets;
  const fixedAssets = selectedClientData.netWorthDetails?.fixedAssets;
  const liabilities = selectedClientData.netWorthDetails?.liabilities;

  return (
    <div className="client-details display-center" id="client-net-worth-details">
      {liquidAssets && <ClientNetWorthTable tableName="Liquid Assets" assets={liquidAssets} />}
      {fixedAssets && <ClientNetWorthTable tableName="Fixed Assets" assets={fixedAssets} />}
      {liabilities && <ClientNetWorthTable tableName="Liabilities" assets={liabilities} />}
    </div>
  );
}

export default ClientNetWorthDetails;
