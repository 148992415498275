import { useState } from "react";
import CreateClientSummarySectionProps from "./CreateClientSummarySectionProps";
import ClientDetail from "../../../../../features/client/components/clientDetail";
import maskString from "../../../../../utils/maskString";

const CreateFullClientSummarySpouseDetails = ({ client }: CreateClientSummarySectionProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const maxHeight = "400px";

  return (
    <div className="create-client-summary-section" style={showMore ? { height: maxHeight } : {}}>
      <div className="create-client-summary-section-header" onClick={() => setShowMore(!showMore)}>
        <p>Spouse Details</p>
        <button onClick={() => setShowMore(!showMore)}>
          {showMore ? (
            <i className="bi bi-caret-up-fill"></i>
          ) : (
            <i className="bi bi-caret-down-fill"></i>
          )}
        </button>
      </div>
      <div className="create-client-summary-section-header-underline"></div>
      <ClientDetail label="Salutation" value={client?.spouseDetails?.salutation} />
      <ClientDetail label="First Name" value={client?.spouseDetails?.firstName} />
      <ClientDetail label="Middle Initial" value={client?.spouseDetails?.middleInitial} />
      <ClientDetail label="Last Name" value={client?.spouseDetails?.lastName} />
      <ClientDetail label="Employer Name" value={client?.spouseDetails?.employerName} />
      <ClientDetail label="Occupation" value={client?.spouseDetails?.occupation} />
      <ClientDetail label="Type of Business" value={client?.spouseDetails?.typeOfBusiness} />
      <ClientDetail
        label="Social Insurance Number"
        value={maskString(client?.spouseDetails?.sin, 0, 7)}
      />
      <ClientDetail label="Gender" value={client?.spouseDetails?.gender} />
    </div>
  );
};

export default CreateFullClientSummarySpouseDetails;
