import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

/// This API uses the UserController found in the .NET API
const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api/user`,
    credentials: "include",
  }),
  tagTypes: ["UserAuth"],
  endpoints: (builder) => ({
    // This endpoint is only authorized by user
    getAuthorizedUser: builder.query({
      query: () => ({
        url: `/authorize`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAuthorizedUserQuery, useLazyGetAuthorizedUserQuery } = userApi;
export default userApi;
