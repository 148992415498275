import React, { useEffect, useState } from "react";
import { InvestmentAccountDetailsProps } from "./CreateInvestmentAccountGeneralDetails";
import { EditClientSelect } from "Components/Pages/CreateFullClient/Helpers/Inputs";
import { HouseholdState } from "stores/Redux/householdSlice";
import { useSelector } from "react-redux";
import { RootState } from "stores/store";
import { ParticipantRole } from "constants/enums";
import { SelectItem } from "types/Shared";
import { CreateInvestmentAccountDto } from "types/InvestmentAccount";
import { Participant } from "types";
import { checkEmptyValue, notify } from "utils";
import AddExistingParticipantWithRoleToAccount from "./AddExistingParticipantWithRoleToAccount";
import AddNewParticipantWithRoleForm from "./AddNewParticipantWithRoleForm";

/**
 * For In Trust For Acounts, we need to esablish who the beneficiary and trustee are.
 *
 * Based on the description from one stakeholder, you can only have one and one (trustee + beneficiary)
 * @param param0
 * @returns
 */
function CreateInvestmentAccountITFDetails({
  account,
  participant,
  handleGoNext,
  setNewInvestmentAccount,
}: InvestmentAccountDetailsProps) {
  const [isAddingBeneficiary, setIsAddingBeneficiary] = useState(false);
  const [isAddingTrustee, setIsAddingTrustee] = useState(false);

  const handleCancel = () => {
    setIsAddingBeneficiary(false);
    setIsAddingTrustee(false);
  };

  return (
    <div className="edit-client-wizard-item" id="edit-invest-account-itf-details">
      <div className="edit-client-wizard-item-header">
        <p>In Trust For Account Details</p>
      </div>
      <p>Here you will need to assign the Trustee and the Beneficiary of the Account</p>
      <div className="edit-client-wizard-item-body">
        <div className="itf-beneficiary-container">
          {account.itfBeneficiaries && account.itfBeneficiaries.length > 0 && (
            <div
              className="itf-beneficiary-card"
              style={{ border: "1px solid purple", padding: "1rem" }}
            >
              <p>Trust Beneficiaries</p>
              <p>Beneficiary ID: {account.itfBeneficiaries[0]}</p>
            </div>
          )}
        </div>
        <div className="itf-trustee-container">
          {account.trustees && account.trustees.length > 0 && (
            <div
              className="itf-beneficiary-card"
              style={{ border: "1px solid purple", padding: "1rem" }}
            >
              <p>Trust Trustee</p>
              <p>Trustee ID: {account.trustees[0]}</p>
            </div>
          )}
        </div>
        {!isAddingTrustee && account?.itfBeneficiaries && account?.itfBeneficiaries.length < 1 && (
          <div className="add-beneficiary-btn-container" style={{ marginBottom: "1rem" }}>
            {!isAddingBeneficiary && (
              <button className="btn btn-success" onClick={() => setIsAddingBeneficiary(true)}>
                Add Trust Beneficiary
              </button>
            )}
          </div>
        )}
        {!isAddingBeneficiary && account?.trustees && account?.trustees.length < 1 && (
          <div className="add-trustee-btn-container">
            {!isAddingTrustee && (
              <button className="btn btn-success" onClick={() => setIsAddingTrustee(true)}>
                Add Trustee
              </button>
            )}
          </div>
        )}
        {!isAddingBeneficiary ||
          (!isAddingTrustee && (
            <div className="add-trustee-btn-container">
              <button className="btn btn-danger" onClick={() => handleCancel()}>
                Cancel
              </button>
            </div>
          ))}
        <div className="add-beneficiary-form-container">
          {isAddingBeneficiary && (
            <AddITFBeneficiaryForm
              account={account}
              setAccount={setNewInvestmentAccount}
              participant={participant}
              setIsAddingParticipant={setIsAddingBeneficiary}
            />
          )}
        </div>
        <div className="add-trustee-form-container">
          {isAddingTrustee && (
            <AddTrusteeForm
              account={account}
              setAccount={setNewInvestmentAccount}
              participant={participant}
              setIsAddingParticipant={setIsAddingTrustee}
            />
          )}
        </div>
        {handleGoNext && (
          <button className="btn-nice-purple btn-save-client-details" onClick={handleGoNext}>
            Continue
          </button>
        )}
      </div>
    </div>
  );
}

interface AddParticipantToAccountFormProps {
  account: CreateInvestmentAccountDto;
  setAccount: React.Dispatch<React.SetStateAction<CreateInvestmentAccountDto>>;
  participant: Participant | undefined;
  setIsAddingParticipant: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Form that adds a trustee to the account
 * In order for a trustee to be added, the participant must be a Trustee
 */
const AddTrusteeForm = ({
  account,
  setAccount,
  participant,
  setIsAddingParticipant,
}: AddParticipantToAccountFormProps) => {
  const handleParticipantAdd = (participantId: string) => {
    const tempAccount = { ...account };
    if (tempAccount.trustees?.includes(participantId)) {
      notify("This Trustee is already added", "error");
      return;
    }

    if (tempAccount.trustees) {
      tempAccount.trustees.push(participantId);
    } else {
      tempAccount.trustees = [participantId];
    }

    setAccount(tempAccount);
    setIsAddingParticipant(false);
  };

  return (
    <div>
      <AddExistingParticipantWithRoleToAccount
        participant={participant!}
        roleLabel="ITF Trustee"
        role={ParticipantRole.TRUSTEE}
        account={account}
        setAccount={setAccount}
        handleParticipantAdd={handleParticipantAdd}
        setIsAddingParticipant={setIsAddingParticipant}
        requiredSINAndDOB={true}
      />
      {participant && (
        <AddNewParticipantWithRoleForm
          role={ParticipantRole.TRUSTEE}
          householdId={participant.householdId}
          createFormLabel="ITF Trustee"
          handleParticipantAdd={handleParticipantAdd}
        />
      )}
    </div>
  );
};

/**
 * Adds a beneficiary to the account
 * @param param0
 * @returns
 */
const AddITFBeneficiaryForm = ({
  account,
  setAccount,
  participant,
  setIsAddingParticipant,
}: AddParticipantToAccountFormProps) => {
  const handleParticipantAdd = (participantId: string) => {
    const tempAccount = { ...account };
    if (tempAccount.itfBeneficiaries?.includes(participantId)) {
      notify("This Beneficiary is already added", "error");
      return;
    }

    if (tempAccount.itfBeneficiaries) {
      tempAccount.itfBeneficiaries.push(participantId);
    } else {
      tempAccount.itfBeneficiaries = [participantId];
    }

    setAccount(tempAccount);
    setIsAddingParticipant(false);
  };

  return (
    <div>
      <AddExistingParticipantWithRoleToAccount
        participant={participant!}
        roleLabel="ITF Beneficiary"
        role={ParticipantRole.BENEFICIARY}
        account={account}
        setAccount={setAccount}
        handleParticipantAdd={handleParticipantAdd}
        setIsAddingParticipant={setIsAddingParticipant}
        requiredSINAndDOB={true}
      />
      {participant && (
        <AddNewParticipantWithRoleForm
          role={ParticipantRole.BENEFICIARY}
          householdId={participant.householdId}
          createFormLabel="ITF Beneficiary"
          handleParticipantAdd={handleParticipantAdd}
        />
      )}
    </div>
  );
  // const [itfBeneficiariesSelect, setSetITFBeneficiariesSelect] = useState<SelectItem[]>([]);
  // const [beneficiary, setBeneficiary] = useState("");
  // const advisorHouseholds: HouseholdState = useSelector((state: RootState) => state.householdStore);
  // useEffect(() => {
  //   if (advisorHouseholds && advisorHouseholds.households.length > 0 && participant) {
  //     // Find the participant's household
  //     const participantHousehold = advisorHouseholds.households.find(
  //       (household) => household.id == participant.householdId
  //     );

  //     // If the participant household exists
  //     // find the possible ITF Beneficiaries in the household
  //     if (participantHousehold) {
  //       const beneficiaries = participantHousehold.newClients.filter((participant) =>
  //         participant.roles.includes(ParticipantRole.BENEFICIARY.toLowerCase())
  //       );
  //       if (beneficiaries.length > 0) {
  //         const selectItems: SelectItem[] = beneficiaries.map((beneficiary) => {
  //           return {
  //             label: `${beneficiary.generalDetails.firstName} ${beneficiary.generalDetails.lastName}`,
  //             value: beneficiary.id,
  //           };
  //         });
  //         setSetITFBeneficiariesSelect(selectItems);
  //       }
  //     }
  //   }
  // }, [advisorHouseholds]);

  // const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   if (checkEmptyValue(beneficiary)) {
  //     notify("Please select a beneficiary", "error");
  //     return;
  //   }

  //   const tempAccount = { ...account };
  //   if (tempAccount.itfBeneficiaries && !tempAccount.itfBeneficiaries.includes(beneficiary)) {
  //     setAccount({
  //       ...tempAccount,
  //       itfBeneficiaries: [...tempAccount.itfBeneficiaries, beneficiary],
  //     });
  //   } else if (!tempAccount.itfBeneficiaries) {
  //     setAccount({ ...tempAccount, itfBeneficiaries: [beneficiary] });
  //   } else {
  //     notify("Beneficiary already added", "error");
  //     return;
  //   }

  //   setBeneficiary("");
  //   setIsAddingParticipant(false);
  // };

  return (
    <div></div>
    // <form onSubmit={handleSubmit}>
    //   <EditClientSelect
    //     label="Select Existing Beneficiary"
    //     value={beneficiary}
    //     selectItems={itfBeneficiariesSelect}
    //     handleChange={(val) => setBeneficiary(val.toString())}
    //   />
    //   <button
    //     className="btn btn-danger"
    //     onClick={() => setIsAddingParticipant(false)}
    //     style={{ margin: "1rem" }}
    //   >
    //     Cancel
    //   </button>
    //   <button className="btn btn-success" style={{ margin: "1rem" }}>
    //     Add Beneficiary
    //   </button>
    // </form>
  );
};

export default CreateInvestmentAccountITFDetails;
