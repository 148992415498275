import { Participant } from "../../../types";
import { BankAccountDetails } from "../../../Components/Pages/CreateInvestmentAccount/BankAccount";

interface AccountDetailsProps {
  selectedClientData: Participant | undefined;
  setIsCreatingAccount: React.Dispatch<React.SetStateAction<boolean>>;
  handleStepChange: (target: string) => void;
}

/**
 * Bank Account Details for the Investment Account Application
 * @param param0
 * @returns
 */
const AccountDetails = ({
  selectedClientData,
  setIsCreatingAccount,
  handleStepChange,
}: AccountDetailsProps) => {
  const createInvestmentAccountHandler = () => {
    setIsCreatingAccount(true);
    handleStepChange("edit-invest-account-general-details");
  };

  const bankAccounts = selectedClientData?.bankAccounts;

  return (
    <div>
      <div className="edit-client-wizard-item active" id="edit-account-details">
        <BankAccountDetails selectedClientData={selectedClientData} />
        {/* If the selected client has no bank account details, we need to do that first */}
        {!bankAccounts || (bankAccounts && bankAccounts.length == 0) ? (
          <div style={{ marginTop: "1rem" }}>
            In Order to open an Investment Account, please add bank account information
          </div>
        ) : (
          <>
            <div className="edit-client-wizard-item-header" style={{ marginTop: "1rem" }}>
              <p>Investment Account Details</p>
            </div>
            <div>
              <button className="btn-nice-purple" onClick={createInvestmentAccountHandler}>
                Add Account
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AccountDetails;
