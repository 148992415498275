import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Participant } from "types";

let counter = 0;
function TestForm() {
  const { register, handleSubmit } = useForm();
  const [data, setData] = useState<Participant>({} as Participant);

  const onSubmit = (newData: any) => {
    setData(newData);
  };

  const renderCounter = useRef(0);
  renderCounter.current = renderCounter.current + 1;

  return (
    <div>
      <h1 style={{ fontSize: "2rem" }}>Testing React Hook Form</h1>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <label htmlFor="firstName">
          First Name <input {...register("generalDetails.firstName")} />
        </label>
        <label htmlFor="firstName">
          Last Name <input {...register("generalDetails.lastName")} />
        </label>
        <label htmlFor="firstName">
          SIN <input {...register("generalDetails.sin")} />
        </label>
        <select {...register("spouseDetails.gender")}>
          <option value="female">female</option>
          <option value="male">male</option>
          <option value="other">other</option>
        </select>

        <button>Submit</button>
        {/* {data.generalDetails?.firstName} */}
        <div>Renders: {renderCounter.current}</div>
      </form>
    </div>
  );
}

export default TestForm;
