import React from "react";
import RegulatoryDataProps from "./RegulatoryDataProps";
import EditClientBoolean from "../Inputs/EditClientBoolean";
import EditClientInput from "../Inputs/EditClientInput";
import EditClientSelect from "../Inputs/EditClientSelect";

function IsAssociateHIODetails({
  selectedClientData,
  handleRegulatoryDetailChange,
  handleDataChange,
}: RegulatoryDataProps) {
  const handleAssociateHIOChange = (
    val: string | number | boolean,
    attrName: string | undefined
  ) => {
    if (selectedClientData && attrName) {
      let tempData = { ...selectedClientData.regulatoryDetails };
      let associateHIODetails = { ...tempData.associateHIODetails! };

      if (!associateHIODetails) {
        associateHIODetails = {
          nameOfAssociate: "",
          jobTitle: "",
          country: "",
          nameOfOrganization: "",
        };
      }

      associateHIODetails[attrName] = val.toString();
      tempData.associateHIODetails = associateHIODetails;
      handleDataChange({ regulatoryDetails: tempData });
    }
  };

  return (
    <>
      <EditClientBoolean
        label="Is Associate of HIO?"
        value={selectedClientData?.regulatoryDetails?.isAssociateHIO}
        inputName="isAssociateHIO"
        attrName="isAssociateHIO"
        handleChange={handleRegulatoryDetailChange}
      />
      {
        // If the client is a foreign PEP, then show the details
        selectedClientData?.regulatoryDetails?.isAssociateHIO && (
          <div className="regulatory-list-item">
            <EditClientInput
              label="Name of Associate"
              value={
                selectedClientData?.regulatoryDetails?.associateHIODetails
                  ?.nameOfAssociate
              }
              attrName="nameOfAssociate"
              handleChange={handleAssociateHIOChange}
            />
            <EditClientInput
              label="Job Title"
              value={
                selectedClientData?.regulatoryDetails?.associateHIODetails
                  ?.jobTitle
              }
              attrName="jobTitle"
              handleChange={handleAssociateHIOChange}
            />
            <EditClientInput
              label="Name of Organization"
              value={
                selectedClientData?.regulatoryDetails?.associateHIODetails
                  ?.nameOfOrganization
              }
              attrName="nameOfOrganization"
              handleChange={handleAssociateHIOChange}
            />
            <EditClientSelect
              label="Country"
              value={
                selectedClientData?.regulatoryDetails?.associateHIODetails
                  ?.country
              }
              attrName="country"
              isCountry={true}
              handleChange={handleAssociateHIOChange}
            />
          </div>
        )
      }
    </>
  );
}

export default IsAssociateHIODetails;
