import { useState } from "react";
import { useCreateBankAccountMutation } from "../../../../Apis/mainApi";
import { isValidUSZip, notify, validatePostalCode } from "../../../../utils";
import { ApiResponseModel } from "../../../../types";
import { BankAccount } from "../../../../types/Participant/Participant";

import { EditClientBoolean, EditClientInput, EditClientSelect } from "../Helpers/Inputs";

import { ValidateBankAccountDetails } from "../Validators";

import {
  DefaultBankInstitutionNumbers,
  DefaultBankInstitutions,
  DefaultInstitutionMapping,
} from "../../../../assets/StaticData/BankAccount";

interface CreateNewBankAccountProps {
  clientId: string | undefined;
  setShowNewBankAccountForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const emptyBankAccount: BankAccount = {
  institutionName: "",
  accountName: "",
  address: "",
  city: "",
  region: "",
  country: "",
  postalCode: "",
  isPrimaryAccount: false,
  transitNumber: "",
  institutionNumber: "",
  accountNumber: "",
  currency: 0,
};

/**
 * Creating a New Bank Account
 * that's to be used for the Investment Accounts
 * @param param0
 * @returns
 */
const CreateNewBankAccount = ({
  clientId,
  setShowNewBankAccountForm,
}: CreateNewBankAccountProps) => {
  // POST method to create a new bank account
  const [createBankAccount] = useCreateBankAccountMutation();

  // State that holds the bank account details in the form
  const [bankAccountDetails, setBankAccountDetails] = useState<BankAccount>(emptyBankAccount);

  /**
   * Handles the change of the bank account details
   */
  const handleBankAccountChange = (
    e: string | number | boolean | undefined,
    attrName: string | undefined,
    check?: boolean
  ) => {
    let value: any = "";
    if (check !== undefined) {
      value = check;
    } else {
      value = e;
    }

    if (attrName) {
      const tempData = { ...bankAccountDetails };

      // Handle the Institution Number to automatically populate
      if (attrName === "institutionName") {
        tempData["institutionNumber"] = DefaultBankInstitutionNumbers[value];
      }
      tempData[attrName] = value;

      setBankAccountDetails(tempData);
    }
  };

  /**
   * Handles the submit of the form
   */
  const handleSubmit = async () => {
    // Validate the Form
    if (!ValidateBankAccountDetails(bankAccountDetails)) {
      return;
    }

    // Need to assign the clientId of the bank account it belongs to
    bankAccountDetails["clientId"] = clientId;
    // Mapping the value of the institution name to the value that's in the database
    bankAccountDetails["institutionName"] =
      DefaultInstitutionMapping[bankAccountDetails["institutionName"]];

    // Create the Bank Account
    const result: ApiResponseModel = await createBankAccount(bankAccountDetails);

    // Check if the result is successful
    if (result.data?.isSuccess) {
      notify("Bank Account Created Successfully", "success");
      setBankAccountDetails(emptyBankAccount);
      setShowNewBankAccountForm(false);
    } else if (result.error) {
      notify("Error Creating Bank Account", "error");
    }
  };

  return (
    <div className="create-new-bank-account-container">
      <div className="create-new-bank-account-container-header">Create New Bank Account</div>
      <div className="create-new-bank-account-container-body">
        <EditClientInput
          label="Account Name"
          value={bankAccountDetails.accountName}
          attrName="accountName"
          handleChange={handleBankAccountChange}
        />
        <EditClientSelect
          label="Institution Name"
          value={bankAccountDetails.institutionName}
          selectItems={DefaultBankInstitutions}
          attrName="institutionName"
          handleChange={handleBankAccountChange}
        />

        <EditClientInput
          label="Address"
          value={bankAccountDetails.address}
          attrName="address"
          handleChange={handleBankAccountChange}
        />
        <EditClientSelect
          label="Country"
          value={bankAccountDetails.country}
          attrName="country"
          handleChange={handleBankAccountChange}
          isCountry={true}
        />
        <EditClientSelect
          label="Region"
          value={bankAccountDetails.region}
          attrName="region"
          handleChange={handleBankAccountChange}
          isRegion={true}
          country={bankAccountDetails.country}
        />
        {(bankAccountDetails.country === "Canada" ||
          bankAccountDetails.country === "United States") && (
          <EditClientInput
            label={bankAccountDetails.country === "Canada" ? "Postal Code" : "Zip Code"}
            value={bankAccountDetails.postalCode}
            attrName="postalCode"
            handleChange={handleBankAccountChange}
            validateValue={
              bankAccountDetails.country === "Canada" ? validatePostalCode : isValidUSZip
            }
          />
        )}

        <EditClientInput
          label="City"
          value={bankAccountDetails.city}
          attrName="city"
          handleChange={handleBankAccountChange}
        />

        <EditClientBoolean
          label="Is Primary Account?"
          value={bankAccountDetails.isPrimaryAccount}
          attrName="isPrimaryAccount"
          handleChange={handleBankAccountChange}
        />
        <EditClientInput
          label="Transit Number"
          value={bankAccountDetails.transitNumber}
          attrName="transitNumber"
          handleChange={handleBankAccountChange}
        />
        <EditClientInput
          label="Institution Number"
          value={bankAccountDetails.institutionNumber}
          attrName="institutionNumber"
          handleChange={handleBankAccountChange}
        />
        <EditClientInput
          label="Account Number"
          value={bankAccountDetails.accountNumber}
          attrName="accountNumber"
          handleChange={handleBankAccountChange}
        />
        <div className="btn-actions-container">
          <button className="btn btn-danger" onClick={() => setShowNewBankAccountForm(false)}>
            Cancel
          </button>
          <button className="btn btn-success" onClick={handleSubmit}>
            Add Account
          </button>
        </div>

        {/* // todo, handle the submit of the form */}
      </div>
    </div>
  );
};

export default CreateNewBankAccount;
