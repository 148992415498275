import { AuthUser } from "features/auth/types";
import "./styles/SIANavBar.scss";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "stores/store";

/**
 * This will be the Navigation Bar for the SIA Application.
 * We will have some options that are available for all users,
 * and some that are only available for certain users that have
 * a specific role. Such roles include admin, manager, and advisor.
 *
 * We need to check the authority here.
 */
const SIANavBar = () => {
  const navigate = useNavigate();
  // User Data if the user is logged in
  const userData: AuthUser = useSelector((state: RootState) => state.userAuthStore);

  // If user is not logged in, then we don't show the header
  if (!userData.user.id) {
    return <></>;
  }

  const handleNavItemClick = (e: React.MouseEvent<HTMLLIElement>, route: string) => {
    // Handle the Active Status First
    var allNavItems = $(".sia-navbar-item");
    allNavItems.removeClass("active");
    $(e.currentTarget).addClass("active");

    // Handle the Route
    navigate(route);
  };

  return (
    <div className="sia-navbar">
      <ul className="sia-navbar-list">
        <li className="sia-navbar-item active" onClick={(e) => handleNavItemClick(e, "/")}>
          <p>Households</p> <i className="bi bi-house-door-fill"></i>
        </li>
        <li className="sia-navbar-item " onClick={(e) => handleNavItemClick(e, "/")}>
          {" "}
          <p>Participants</p> <i className="bi bi-people-fill"></i>
        </li>
      </ul>
    </div>
  );
};

export default SIANavBar;
