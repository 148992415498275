import { SelectItem } from "types/Shared";

export const transferTypeOptions: SelectItem[] = [
  {
    label: "Cash",
    value: 1,
  },
  {
    label: "Kind",
    value: 2,
  },
];
