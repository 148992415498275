import React from "react";
import { ClientDetailsProps } from "features/client/types";
import ClientDetail from "./clientDetail";

function ClientSpouseDetails({ selectedClientData }: ClientDetailsProps) {
  return (
    <div className="client-details" id="client-spouse-details">
      {selectedClientData.spouseDetails ? (
        <>
          <ClientDetail
            label="Spouse Name"
            value={`${selectedClientData.spouseDetails.firstName} ${selectedClientData.spouseDetails.middleInitial} ${selectedClientData.spouseDetails.lastName} `}
          />
          <ClientDetail
            label="Employer Name"
            value={`${selectedClientData.spouseDetails.employerName} `}
          />
          <ClientDetail
            label="Occupation"
            value={`${selectedClientData.spouseDetails.occupation} `}
          />
          <ClientDetail
            label="Type of Business"
            value={`${selectedClientData.spouseDetails.typeOfBusiness} `}
          />
          <ClientDetail // Note here, we should put the masking functionality into the Client Detail Component
            label="Social Insurance Number"
            value={selectedClientData.spouseDetails.sin}
            isSecure={true}
          />
          <ClientDetail label="Gender" value={`${selectedClientData.spouseDetails.gender} `} />
        </>
      ) : (
        <>
          <p>Spousal Information Not Found. Please Complete KYC.</p>
        </>
      )}
    </div>
  );
}

export default ClientSpouseDetails;
