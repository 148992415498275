import React from "react";
import { RiskToleranceFormData } from "../../../types";
import riskSections from "../../../assets/StaticData/RiskToleranceSections";
import RiskWizardSection from "./riskWizardSection";
import "../styles/riskWizardSections.scss";

interface RiskWizardGroupProps {
  propData: RiskToleranceFormData;
  onChange: (data: Partial<RiskToleranceFormData>) => void;
  startIndex?: number;
  endIndex?: number;
}

// const startIndex: number = 0;
// const endIndex: number = 3;
/**
 * This component will focus on the main risktolerance questions
 * @param param0
 * @returns
 */
const RiskWizardSections = ({ propData, onChange, startIndex, endIndex }: RiskWizardGroupProps) => {
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>, sectionId: number) => {
    const riskToleranceAnswers = propData.riskScoreSections;
    const answerScore: number = Number(e.target.value);
    // Update the value to the correct value
    const updatedAnswers = riskToleranceAnswers.map((answer) => {
      if (answer.sectionId == sectionId) {
        return { ...answer, answerScore };
      }
      return answer;
    });

    onChange({ riskScoreSections: updatedAnswers });
  };

  const sections = riskSections.slice(startIndex, endIndex);

  return (
    <div className="risk-group-container">
      {sections.map((section) => (
        <RiskWizardSection
          section={section}
          key={section.id}
          onChange={handleSelectChange}
          formData={propData}
        />
      ))}
    </div>
  );
};

export default RiskWizardSections;
