import { useState } from "react";
import { IPSSectionComponentProps } from "../types";
import { notify, numberWithCommas } from "utils";
import { CreateAccountFeeDescriptionDto } from "../types/CreateIndividualPolicyStatementDto";
import { Tooltip } from "react-tooltip";

/**
 * Allows users to view, add and remove account fee descriptions from the IPS
 * @param param0
 * @returns
 */
const IPSAccountFeeDescriptions = ({ formData, setFormData }: IPSSectionComponentProps) => {
  const [addingDescription, setAddingDescription] = useState(false);

  return (
    <div className="ips-section-container">
      <div className="ips-section-header-container">
        <p className="ips-section-header">Account Fee Descriptions</p>
        <IPSNewAccountFeeDescriptionDetails formData={formData} setFormData={setFormData} />
        {addingDescription ? (
          <button className="btn btn-danger" onClick={() => setAddingDescription(false)}>
            Cancel
          </button>
        ) : (
          <button className="btn btn-success" onClick={() => setAddingDescription(true)}>
            Add New Fee Description
          </button>
        )}
      </div>
      <div className="ips-section-body-container">
        {addingDescription && (
          <IPSNewAccountFeeDescriptionForm
            formData={formData}
            setFormData={setFormData}
            setAddingDescription={setAddingDescription}
          />
        )}
      </div>
    </div>
  );
};

const IPSNewAccountFeeDescriptionDetails = ({
  formData,
  setFormData,
}: IPSSectionComponentProps) => {
  const handleRemove = (index: number) => {
    if (window.confirm("Are you sure you want to remove this detail?")) {
      const temp = { ...formData };
      temp.accountFeeDescriptions.splice(index, 1);
      setFormData(temp);
    }
  };

  // display all fee descriptions
  return (
    <div className="account-fee-descriptions">
      {formData.accountFeeDescriptions.length == 0 && <p>No Account Fee Descriptions Found</p>}
      {formData.accountFeeDescriptions.map((description, index) => {
        return (
          <div className="account-fee-description" key={index}>
            <div className="account-fee-description-header-container">
              <p className="account-fee-description-header">
                {description.accountDescription} - {description.accountNumber}
              </p>
              <i className="bi bi-x-circle-fill" onClick={() => handleRemove(index)}></i>
            </div>
            <p className="account-fee-description-body">
              Client Name: {description.clientName}
              <br />
              Approximate Value: ${numberWithCommas(description.approximateValue)}
              <br />
              Management Fee: {description.managementFee}%
            </p>
          </div>
        );
      })}
    </div>
  );
};

const emptyAccountDescription: CreateAccountFeeDescriptionDto = {
  accountNumber: "",
  clientName: "",
  accountDescription: "",
  approximateValue: 0,
  managementFee: 0,
};

interface FormProps extends IPSSectionComponentProps {
  setAddingDescription: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Allows User to Create a New Account Fee Description
 * @param param0
 * @returns
 */
const IPSNewAccountFeeDescriptionForm = ({
  formData,
  setFormData,
  setAddingDescription,
}: FormProps) => {
  const [newFeeDescription, setNewFeeDescription] =
    useState<CreateAccountFeeDescriptionDto>(emptyAccountDescription);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateNewAccountDescription(newFeeDescription)) {
      // add new fee description to list
      const temp = { ...formData };
      temp.accountFeeDescriptions.push(newFeeDescription);
      setFormData(temp);
      // clear form
      setNewFeeDescription(emptyAccountDescription);
      // close form
      setAddingDescription(false);
    }
  };

  return (
    <form className="account-fee-description-form" onSubmit={handleFormSubmit}>
      <Tooltip id="form-tooltip" />
      <p className="form-header">Create Account Description</p>
      <div className="input-control">
        <label>
          FCC Account Number
          <span style={{ marginLeft: "0.5rem" }}>
            <a
              data-tooltip-id="form-tooltip"
              data-tooltip-content="The Account Number Provided By Fidelity"
            >
              <i className="bi bi-info-circle-fill"></i>
            </a>
          </span>
        </label>
        <input
          value={newFeeDescription.accountNumber}
          onChange={(e) =>
            setNewFeeDescription({ ...newFeeDescription, accountNumber: e.target.value })
          }
        ></input>
      </div>
      <div className="input-control">
        <label>
          Client Name
          <span style={{ marginLeft: "0.5rem" }}>
            <a
              data-tooltip-id="form-tooltip"
              data-tooltip-content="The Client Name Associated With The Account"
            >
              <i className="bi bi-info-circle-fill"></i>
            </a>
          </span>
        </label>

        <input
          value={newFeeDescription.clientName}
          onChange={(e) =>
            setNewFeeDescription({ ...newFeeDescription, clientName: e.target.value })
          }
        ></input>
      </div>
      <div className="input-control">
        <label>
          Account Description
          <span style={{ marginLeft: "0.5rem" }}>
            <a
              data-tooltip-id="form-tooltip"
              data-tooltip-content="The type of Account that is associated with the Account Number"
            >
              <i className="bi bi-info-circle-fill"></i>
            </a>
          </span>
        </label>
        <input
          value={newFeeDescription.accountDescription}
          onChange={(e) =>
            setNewFeeDescription({ ...newFeeDescription, accountDescription: e.target.value })
          }
        ></input>
      </div>
      <div className="input-control">
        <label>
          Approximate Value
          <span style={{ marginLeft: "0.5rem" }}>
            <a
              data-tooltip-id="form-tooltip"
              data-tooltip-content="The approximate market value of the account"
            >
              <i className="bi bi-info-circle-fill"></i>
            </a>
          </span>
        </label>
        $
        <input
          value={newFeeDescription.approximateValue}
          onChange={(e) =>
            setNewFeeDescription({ ...newFeeDescription, approximateValue: Number(e.target.value) })
          }
          type="number"
        ></input>
      </div>
      <div className="input-control">
        <label>
          Management Fee
          <span style={{ marginLeft: "0.5rem" }}>
            <a
              data-tooltip-id="form-tooltip"
              data-tooltip-content="The percentage of the account value that is charged as a management fee"
            >
              <i className="bi bi-info-circle-fill"></i>
            </a>
          </span>
        </label>
        <input
          value={newFeeDescription.managementFee}
          onChange={(e) =>
            setNewFeeDescription({ ...newFeeDescription, managementFee: Number(e.target.value) })
          }
          type="number"
        ></input>
      </div>
      <div className="submit-btn-container">
        <button type="submit" className="btn btn-success">
          Add Account Description
        </button>
      </div>
    </form>
  );
};

const validateNewAccountDescription = (formData: CreateAccountFeeDescriptionDto) => {
  if (formData.accountNumber.length == 0) {
    notify("Please provide a valid account number", "error");
    return false;
  } else if (formData.clientName.length == 0) {
    notify("Please provide a valid client name", "error");
    return false;
  } else if (formData.accountDescription.length == 0) {
    notify("Please provide a valid account description", "error");
    return false;
  } else if (formData.approximateValue <= 0) {
    notify("Please provide a valid approximate value", "error");
    return false;
  } else if (formData.managementFee <= 0 || formData.managementFee > 100) {
    notify("Please provide a valid management fee", "error");
    return false;
  }

  return true;
};

export default IPSAccountFeeDescriptions;
