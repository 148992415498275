import { CreateInvestmentAccountDto } from "../types";

export const resetCreateAccountType = (createAccountDto: CreateInvestmentAccountDto) => {
  createAccountDto.subType = 0; // reset subtype
  createAccountDto.isJointAccount = undefined;
  // We need to ensure we reset the account holders as well by removing everything but the first entry
  if (createAccountDto.accountHolders) {
    createAccountDto.accountHolders = [createAccountDto.accountHolders[0]];
  }

  // We need to ensure we reset the beneficiaries as well by removing everything but the first entry
  createAccountDto.allowBeneficiarys = undefined;
  if (createAccountDto.beneficiaries) {
    createAccountDto.beneficiaries = [];
  }

  // Reset the other beneficiaries as well
  createAccountDto.respBeneficiaries = [];
  createAccountDto.itfBeneficiaries = [];
  createAccountDto.trustees = [];
  createAccountDto.spouseContributers = [];
};
