import { TransferDetail } from "features/createInvestmentAccount/types/CreateInvestmentAccountDto";

export const EmptyTransferDetail: TransferDetail = {
  deliveringInstitution: "",
  institutionCountry: "",
  institutionAddress: "",
  institutionBusinessPhone: "",
  institutionBusinessPhoneExt: "",
  institutionContact: "",
  institutionDealerNumber: "",
  instructions: [],
};
