export const SourceOfFundsMappingById: Record<string | number, string> = {
  "1": "Retirement Income",
  "2": "Insurance Settlement",
  "3": "Sale Of Property",
  "4": "Savings",
  "5": "Gift / Donation",
  "6": "Investment Earnings",
  "7": "Inheritence",
  "8": "Other",
};
