import { useState } from "react";
import { Participant } from "../../../../types";
import maskString from "../../../../utils/maskString";
import { CreateNewBankAccount } from "../../CreateFullClient/Steps";

interface BankAccountDetailsProps {
  selectedClientData: Participant | undefined;
}

const BankAccountDetails = ({ selectedClientData }: BankAccountDetailsProps) => {
  const [showNewBankAccountForm, setShowNewBankAccountForm] = useState<boolean>(false); // Used to toggle the new bank account form

  return (
    <>
      <div className="edit-client-wizard-item-header">
        <p>Bank Account Details</p>
      </div>
      <div className="edit-client-wizard-item-body">
        {selectedClientData?.bankAccounts.length === 0 ? (
          <div>No Accounts Found </div>
        ) : (
          // Here should be a component that loads in the bank account details
          // TODO: Clean this code up tomorrow, add functionality for Investment Account Creation
          <div className="bank-account-details-list">
            {selectedClientData?.bankAccounts.map((account, index) => {
              return (
                <div className="bank-account-detail-item" key={index}>
                  <div className="bank-account-detail-item-header">
                    <p className="bank-account-detail-item-account-name">
                      {account.accountName} (
                      {maskString(account.accountNumber, 0, account.accountNumber.length - 3)})
                      {account.isPrimaryAccount && (
                        <i className="fav-account-icon bi bi-star-fill"></i>
                      )}
                    </p>
                    <p className="bank-account-detail-item-account-institution">
                      {account.institutionName}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {!showNewBankAccountForm && (
          <div>
            <button className="btn-nice-purple" onClick={() => setShowNewBankAccountForm(true)}>
              Add Account
            </button>
          </div>
        )}
        {
          // If the user wants to create a new bank account, they get this form
          showNewBankAccountForm && (
            <CreateNewBankAccount
              clientId={selectedClientData?.id}
              setShowNewBankAccountForm={setShowNewBankAccountForm}
            />
          )
        }
      </div>
    </>
  );
};

export default BankAccountDetails;
