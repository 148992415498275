import { NewParticipantWithDOBDto } from "types/Participant";
import { checkEmptyValue, isValidSIN, notify, validateEmail, validatePhone } from "utils";

const validateNewParticipant = (participantDetails: NewParticipantWithDOBDto) => {
  if (checkEmptyValue(participantDetails.firstName)) {
    notify("Please enter a first name for the new participant", "error");
    return false;
  }
  if (checkEmptyValue(participantDetails.lastName)) {
    notify("Please enter a last name for the new participant", "error");
    return false;
  }
  if (!checkEmptyValue(participantDetails.email) && !validateEmail(participantDetails.email)) {
    notify("Please enter a valid email for the new participant", "error");
    return false;
  }
  if (
    !checkEmptyValue(participantDetails.phoneNumber) &&
    !validatePhone(participantDetails.phoneNumber)
  ) {
    notify("Please enter a valid phone number for the new participant", "error");
    return false;
  }
  if (checkEmptyValue(participantDetails.sin)) {
    notify("Please enter a SIN for the new participant", "error");
    return false;
  }
  if (!isValidSIN(participantDetails.sin)) {
    notify("Please enter a valid SIN for the new participant", "error");
    return false;
  }
  if (checkEmptyValue(participantDetails.dateOfBirth)) {
    notify("Please enter a date of birth for the new participant", "error");
    return false;
  }

  return true;
};

export default validateNewParticipant;
