import { useState } from "react";
import CreateClientSummarySectionProps from "./CreateClientSummarySectionProps";
import ClientDetail from "../../../../../features/client/components/clientDetail";
import { AddressTypeMappingById } from "../../../../../constants/mappings";

const CreateFullClientSummaryAddressDetails = ({ client }: CreateClientSummarySectionProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const maxHeight = "450px";

  return (
    <div className="create-client-summary-section" style={showMore ? { height: maxHeight } : {}}>
      <div className="create-client-summary-section-header" onClick={() => setShowMore(!showMore)}>
        <p>Address Details</p>
        <button onClick={() => setShowMore(!showMore)}>
          {showMore ? (
            <i className="bi bi-caret-up-fill"></i>
          ) : (
            <i className="bi bi-caret-down-fill"></i>
          )}
        </button>
      </div>
      <div className="create-client-summary-section-header-underline"></div>
      <div className="multiple-sections-summary">
        <div>
          <p className="create-client-summary-section-subheader">Permanent Address Details</p>
          <ClientDetail
            label="Address Type"
            value={
              client?.permanentAddressDetails?.addressType
                ? AddressTypeMappingById[client?.permanentAddressDetails?.addressType]
                : ""
            }
          />
          <ClientDetail
            label="Address Number"
            value={client?.permanentAddressDetails?.aptUnitSuiteNumber}
          />
          <ClientDetail label="Street Name" value={client?.permanentAddressDetails?.streetName} />
          <ClientDetail label="City" value={client?.permanentAddressDetails?.city} />
          <ClientDetail label="Region" value={client?.permanentAddressDetails?.region} />
          <ClientDetail label="Country" value={client?.permanentAddressDetails?.country} />
          <ClientDetail label="Postal Code" value={client?.permanentAddressDetails?.postalCode} />
        </div>
        {
          // If the mailing address is different, then show the mailing address
          !client?.permanentAddressDetails?.isSameAsMailingAddress && (
            <div>
              <p className="create-client-summary-section-subheader">Mailing Address Details</p>
              <ClientDetail
                label="Address Type"
                value={
                  client?.permanentAddressDetails?.addressType
                    ? AddressTypeMappingById[client?.permanentAddressDetails?.addressType]
                    : ""
                }
              />
              <ClientDetail
                label="Address Number"
                value={client?.mailingAddressDetails?.aptUnitSuiteNumber}
              />
              <ClientDetail label="Street Name" value={client?.mailingAddressDetails?.streetName} />
              <ClientDetail label="City" value={client?.mailingAddressDetails?.city} />
              <ClientDetail label="Region" value={client?.mailingAddressDetails?.region} />
              <ClientDetail label="Country" value={client?.mailingAddressDetails?.country} />
              <ClientDetail label="Postal Code" value={client?.mailingAddressDetails?.postalCode} />
            </div>
          )
        }
        {
          // If the mailing address is different, then show the mailing address
          client?.permanentAddressDetails?.hasThirdPartyAddress && (
            <div>
              <p className="create-client-summary-section-subheader">Third Party Address Details</p>
              <ClientDetail
                label="Address Type"
                value={
                  client?.thirdPartyAddressDetails?.addressType
                    ? AddressTypeMappingById[client?.thirdPartyAddressDetails?.addressType]
                    : ""
                }
              />
              <ClientDetail
                label="Address Number"
                value={client?.thirdPartyAddressDetails?.aptUnitSuiteNumber}
              />
              <ClientDetail
                label="Street Name"
                value={client?.thirdPartyAddressDetails?.streetName}
              />
              <ClientDetail label="City" value={client?.thirdPartyAddressDetails?.city} />
              <ClientDetail label="Region" value={client?.thirdPartyAddressDetails?.region} />
              <ClientDetail label="Country" value={client?.thirdPartyAddressDetails?.country} />
              <ClientDetail
                label="Postal Code"
                value={client?.thirdPartyAddressDetails?.postalCode}
              />
            </div>
          )
        }
      </div>
    </div>
  );
};

export default CreateFullClientSummaryAddressDetails;
