function validatePhone(phone: string | number | undefined) {
  if (phone && typeof phone == "string") {
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    var cleanedEmail = phone?.toString();
    return re.test(cleanedEmail);
  }

  return false;
}

export default validatePhone;
