import React from "react";
import RegulatoryDataProps from "./RegulatoryDataProps";
import EditClientBoolean from "../Inputs/EditClientBoolean";
import EditClientInput from "../Inputs/EditClientInput";

function IsAssociateDomesticPEP({
  selectedClientData,
  handleRegulatoryDetailChange,
  handleDataChange,
}: RegulatoryDataProps) {
  const handleAssociateDomesticPEPChange = (
    val: string | number | boolean,
    attrName: string | undefined
  ) => {
    if (selectedClientData && attrName) {
      let tempData = { ...selectedClientData.regulatoryDetails };
      let domesticAssociatePEPDetails = {
        ...tempData.domesticAssociatePEPDetails!,
      };

      if (!domesticAssociatePEPDetails) {
        domesticAssociatePEPDetails = {
          associateName: "",
          organizationName: "",
          jobTitle: "",
          relationship: "",
          positionHeld: "",
        };
      }

      domesticAssociatePEPDetails[attrName] = val.toString();
      tempData.domesticAssociatePEPDetails = domesticAssociatePEPDetails;
      handleDataChange({ regulatoryDetails: tempData });
    }
  };
  return (
    <>
      <EditClientBoolean
        label="Is Associate of Domestic PEP Member?"
        value={selectedClientData?.regulatoryDetails?.isAssociateDomesticPEP}
        inputName="isAssociateDomesticPEP"
        attrName="isAssociateDomesticPEP"
        handleChange={handleRegulatoryDetailChange}
      />
      {selectedClientData?.regulatoryDetails?.isAssociateDomesticPEP && (
        <div className="regulatory-list-item">
          <EditClientInput
            label="Associate Name"
            value={
              selectedClientData?.regulatoryDetails?.domesticAssociatePEPDetails
                ?.associateName
            }
            attrName="associateName"
            handleChange={handleAssociateDomesticPEPChange}
          />
          <EditClientInput
            label="Organization Name"
            value={
              selectedClientData?.regulatoryDetails?.domesticAssociatePEPDetails
                ?.organizationName
            }
            attrName="organizationName"
            handleChange={handleAssociateDomesticPEPChange}
          />
          <EditClientInput
            label="Job Title"
            value={
              selectedClientData?.regulatoryDetails?.domesticAssociatePEPDetails
                ?.jobTitle
            }
            attrName="jobTitle"
            handleChange={handleAssociateDomesticPEPChange}
          />
          <EditClientInput
            label="Relationship"
            value={
              selectedClientData?.regulatoryDetails?.domesticAssociatePEPDetails
                ?.relationship
            }
            attrName="relationship"
            handleChange={handleAssociateDomesticPEPChange}
          />
          <EditClientInput
            label="Posoiton Held"
            value={
              selectedClientData?.regulatoryDetails?.domesticAssociatePEPDetails
                ?.positionHeld
            }
            attrName="positionHeld"
            handleChange={handleAssociateDomesticPEPChange}
          />
        </div>
      )}
    </>
  );
}

export default IsAssociateDomesticPEP;
