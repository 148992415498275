import { notify } from "../../../../utils";
import validateSIN from "../../../../utils/validateSIN";
import { Participant } from "../../../../types";

/**
 * Function that validates a client's general details
 * @param client
 * @returns
 **/

const ValidateGeneralDetails = (client: Participant) => {
  // Check Details of General Information
  if (client.generalDetails.isIndividual == undefined || null) {
    notify("Please Select an Individual Status", "error");
    return false;
  }

  if (client.generalDetails.title == undefined || null) {
    notify("Please Select a Title", "error");
    return false;
  }
  if (client.generalDetails.firstName == undefined || null) {
    notify("Please Enter a First Name", "error");
    return false;
  }
  if (client.generalDetails.lastName == undefined || null) {
    notify("Please Enter a Last Name", "error");
    return false;
  }
  // SIN is required and needs to be validated
  if (client.generalDetails.sin == undefined || null) {
    notify("Please Enter a Social Insurance Number", "error");
    return false;
  } else if (validateSIN(client.generalDetails.sin) == false) {
    notify("Please Enter a Valid Social Insurance Number", "error");
    return false;
  }
  // Check that the date of birth is not null
  if (
    client.generalDetails.dateOfBirth == undefined ||
    null ||
    client.generalDetails.dateOfBirth.length == 0
  ) {
    notify("Please Enter a Date of Birth", "error");
    return false;
  }

  if (client.generalDetails.numDependents == undefined || null) {
    notify("Please Enter a Number of Dependents", "error");
    return false;
  }
  // Check Citizenship
  if (client.generalDetails.citizenship == (undefined || null || 0)) {
    notify("Please Select a Citizenship", "error");
    return false;
  }

  return true;
};

export default ValidateGeneralDetails;
