import "./styles/SpinningLoader.scss";
const SpinningLoader = () => {
  return (
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default SpinningLoader;
