import { useEffect, useState } from "react";
import { HouseholdState } from "stores/Redux/householdSlice";
import { useSelector } from "react-redux";
import { RootState } from "stores/store";
import { InvestmentAccountDetailsProps } from "./CreateInvestmentAccountGeneralDetails";
import { Participant } from "types";
import { checkEmptyValue, notify } from "utils";
import "../styles/beneficiaryDetails.scss";
import { ParticipantRole } from "constants/enums";
import { CreateInvestmentAccountDto } from "types/InvestmentAccount";
import { CreateBeneficiaryDto } from "../types/CreateInvestmentAccountDto";
import { AddParticipantToAccountProps } from "../types";
import AddNewParticipantWithRoleForm from "./AddNewParticipantWithRoleForm";
import AddExistingParticipantWithRoleToAccount from "./AddExistingParticipantWithRoleToAccount";

/**
 * Component for the Beneficiary Details of the Investment Account
 * Parent component that uses child components as helpers to fill out the details
 *
 * @todo, this component is messy, need to clean it up
 * @returns
 */

function CreateInvestmentAccountBeneficiaryDetails({
  participant,
  account,
  setNewInvestmentAccount,
  handleGoNext,
}: InvestmentAccountDetailsProps) {
  const [existingClients, setExistingClients] = useState<Participant[]>([]);
  const advisorHouseholds: HouseholdState = useSelector((state: RootState) => state.householdStore);

  const [isAddingBeneficiary, setIsAddingBeneficiary] = useState(false); // Lets us add a Regular Beneficiary
  let totalBeneficiaryAllocation = account.beneficiaries?.reduce((acc, beneficiary) => {
    return Math.round((acc + beneficiary.allocation) * 100) / 100;
  }, 0);

  /**
   * Function to handle the removal of a beneficiary from the array
   * */
  const handleBeneficiaryRemove = (index: number) => {
    // Create a copy on the new investment account
    const tempData = { ...account };
    // If there is nothing there,then there is nothing to delete
    if (!tempData.beneficiaries || tempData.beneficiaries.length == 0) {
      return;
    }
    // Remove the specified index from the array
    tempData.beneficiaries.splice(index, 1);

    setNewInvestmentAccount({
      ...account,
      beneficiaries: tempData.beneficiaries,
    });
  };

  // Setting Existing Beneficiaries
  useEffect(() => {
    if (advisorHouseholds && participant) {
      SetExistingHouseholdParticipants(advisorHouseholds, participant, setExistingClients);
    }
  }, [advisorHouseholds, participant]);

  return (
    <div className="edit-client-wizard-item" id="edit-invest-account-beneficiary-details">
      <div className="edit-client-wizard-item-header">
        <p>Beneficiary Details</p>
      </div>
      <div className="edit-client-wizard-item-body">
        <BeneficiaryDetails
          account={account}
          existingParticipants={existingClients}
          handleBeneficiaryRemove={handleBeneficiaryRemove}
          setInvestmentAccount={setNewInvestmentAccount}
        />
        {!isAddingBeneficiary ? (
          <div>
            <button className="btn btn-success" onClick={() => setIsAddingBeneficiary(true)}>
              Add Beneficiary
            </button>
          </div>
        ) : (
          <div>
            <button className="btn btn-danger" onClick={() => setIsAddingBeneficiary(false)}>
              Cancel
            </button>
          </div>
        )}
        {isAddingBeneficiary && (
          <AddBeneficiary
            account={account}
            setAccount={setNewInvestmentAccount}
            participant={participant}
            setIsAddingParticipant={setIsAddingBeneficiary}
            totalBeneficiaryAllocation={totalBeneficiaryAllocation}
          />
        )}

        {handleGoNext && (
          <button className="btn-nice-purple btn-save-client-details" onClick={handleGoNext}>
            Continue
          </button>
        )}
      </div>
    </div>
  );
}

/**
 * Component that has the Add Existing Beneficiary and Add New Beneficiary Form
 */
interface AddBeneficiaryProps extends AddParticipantToAccountProps {
  totalBeneficiaryAllocation: number | undefined;
}

const AddBeneficiary = ({
  participant,
  account,
  setAccount,
  setIsAddingParticipant,
}: AddBeneficiaryProps) => {
  // Here, there is an interesting rule about adding spouses as beneficiaries
  const handleBeneficiaryAdd = (participantId: string) => {
    if (checkEmptyValue(participantId)) {
      notify("Please enter valid beneficiary", "error");
    }

    // Check if the beneficiary is a spouse
    if (
      participant &&
      participant.spouseDetails &&
      participant.spouseDetails?.participantId &&
      participant.spouseDetails?.participantId == participantId
    ) {
      if (
        !window.confirm(
          "Are you sure you want to add the participant's spouse as a beneficiary? Check with Primary Advisor of the Participant"
        )
      ) {
        return;
      }
    }

    const tempData = { ...account };
    // If there is nothing there, create an empty array with the participant id
    if (!tempData.beneficiaries) {
      tempData.beneficiaries = [{ participantId: participantId, allocation: 100 }];
    } else if (
      tempData.beneficiaries &&
      tempData.beneficiaries.find((beneficiary) => beneficiary.participantId == participantId)
    ) {
      notify("Beneficiary already added", "error");

      return;
    } else {
      // find the current allocation of the beneficiaries
      const splitAllocation = 100 / (tempData.beneficiaries.length + 1);

      tempData.beneficiaries = [
        ...tempData.beneficiaries,
        { participantId: participantId, allocation: 0 },
      ];

      // loop through the beneficiaries and set the allocation to the split allocation
      tempData.beneficiaries.forEach((beneficiary) => {
        beneficiary.allocation = splitAllocation;
      });
    }

    setAccount({ ...account, beneficiaries: tempData.beneficiaries });
    setIsAddingParticipant(false);
  };

  return (
    <div>
      <AddExistingParticipantWithRoleToAccount
        participant={participant!}
        roleLabel="Regular Beneficiary"
        role={ParticipantRole.BENEFICIARY}
        account={account}
        setAccount={setAccount}
        handleParticipantAdd={handleBeneficiaryAdd}
        setIsAddingParticipant={setIsAddingParticipant}
      />
      <AddNewParticipantWithRoleForm
        createFormLabel="Regular Beneficiary"
        role={ParticipantRole.BENEFICIARY}
        householdId={participant!.householdId!}
        handleParticipantAdd={handleBeneficiaryAdd}
      />
    </div>
  );
};

/**
 * Component for the Beneficiary Details of the Investment Account, displays in a list
 * who the beneficiare for the account are.
 */
interface BeneficiaryDetailsProps {
  account: CreateInvestmentAccountDto;
  existingParticipants: Participant[];
  handleBeneficiaryRemove: (index: number) => void;
  setInvestmentAccount: React.Dispatch<React.SetStateAction<CreateInvestmentAccountDto>>;
}

const BeneficiaryDetails = ({
  account,
  existingParticipants: existingClients,
  handleBeneficiaryRemove,
  setInvestmentAccount,
}: BeneficiaryDetailsProps) => {
  const totalAllocation = account.beneficiaries?.reduce((acc, beneficiary) => {
    return Math.round((acc + beneficiary.allocation) * 100) / 100;
  }, 0);

  return (
    <div className="account-beneficiary-list-container">
      <p className="account-beneficiary-list-container-header">
        Total Beneficiary Allocation: {totalAllocation}%
      </p>
      {account.beneficiaries && account.beneficiaries.length > 0 ? (
        <>
          {account.beneficiaries.map((beneficiary, index) => {
            // Function to find more infomation about the client from the existing client
            const participant = existingClients.find(
              (client) => client.id == beneficiary.participantId
            );
            if (participant) {
              return (
                <BeneficiaryDetail
                  participant={participant}
                  beneficiaryDetail={beneficiary}
                  index={index}
                  handleBeneficiaryRemove={handleBeneficiaryRemove}
                  setInvestmentAccount={setInvestmentAccount}
                  account={account}
                />
              );
            }
          })}
        </>
      ) : (
        <p>
          There are no beneficiaries for this account. Please Add an Existing Client or Create a New
          One
        </p>
      )}
    </div>
  );
};

/**
 * Helper component for the Details of the Beneficiary
 */
interface BeneficiaryDetailProps {
  participant: Participant;
  beneficiaryDetail: CreateBeneficiaryDto;
  index: number;
  handleBeneficiaryRemove: (index: number) => void;
  account: CreateInvestmentAccountDto;
  setInvestmentAccount: React.Dispatch<React.SetStateAction<CreateInvestmentAccountDto>>;
}

const BeneficiaryDetail = ({
  participant,
  beneficiaryDetail,
  index,
  handleBeneficiaryRemove,
  account,
  setInvestmentAccount,
}: BeneficiaryDetailProps) => {
  const handleAllocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const tempData = { ...account };
    const beneficiaries = tempData.beneficiaries;
    if (beneficiaries) {
      // Check to see if the allocation is greater than 100
      const beneficiaryDetail = beneficiaries[index];
      const currentAllocation = beneficiaries.reduce((acc, beneficiary) => {
        return acc + beneficiary.allocation;
      }, 0);
      const allocationWithoutCurrent = currentAllocation - beneficiaryDetail.allocation;
      if (Number(val) + allocationWithoutCurrent > 100) {
        notify("Allocation cannot be greater than 100", "error");
        return;
      }

      beneficiaryDetail.allocation = Number(val);

      setInvestmentAccount({ ...account, beneficiaries: beneficiaries });
    }
  };

  return (
    <div className="beneficiary-detail-card">
      <div className="beneficiary-detail-card-header">
        <p className="beneficiary-detail-card-name">{`${participant.generalDetails.firstName} ${
          participant.generalDetails.lastName
        } (${participant.id.slice(0, 6)})`}</p>
        <div className="delete-benificary-detail" onClick={() => handleBeneficiaryRemove(index)}>
          <i className="bi bi-trash-fill"></i>
        </div>
      </div>
      <div>
        <label style={{ marginRight: "0.5rem" }}>Allocation</label>
        <input
          value={beneficiaryDetail.allocation}
          type="number"
          style={{ padding: "0.25rem 0.5rem" }}
          onChange={handleAllocationChange}
          step="any"
        />
        <label>%</label>
      </div>
    </div>
  );
};

export default CreateInvestmentAccountBeneficiaryDetails;

export function SetExistingHouseholdParticipants(
  advisorHouseholds: HouseholdState,
  participant: Participant,
  setExistingClients: React.Dispatch<React.SetStateAction<Participant[]>>
) {
  const participantHousehold = advisorHouseholds.households.find(
    (household) => household.id == participant?.householdId
  );

  if (
    participantHousehold &&
    participantHousehold.newClients &&
    participantHousehold.newClients.length > 0
  ) {
    // Filter out the clients that aren't this one
    const clients = participantHousehold.newClients;

    if (clients.length > 0) {
      setExistingClients(clients);
    }
  }
}
