import withAuth from "HOC/withAuth";
import "../styles/participant.scss";
import { ReturnIconSvg } from "assets/svg";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetClientByIdQuery } from "Apis/mainApi";
import { SetStateAction, useEffect, useState } from "react";
import { Participant } from "types";
import { EmptyNewParticipant } from "constants/emptyStates";
import { set } from "react-hook-form";
import { MiniLoader } from "Components/Shared";
import { ParticipantRoleMapping } from "constants/mappings/ParticipantRoleMapping";
import { ParticipantDetailsSectionProps, ParticipantProp } from "../types";
import $ from "jquery";
import maskString from "utils/maskString";
import { detailFormatter } from "../utils";
import { numberWithCommas } from "utils";
import ParticipantDetails from "./ParticipantDetails";
import ParticipantWorkflows from "./ParticipantWorkflows";
import ParticipantAccounts from "./ParticipantAccounts";
/**
 * This is the main participant page that will be displayed when a user clicks on a participant
 * from the household participants page or the participants page.
 * @returns
 */

interface ComponentProp {
  component: React.ComponentType<ParticipantDetailsSectionProps>;
}

const pageComponents: ComponentProp[] = [
  {
    component: ParticipantDetails,
  },
  {
    component: ParticipantWorkflows,
  },
  {
    component: ParticipantAccounts,
  },
];

const ParticipantPage = () => {
  // Navigation
  const navigate = useNavigate();
  // Participant Query
  const [participantQuery, participantResult] = useLazyGetClientByIdQuery();
  // Participant State
  const [participant, setParticipant] = useState<Participant>();
  // Pariticipant Id from the parameters
  const { participantId } = useParams();
  // Loading State
  const [loading, setLoading] = useState(false);
  // Error State for the participant query
  const [error, setError] = useState(false);
  // Current Component State
  const [currentComponentStep, setCurrentComponentStep] = useState(0);
  // Get the Current Compontent
  const CurrentComponent = pageComponents[currentComponentStep].component;

  // Use Effect when Participant Id is not null
  useEffect(() => {
    if (participantId) {
      setLoading(true);
      participantQuery(participantId);
    }
  }, [participantId]);

  // Use Effect for the participant result
  useEffect(() => {
    if (participantResult.data && participantResult.isSuccess) {
      setParticipant(participantResult.data.result);
      setLoading(false);
      setError(false);
    } else {
      setError(true);
    }
  }, [participantResult.data]);

  return (
    <div className="main-participant-detail-page-container">
      {participant && <ParticipantPageHeader participant={participant} />}
      {participant && <ParticipantPageTabs setCurrentComponentStep={setCurrentComponentStep} />}
      {participant && <CurrentComponent participant={participant} />}
    </div>
  );
};

/**
 * Component for the participant page header
 * @param param0
 * @returns
 */
const ParticipantPageHeader = ({ participant }: ParticipantProp) => {
  const navigate = useNavigate();
  return (
    <div className="page-header">
      <div className="left-side-header">
        <div className="page-header-rtn-container">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <ReturnIconSvg />
          </button>
        </div>
        {participant && (
          <div className="state-information">
            <p className="page-header-primary-text">
              {participant.generalDetails.firstName} {participant.generalDetails.lastName}
            </p>
            <p className="page-header-secondary-text">{participant.id}</p>
            <p className="page-header-secondary-text">Household: Test Household</p>
          </div>
        )}
      </div>
      <div className="right-side-header">
        {participant && (
          <div className="participant-roles-container">
            <p className="container-title">Roles</p>
            {participant.roles.map((role) => {
              return <p>{ParticipantRoleMapping[role.toLowerCase()]}</p>;
            })}
            <div className="participant-roles-container">
              <button className="btn btn-primary">Add/Edit Role</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

interface ParticipantPageTabsProps {
  setCurrentComponentStep: React.Dispatch<SetStateAction<number>>;
}

const ParticipantPageTabs = ({ setCurrentComponentStep }: ParticipantPageTabsProps) => {
  const handleTabClick = (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
    // Remove the active class from all the tabs using jquery
    $(".participant-page-tab").removeClass("active");
    // Add the active class to the component that was clicked
    $(e.currentTarget).parent().addClass("active");
    // Set the current component index
    setCurrentComponentStep(index);
  };

  return (
    <div className="participant-page-tabs">
      <div className="participant-page-tab active">
        <button onClick={(e) => handleTabClick(e, 0)}>Details</button>
      </div>
      <div className="participant-page-tab">
        <button onClick={(e) => handleTabClick(e, 1)}>Workflows</button>
      </div>
      <div className="participant-page-tab">
        <button onClick={(e) => handleTabClick(e, 2)}>Accounts</button>
      </div>
    </div>
  );
};

// const ParticipantPageContent = ({ participant }: ParticipantProps) => {
//   return <div className="page-content">Participant</div>;
// };

// interface DetailStep {
//   component: React.ComponentType<ParticipantProps>;
// }
// /**
//  * This component will display the participant details including general details, net worth, etc.
//  * @returns
//  */

// const ParticipantDetails = ({ participant }: ParticipantProps) => {
//   const [currentStep, setCurrentStep] = useState(0);
//   const steps: DetailStep[] = [
//     { component: ParticipantGeneralDetails },
//     { component: ParticipantNetWorth },
//     { component: ParticipantRegulatoryDetails },
//   ];

//   const CurrentComponent = steps[currentStep].component;

//   const handleChangeComponent = (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
//     // Remove the active class from all the tabs using jquery
//     $(".participant-page-details-header-tab").removeClass("active");
//     // Add the active class to the component that was clicked
//     $(e.currentTarget).parent().addClass("active");
//     // Set the current component index2
//     setCurrentStep(index);
//   };

//   return (
//     <div className="participant-page-details">
//       <div className="participant-page-details-header">
//         <p className="page-header-primary-text">Participant Details</p>
//         <div className="participant-page-details-header-tabs">
//           <div className="participant-page-details-header-tab active">
//             <button onClick={(e) => handleChangeComponent(e, 0)}>General Details</button>
//           </div>
//           {participant.netWorthDetails && (
//             <div className="participant-page-details-header-tab">
//               <button onClick={(e) => handleChangeComponent(e, 1)}>Net Worth Details</button>
//             </div>
//           )}
//           {participant.regulatoryDetails && (
//             <div className="participant-page-details-header-tab">
//               <button onClick={(e) => handleChangeComponent(e, 2)}>Regulatory Details</button>
//             </div>
//           )}
//         </div>
//         <div className="page-header-action-container">
//           <div>
//             <button className="btn btn-warning">Edit</button>
//           </div>
//         </div>
//       </div>
//       <CurrentComponent participant={participant} />
//     </div>
//   );
// };

// const ParticipantGeneralDetails = ({ participant }: ParticipantProps) => {
//   // Employer Address Information
//   return (
//     <div className="participant-details-container">
//       <div className="details-section">
//         <div className="details-section-header">
//           <p className="details-section-header-text">Basic Information</p>
//         </div>
//         <ParticipantBasicInformation participant={participant} />
//       </div>
//       <div className="vr"></div>
//       {participant.employmentDetails && (
//         <ParticipantEmployeeInformation participant={participant} />
//       )}
//       <div className="vr"></div>
//       {participant.spouseDetails && <ParticipantSpouseInformation participant={participant} />}
//     </div>
//   );
// };

// const ParticipantBasicInformation = ({ participant }: ParticipantProps) => {
//   // General Details
//   const generalDetails = participant.generalDetails;
//   // Permanent Address Information
//   const permAddressDetails = participant.permanentAddressDetails;
//   const fullPermAddress = `${permAddressDetails.aptUnitSuiteNumber} - ${permAddressDetails.streetNumber} ${permAddressDetails.streetName},
//                             ${permAddressDetails.city}, ${permAddressDetails.region}, ${permAddressDetails.postalCode} (${permAddressDetails.addressType})`;

//   // Mailing Address Information
//   const mailAddressDetails = participant.mailingAddressDetails;
//   const fullMailAddress = permAddressDetails.isSameAsMailingAddress
//     ? "Same as Permanent Address"
//     : `${mailAddressDetails.aptUnitSuiteNumber} - ${mailAddressDetails.streetNumber} ${mailAddressDetails.streetName},
//         ${mailAddressDetails.city}, ${mailAddressDetails.region}, ${mailAddressDetails.postalCode} (${mailAddressDetails.addressType})`;

//   // Third Party Address Information
//   const thirdPartyAddressDetails = participant.thirdPartyAddressDetails;
//   const fullThirdPartyAddress = permAddressDetails.hasThirdPartyAddress
//     ? `${thirdPartyAddressDetails.aptUnitSuiteNumber} - ${thirdPartyAddressDetails.streetNumber} ${thirdPartyAddressDetails.streetName},
//   ${thirdPartyAddressDetails.city}, ${thirdPartyAddressDetails.region}, ${thirdPartyAddressDetails.postalCode} (${thirdPartyAddressDetails.addressType})`
//     : "-";
//   return (
//     <div className="details-container">
//       <div className="detail-container">
//         <p className="detail-label">First Name</p>
//         <p className="detail-value">{detailFormatter(generalDetails.firstName)}</p>
//       </div>
//       <div className="detail-container">
//         <p className="detail-label">Last Name</p>
//         <p className="detail-value">{detailFormatter(generalDetails.lastName)}</p>
//       </div>
//       <div className="detail-container">
//         <p className="detail-label">Middle Initial</p>
//         <p className="detail-value">{detailFormatter(generalDetails.middleInitial)}</p>
//       </div>
//       <div className="detail-container">
//         <p className="detail-label">Email</p>
//         <p className="detail-value">{detailFormatter(generalDetails.email)}</p>
//       </div>
//       <div className="detail-container">
//         <p className="detail-label">Phone Number</p>
//         <p className="detail-value">{detailFormatter(generalDetails.cellPhone)}</p>
//       </div>
//       <div className="detail-container">
//         <p className="detail-label">Citizenship</p>
//         <p className="detail-value">{detailFormatter(generalDetails.citizenship)}</p>
//       </div>
//       <div className="detail-container">
//         <p className="detail-label">SIN</p>
//         <p className="detail-value">{maskString(detailFormatter(generalDetails.sin), 0, 8)}</p>
//       </div>
//       <div className="detail-container">
//         <p className="detail-label">Perm. Address</p>
//         <p className="detail-value">{fullPermAddress}</p>
//       </div>
//       <div className="detail-container">
//         <p className="detail-label">Mail Address</p>
//         <p className="detail-value">{fullMailAddress}</p>
//       </div>
//       <div className="detail-container">
//         <p className="detail-label">3rd Party Address</p>
//         <p className="detail-value">{fullThirdPartyAddress}</p>
//       </div>
//     </div>
//   );
// };

// const ParticipantEmployeeInformation = ({ participant }: ParticipantProps) => {
//   // Employee Information
//   const employeeInformation = participant.employmentDetails;
//   const employerAddressDetails = employeeInformation.address;
//   return (
//     <div className="details-section">
//       <div className="details-section-header">
//         <p className="details-section-header-text">Employee Information</p>
//       </div>
//       <div className="details-container">
//         <div className="detail-container">
//           <p className="detail-label">Employment Status</p>
//           <p className="detail-value">{detailFormatter(employeeInformation.status)}</p>
//         </div>
//         <div className="detail-container">
//           <p className="detail-label">Occupation</p>
//           <p className="detail-value">{detailFormatter(employeeInformation.occupation)}</p>
//         </div>
//         <div className="detail-container">
//           <p className="detail-label">Business Name</p>
//           <p className="detail-value">{detailFormatter(employeeInformation.name)}</p>
//         </div>
//         <div className="detail-container">
//           <p className="detail-label">Employment Length</p>
//           <p className="detail-value">{`${detailFormatter(
//             employeeInformation.lengthInYears
//           )} Years`}</p>
//         </div>
//         <div className="detail-container">
//           <p className="detail-label">Employer Address</p>
//           <p className="detail-value">{detailFormatter(employerAddressDetails)}</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// const ParticipantSpouseInformation = ({ participant }: ParticipantProps) => {
//   const spouseDetails = participant.spouseDetails;
//   return (
//     <div className="details-section">
//       <div className="details-section-header">
//         <p className="details-section-header-text">Spouse Information</p>
//       </div>
//       <div className="details-container">
//         <div className="detail-container">
//           <p className="detail-label">First Name</p>
//           <p className="detail-value">{detailFormatter(spouseDetails.firstName)}</p>
//         </div>
//         <div className="detail-container">
//           <p className="detail-label">Last Name</p>
//           <p className="detail-value">{detailFormatter(spouseDetails.lastName)}</p>
//         </div>
//         <div className="detail-container">
//           <p className="detail-label">Employer</p>
//           <p className="detail-value">{detailFormatter(spouseDetails.employerName)}</p>
//         </div>
//         <div className="detail-container">
//           <p className="detail-label">Occupation</p>
//           <p className="detail-value">{detailFormatter(spouseDetails.occupation)}</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// const ParticipantNetWorth = ({ participant }: ParticipantProps) => {
//   return (
//     <div className="participant-details-container">
//       <div className="details-section">
//         <div className="details-section-header">
//           <p className="details-section-header-text">Net Worth Details</p>
//         </div>
//         <div className="details-container">
//           <ParticipantNetWorthTable participant={participant} />
//         </div>
//       </div>
//       <div className="vr"></div>
//       <div className="details-section">
//         <div className="details-section-header">
//           <p className="details-section-header-text">External Investments</p>
//         </div>
//         <div className="details-container">
//           <ParticipantExternalInvestmentsTable participant={participant} />
//         </div>
//       </div>
//     </div>
//   );
// };

// const ParticipantNetWorthTable = ({ participant }: ParticipantProps) => {
//   return (
//     <table className="details-table">
//       <tr>
//         <th>Type</th>
//         <th>Name</th>
//         <th>Amount</th>
//       </tr>
//       {participant.netWorthDetails.liquidAssets.map((asset) => {
//         return (
//           <tr>
//             <td>Liquid Asset</td>
//             <td>{asset.type}</td>
//             <td>${numberWithCommas(asset.amount)}</td>
//           </tr>
//         );
//       })}
//       {participant.netWorthDetails.fixedAssets.map((asset) => {
//         return (
//           <tr>
//             <td>Fixed Asset</td>
//             <td>{asset.type}</td>
//             <td>${numberWithCommas(asset.amount)}</td>
//           </tr>
//         );
//       })}
//       {participant.netWorthDetails.liabilities.map((asset) => {
//         return (
//           <tr>
//             <td>Liability</td>
//             <td>{asset.type}</td>
//             <td>${numberWithCommas(asset.amount)}</td>
//           </tr>
//         );
//       })}
//     </table>
//   );
// };

// const ParticipantExternalInvestmentsTable = ({ participant }: ParticipantProps) => {
//   return (
//     <table className="details-table">
//       <tr>
//         <th>Type</th>
//         <th>Amount</th>
//       </tr>
//       <tr>
//         <td>Equity</td>
//         <td>${numberWithCommas(participant.externalInvestmentDetails.equitiesValue)}</td>
//       </tr>
//       <tr>
//         <td>Fixed Income</td>
//         <td>${numberWithCommas(participant.externalInvestmentDetails.fixedIncomeValue)}</td>
//       </tr>
//       <tr>
//         <td>Cash and Equivalent</td>
//         <td>${numberWithCommas(participant.externalInvestmentDetails.cashEquivalentValue)}</td>
//       </tr>
//       <tr>
//         <td>Real Estate</td>
//         <td>${numberWithCommas(participant.externalInvestmentDetails.realEstateValue)}</td>
//       </tr>
//       <tr>
//         <td>Commodities</td>
//         <td>${numberWithCommas(participant.externalInvestmentDetails.commoditiesValue)}</td>
//       </tr>
//       <tr>
//         <td>Others Value</td>
//         <td>${numberWithCommas(participant.externalInvestmentDetails.otherValue)}</td>
//       </tr>
//       <tr>
//         <td>Annual Income</td>
//         <td>${numberWithCommas(participant.externalInvestmentDetails.annualIncome)}</td>
//       </tr>
//     </table>
//   );
// };

// const ParticipantRegulatoryDetails = ({ participant }: ParticipantProps) => {
//   const regulatoryDetails = participant.regulatoryDetails;
//   console.log(regulatoryDetails);
//   return <div>Regulatory Details</div>;
// };

/**
 * This component will display the participant accounts including investment accounts, bank accounts, etc.
 */
//const ParticipantAccounts = () => {};

/**
 * This component will display the participant workflow including risk tolerance, investment policy statement, etc.
 */
//const ParticipantWorkflow = () => {};

export default withAuth(ParticipantPage);
