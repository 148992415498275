function validateEmail(email: string | number | undefined) {
  if (email && typeof email == "string") {
    var re = /\S+@\S+\.\S+/;
    var cleanedEmail = email?.toString();
    return re.test(cleanedEmail);
  }

  return false;
}

export default validateEmail;
