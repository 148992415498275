import React, { useState } from "react";
import { InvestmentAccountDetailsProps } from "./CreateInvestmentAccountGeneralDetails";
import { checkEmptyValue, notify } from "utils";
import "../styles/eftDetails.scss";
const findBankAccount = (bankAccounts: any, bankAccountId: string) => {
  return bankAccounts.find((bankAccount: any) => bankAccount.id === bankAccountId);
};

/**
 * The purpose of this section is to select the account that we want to do the EFT with
 *
 * Question: Do we want to allow a client to be able to make a Bank Account on the fly here.
 * @param param0
 * @returns
 */
const CreateInvestmentAccountEFTDetails = ({
  participant: client,
  account,
  handleGoNext,
  setNewInvestmentAccount,
}: InvestmentAccountDetailsProps) => {
  const [selectedBankAccount, setSelectedBankAccount] = useState<string>("");
  const bankAccounts = client?.bankAccounts;

  const handleEFTDetailAdd = () => {
    // Check for empty string
    if (checkEmptyValue(selectedBankAccount)) {
      notify("Please select a bank account", "error");
      return;
    }

    const tempData = { ...account };
    // If there is nothing there, create an empty array
    if (!tempData.eftBankAccounts || tempData.eftBankAccounts.length == 0) {
      tempData.eftBankAccounts = [];
    }
    // Check if the bank account already exists in the ETF Fields
    else if (tempData.eftBankAccounts.find((eftDetail) => eftDetail == selectedBankAccount)) {
      notify("Bank Account Already Used", "error");
      return;
    }

    tempData.eftBankAccounts = [...tempData.eftBankAccounts, selectedBankAccount];
    setNewInvestmentAccount({ ...account, eftBankAccounts: tempData.eftBankAccounts });
  };

  const handleEFTDetailRemove = (index: number) => {
    const tempData = { ...account };
    // If there is nothing there,then there is nothing to delete
    if (!tempData.eftBankAccounts || tempData.eftBankAccounts.length == 0) {
      return;
    }
    // Remove the specified index from the array
    tempData.eftBankAccounts.splice(index, 1);

    setNewInvestmentAccount({
      ...account,
      eftBankAccounts: tempData.eftBankAccounts,
    });
  };

  return (
    <div className="edit-client-wizard-item" id="edit-invest-account-eft-details">
      <div className="edit-client-wizard-item-header">
        <p>Electronic Funds Transfer (EFT) Details</p>
      </div>
      <div className="edit-client-wizard-item-body">
        <div className="eft-bank-accounts-container">
          <div className="eft-bank-accounts-header">
            <p>EFT Bank Accounts</p>
          </div>
          <div className="eft-bank-accounts-body">
            {account.eftBankAccounts && account.eftBankAccounts.length > 0 ? (
              <>
                {account.eftBankAccounts.map((eftDetail, index) => {
                  const account = findBankAccount(bankAccounts, eftDetail);
                  return (
                    <div className="eft-bank-account-container" key={index}>
                      <p>{account.accountName}</p>
                      <button onClick={() => handleEFTDetailRemove(index)}>Remove</button>
                    </div>
                  );
                })}
              </>
            ) : (
              <div>No Bank Accounts Found for EFT</div>
            )}
          </div>
        </div>
        <div className="add-bank-account-to-etf-details-container">
          <label>Select Bank Account To Add</label>
          <br />
          <select
            value={selectedBankAccount}
            onChange={(e) => setSelectedBankAccount(e.target.value)}
          >
            <option value="">Select Bank Account</option>
            {bankAccounts &&
              bankAccounts.map((bankAccount, index) => (
                <option value={bankAccount.id} key={index}>
                  {bankAccount.accountName}
                </option>
              ))}
          </select>
          <button onClick={handleEFTDetailAdd} className="btn btn-success">
            Add Account
          </button>
        </div>
        {handleGoNext && (
          <button className="btn-nice-purple btn-save-client-details" onClick={handleGoNext}>
            Continue
          </button>
        )}
      </div>
    </div>
  );
};

export default CreateInvestmentAccountEFTDetails;
