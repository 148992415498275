import { useSelector } from "react-redux";
import { AuthUser } from "../features/auth/types";
import { RootState } from "../stores/store";
import AccessDenied from "../features/misc/AccessDenied";

const withAdminAuth = (WrappedComponent: any) => {
  return (props: any) => {
    const userData: AuthUser = useSelector(
      (state: RootState) => state.userAuthStore
    );

    // User must have the admin role with this wrapped higher order component
    if (userData.roles.indexOf("admin") == -1) {
      return <AccessDenied />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAdminAuth;
