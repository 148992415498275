/**
 * This Store will be used for the Logged In User
 * We can possibly implement an admin only functionality where we can use a different store for that
 */

import { createSlice } from "@reduxjs/toolkit";
import { Advisor } from "../../types/Advisor/Advisor";
import { Household } from "../../types";

export interface HouseholdState {
  households: Household[];
}

export const emptyHouseholdsState: HouseholdState = {
  households: [],
};

export const householdSlice = createSlice({
  name: "householdSlice",
  initialState: emptyHouseholdsState,
  reducers: {
    setHouseholds: (state, action) => {
      state.households = action.payload.households;
    },
  },
});

export const { setHouseholds } = householdSlice.actions;
export const householdSliceReducer = householdSlice.reducer;
