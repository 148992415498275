import "../styles/createRiskTolerance.scss";
import { useEffect, useState } from "react";
import RiskToleranceWizard from "./riskToleranceWizard";
import RiskWizardSections from "./riskWizardSections";
import withAuth from "HOC/withAuth";
import withAdvisorAuth from "HOC/withAdvisorAuth";
import { ApiResponseModel, RiskToleranceFormData, RiskToleranceWizardStep } from "types";
import SpecialNotesSection from "./specialNotesSection";
import { GuidValues } from "constants/enums";
import riskSections from "assets/StaticData/RiskToleranceSections";
import { useCreateRiskToleranceResponseMutation } from "Apis/mainApi";
import { notify, sanitizeString } from "utils";
import CompleteWizard from "./completeWizard";
import { useParams } from "react-router-dom";
import { useSendForSignatureMutation } from "Apis/riskToleranceApi";

const riskToleranceWizardSteps: RiskToleranceWizardStep[] = [
  {
    component: RiskWizardSections,
    startIndex: 0,
    endIndex: 3,
  },
  {
    component: RiskWizardSections,
    startIndex: 3,
    endIndex: 6,
  },
  {
    component: RiskWizardSections,
    startIndex: 6,
    endIndex: 7,
  },
  {
    component: RiskWizardSections,
    startIndex: 7,
    endIndex: 8,
  },
  {
    component: RiskWizardSections,
    startIndex: 8,
    endIndex: 12,
  },
  {
    component: RiskWizardSections,
    startIndex: 12,
    endIndex: 15,
  },
  {
    component: SpecialNotesSection,
  },
];

/**
 * This Component will create and edit clients' risk tolerance questionaire
 * @returns
 */
const CreateRiskTolerance = () => {
  // Keeping Track of Risk Scores
  const { clientId } = useParams();
  const [currentRiskScore, setCurrentRiskScore] = useState(0);
  const [submitRisk] = useCreateRiskToleranceResponseMutation();
  const [sendRiskEmailForSignature] = useSendForSignatureMutation();
  const [isCompleted, setIsCompleted] = useState(false);

  // Initial Form Data To Be Used
  const [formData, setFormData] = useState<RiskToleranceFormData>({
    foClientId: clientId ? clientId : GuidValues.EMPTY_GUID,
    responseId: undefined,
    // All of the risk form answers
    riskScoreSections: riskSections.map((section) => {
      return {
        sectionId: section.id,
        answerScore: 0,
      };
    }),
    specialNotes: "",
  });

  // Anytime an input changes or something on the step changes, pass this in
  const handleStepChange = async (data: Partial<RiskToleranceFormData>): Promise<void> => {
    setFormData((prevFormData) => ({ ...prevFormData, ...data }));

    if (data.riskScoreSections) {
      // If we are setting the risk tolerance score
      var tempScore = 0;
      data.riskScoreSections.map((answer) => {
        tempScore += answer.answerScore;
      });

      setCurrentRiskScore(tempScore);
    }
  };

  // The goal here is to update the database everytime the Form Data object is being updated
  useEffect(() => {
    const updateRiskForm = async () => {
      if (formData.foClientId != GuidValues.EMPTY_GUID && formData.responseId != undefined) {
        // Submit the Risk Response
        const riskSubmissionResponse: ApiResponseModel = await submitRisk(formData);

        // If an error has occured, let the user know
        if (riskSubmissionResponse.error) {
          //notify("An error occurred with saving", "error");
          console.log(riskSubmissionResponse.error);
        }

        // Change the Response ID in state to the current response ID if it is empty
        if (formData.responseId == GuidValues.EMPTY_GUID) {
          handleStepChange({
            responseId: riskSubmissionResponse.data?.result.id,
          });
        }
      }
    };

    updateRiskForm();
  }, [formData]);

  /**
   * Form Submission Handler
   */
  const handleRiskFormSubmit = async () => {
    // 1. See if any form submission values are 0
    const formAnswers = formData.riskScoreSections;
    const isZeroAnswers = formAnswers.filter((answer) => answer.answerScore == 0);

    if (isZeroAnswers.length > 0) {
      notify("Please Answer All Risk Tolerance Questions", "error");
      return;
    }
    // 2. Sanitize the Special Notes section text area
    formData.specialNotes = sanitizeString(formData.specialNotes);
    // 2.5 Set the status to 2
    //formData.status = SD_DocumentStatus.UNSIGNED;
    // 3. Submit it to the API
    const riskSubmissionResponse: ApiResponseModel = await submitRisk(formData);
    if (riskSubmissionResponse.error) {
      notify("An error has occured", "error");
    }
    if (riskSubmissionResponse.data && riskSubmissionResponse.data.isSuccess) {
      notify("Successfully Submitted Risk Response", "success");
      setIsCompleted(true);

      // Send the Email Link
      const sendFormResult: ApiResponseModel = await sendRiskEmailForSignature(formData.responseId);

      if (sendFormResult.data?.isSuccess) {
        notify("Successfully Sent Email", "success");
      } else {
        console.log(sendFormResult);
        notify("An error has occured", "error");
      }
    }
  };

  return (
    <div className="main-container">
      <h1 className="main-container-header">Client Risk Tolerance Wizard</h1>
      <RiskToleranceWizard
        steps={riskToleranceWizardSteps}
        formData={formData}
        setFormData={setFormData}
        handleStepChange={handleStepChange}
        currentRiskScore={currentRiskScore}
        handleRiskFormSubmit={handleRiskFormSubmit}
        isCompleted={isCompleted}
        CompletedComponent={CompleteWizard}
        showRiskScore={true}
      />
      <button onClick={() => console.log(formData)}>Show Risk Form Data</button>
    </div>
  );
};

export default withAuth(withAdvisorAuth(CreateRiskTolerance));
