import { notify } from "utils";
import { CreateIndividualPolicyStatementDto } from "../types";

const validateLimits = (formData: CreateIndividualPolicyStatementDto) => {
  if (
    formData.maxCommericialPaperPercentage > 0 &&
    formData.maxCommericialPaperPercentage < 100 &&
    formData.maxSecurityIssuerPercentage > 0 &&
    formData.maxSecurityIssuerPercentage < 100
  ) {
    return true;
  }

  notify("Please provide valid limits for investments", "error");
  return false;
};

export default validateLimits;
