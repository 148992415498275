import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CreateNewClient } from "../types/Participant/Participant";

/**
 * This endpoint uses the Client Controller found in the .NET API
 */

const newClientApi = createApi({
  reducerPath: "newClientApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api/newClient`,
    credentials: "include",
  }),
  tagTypes: ["NewClients"],
  endpoints: (builder) => ({
    // Gets all clients from the database
    getNewClientsByAdvisor: builder.query({
      query: (householdId: string) => ({
        url: `/household/${householdId}`,
        method: "GET",
      }),
    }),
    createNewClient: builder.mutation({
      query: (createClientRequest: CreateNewClient) => ({
        url: `/initial`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: createClientRequest,
      }),
      invalidatesTags: ["NewClients"],
    }),
  }),
});

export const { useGetNewClientsByAdvisorQuery, useCreateNewClientMutation } = newClientApi;
export default newClientApi;
