import { checkEmptyValue, isValidUSZip, notify, validatePostalCode } from "../../../../utils";
import { Participant } from "../../../../types";

/**
 * Function that validates a client's address details
 * @param client
 * @returns
 * @author Teddy K.
 **/

const ValidateAddressDetailsDetails = (client: Participant) => {
  // Validate the Address Type

  if (checkEmptyValue(client.permanentAddressDetails.addressType)) {
    notify("Please Select an Address Type", "error");
    return false;
  }

  // Validate the Apartment/Unit/Suite Number
  if (checkEmptyValue(client.permanentAddressDetails.aptUnitSuiteNumber)) {
    notify("Please Enter an Apartment/Unit/Suite Number", "error");
    return false;
  }

  // Validate the Street Name
  if (checkEmptyValue(client.permanentAddressDetails.streetName)) {
    notify("Please Enter a Street Name", "error");
    return false;
  }

  // Validate the Country
  if (checkEmptyValue(client.permanentAddressDetails.country)) {
    notify("Please Enter a Country", "error");
    return false;
  }

  // Validate the Region
  if (checkEmptyValue(client.permanentAddressDetails.region)) {
    notify("Please Enter a Region", "error");
    return false;
  }

  // Validate the City
  if (checkEmptyValue(client.permanentAddressDetails.city)) {
    notify("Please Enter a City", "error");
    return false;
  }

  // Validate the Postal Code and/or Zip Code
  if (client.permanentAddressDetails.country == "Canada") {
    if (checkEmptyValue(client.permanentAddressDetails.postalCode)) {
      notify("Please Enter a Postal Code", "error");
      return false;
    } else if (validatePostalCode(client.permanentAddressDetails.postalCode) == false) {
      notify("Please Enter a Valid Postal Code", "error");
      return false;
    }
  } else if (client.permanentAddressDetails.country == "United States") {
    if (checkEmptyValue(client.permanentAddressDetails.postalCode)) {
      notify("Please Enter a Zip Code", "error");
      return false;
    } else if (isValidUSZip(client.permanentAddressDetails.postalCode) == false) {
      notify("Please Enter a Valid Zip Code", "error");
      return false;
    }
  }

  // Now Validate the Mailing Address Details if applicable
  if (client.permanentAddressDetails.isSameAsMailingAddress == false) {
    // Validate the Address Type
    if (checkEmptyValue(client.mailingAddressDetails?.addressType)) {
      notify("Please Select a Mailing Address Type", "error");
      return false;
    }
    // Validate the Apartment/Unit/Suite Number
    if (checkEmptyValue(client.mailingAddressDetails?.aptUnitSuiteNumber)) {
      notify("Please Enter an Apartment/Unit/Suite Number", "error");
      return false;
    }

    // Validate the Street Name
    if (checkEmptyValue(client.mailingAddressDetails?.streetName)) {
      notify("Please Enter a Street Name", "error");
      return false;
    }

    // Validate the Country
    if (checkEmptyValue(client.mailingAddressDetails?.country)) {
      notify("Please Enter a Country", "error");
      return false;
    }

    // Validate the Region
    if (checkEmptyValue(client.mailingAddressDetails?.region)) {
      notify("Please Enter a Region", "error");
      return false;
    }

    // Validate the City
    if (checkEmptyValue(client.mailingAddressDetails?.city)) {
      notify("Please Enter a City", "error");
      return false;
    }

    // Validate the Postal Code
    if (client.mailingAddressDetails.country == "Canada") {
      if (checkEmptyValue(client.mailingAddressDetails.postalCode)) {
        notify("Please Enter a valid Mailing Postal Code", "error");
        return false;
      } else if (validatePostalCode(client.mailingAddressDetails.postalCode) == false) {
        notify("Please Enter a valid Mailing  Postal Code", "error");
        return false;
      }
    } else if (client.mailingAddressDetails.country == "United States") {
      if (checkEmptyValue(client.mailingAddressDetails.postalCode)) {
        notify("Please Enter a valid Mailing Zip Code", "error");
        return false;
      } else if (isValidUSZip(client.mailingAddressDetails.postalCode) == false) {
        notify("Please Enter a valid Mailing Zip Postal Code", "error");
        return false;
      }
    }
  }

  // Now Validate the Third Party Address Details if applicable
  if (client.permanentAddressDetails.hasThirdPartyAddress) {
    // Validate the Address Type
    if (checkEmptyValue(client.thirdPartyAddressDetails?.addressType)) {
      notify("Please Select a Third Party Mailing Address Type", "error");
      return false;
    }
    // Validate the Apartment/Unit/Suite Number
    if (checkEmptyValue(client.thirdPartyAddressDetails?.aptUnitSuiteNumber)) {
      notify("Please Enter an Third Party Apartment/Unit/Suite Number", "error");
      return false;
    }

    // Validate the Street Name
    if (checkEmptyValue(client.thirdPartyAddressDetails?.streetName)) {
      notify("Please Enter a Third Party Third Party Street Name", "error");
      return false;
    }

    // Validate the Country
    if (checkEmptyValue(client.thirdPartyAddressDetails?.country)) {
      notify("Please Enter a Third Party Country", "error");
      return false;
    }

    // Validate the Region
    if (checkEmptyValue(client.thirdPartyAddressDetails?.region)) {
      notify("Please Enter a Third Party Region", "error");
      return false;
    }

    // Validate the City
    if (checkEmptyValue(client.thirdPartyAddressDetails?.city)) {
      notify("Please Enter a Third Party City", "error");
      return false;
    }

    // Validate the Postal Code
    if (client.thirdPartyAddressDetails.country == "Canada") {
      if (checkEmptyValue(client.thirdPartyAddressDetails.postalCode)) {
        notify("Please Enter a valid Third Party Postal Code", "error");
        return false;
      } else if (validatePostalCode(client.thirdPartyAddressDetails.postalCode) == false) {
        notify("Please Enter a valid Third Party Postal Code", "error");
        return false;
      }
    } else if (client.thirdPartyAddressDetails.country == "United States") {
      if (checkEmptyValue(client.thirdPartyAddressDetails.postalCode)) {
        notify("Please Enter a valid Third Party Zip Code", "error");
        return false;
      } else if (isValidUSZip(client.thirdPartyAddressDetails.postalCode) == false) {
        notify("Please Enter a valid Third Party Valid Zip Code", "error");
        return false;
      }
    }
  }

  return true;
};

export default ValidateAddressDetailsDetails;
