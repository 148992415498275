import React from "react";
import { InvestmentAccountDetailsProps } from "./CreateInvestmentAccountGeneralDetails";
import {
  EditClientBoolean,
  EditClientInput,
} from "Components/Pages/CreateFullClient/Helpers/Inputs";
import { InvestmentAccountSubType } from "constants/enums";

/**
 * The only information required here is Min Payment, Payment Above the Minimum,
 * And for Locked In Accounts, you need to set the maximum amount that can be withdrawn.
 * @param param0
 * @returns
 */

function CreateInvestmentAccountIncomeFundDetails({
  account,
  handleGoNext,
  setNewInvestmentAccount,
}: InvestmentAccountDetailsProps) {
  /**
   * Handles the Income Fund Details Change for Min Payment, Payment Above the Minimum,
   * @param val
   * @param attrName
   */
  const handleIncomeFundDetailChange = (
    val: string | number | boolean,
    attrName: string | undefined
  ) => {
    if (attrName) {
      const tempAccount = { ...account };
      if (tempAccount.incomeFundInformation) {
        tempAccount.incomeFundInformation[attrName] = val;
      } else {
        tempAccount.incomeFundInformation = {
          minPayment: 0,
          paymentAboveMin: 0,
        };

        tempAccount.incomeFundInformation[attrName] = Number(val);
      }

      setNewInvestmentAccount(tempAccount);
    }
  };

  const handleLockedInAccountChange = (val: string | number | boolean) => {
    const tempAccount = { ...account };
    if (tempAccount.lifInformation) {
      tempAccount.lifInformation.maxPayment = Number(val);
    } else {
      tempAccount.lifInformation = {
        maxPayment: Number(val),
      };
    }
    setNewInvestmentAccount(tempAccount);
  };

  return (
    <div className="edit-client-wizard-item" id="edit-invest-account-income-fund-details">
      <div className="edit-client-wizard-item-header">
        <p>Income Fund Details</p>
      </div>
      <div className="edit-client-wizard-item-body">
        <EditClientInput
          label="Min Payment"
          value={account.incomeFundInformation?.minPayment}
          handleChange={handleIncomeFundDetailChange}
          attrName="minPayment"
          isDollarAmount={true}
          isNumber={true}
        />
        <EditClientInput
          label="Payment Above the Minimum"
          value={account.incomeFundInformation?.paymentAboveMin}
          handleChange={handleIncomeFundDetailChange}
          attrName="paymentAboveMin"
          isDollarAmount={true}
          isNumber={true}
        />
        {account.subType == InvestmentAccountSubType.LOCKED_IN && (
          <EditClientInput
            label="Maximum Payment"
            value={account.lifInformation?.maxPayment}
            handleChange={handleLockedInAccountChange}
            isDollarAmount={true}
            isNumber={true}
          />
        )}
        {handleGoNext && (
          <button className="btn-nice-purple btn-save-client-details" onClick={handleGoNext}>
            Continue
          </button>
        )}
      </div>
    </div>
  );
}

export default CreateInvestmentAccountIncomeFundDetails;
