import { useCreateInvestmentAccountMutation } from "Apis/investmentAccountApi";
import "../styles/reviewDetails.scss";
import { InvestmentAccountDetailsProps } from "./CreateInvestmentAccountGeneralDetails";
import {
  CurrencyMapping,
  IntendedAccountUsesById,
  InvestmentAccountSubTypeMapping,
  InvestmentAccountTypeMapping,
  SourceOfFundsMappingById,
} from "constants/mappings";
import { ApiResponseModel } from "types";
import { notify } from "utils";

function CreateInvestmentAccountReviewDetails({
  account,
  handleGoNext,
  setNewInvestmentAccount,
}: InvestmentAccountDetailsProps) {
  const [createInvestmentAccount] = useCreateInvestmentAccountMutation();

  const handleCreateInvestmentAccount = async () => {
    const response: ApiResponseModel = await createInvestmentAccount(account);

    if (response.data && response.data.isSuccess) {
      console.log(response.data);
      notify("Investment Account Created Successfully", "success");
    } else if (response.data && !response.data.isSuccess) {
      notify("An error has occured", "error");
      console.log(response.data);
    } else if (response.error) {
      notify("An error has occured", "error");
      console.log(response.error);
    }
  };

  return (
    <div className="edit-client-wizard-item" id="edit-invest-account-review-details">
      <div className="edit-client-wizard-item-header">
        <p>Review Details</p>
      </div>
      <p style={{ fontSize: "1.2rem" }}>Please ensure that all information is correct</p>
      <div className="create-account-review-details-container">
        <div className="create-account-review-details-item">
          <p className="create-account-label">Account Type</p>
          <p className="create-account-value">
            {InvestmentAccountTypeMapping[account.type.toString()]}
          </p>
        </div>
        <div className="create-account-review-details-item">
          <p className="create-account-label">Account SubType</p>
          <p className="create-account-value">
            {InvestmentAccountSubTypeMapping[account.subType.toString()]}
          </p>
        </div>
        <div className="create-account-review-details-item">
          <p className="create-account-label">Currency</p>
          <p className="create-account-value">{CurrencyMapping[account.currency.toString()]}</p>
        </div>
        <div className="create-account-review-details-item">
          <p className="create-account-label">Intended Use of Accounts</p>
          <p className="create-account-value">
            {IntendedAccountUsesById[account.intendedUseOfAccount.toString()]}
          </p>
        </div>
        <div className="create-account-review-details-item">
          <p className="create-account-label">Source of Funds</p>
          <p className="create-account-value">
            {SourceOfFundsMappingById[account.sourceOfFunds.toString()]}
          </p>
        </div>
        <div className="create-account-review-details-item">
          <p className="create-account-label">Is Joint Account?</p>
          <p className="create-account-value">{account.isJointAccount ? "Yes" : "No"}</p>
        </div>
        {account.isJointAccount && (
          <div className="create-account-review-details-item">
            <p className="create-account-label">Joint Account Holders</p>
            {account.accountHolders &&
              account.accountHolders.map((accountHolder, index) => (
                <p className="create-account-value">
                  Account Holder {index + 1}: {accountHolder}
                </p>
              ))}
          </div>
        )}
        <div className="create-account-review-details-item">
          <p className="create-account-label">Has Beneficiaries?</p>
          <p className="create-account-value">{account.allowBeneficiarys ? "Yes" : "No"}</p>
        </div>
        <div className="account-create-button-container" style={{ marginTop: "1rem" }}>
          <button className="btn btn-success" onClick={handleCreateInvestmentAccount}>
            Create Investment Account
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateInvestmentAccountReviewDetails;
