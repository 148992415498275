import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

/**
 * This endpoint uses the Client Controller found in the .NET API
 */
const advisorApi = createApi({
  reducerPath: "advisorApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api/advisor`,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    // Gets all clients from the database
    getAdvisorByUserID: builder.query({
      query: (applicationUserId: string) => ({
        url: `user/${applicationUserId}`,
        method: "GET",
        params: {
          userId: applicationUserId,
        },
      }),
    }),
  }),
});

export const { useGetAdvisorByUserIDQuery, useLazyGetAdvisorByUserIDQuery } =
  advisorApi;
export default advisorApi;
