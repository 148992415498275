import { InvestmentAccountType } from "../../../constants/enums";

// Accounts that can have a joint participant
// Currently only RESP, Cash, Margin
const JointTypeRequiredAccounts: number[] = [
  InvestmentAccountType.CASH,
  InvestmentAccountType.MARGIN,
  InvestmentAccountType.RESP,
];

// Accounts that can have a beneficiary
const BeneficiaryTypeRequiredAccounts: number[] = [
  InvestmentAccountType.RSP,
  InvestmentAccountType.RIF,
  InvestmentAccountType.TFSA,
];

// Accounts that can have a successor Annuitant
const SuccessorAnnuitantTypeRequiredAccounts: number[] = [
  InvestmentAccountType.TFSA,
  InvestmentAccountType.RIF,
];

// Accounts that can have EFT Actions performed to it
const EFTTypeRequiredAccounts: number[] = [
  InvestmentAccountType.CASH,
  InvestmentAccountType.MARGIN,
  InvestmentAccountType.RSP,
  InvestmentAccountType.RIF,
  InvestmentAccountType.TFSA,
];

// Accounts that can have PAC Actions performed to it (EFT IN)
const PACTypeRequiredAccounts: number[] = [
  InvestmentAccountType.CASH,
  InvestmentAccountType.MARGIN,
  InvestmentAccountType.RSP,
];

// Accounts that can have SWP Actions performed to it (EFT OUT)
const SWTTypeRequiredAccounts: number[] = [
  InvestmentAccountType.CASH,
  InvestmentAccountType.MARGIN,
  InvestmentAccountType.RIF,
  InvestmentAccountType.TFSA,
];

// Account types that can have contribution instructions (PAC or SWP)
const ContributionTypeRequiredAccounts: number[] = [
  InvestmentAccountType.RSP,
  InvestmentAccountType.RESP,
  InvestmentAccountType.TFSA,
];

export {
  JointTypeRequiredAccounts,
  BeneficiaryTypeRequiredAccounts,
  EFTTypeRequiredAccounts,
  PACTypeRequiredAccounts,
  SWTTypeRequiredAccounts,
  ContributionTypeRequiredAccounts,
  SuccessorAnnuitantTypeRequiredAccounts,
};
