import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { SelectItem } from "../../../../../types/Shared";
import { useState } from "react";

interface EditClientProps {
  label: string;
  value: string | number | undefined;
  selectItems?: SelectItem[];
  attrName?: string;
  isRequired?: boolean;
  isCountry?: boolean;
  isRegion?: boolean;
  country?: string;
  defaultValue?: string;
  handleChange: (e: string | number | boolean, attrName: string | undefined) => void;
}

const EditClientSelect = ({
  label,
  value,
  selectItems,
  attrName,
  isRequired,
  isCountry,
  isRegion,
  country,
  defaultValue,
  handleChange,
}: EditClientProps) => {
  if (selectItems?.length == 0) {
    console.log("what is happening here");
    return (
      <div className="edit-client-wizard-item-input-control">
        <label>
          {label}
          {isRequired && "*"}
        </label>
        <select value={value} onChange={(e) => handleChange(e.target.value, attrName)}>
          <option value={0}>No Options Found</option>
        </select>
      </div>
    );
  }

  if (isCountry) {
    return (
      <div className="edit-client-wizard-item-input-control">
        <label>
          {label}
          {isRequired && "*"}
        </label>
        <CountryDropdown
          value={value ? value.toString() : ""}
          onChange={(val) => handleChange(val, attrName)}
        />
      </div>
    );
  }

  if (isRegion) {
    return (
      <div className="edit-client-wizard-item-input-control">
        <label>
          {label}
          {isRequired && "*"}
        </label>
        <RegionDropdown
          country={country ? country : ""}
          value={value ? value.toString() : ""}
          onChange={(val) => handleChange(val, attrName)}
        />
      </div>
    );
  }

  return (
    <div className="edit-client-wizard-item-input-control">
      <label>
        {label}
        {isRequired && "*"}
      </label>
      <select value={value} onChange={(e) => handleChange(e.target.value, attrName)}>
        <option value={0}>{defaultValue ? defaultValue : `Select Item...`}</option>
        {selectItems &&
          selectItems.map((item, index) => {
            return (
              <option value={item.value} key={index}>
                {item.label}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default EditClientSelect;
