import {
  EditClientInput,
  EditClientSelect,
} from "Components/Pages/CreateFullClient/Helpers/Inputs";
import { EmptyNewParticipant } from "constants/emptyStates";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "stores/store";
import { ApiResponseModel, Participant } from "types";
import { NewParticipantWithDOBDto } from "types/Participant";
import { useCreateInitialNewParticipantMutation } from "Apis/mainApi";
import { validateNewParticipant } from "../utils";
import { notify } from "utils";
import { SelectItem } from "types/Shared";

interface AddNewParticipantWithRoleProps {
  createFormLabel: string;
  role: string;
  householdId: string;
  handleParticipantAdd: (participantId: string) => void;
}

/**
 * Form that will be used throughout the account process stage when users can create
 * a pariticipant
 */
const AddNewParticipantWithRoleForm = ({
  createFormLabel,
  role,
  householdId,
  handleParticipantAdd,
}: AddNewParticipantWithRoleProps) => {
  // Get the advisor's households
  const [createNewParticipant] = useCreateInitialNewParticipantMutation();
  const [newParticipant, setNewParticipant] =
    useState<NewParticipantWithDOBDto>(EmptyNewParticipant);

  const handleFormChange = (val: string | number | boolean, attrName: string | undefined) => {
    if (attrName) {
      setNewParticipant({ ...newParticipant, [attrName]: val });
    }
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Validate all the details of the form
    if (validateNewParticipant(newParticipant)) {
      // Create the new participant
      newParticipant.roles = [role];
      newParticipant.householdId = householdId;
      const apiResponse: ApiResponseModel = await createNewParticipant(newParticipant);
      if (apiResponse.data && apiResponse.data?.isSuccess) {
        notify("Sucessfully created new participant", "success");
        const participantId = apiResponse.data.result.id;
        handleParticipantAdd(participantId);
        //setIsAddingParticipant(false);
      } else if (apiResponse.error) {
        console.log(apiResponse.error);
        notify("Failed to create new participant", "error");
      }
    }
  };

  return (
    <form onSubmit={handleFormSubmit} style={{ marginTop: "1rem", borderTop: "1px solid black" }}>
      <p className="form-header" style={{ fontSize: "1.2rem" }}>
        Create New {createFormLabel}
      </p>
      <EditClientInput
        label="First Name*"
        value={newParticipant.firstName}
        attrName="firstName"
        handleChange={handleFormChange}
      />
      <EditClientInput
        label="Last Name*"
        value={newParticipant.lastName}
        attrName="lastName"
        handleChange={handleFormChange}
      />
      <EditClientInput
        label="SIN*"
        value={newParticipant.sin}
        attrName="sin"
        handleChange={handleFormChange}
      />
      <EditClientInput
        label="Date of Birth*"
        value={newParticipant.dateOfBirth}
        attrName="dateOfBirth"
        isDate={true}
        handleChange={handleFormChange}
      />
      <EditClientInput
        label="Email"
        value={newParticipant.email}
        attrName="email"
        placeholder="user@example.com"
        handleChange={handleFormChange}
      />
      <EditClientInput
        label="Phone Number"
        value={newParticipant.phoneNumber}
        attrName="phoneNumber"
        handleChange={handleFormChange}
      />
      <div style={{ width: "70%", display: "flex", justifyContent: "space-evenly" }}>
        <button className="btn btn-success">Create And Add {createFormLabel}</button>
      </div>
    </form>
  );
};

export default AddNewParticipantWithRoleForm;
