import { checkEmptyValue, notify } from "../../../../utils";
import validateSIN from "../../../../utils/validateSIN";
import { Participant } from "../../../../types";

/**
 * Function that validates a client's spouse details
 * @param client
 * @returns
 **/

const ValidateSpouseDetails = (client: Participant) => {
  if (client.spouseDetails) {
    if (checkEmptyValue(client.spouseDetails.firstName)) {
      notify(`Please Enter Spouse First Name`, "error");
      return false;
    }

    if (checkEmptyValue(client.spouseDetails.lastName)) {
      notify(`Please Enter Spouse Last Name`, "error");
      return false;
    }

    if (checkEmptyValue(client.spouseDetails.employerName)) {
      notify(`Please Enter Spouse Employer Name`, "error");
      return false;
    }

    if (checkEmptyValue(client.spouseDetails.occupation)) {
      notify(`Please Enter Spouse Occupation`, "error");
      return false;
    }

    if (checkEmptyValue(client.spouseDetails.sin)) {
      notify(`Please Enter Spouse SIN`, "error");
      return false;
    } else if (!validateSIN(client.spouseDetails.sin)) {
      notify(`Please Enter Valid Spouse SIN`, "error");
      return false;
    }

    if (checkEmptyValue(client.spouseDetails.gender)) {
      notify("Please Enter Spouse Gender", "error");
      return false;
    }
  } else {
    notify("Please Enter Spouse Details", "error");
    return false;
  }

  return true;
};

export default ValidateSpouseDetails;
