import React from "react";
import RegulatoryDataProps from "./RegulatoryDataProps";
import EditClientBoolean from "../Inputs/EditClientBoolean";
import EditClientInput from "../Inputs/EditClientInput";
import EditClientSelect from "../Inputs/EditClientSelect";

function IsHIODetails({
  selectedClientData,
  handleRegulatoryDetailChange,
  handleDataChange,
}: RegulatoryDataProps) {
  const handleHIOChange = (
    val: string | number | boolean,
    attrName: string | undefined
  ) => {
    if (selectedClientData && attrName) {
      let tempData = { ...selectedClientData.regulatoryDetails };
      let hioDetails = { ...tempData.hioDetails! };

      if (!hioDetails) {
        hioDetails = {
          jobTitle: "",
          country: "",
          nameOfOrganization: "",
        };
      }

      hioDetails[attrName] = val.toString();
      tempData.hioDetails = hioDetails;
      handleDataChange({ regulatoryDetails: tempData });
    }
  };

  return (
    <>
      <EditClientBoolean
        label="Is HIO?"
        value={selectedClientData?.regulatoryDetails?.isHIO}
        inputName="isHIO"
        attrName="isHIO"
        handleChange={handleRegulatoryDetailChange}
      />
      {
        // If the client is a foreign PEP, then show the details
        selectedClientData?.regulatoryDetails?.isHIO && (
          <div className="regulatory-list-item">
            <EditClientInput
              label="Job Title"
              value={
                selectedClientData?.regulatoryDetails?.hioDetails?.jobTitle
              }
              attrName="jobTitle"
              handleChange={handleHIOChange}
            />
            <EditClientInput
              label="Name of Organization"
              value={
                selectedClientData?.regulatoryDetails?.hioDetails
                  ?.nameOfOrganization
              }
              attrName="nameOfOrganization"
              handleChange={handleHIOChange}
            />
            <EditClientSelect
              label="Country"
              value={selectedClientData?.regulatoryDetails?.hioDetails?.country}
              attrName="country"
              isCountry={true}
              handleChange={handleHIOChange}
            />
          </div>
        )
      }
    </>
  );
}

export default IsHIODetails;
