import { useEffect, useState } from "react";
import { EditClientBoolean, EditClientInput } from "../Helpers/Inputs";
import { EditClientWizardItemProps } from "./EditClientWizardItemProps";
import { ApiResponseModel, Participant } from "types";
import { HouseholdState } from "stores/Redux/householdSlice";
import { useSelector } from "react-redux";
import { RootState } from "stores/store";
import {
  useCreateClientFromSpouseInformationMutation,
  useCreateClientSpouseRelationshipMutation,
  useDeleteClientSpouseRelationshipMutation,
} from "Apis/mainApi";
import { checkEmptyValue, notify } from "utils";
import "../styles/spouseDetails.scss";
import maskString from "utils/maskString";
const SECTION_ID = 4;

/**
 * This component is used to create or edit the general details of a client
 * Passes in a client as well as the handling of the client data changes from the parent component
 * that holds the full client data
 * @param param0
 * @returns
 */
const CreateClientSpouseDetails = ({
  selectedClientData,
  handleDataChange,
  handleSaveClientDetails, // This function is only used to save directly on the Client Object, not the spouse
  handleGoBack,
}: EditClientWizardItemProps) => {
  const [hasSpouseDetails, setHasSpouseDetails] = useState<boolean>(false); // This is used to check if the client has a spouse or not in the DB
  const [spouseIsClient, setSpouseIsClient] = useState<boolean | undefined>();
  const [useExistingClientForSpouse, setUseExistingClientForSpouse] = useState<boolean>(false);
  const [createClientFromSpouseDetails] = useCreateClientFromSpouseInformationMutation();
  const [deleteSpouseRelationship] = useDeleteClientSpouseRelationshipMutation();
  // Handles the check on if the spouse is a client or not
  const handleSpouseClientChange = (
    val: number | string | boolean,
    attrName: string | undefined,
    check?: boolean
  ) => {
    setSpouseIsClient(check);
  };

  // Handles the change of the spouse details, just used if the spouse is NOT a client
  const handleSpouseDetailsChange = (
    val: number | string | boolean,
    attrName: string | undefined,
    check?: boolean
  ) => {
    let value: any = "";
    if (check !== undefined) {
      value = check;
    } else {
      value = val;
    }

    if (selectedClientData && attrName) {
      let tempData = { ...selectedClientData.spouseDetails };
      tempData[attrName] = value;
      handleDataChange({ spouseDetails: tempData });
    }
  };

  /**
   * This function is used to delete the spouse relationship from the client if the spouse is a client
   * Otherwise, they can just edit the form that they have
   */
  const handleDeleteClientSpouseRelationship = async () => {
    const deleteResult: ApiResponseModel = await deleteSpouseRelationship(selectedClientData?.id!);
    if (deleteResult.data && deleteResult.data.isSuccess) {
      notify("Successfully deleted spouse relationship", "success");
      setSpouseIsClient(undefined);
      setHasSpouseDetails(false);
    } else if (deleteResult.data && !deleteResult.data.isSuccess) {
      notify("An error occured", "error");
    }
  };

  /**
   * Handles the saving of spouse details, the following scenarios can happen
   * 1. If the spouse is not a client, then we continue as is, there is no need to do anything special
   * 2. If the spouse is an EXISTING client, when we just have to set the state hook to true to show that they have details available to display
   * 3. If the spouse is a NEW client, then we have to create a new client and then link the spouse details to the client, this is done on the API side
   * @param sectionId - the sectionId of the KYC Form
   */
  const handleSaveSpouseDetails = async (sectionId: number) => {
    // If the spouse is not a client, then we continue as is by saving it to the seperate object
    if (!spouseIsClient) {
      handleSaveClientDetails(sectionId);
    } else {
      // Need to handle the creation of a new client or use an exisitng client
      if (useExistingClientForSpouse) {
        // Use existing client from select picker
        setHasSpouseDetails(true);
      } else {
        // Take out the spouse details from form data
        const spouseDetails = { ...selectedClientData?.spouseDetails }; // Creates a copy
        spouseDetails["clientId"] = selectedClientData?.id; // should be participant id

        // Set the spouse details in the formData to undefined;
        handleDataChange({ spouseDetails: undefined });

        const createResult: ApiResponseModel = await createClientFromSpouseDetails(spouseDetails);
        if (createResult.data && createResult.data.isSuccess) {
          notify("Successfully created client and linked as spouse", "success");
          setHasSpouseDetails(true);
          handleSaveClientDetails(sectionId, true); // No need to save or validate in parent component, we just need to save the spouse details
          console.log("I am here");
          setUseExistingClientForSpouse(false);
        } else if (createResult.data && !createResult.data.isSuccess) {
          const errorMessages = createResult.data.errorMessages;
          let errorMessage = "";
          if (errorMessages && errorMessages.length > 0) {
            errorMessage = errorMessages[0];
          }
          notify(errorMessage, "error");
          notify("An error occured with saving ", "error");
        }

        //handleSaveClientDetails(sectionId, true); // I don't want to save this to the client, just the spouse
      }
    }
  };

  useEffect(() => {
    if (selectedClientData && selectedClientData.spouseDetails) {
      setHasSpouseDetails(true); // There is spouse details
      if (selectedClientData.spouseDetails.isClient) {
        setSpouseIsClient(true);
      }
    }
  }, []);

  return (
    <div className="edit-client-wizard-item" id="edit-client-spouse-details">
      <div className="edit-client-wizard-item-header">
        <button
          className="edit-client-wizard-item-back"
          onClick={handleGoBack}
          style={{ fontSize: "1rem" }}
        >
          <i className="bi bi-arrow-left-circle-fill"></i>
        </button>
        <p>Spouse Details</p>
      </div>

      {/* // If a spouse has not been created for the client */}
      {!hasSpouseDetails && (
        <>
          <EditClientBoolean
            label="Spouse is a client?"
            value={spouseIsClient}
            attrName="spouseIsClient"
            handleChange={handleSpouseClientChange}
          />

          {spouseIsClient && (
            <div>
              {!useExistingClientForSpouse ? (
                <button
                  className="btn btn-success"
                  onClick={() => setUseExistingClientForSpouse(!useExistingClientForSpouse)}
                >
                  Choose Existing Participant
                </button>
              ) : (
                <button
                  className="btn btn-danger"
                  onClick={() => setUseExistingClientForSpouse(!useExistingClientForSpouse)}
                >
                  Cancel
                </button>
              )}
            </div>
          )}

          {/* // If the spouse is not a client, then we just make a new entry in the table that stores spouse details */}
          {spouseIsClient === false && (
            <CreateSpouseDetailsForClientForm
              selectedClientData={selectedClientData}
              handleSpouseDetailsChange={handleSpouseDetailsChange}
              handleSaveClientDetails={handleSaveSpouseDetails}
              header="Enter Spouse Details"
            />
          )}

          {/* If the spouse is a client and they want to make a new client then show the same form, save functionality will change */}
          {spouseIsClient === true && useExistingClientForSpouse == false && (
            <CreateSpouseDetailsForClientForm
              selectedClientData={selectedClientData}
              handleSpouseDetailsChange={handleSpouseDetailsChange}
              handleSaveClientDetails={handleSaveSpouseDetails}
              header="Enter New Participant Details"
            />
          )}

          {/* // if the spouse is a client and they want to use an existing client, then show the form to select a client */}
          {spouseIsClient === true && useExistingClientForSpouse === true && (
            <CreateClientSpouseForExistingClient
              selectedClientData={selectedClientData}
              handleSaveSpouseDetails={handleSaveSpouseDetails}
              setUseExistingClientForSpouse={setUseExistingClientForSpouse}
            />
          )}
        </>
      )}
      {/* // If a spouse has been created for the client */}
      {hasSpouseDetails && (
        <>
          {/* // If the client has a spouse that is a client, we dont want them to be allowed to edit this section, they would have edit the client information on the client's page. */}
          {selectedClientData?.spouseDetails?.isClient && (
            <div className="spouse-client-card-container">
              <button onClick={handleDeleteClientSpouseRelationship}>Delete Relationship</button>
              <p className="spouse-client-card-name">{`${selectedClientData.spouseDetails.firstName} ${selectedClientData.spouseDetails.lastName}`}</p>
              <p className="spouse-client-card-sin">{`${maskString(
                selectedClientData.spouseDetails.sin,
                0,
                7
              )}`}</p>
              <p>🚫 To edit this Participant, please visit the Participant Page.</p>
            </div>
          )}
          {/* // If the spouse is not a client, then we can allow them to edit details in the form */}
          {!selectedClientData?.spouseDetails?.isClient && (
            <CreateSpouseDetailsForClientForm
              selectedClientData={selectedClientData}
              handleSpouseDetailsChange={handleSpouseDetailsChange}
              handleSaveClientDetails={handleSaveSpouseDetails}
              header="Enter Spouse Details"
            />
          )}
        </>
      )}
    </div>
  );
};

// There's a few options we can do here
// 1. If a spouse has not been created for the client, they do the following
//      1.1. Create a new client using a form
//      1.2  Create a new client using an existing client
//      1.3  If they do not wish to add as client, it will add a spouse detail to the client
// 2. If a spouse has been created for the client, they can do the following
//     2.1. Edit the spouse details if the spouse is not a client
//     2.2. View the spouse details if the spouse is a client

interface CreateSpouseDetailsForClientFormProps {
  selectedClientData: Participant | undefined;
  handleSpouseDetailsChange: (
    val: number | string | boolean,
    attrName: string | undefined,
    check?: boolean
  ) => void;
  handleSaveClientDetails: (sectionId: number) => Promise<void>;
  header?: string;
}

const CreateSpouseDetailsForClientForm = ({
  selectedClientData,
  handleSpouseDetailsChange,
  handleSaveClientDetails,
  header,
}: CreateSpouseDetailsForClientFormProps) => {
  return (
    <>
      <div className="edit-client-wizard-item-body" style={{ marginTop: "1rem" }}>
        {header && <p style={{ fontSize: "1.2rem", marginLeft: "10px" }}>{header}</p>}
        <EditClientInput
          label="Salutation"
          value={selectedClientData?.spouseDetails?.salutation}
          attrName="salutation"
          handleChange={handleSpouseDetailsChange}
        />
        <EditClientInput
          label="First Name"
          value={selectedClientData?.spouseDetails?.firstName}
          attrName="firstName"
          handleChange={handleSpouseDetailsChange}
        />
        <EditClientInput
          label="Middle Initial"
          value={selectedClientData?.spouseDetails?.middleInitial}
          attrName="middleInitial"
          handleChange={handleSpouseDetailsChange}
        />
        <EditClientInput
          label="Last Name"
          value={selectedClientData?.spouseDetails?.lastName}
          attrName="lastName"
          handleChange={handleSpouseDetailsChange}
        />
        <EditClientInput
          label="Employer Name"
          value={selectedClientData?.spouseDetails?.employerName}
          attrName="employerName"
          handleChange={handleSpouseDetailsChange}
        />
        <EditClientInput
          label="Occupation"
          value={selectedClientData?.spouseDetails?.occupation}
          attrName="occupation"
          handleChange={handleSpouseDetailsChange}
        />
        <EditClientInput
          label="Type of Business"
          value={selectedClientData?.spouseDetails?.typeOfBusiness}
          attrName="typeOfBusiness"
          handleChange={handleSpouseDetailsChange}
        />
        <EditClientInput
          label="Social Insurance Number"
          value={selectedClientData?.spouseDetails?.sin}
          attrName="sin"
          handleChange={handleSpouseDetailsChange}
        />
        <EditClientInput
          label="Gender"
          value={selectedClientData?.spouseDetails?.gender}
          attrName="gender"
          handleChange={handleSpouseDetailsChange}
        />
      </div>
      <button
        className="btn-nice-purple btn-save-client-details"
        onClick={() => handleSaveClientDetails(SECTION_ID)}
      >
        Save and Continue
      </button>
    </>
  );
};

interface CreateClientSpouseForExistingClient {
  selectedClientData: Participant | undefined;
  handleSaveSpouseDetails: (sectionId: number) => Promise<void>;
  setUseExistingClientForSpouse: React.Dispatch<React.SetStateAction<boolean>>;
}

// @todo - add mutation to add a spouse to an existing client
const CreateClientSpouseForExistingClient = ({
  selectedClientData,
  handleSaveSpouseDetails,
  setUseExistingClientForSpouse,
}: CreateClientSpouseForExistingClient) => {
  // This API call just needs the client id (of the client currently selected) and the spouse id (from the selectpicker)
  const [createSpouseRelationship] = useCreateClientSpouseRelationshipMutation();
  const [spouseId, setSpouseId] = useState<string>("");
  const householdId = selectedClientData?.householdId!;
  const advisorHouseholds: HouseholdState = useSelector((state: RootState) => state.householdStore);

  const clientsForHousehold = advisorHouseholds.households.find((household) => {
    return household.id === householdId;
  })?.newClients;

  const excludingSelectedClient = clientsForHousehold?.filter((client) => {
    return client.id !== selectedClientData?.id;
  });

  const handleAddSpouse = async () => {
    if (checkEmptyValue(spouseId)) {
      notify("Please select a client to add as a spouse", "error");
    }

    const inputs: any = {
      clientId: selectedClientData?.id,
      spouseId: spouseId,
    };

    const response: ApiResponseModel = await createSpouseRelationship(inputs);
    if (response.data && response.data.isSuccess) {
      notify("Successfully added spouse", "success");

      handleSaveSpouseDetails(SECTION_ID);

      setUseExistingClientForSpouse(false);
    } else if (response.data && !response.data.isSuccess) {
      const errorMessages = response.data.errorMessages;
      let errorMessage = "";
      if (errorMessages && errorMessages.length > 0) {
        errorMessage = errorMessages[0];
      }
      notify(errorMessage, "error");
    }
  };

  return (
    <>
      <div style={{ marginTop: "1rem" }}>
        <p style={{ fontSize: "1.1rem" }}>Use Existing Client</p>
      </div>
      <div>
        <select
          style={{ padding: "0.5rem 1rem" }}
          value={spouseId}
          onChange={(e) => setSpouseId(e.target.value)}
        >
          <option value="">Select a Client from Household</option>
          {excludingSelectedClient?.map((client) => {
            return (
              <option
                value={client.id}
              >{`${client.generalDetails.firstName} ${client.generalDetails.lastName} `}</option>
            );
          })}
        </select>
      </div>
      <div>
        <button style={{ marginTop: "1rem" }} className="btn btn-success" onClick={handleAddSpouse}>
          Add Client As Spouse
        </button>
      </div>
    </>
  );
};

export default CreateClientSpouseDetails;
