import React from "react";
import "./styles/NotFound.scss";
import withClientRiskCode from "../../HOC/withClientRiskCode";
function NotFound() {
  return (
    <div className="container">
      <p className="header text-center">Page Not Found</p>
      <p className="subheader text-center">
        Please contact SIA Wealth Management if issue persists. <br /> Have a
        nice day!
      </p>
    </div>
  );
}

export default NotFound;
