import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Household, Participant } from "../types";

/**
 * This endpoint uses the Household and Client Controller found in the .NET API
 * The reason for putting both household and client in here is due to the dependencies
 * between the two.
 *
 * We will also need to put in the clients in here as well
 */

const householdApiRoute = "household";
const clientApiRoute = "newClient";
const riskToleranceApiRoute = "riskTolerance";
const ipsApiRoute = "ips";

const mainApi = createApi({
  reducerPath: "householdApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    credentials: "include",
    cache: "no-cache",
  }),
  tagTypes: ["Households", "NewClients"],
  endpoints: (builder) => ({
    // Gets all households and clients from database
    getHouseholdsByAdvisor: builder.query({
      query: (advisorId: string) => ({
        url: `${householdApiRoute}/advisor/${advisorId}`,
        method: "GET",
      }),

      providesTags: ["Households", "NewClients"],
    }),
    // Creates a new household
    createHousehold: builder.mutation({
      query: (createHouseholdRequest) => ({
        url: `${householdApiRoute}`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: createHouseholdRequest,
      }),
      invalidatesTags: ["Households"],
    }),
    getParticipantsByHousehold: builder.query({
      query: (householdId) => ({
        url: `${clientApiRoute}/household/${householdId}`,
        method: "GET",
      }),
      providesTags: ["Households", "NewClients"],
    }),
    // Updates an existing household
    updateHousehold: builder.mutation({
      query: (updateHouseholdRequest: Household) => ({
        url: `${householdApiRoute}/${updateHouseholdRequest.id}`,
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: updateHouseholdRequest,
      }),
      invalidatesTags: ["Households"],
    }),
    // Creates an initial client for a household, only basic details required
    createInitialNewParticipant: builder.mutation({
      query: (createClientRequest) => ({
        url: `${clientApiRoute}/createNewParticipant`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: createClientRequest,
      }),
      invalidatesTags: ["NewClients"],
    }),
    // Create a full new client for household
    createNewClient: builder.mutation({
      query: (createClientRequest) => ({
        url: `${clientApiRoute}`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: createClientRequest,
      }),
      invalidatesTags: ["NewClients"],
    }),
    // Gets a client by its ID
    getClientById: builder.query({
      query: (clientId: string) => ({
        url: `${clientApiRoute}/${clientId}`,
        method: "GET",
      }),
      providesTags: ["Households", "NewClients"],
    }),
    // Updates a client
    updateNewClient: builder.mutation({
      query: (updateClientRequest: Participant) => ({
        url: `${clientApiRoute}/${updateClientRequest.id}`,
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: updateClientRequest,
      }),
      invalidatesTags: ["NewClients"],
    }),
    // Deletes a client
    deleteNewClient: builder.mutation({
      query: (clientId: string) => ({
        url: `${clientApiRoute}/${clientId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["NewClients"],
    }),
    // Creates a Risk Tolerance ID
    createRiskToleranceResponse: builder.mutation({
      query: (riskToleranceResponse) => ({
        url: `${riskToleranceApiRoute}`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: riskToleranceResponse,
      }),
      invalidatesTags: ["NewClients"],
    }),
    createBeneficiary: builder.mutation({
      query: (createBeneficiaryRequest) => ({
        url: `${clientApiRoute}/beneficiary`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: createBeneficiaryRequest,
      }),
      invalidatesTags: ["NewClients"],
    }),
    createBankAccount: builder.mutation({
      query: (createBankAccountRequest) => ({
        url: `${clientApiRoute}/bankAccount/${createBankAccountRequest.clientId}`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: createBankAccountRequest,
      }),
      invalidatesTags: ["NewClients"],
    }),
    createClientFromSpouseInformation: builder.mutation({
      query: (createClientRequest) => ({
        url: `${clientApiRoute}/spouse/${createClientRequest.clientId}`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: createClientRequest,
      }),
      invalidatesTags: ["NewClients"],
    }),
    createClientSpouseRelationship: builder.mutation({
      query: (createClientRequest) => ({
        url: `${clientApiRoute}/spouseRelationship`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: createClientRequest,
      }),
      invalidatesTags: ["NewClients"],
    }),
    deleteClientSpouseRelationship: builder.mutation({
      query: (participantId) => ({
        url: `${clientApiRoute}/spouseRelationship/${participantId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["NewClients"],
    }),
    sendMagicLink: builder.mutation({
      query: (createMagicLinkRequest) => ({
        url: `${riskToleranceApiRoute}/sendMagicLink`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: createMagicLinkRequest,
      }),
      invalidatesTags: ["NewClients"],
    }),
    downloadUnsignedKYCDocument: builder.query({
      query: (participantId) => ({
        url: `${clientApiRoute}/kycPdf/${participantId}`,
        method: "GET",
        responseHandler: async (response) => {
          var hiddenElement = document.createElement("a");
          var url = window.URL || window.webkitURL;
          var blob = await response.blob();
          var blobPDF = url.createObjectURL(blob);
          hiddenElement.href = blobPDF;
          hiddenElement.target = "_blank";
          hiddenElement.download = `${participantId.slice(0, 6)}_UnsignedKYC.pdf`;
          hiddenElement.click();
        },
        cache: "no-cache",
      }),
    }),
    createIPS: builder.mutation({
      query: (createIPSRequest) => ({
        url: `${ipsApiRoute}`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: createIPSRequest,
      }),
      invalidatesTags: ["NewClients"],
    }),
  }),
});

export const {
  useGetHouseholdsByAdvisorQuery,
  useCreateHouseholdMutation,
  useUpdateHouseholdMutation,
  useLazyGetHouseholdsByAdvisorQuery,
  useCreateNewClientMutation,
  useUpdateNewClientMutation,
  useDeleteNewClientMutation,
  useCreateInitialNewParticipantMutation,
  useGetClientByIdQuery,
  useLazyGetClientByIdQuery,
  useCreateRiskToleranceResponseMutation,
  useCreateBankAccountMutation,
  useCreateBeneficiaryMutation,
  useSendMagicLinkMutation,
  useCreateClientFromSpouseInformationMutation,
  useCreateClientSpouseRelationshipMutation,
  useDeleteClientSpouseRelationshipMutation,
  useLazyDownloadUnsignedKYCDocumentQuery,
  useCreateIPSMutation,
  useLazyGetParticipantsByHouseholdQuery,
} = mainApi;
export default mainApi;
