import React from "react";
import { Link, useNavigate } from "react-router-dom";

function CompleteWizard() {
  const navigate = useNavigate();
  return (
    <div>
      <p>The Risk Tolerance Questionaire has been completed.</p>
      <p>
        <span style={{ fontWeight: "bold" }} onClick={() => navigate(-1)}>
          Go Back
        </span>
      </p>
    </div>
  );
}

export default CompleteWizard;
