/**
 * Function that validates a client's general details
 * @param client
 * @returns
 **/

import { notify } from "../../../../utils";
import { Participant } from "../../../../types";

const ValidateExternalInvestments = (client: Participant) => {
  // Check Details of General Information
  return true;
};

export default ValidateExternalInvestments;
