import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CreateInvestmentAccountDto } from "types/InvestmentAccount";

const investmentAccountApiRoute = "investmentAccount";

const investmentAccountApi = createApi({
  reducerPath: "investmentAccountApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/`,
    credentials: "include",
    cache: "no-cache",
  }),
  endpoints: (builder) => ({
    // Create an Investment Account
    createInvestmentAccount: builder.mutation({
      query: (createInvestmentAccountRequest: CreateInvestmentAccountDto) => ({
        url: `${investmentAccountApiRoute}`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: createInvestmentAccountRequest,
      }),
    }),
  }),
});

export const { useCreateInvestmentAccountMutation } = investmentAccountApi;
export default investmentAccountApi;
