import { EditClientBoolean, EditClientInput, EditClientSelect } from "../Helpers/Inputs";
import { EditClientWizardItemProps } from "./EditClientWizardItemProps";
import { AddressTypes } from "../../../../assets/StaticData";
import { isValidUSZip, validatePostalCode } from "../../../../utils";

const SECTION_ID = 2;

function CreateClientAddressDetails({
  selectedClientData,
  handleDataChange,
  handleSaveClientDetails,
  handleGoBack,
}: EditClientWizardItemProps) {
  /**
   * Handles any change to the inputs in any of the address details
   * @param val
   * @param attrName
   * @param check
   */
  const handlePermAddressDetailsChange = (
    val: string | number | boolean,
    attrName: string | undefined,
    check?: boolean
  ) => {
    let value: any = "";
    if (check !== undefined) {
      value = check;
    } else {
      value = val;
    }
    if (selectedClientData && attrName) {
      let tempData = { ...selectedClientData.permanentAddressDetails };
      tempData[attrName] = value;
      handleDataChange({ permanentAddressDetails: tempData });
    }
  };

  const handleMailingAddressDetailsChange = (
    val: string | number | boolean,
    attrName: string | undefined,
    check?: boolean
  ) => {
    let value: any = "";
    if (check !== undefined) {
      value = check;
    } else {
      value = val;
    }
    if (selectedClientData && attrName) {
      let tempData = { ...selectedClientData.mailingAddressDetails };
      tempData[attrName] = value;
      handleDataChange({ mailingAddressDetails: tempData });
    }
  };

  const handleThirdPartyAddressDetailsChange = (
    val: string | number | boolean,
    attrName: string | undefined,
    check?: boolean
  ) => {
    let value: any = "";
    if (check !== undefined) {
      value = check;
    } else {
      value = val;
    }
    if (selectedClientData && attrName) {
      let tempData = { ...selectedClientData.thirdPartyAddressDetails };
      tempData[attrName] = value;
      handleDataChange({ thirdPartyAddressDetails: tempData });
    }
  };

  return (
    <div className="edit-client-wizard-item " id="edit-client-address-details">
      <div className="edit-client-wizard-item-header">
        <button
          className="edit-client-wizard-item-back"
          onClick={handleGoBack}
          style={{ fontSize: "1rem" }}
        >
          <i className="bi bi-arrow-left-circle-fill"></i>
        </button>
        <p>Permanent Address Details</p>
      </div>
      <div className="edit-client-wizard-item-body">
        <EditClientSelect
          label="Address Type"
          value={selectedClientData?.permanentAddressDetails?.addressType}
          attrName="addressType"
          selectItems={AddressTypes}
          handleChange={handlePermAddressDetailsChange}
        />
        <EditClientInput
          label="Apt/Unit/Suite Number"
          value={selectedClientData?.permanentAddressDetails?.aptUnitSuiteNumber}
          attrName="aptUnitSuiteNumber"
          handleChange={handlePermAddressDetailsChange}
          isNumber={true}
        />
        <EditClientInput
          label="Street Number"
          value={selectedClientData?.permanentAddressDetails?.streetNumber}
          attrName="streetNumber"
          handleChange={handlePermAddressDetailsChange}
          isNumber={true}
        />
        <EditClientInput
          label="Street Name"
          value={selectedClientData?.permanentAddressDetails?.streetName}
          attrName="streetName"
          handleChange={handlePermAddressDetailsChange}
        />
        <EditClientSelect
          label="Country"
          value={selectedClientData?.permanentAddressDetails?.country}
          attrName="country"
          handleChange={handlePermAddressDetailsChange}
          isCountry={true}
        />
        <EditClientSelect
          label="Region"
          value={selectedClientData?.permanentAddressDetails?.region}
          attrName="region"
          handleChange={handlePermAddressDetailsChange}
          isRegion={true}
          country={selectedClientData?.permanentAddressDetails?.country}
        />
        <EditClientInput
          label="City"
          value={selectedClientData?.permanentAddressDetails?.city}
          attrName="city"
          handleChange={handlePermAddressDetailsChange}
        />

        {(selectedClientData?.permanentAddressDetails?.country === "Canada" ||
          selectedClientData?.permanentAddressDetails?.country === "United States") && (
          <EditClientInput
            label={
              selectedClientData?.permanentAddressDetails?.country === "Canada"
                ? "Postal Code"
                : "Zip Code"
            }
            value={selectedClientData?.permanentAddressDetails?.postalCode}
            attrName="postalCode"
            handleChange={handlePermAddressDetailsChange}
            validateValue={
              selectedClientData?.permanentAddressDetails?.country === "Canada"
                ? validatePostalCode
                : isValidUSZip
            }
          />
        )}

        <EditClientBoolean
          label="Mailing Address Same as Permanent?"
          value={selectedClientData?.permanentAddressDetails?.isSameAsMailingAddress}
          inputName="isSameAsMailingAddress"
          attrName="isSameAsMailingAddress"
          handleChange={handlePermAddressDetailsChange}
        />
        <EditClientBoolean
          label="Has Third Party Address?"
          value={selectedClientData?.permanentAddressDetails?.hasThirdPartyAddress}
          inputName="hasThirdPartyAddress"
          attrName="hasThirdPartyAddress"
          handleChange={handlePermAddressDetailsChange}
        />
      </div>
      {!selectedClientData?.permanentAddressDetails?.isSameAsMailingAddress && (
        <>
          <div className="edit-client-wizard-item-header">
            <p>Mailing Address Details</p>
          </div>
          <div className="edit-client-wizard-item-body">
            <EditClientSelect
              label="Address Type"
              value={selectedClientData?.mailingAddressDetails?.addressType}
              attrName="addressType"
              selectItems={AddressTypes}
              handleChange={handleMailingAddressDetailsChange}
            />
            <EditClientInput
              label="Apt/Unit/Suite Number"
              value={selectedClientData?.mailingAddressDetails?.aptUnitSuiteNumber}
              attrName="aptUnitSuiteNumber"
              handleChange={handleMailingAddressDetailsChange}
              isNumber={true}
            />
            <EditClientInput
              label="Street Number"
              value={selectedClientData?.mailingAddressDetails?.streetNumber}
              attrName="streetNumber"
              handleChange={handleMailingAddressDetailsChange}
              isNumber={true}
            />
            <EditClientInput
              label="Street Name"
              value={selectedClientData?.mailingAddressDetails?.streetName}
              attrName="streetName"
              handleChange={handleMailingAddressDetailsChange}
            />
            <EditClientSelect
              label="Country"
              value={selectedClientData?.mailingAddressDetails?.country}
              attrName="country"
              handleChange={handleMailingAddressDetailsChange}
              isCountry={true}
            />
            <EditClientSelect
              label="Region"
              value={selectedClientData?.mailingAddressDetails?.region}
              attrName="region"
              handleChange={handleMailingAddressDetailsChange}
              isRegion={true}
              country={selectedClientData?.mailingAddressDetails?.country}
            />
            <EditClientInput
              label="City"
              value={selectedClientData?.mailingAddressDetails?.city}
              attrName="city"
              handleChange={handleMailingAddressDetailsChange}
            />
            {(selectedClientData?.mailingAddressDetails?.country === "Canada" ||
              selectedClientData?.mailingAddressDetails?.country === "United States") && (
              <EditClientInput
                label={
                  selectedClientData?.mailingAddressDetails?.country === "Canada"
                    ? "Postal Code"
                    : "Zip Code"
                }
                value={selectedClientData?.mailingAddressDetails?.postalCode}
                attrName="postalCode"
                handleChange={handleMailingAddressDetailsChange}
                validateValue={
                  selectedClientData?.mailingAddressDetails?.country === "Canada"
                    ? validatePostalCode
                    : isValidUSZip
                }
              />
            )}
          </div>
        </>
      )}
      {selectedClientData?.permanentAddressDetails?.hasThirdPartyAddress && (
        <>
          <div className="edit-client-wizard-item-header">
            <p>Third Party Address Details</p>
          </div>
          <div className="edit-client-wizard-item-body">
            <EditClientSelect
              label="Address Type"
              value={selectedClientData?.thirdPartyAddressDetails?.addressType}
              attrName="addressType"
              selectItems={AddressTypes}
              handleChange={handleThirdPartyAddressDetailsChange}
            />
            <EditClientInput
              label="Apt/Unit/Suite Number"
              value={selectedClientData?.thirdPartyAddressDetails?.aptUnitSuiteNumber}
              attrName="aptUnitSuiteNumber"
              handleChange={handleThirdPartyAddressDetailsChange}
              isNumber={true}
            />
            <EditClientInput
              label="Street Number"
              value={selectedClientData?.thirdPartyAddressDetails?.streetNumber}
              attrName="streetNumber"
              handleChange={handleThirdPartyAddressDetailsChange}
              isNumber={true}
            />
            <EditClientInput
              label="Street Name"
              value={selectedClientData?.thirdPartyAddressDetails?.streetName}
              attrName="streetName"
              handleChange={handleThirdPartyAddressDetailsChange}
            />
            <EditClientSelect
              label="Country"
              value={selectedClientData?.thirdPartyAddressDetails?.country}
              attrName="country"
              handleChange={handleThirdPartyAddressDetailsChange}
              isCountry={true}
            />
            <EditClientSelect
              label="Region"
              value={selectedClientData?.thirdPartyAddressDetails?.region}
              attrName="region"
              handleChange={handleThirdPartyAddressDetailsChange}
              isRegion={true}
              country={selectedClientData?.thirdPartyAddressDetails?.country}
            />
            <EditClientInput
              label="City"
              value={selectedClientData?.thirdPartyAddressDetails?.city}
              attrName="city"
              handleChange={handleThirdPartyAddressDetailsChange}
            />
            {(selectedClientData?.thirdPartyAddressDetails?.country === "Canada" ||
              selectedClientData?.thirdPartyAddressDetails?.country === "United States") && (
              <EditClientInput
                label={
                  selectedClientData?.thirdPartyAddressDetails?.country === "Canada"
                    ? "Postal Code"
                    : "Zip Code"
                }
                value={selectedClientData?.thirdPartyAddressDetails?.postalCode}
                attrName="postalCode"
                handleChange={handleThirdPartyAddressDetailsChange}
                validateValue={
                  selectedClientData?.thirdPartyAddressDetails?.country === "Canada"
                    ? validatePostalCode
                    : isValidUSZip
                }
              />
            )}
          </div>
        </>
      )}
      <button
        className="btn-nice-purple btn-save-client-details"
        onClick={() => handleSaveClientDetails(SECTION_ID)}
      >
        Save and Continue
      </button>
    </div>
  );
}

export default CreateClientAddressDetails;
