import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

/**
 * This endpoint uses the Client Controller found in the .NET API
 */
const clientApi = createApi({
  reducerPath: "clientApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api/client`,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    // Gets all clients from the database
    getClients: builder.query({
      query: () => ({
        url: "/",
        method: "GET",
      }),
    }),

    // Creates a Client on the database side
    createClient: builder.mutation({
      query: (createClientRequest) => ({
        url: `/`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: createClientRequest,
      }),
    }),
  }),
});

export const { useGetClientsQuery } = clientApi;
export default clientApi;
