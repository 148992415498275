import React from "react";
import { ClientDetailsProps } from "features/client/types";
import ClientDetail from "./clientDetail";

/**
 * Component is used in the Client Summary Page for Regulatory Details about the client
 * @param param0
 * @returns
 */
function ClientRegulatoryDetails({ selectedClientData }: ClientDetailsProps) {
  const regulatoryDetails = selectedClientData.regulatoryDetails;

  return (
    <div className="client-details display-column" id="client-regulatory-details">
      <ClientDetail label={"Has Power of Attorney?"} value={regulatoryDetails.hasPowerOfAttorney} />
      <div className="client-details-regulatory-section">
        <p>Insider Details</p>
      </div>
      <ClientDetail label={"Insider?"} value={regulatoryDetails.isInsider} />
      {regulatoryDetails.isInsider &&
        regulatoryDetails.insiderDetails.map((insiderDetail, index) => {
          return (
            <div className="client-regulatory-row" key={index}>
              <ClientDetail label={`Insider Symbol ${index + 1}`} value={insiderDetail.symbol} />
              <ClientDetail
                label={`Insider Company Name ${index + 1}`}
                value={insiderDetail.companyName}
              />
            </div>
          );
        })}
      <div className="client-details-regulatory-section">
        <p>Stakeholder Details</p>
      </div>
      <ClientDetail label={"Stakeholder 10%?"} value={regulatoryDetails.is10PercentShareholder} />
      {regulatoryDetails.is10PercentShareholder &&
        regulatoryDetails.shareholder10Details.map((detail, index) => {
          return (
            <div className="client-regulatory-row" key={index}>
              <ClientDetail label={`10% Shareholder Symbol ${index + 1}`} value={detail.symbol} />
              <ClientDetail
                label={`10% Shareholder Company Name ${index + 1}`}
                value={detail.companyName}
              />
            </div>
          );
        })}
      <div className="hr"></div>
      <ClientDetail label={"Stakeholder 20%?"} value={regulatoryDetails.is20PercentShareholder} />
      {regulatoryDetails.is20PercentShareholder &&
        regulatoryDetails.shareholder20Details.map((detail, index) => {
          return (
            <div className="client-regulatory-row" key={index}>
              <ClientDetail label={`20% Shareholder Symbol ${index + 1}`} value={detail.symbol} />
              <ClientDetail
                label={`20% Shareholder Company Name ${index + 1}`}
                value={detail.companyName}
              />
            </div>
          );
        })}
      <div className="client-details-regulatory-section">
        <p>IIROC Details</p>
      </div>
      <ClientDetail label={"IIROC Member?"} value={regulatoryDetails.isIIROCMember} />
      {regulatoryDetails.isIIROCMember && (
        <ClientDetail
          label="Name of IIROC Firm"
          value={regulatoryDetails.iirocDetails.nameOfFirm}
        />
      )}
      <div className="client-details-regulatory-section">
        <p>Politically Exposed Person (PEP) Details</p>
      </div>
      <ClientDetail label={"Is Foreign PEP?"} value={regulatoryDetails.isForeignPEP} />
      {regulatoryDetails.isForeignPEP && (
        <div className="client-regulatory-row">
          <ClientDetail
            label={"Foreign PEP Position Held"}
            value={regulatoryDetails.foreignPEPDetails.positionHeld}
          />
          <ClientDetail
            label={"Foreign PEP Country"}
            value={regulatoryDetails.foreignPEPDetails.country}
          />
        </div>
      )}
      <div className="hr"></div>
      <ClientDetail
        label={"Is Associate of Foreign PEP?"}
        value={regulatoryDetails.isAssociateForeignPEP}
      />
      {regulatoryDetails.isAssociateForeignPEP && (
        <div className="client-regulatory-row">
          <ClientDetail
            label={"Associate Foreign PEP Name"}
            value={regulatoryDetails.foreignAssociatePEPDetails.associateName}
          />
          <ClientDetail
            label={"Associate Foreign PEP Country"}
            value={regulatoryDetails.foreignAssociatePEPDetails.country}
          />
          <ClientDetail
            label={"Associate Foreign PEP Organization"}
            value={regulatoryDetails.foreignAssociatePEPDetails.organizationName}
          />
          <ClientDetail
            label={"Associate Foreign PEP Job Title"}
            value={regulatoryDetails.foreignAssociatePEPDetails.jobTitle}
          />
          <ClientDetail
            label={"Associate Foreign PEP Relationship"}
            value={regulatoryDetails.foreignAssociatePEPDetails.relationship}
          />
          <ClientDetail
            label={"Associate Foreign PEP Position Held"}
            value={regulatoryDetails.foreignAssociatePEPDetails.positionHeld}
          />
        </div>
      )}
      <div className="hr"></div>
      <ClientDetail label={"Is Domestic PEP?"} value={regulatoryDetails.isDomesticPEP} />
      {regulatoryDetails.domesticPEPDetails && (
        <ClientDetail
          label="Domestic PEP Position Held"
          value={regulatoryDetails.domesticPEPDetails.positionHeld}
        />
      )}
      <div className="hr"></div>
      <ClientDetail
        label={"Is Associate of Domestic PEP?"}
        value={regulatoryDetails.isAssociateDomesticPEP}
      />
      {regulatoryDetails.isAssociateDomesticPEP && (
        <div className="client-regulatory-row">
          <ClientDetail
            label={"Associate Foreign PEP Name"}
            value={regulatoryDetails.domesticAssociatePEPDetails.associateName}
          />
          <ClientDetail
            label={"Associate Foreign PEP Organization"}
            value={regulatoryDetails.domesticAssociatePEPDetails.organizationName}
          />
          <ClientDetail
            label={"Associate Foreign PEP Job Title"}
            value={regulatoryDetails.domesticAssociatePEPDetails.jobTitle}
          />
          <ClientDetail
            label={"Associate Foreign PEP Relationship"}
            value={regulatoryDetails.domesticAssociatePEPDetails.relationship}
          />
          <ClientDetail
            label={"Associate Foreign PEP Position Held"}
            value={regulatoryDetails.domesticAssociatePEPDetails.positionHeld}
          />
        </div>
      )}
      <div className="client-details-regulatory-section">
        <p>Head of Internal Organization (HIO) Details</p>
      </div>
      <ClientDetail label={"Is HIO?"} value={regulatoryDetails.isHIO} />
      {regulatoryDetails.isHIO && (
        <div className="client-regulatory-row">
          <ClientDetail label={"HIO Job Title"} value={regulatoryDetails.hioDetails.jobTitle} />
          <ClientDetail label={"HIO Country"} value={regulatoryDetails.hioDetails.country} />
          <ClientDetail
            label={"Name of Organization"}
            value={regulatoryDetails.hioDetails.nameOfOrganization}
          />
        </div>
      )}
      <div className="hr"></div>
      <ClientDetail label={"Is Associate of HIO?"} value={regulatoryDetails.isAssociateHIO} />
      {regulatoryDetails.isAssociateHIO && (
        <div className="client-regulatory-row">
          <ClientDetail
            label={"Associate HIO Name"}
            value={regulatoryDetails.associateHIODetails.nameOfAssociate}
          />
          <ClientDetail
            label={"Associate HIO Job Title"}
            value={regulatoryDetails.associateHIODetails.jobTitle}
          />
          <ClientDetail
            label={"Associate HIO Country"}
            value={regulatoryDetails.associateHIODetails.country}
          />
          <ClientDetail
            label={"Associate Name of Organization"}
            value={regulatoryDetails.associateHIODetails.nameOfOrganization}
          />
        </div>
      )}
      <div className="client-details-regulatory-section">
        <p>Securities Firm Details</p>
      </div>
      <ClientDetail
        label={"Is part of Securities Firm?"}
        value={regulatoryDetails.isSecuritiesFirm}
      />
      {regulatoryDetails.isSecuritiesFirm && (
        <div className="client-regulatory-row">
          <ClientDetail
            label={"Security Firm Name"}
            value={regulatoryDetails.securitiesFirmInformation.name}
          />
          <ClientDetail
            label={"Security Firm Relationship"}
            value={regulatoryDetails.securitiesFirmInformation.relationship}
          />
          <ClientDetail
            label={"Security Firm Company Name"}
            value={regulatoryDetails.securitiesFirmInformation.companyName}
          />
          <ClientDetail
            label={"Security Firm Company Position"}
            value={regulatoryDetails.securitiesFirmInformation.companyPosition}
          />
        </div>
      )}
      <div className="client-details-regulatory-section">
        <p>Broker/Dealer Firm Details</p>
      </div>
      <ClientDetail label={"Is part of Broker/Dealer?"} value={regulatoryDetails.isBrokerDealer} />
      {regulatoryDetails.isBrokerDealer && (
        <div className="client-regulatory-row">
          <ClientDetail
            label={"Broker/Dealer Firm Name"}
            value={regulatoryDetails.brokerDealerInformation.name}
          />
          <ClientDetail
            label={"Broker/Dealer  Firm Relationship"}
            value={regulatoryDetails.brokerDealerInformation.relationship}
          />
          <ClientDetail
            label={"Broker/Dealer  Firm Company Name"}
            value={regulatoryDetails.brokerDealerInformation.companyName}
          />
          <ClientDetail
            label={"Broker/Dealer  Firm Company Position"}
            value={regulatoryDetails.brokerDealerInformation.companyPosition}
          />
        </div>
      )}
    </div>
  );
}

export default ClientRegulatoryDetails;
