import { Participant } from "types";
import { CreateInvestmentAccountDto } from "../types";
import { SelectItem } from "types/Shared";
import { useEffect, useState } from "react";
import { HouseholdState } from "stores/Redux/householdSlice";
import { RootState } from "stores/store";
import { useSelector } from "react-redux";
import { EditClientSelect } from "Components/Pages/CreateFullClient/Helpers/Inputs";
import { checkEmptyValue } from "utils";

interface AddExistingParticipantWithRoleToAccountProps {
  participant: Participant;
  roleLabel: string;
  role: string;
  account: CreateInvestmentAccountDto;
  setAccount: React.Dispatch<React.SetStateAction<CreateInvestmentAccountDto>>;
  setIsAddingParticipant: React.Dispatch<React.SetStateAction<boolean>>;
  handleParticipantAdd: (participantId: string) => void;
  requiredSINAndDOB?: boolean;
}

const AddExistingParticipantWithRoleToAccount = ({
  participant,
  roleLabel,
  role,
  handleParticipantAdd,
  setIsAddingParticipant,
  requiredSINAndDOB,
}: AddExistingParticipantWithRoleToAccountProps) => {
  const [existingParticipantsSelect, setExistingParticipantsSelect] = useState<SelectItem[]>([]); // Lets us add an RESP Beneficiary
  const advisorHouseholds: HouseholdState = useSelector((state: RootState) => state.householdStore);
  const [selectedParticipant, setSelectedParticipant] = useState<string>(""); // Lets us add an RESP Beneficiary

  /**
   * Sets up the Spousal Contributers to the form
   */
  useEffect(() => {
    if (advisorHouseholds && advisorHouseholds.households.length > 0 && participant) {
      // Find the participant's household
      const participantHousehold = advisorHouseholds.households.find(
        (household) => household.id == participant.householdId
      );

      // If the participant household exists
      // find the possible RESP Beneficiaries in the household
      if (participantHousehold) {
        let existingRoleParticipants = participantHousehold.newClients.filter(
          (householdParticipant) =>
            householdParticipant.roles.includes(role.toLowerCase()) &&
            householdParticipant.id != participant.id
        );

        // If we need SINs and DOBs, do another filter
        // to make sure we have them
        if (requiredSINAndDOB) {
          existingRoleParticipants = existingRoleParticipants.filter((participant) => {
            return (
              !checkEmptyValue(participant.generalDetails.sin) &&
              !checkEmptyValue(participant.generalDetails.dateOfBirth)
            );
          });
        }

        if (existingRoleParticipants.length > 0) {
          const selectItems: SelectItem[] = existingRoleParticipants.map((participant) => {
            return {
              label: `${participant.generalDetails.firstName} ${participant.generalDetails.lastName}`,
              value: participant.id,
            };
          });

          setExistingParticipantsSelect(selectItems);
        }
      }
    }
  }, [advisorHouseholds]);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleParticipantAdd(selectedParticipant);
  };

  return (
    <form onSubmit={handleFormSubmit} style={{ borderTop: "1px solid black", marginTop: "1rem" }}>
      <p className="form-header" style={{ fontSize: "1.2rem" }}>
        Existing {roleLabel}
      </p>
      <EditClientSelect
        label={`Select ${roleLabel}`}
        value={selectedParticipant}
        selectItems={existingParticipantsSelect}
        handleChange={(e) => setSelectedParticipant(e.toString())}
        defaultValue="Select Participant..."
      />
      <div style={{ width: "70%", display: "flex", justifyContent: "space-evenly" }}>
        <button className="btn btn-success">Add Existing {roleLabel}</button>
      </div>
    </form>
  );
};

export default AddExistingParticipantWithRoleToAccount;
