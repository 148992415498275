import {
  CurrencyOptions,
  DefaultInvestMentAccountSubTypes,
  DefaultInvestMentAccountTypes,
  IntendedUseOfAccounts,
} from "../../../assets/StaticData";

import {
  BeneficiaryTypeRequiredAccounts,
  JointTypeRequiredAccounts,
} from "../../../assets/StaticData/InvestmentAccount";

import { SourceOfFundsOptions } from "../../../assets/StaticData/EditClientOptions";
import { Participant } from "types";
import { CreateInvestmentAccountDto } from "../types/CreateInvestmentAccountDto";
import {
  EditClientBoolean,
  EditClientSelect,
} from "../../../Components/Pages/CreateFullClient/Helpers/Inputs";
import { useEffect, useState } from "react";
import { ResetCreateAccountType } from "../utils";
import { SuccessorAnnuitantTypeRequiredAccounts } from "assets/StaticData/InvestmentAccount/AccountTypeRequiredData";

export interface InvestmentAccountDetailsProps {
  account: CreateInvestmentAccountDto;
  participant: Participant | undefined;
  setNewInvestmentAccount: React.Dispatch<React.SetStateAction<CreateInvestmentAccountDto>>;
  handleGoNext?: () => void;
  handleCancelAccountCreate?: () => void;
  householdParticipants?: Participant[];
}

const CreateInvestmentAccountGeneralDetails = ({
  account,
  participant,
  handleGoNext,
  setNewInvestmentAccount,
  handleCancelAccountCreate,
}: InvestmentAccountDetailsProps) => {
  /**
   * Handles changes to the investment account
   * If the change is type, we need to reset the subtype as well as the other boolean fields
   * If the change is subtype, then we need to reset the other boolean fields
   *
   * The boolean fields that need to be worried about are ones that are dependent on the account and subtype
   *
   * For EFTs, Transfers,  and PACs, we don't need to worry about it because they are always allowed
   */
  const handleInvestmentAccountChanges = (
    val: string | number | boolean,
    attrName: string | undefined,
    check?: boolean
  ) => {
    let value: any = "";
    if (check !== undefined) {
      value = check;
    } else {
      value = val;
    }
    if (account && attrName) {
      let tempData = { ...account };

      // Reset Subtype if Type is changed
      if (attrName === "type") {
        ResetCreateAccountType(tempData);
      }

      setNewInvestmentAccount({ ...tempData, [attrName]: value });
    }
  };

  const handleDesignationChange = (e: any, designation: string) => {
    const tempAccount = { ...account };
    if (designation == "successor") {
      tempAccount.allowBeneficiarys = false;
      tempAccount.allowSuccessorAnnuitants = true;
      tempAccount.allowEstate = false;

      // We need to reset the beneficiarys
      tempAccount.beneficiarys = [];

      // If the participant has a spouse in the system, we need to add them as a successor
      if (participant && participant.spouseDetails && participant.spouseDetails?.participantId) {
        tempAccount.successorAnnuitants = [participant.spouseDetails?.participantId];
      }
    } else if (designation == "beneficiary") {
      // Beneficiaries will be added
      tempAccount.allowBeneficiarys = true;
      tempAccount.allowSuccessorAnnuitants = false;
      tempAccount.allowEstate = false;

      // Resey the successor annuitants
      tempAccount.successorAnnuitants = [];
    } else {
      tempAccount.allowBeneficiarys = false;
      tempAccount.allowSuccessorAnnuitants = false;
      tempAccount.allowEstate = true;

      // Reset both the beneficiarys and successor annuitants
      tempAccount.successorAnnuitants = [];
      tempAccount.beneficiarys = [];
    }

    setNewInvestmentAccount(tempAccount);
  };

  /**
   * Adds the current client to the account holders array
   */
  useEffect(() => {
    if (participant && account) {
      console.log(participant.id);
      let tempData = { ...account };
      if (tempData.accountHolders && !tempData.accountHolders.includes(participant.id)) {
        tempData.accountHolders.push(participant.id);
        setNewInvestmentAccount(tempData);
      } else {
        setNewInvestmentAccount({ ...tempData, accountHolders: [participant.id] });
      }
    }
  }, []);

  return (
    <div className="edit-client-wizard-item" id="edit-invest-account-general-details">
      <div className="edit-client-wizard-item-header">
        <p>General Details</p>
        {handleCancelAccountCreate && (
          <div className="btn-cancel-container">
            <button className="btn btn-danger" onClick={handleCancelAccountCreate}>
              Cancel
            </button>
          </div>
        )}
      </div>
      <div className="edit-client-wizard-item-body">
        <EditClientSelect
          label="Account Type"
          attrName="type"
          selectItems={DefaultInvestMentAccountTypes}
          value={account.type}
          handleChange={handleInvestmentAccountChanges}
        />
        <EditClientSelect
          label="Account Sub Type"
          attrName="subType"
          selectItems={DefaultInvestMentAccountSubTypes[account.type]}
          value={account.subType}
          handleChange={handleInvestmentAccountChanges}
        />
        {BeneficiaryTypeRequiredAccounts.includes(Number(account.type)) && (
          <div style={{ margin: "1rem", display: "flex", width: "100%", alignItems: "center" }}>
            <label style={{ minWidth: "310px", fontWeight: "bold" }}>Beneficiary Designation</label>
            <div>
              {SuccessorAnnuitantTypeRequiredAccounts.includes(Number(account.type)) && (
                <div style={{ minWidth: "10%" }}>
                  <input
                    type="radio"
                    name="account-designation"
                    onClick={(e) => handleDesignationChange(e, "successor")}
                  />
                  <label style={{ fontWeight: "600", marginLeft: "0.5rem" }}>
                    Successor Annuitant
                  </label>
                </div>
              )}

              <div style={{ minWidth: "10%" }}>
                <input
                  type="radio"
                  name="account-designation"
                  onClick={(e) => handleDesignationChange(e, "beneficiary")}
                />
                <label style={{ fontWeight: "600", marginLeft: "0.5rem" }}>Beneficiary</label>
              </div>
              <div style={{ minWidth: "10%" }}>
                <input
                  type="radio"
                  name="account-designation"
                  onClick={(e) => handleDesignationChange(e, "estate")}
                />
                <label style={{ fontWeight: "600", marginLeft: "0.5rem" }}>Estate</label>
              </div>
            </div>
          </div>
        )}
        <EditClientSelect
          label="Currency"
          value={account.currency}
          attrName="currency"
          selectItems={CurrencyOptions}
          handleChange={handleInvestmentAccountChanges}
        />
        <EditClientSelect
          label="Intended Use of Account"
          value={account.intendedUseOfAccount}
          attrName="intendedUseOfAccount"
          selectItems={IntendedUseOfAccounts}
          handleChange={handleInvestmentAccountChanges}
        />
        <EditClientSelect
          label="Source of Funds"
          value={account.sourceOfFunds}
          attrName="sourceOfFunds"
          selectItems={SourceOfFundsOptions}
          handleChange={handleInvestmentAccountChanges}
        />
        <EditClientBoolean
          label="Borrowing to Invest?"
          value={account.isBorrowingToInvest}
          attrName="isBorrowingToInvest"
          handleChange={handleInvestmentAccountChanges}
        />

        <EditClientBoolean
          label="Allow EFTs?"
          value={account.allowEFT}
          attrName="allowEFT"
          handleChange={handleInvestmentAccountChanges}
        />
        {JointTypeRequiredAccounts.includes(Number(account.type)) && (
          <EditClientBoolean
            label="Is Joint Account?"
            value={account.isJointAccount}
            attrName="isJointAccount"
            handleChange={handleInvestmentAccountChanges}
          />
        )}

        {/* Beneficiaries Not Allowed for Margin or Cash */}
        {/* {BeneficiaryTypeRequiredAccounts.includes(Number(account.type)) && (
          <EditClientBoolean
            label="Allow Beneficiaries?"
            value={account.allowBeneficiarys}
            attrName="allowBeneficiarys"
            handleChange={handleInvestmentAccountChanges}
          />
        )}
        {SuccessorAnnuitantTypeRequiredAccounts.includes(Number(account.type)) && (
          <EditClientBoolean
            label="Allow Successor Annuitant?"
            value={account.allowSuccessorAnnuitants}
            attrName="allowSuccessorAnnuitants"
            handleChange={handleInvestmentAccountChanges}
          />
        )} */}

        <EditClientBoolean
          label="Allow Transfer?"
          value={account.allowTransfers}
          attrName="allowTransfers"
          handleChange={handleInvestmentAccountChanges}
        />
        <EditClientBoolean
          label="Allow PACs?"
          value={account.allowPAC}
          attrName="allowPAC"
          handleChange={handleInvestmentAccountChanges}
        />
        <EditClientBoolean
          label="Allow SWPs?"
          value={account.allowSWP}
          attrName="allowSWP"
          handleChange={handleInvestmentAccountChanges}
        />

        {handleGoNext && (
          <button className="btn-nice-purple btn-save-client-details" onClick={handleGoNext}>
            Continue
          </button>
        )}
      </div>
    </div>
  );
};

export default CreateInvestmentAccountGeneralDetails;
