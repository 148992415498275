/**
 * This Store will be used for the Logged In User
 * We can possibly implement an admin only functionality where we can use a different store for that
 */

import { createSlice } from "@reduxjs/toolkit";
import { GuidValues } from "../../constants/enums";

const emptyNewClientRiskState = {
  clientId: GuidValues.EMPTY_GUID,
  clientFullName: "",
  advisorId: GuidValues.EMPTY_GUID,
  responseId: GuidValues.EMPTY_GUID,
  riskScoreSections: [],
};

export const newClientRiskSlice = createSlice({
  name: "newClientRiskSlice",
  initialState: emptyNewClientRiskState,
  reducers: {
    setNewClientRiskState: (state, action) => {
      state.clientId = action.payload.clientId;
      state.clientFullName = action.payload.clientFullName;
      state.advisorId = action.payload.advisorId;
      state.responseId = action.payload.responseId;
      state.riskScoreSections = action.payload.riskScoreSections;
    },
  },
});

export const { setNewClientRiskState } = newClientRiskSlice.actions;
export const newClientRiskSliceReducer = newClientRiskSlice.reducer;
