import { Participant } from "../../../../types";
import {
  CreateFullClientSummaryAddressDetails,
  CreateFullClientSummaryEmploymentDetails,
  CreateFullClientSummaryExternalInvestmentDetails,
  CreateFullClientSummaryGeneralDetails,
  CreateFullClientSummaryNetWorthDetails,
  CreateFullClientSummaryRegulatoryDetails,
  CreateFullClientSummarySpouseDetails,
} from "../Helpers/Summary";

interface CreateFullClientSummaryProps {
  client: Participant | undefined;
  handleGoBack: () => void;
  handleFinalSaveDetails: () => void;
}

const CreateFullClientSummary = ({
  client,
  handleGoBack,
  handleFinalSaveDetails,
}: CreateFullClientSummaryProps) => {
  return (
    <div className="create-client-summary edit-client-wizard-item" id="edit-client-summary-details">
      <div className="edit-client-wizard-item-header">
        <button
          className="edit-client-wizard-item-back"
          onClick={handleGoBack}
          style={{ fontSize: "1rem" }}
        >
          <i className="bi bi-arrow-left-circle-fill"></i>
        </button>
        <p>Review Details</p>
      </div>
      {/* General Details */}
      <CreateFullClientSummaryGeneralDetails client={client} />
      {/* Address Details */}
      <CreateFullClientSummaryAddressDetails client={client} />
      {/* Employment Details */}
      <CreateFullClientSummaryEmploymentDetails client={client} />

      {/* Spouse Details */}
      {client?.spouseDetails && <CreateFullClientSummarySpouseDetails client={client} />}

      <CreateFullClientSummaryNetWorthDetails client={client} />

      <CreateFullClientSummaryExternalInvestmentDetails client={client} />
      <CreateFullClientSummaryRegulatoryDetails client={client} />
      {/* to for tomorrow: finish this summary page, style up and logizize the action items,
        make sure the PDF functionality works, start to work on Accounts */}
      <div className="create-client-summary-submit">
        <button onClick={handleFinalSaveDetails}>Submit Details</button>
      </div>
    </div>
  );
};

export default CreateFullClientSummary;
