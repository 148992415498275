import { SelectItem } from "../../../types/Shared";
import { InvestmentAccountType } from "../../../constants/enums";

/**
 * These are the default investment account types that are used when creating a new client.
 */
const DefaultInvestMentAccountTypes: SelectItem[] = [
  {
    value: InvestmentAccountType.CASH,
    label: "Cash",
  },
  {
    value: InvestmentAccountType.MARGIN,
    label: "Margin",
  },
  {
    value: InvestmentAccountType.CASH_IN_TRUST_FOR,
    label: "In Trust For",
  },
  {
    value: InvestmentAccountType.CASH_LEVERAGED,
    label: "Cash (Leveraged)",
  },
  {
    value: InvestmentAccountType.TFSA,
    label: "Tax-Free Savings Account",
  },
  {
    value: InvestmentAccountType.RESP,
    label: "Registered Education Savings Account",
  },
  {
    value: InvestmentAccountType.RSP,
    label: "Registered Savings Account",
  },
  {
    value: InvestmentAccountType.RIF,
    label: "Registered Income Fund",
  },
];

export default DefaultInvestMentAccountTypes;
