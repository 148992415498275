import { IPSSectionComponentProps } from "../types";

/**
 * Component for the Client Summary Section of the IPS
 * @param param0
 * @returns
 */
const IPSClientSummary = ({ formData, setFormData }: IPSSectionComponentProps) => {
  const handleClientSummaryChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newVal = e.target.value;
    const temp = { ...formData, clientSummary: newVal };
    setFormData(temp);
  };

  return (
    <div className="ips-section-container">
      <div className="ips-section-header-container">
        <p className="ips-section-header">Please Provide a Client Summary</p>
      </div>
      <div className="ips-section-body-container">
        <textarea value={formData.clientSummary} onChange={handleClientSummaryChange} />
      </div>
    </div>
  );
};

export default IPSClientSummary;
