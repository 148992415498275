import { ClientDetailsProps } from "features/client/types";
import ClientDetail from "./clientDetail";
import { AddressTypeMappingById } from "../../../constants/mappings";

function ClientAddressDetails({ selectedClientData }: ClientDetailsProps) {
  return (
    <>
      {selectedClientData.permanentAddressDetails ? (
        <div className="client-details" id="client-address-details">
          <ClientDetail
            label="Perm. Address"
            value={`${selectedClientData.permanentAddressDetails.aptUnitSuiteNumber} 
                    ${selectedClientData.permanentAddressDetails.streetName}, 
                    ${selectedClientData.permanentAddressDetails.city},
                    ${selectedClientData.permanentAddressDetails.region},
                    ${selectedClientData.permanentAddressDetails.country},
                    ${selectedClientData.permanentAddressDetails.postalCode} `}
          />
          <ClientDetail
            label="Perm. Address Type"
            value={AddressTypeMappingById[selectedClientData?.permanentAddressDetails.addressType]}
          />

          {!selectedClientData.permanentAddressDetails.isSameAsMailingAddress && (
            <>
              <ClientDetail
                label="Mailing Address"
                value={`${selectedClientData.mailingAddressDetails.aptUnitSuiteNumber} 
                    ${selectedClientData.mailingAddressDetails.streetName}, 
                    ${selectedClientData.mailingAddressDetails.city},
                    ${selectedClientData.mailingAddressDetails.region},
                    ${selectedClientData.mailingAddressDetails.country},
                    ${selectedClientData.mailingAddressDetails.postalCode} `}
              />
              <ClientDetail
                label="Mail Address Type"
                value={AddressTypeMappingById[selectedClientData.mailingAddressDetails.addressType]}
              />
            </>
          )}
          {selectedClientData.permanentAddressDetails.hasThirdPartyAddress && (
            <>
              <ClientDetail
                label="Third Party Address"
                value={`${selectedClientData.thirdPartyAddressDetails.aptUnitSuiteNumber} 
                    ${selectedClientData.thirdPartyAddressDetails.streetName}, 
                    ${selectedClientData.thirdPartyAddressDetails.city},
                    ${selectedClientData.thirdPartyAddressDetails.region},
                    ${selectedClientData.thirdPartyAddressDetails.country},
                    ${selectedClientData.thirdPartyAddressDetails.postalCode} `}
              />
              <ClientDetail
                label="Third Party Address Type"
                value={
                  AddressTypeMappingById[selectedClientData.thirdPartyAddressDetails.addressType]
                }
              />
            </>
          )}
        </div>
      ) : (
        <div className="client-details" id="client-address-details">
          <p>Address Details Not Found. Please complete KYC</p>
        </div>
      )}
    </>
  );
}

export default ClientAddressDetails;
