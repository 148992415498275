import { ClientCitizenshipOptions } from "../../../../constants/selectOptions";
import { validateEmail } from "../../../../utils";
import validatePhone from "../../../../utils/validatePhone";
import validateSIN from "../../../../utils/validateSIN";
import { CitizenshipType } from "../../../../constants/enums";
import { EditClientBoolean, EditClientInput, EditClientSelect } from "../Helpers/Inputs";
import { EditClientWizardItemProps } from "./EditClientWizardItemProps";
import { ClientTitleOptions, MaritalStatusOptions } from "../../../../constants/selectOptions";
const SECTION_ID = 1;

/**
 * This component is used to create or edit the general details of a client
 * Passes in a client as well as the handling of the client data changes from the parent component
 * that holds the full client data
 * @param param0
 * @returns
 */
const CreateClientGeneralDetails = ({
  selectedClientData,
  handleDataChange,
  handleSaveClientDetails,
}: EditClientWizardItemProps) => {
  const handleGeneralDetailsChange = (
    val: number | string | boolean,
    attrName: string | undefined,
    check?: boolean
  ) => {
    let value: any = "";
    if (check !== undefined) {
      value = check;
    } else {
      value = val;
    }

    if (selectedClientData && attrName) {
      let tempData = { ...selectedClientData.generalDetails };
      tempData[attrName] = value;
      handleDataChange({ generalDetails: tempData });
    }
  };

  return (
    <div className="edit-client-wizard-item active" id="edit-client-general-details">
      <div className="edit-client-wizard-item-header">
        <p>General Details</p>
      </div>
      <div className="edit-client-wizard-item-body">
        <EditClientBoolean
          label="Is Individual?"
          value={selectedClientData?.generalDetails.isIndividual}
          attrName="isIndividual"
          handleChange={handleGeneralDetailsChange}
          inputName="isIndividual"
        />
        <EditClientSelect
          label="Title"
          value={selectedClientData?.generalDetails.title}
          attrName="title"
          handleChange={handleGeneralDetailsChange}
          selectItems={ClientTitleOptions}
        />
        <EditClientInput
          label="First Name"
          value={selectedClientData?.generalDetails.firstName}
          attrName="firstName"
          handleChange={handleGeneralDetailsChange}
          isRequired={true}
        />
        <EditClientInput
          label="Middle Initial"
          value={selectedClientData?.generalDetails.middleInitial}
          attrName="middleInitial"
          handleChange={handleGeneralDetailsChange}
        />
        <EditClientInput
          label="Last Name"
          value={selectedClientData?.generalDetails.lastName}
          attrName="lastName"
          handleChange={handleGeneralDetailsChange}
          isRequired={true}
        />
        <EditClientSelect
          label="Marital Status"
          value={selectedClientData?.generalDetails.maritalStatus}
          attrName="maritalStatus"
          selectItems={MaritalStatusOptions}
          handleChange={handleGeneralDetailsChange}
        />

        <EditClientInput
          label="Social Insurance Number"
          value={selectedClientData?.generalDetails.sin}
          attrName="sin"
          handleChange={handleGeneralDetailsChange}
          validateValue={validateSIN}
          isRequired={true}
        />
        <EditClientInput
          label="Date Of Birth"
          value={selectedClientData?.generalDetails.dateOfBirth}
          attrName="dateOfBirth"
          isDate={true}
          handleChange={handleGeneralDetailsChange}
          isRequired={true}
        />
        <EditClientInput
          label="Number of Dependents"
          value={selectedClientData?.generalDetails.numDependents}
          attrName="numDependents"
          isNumber={true}
          handleChange={handleGeneralDetailsChange}
          isRequired={true}
        />
        <EditClientInput
          label="Home Phone #"
          value={selectedClientData?.generalDetails.homePhone}
          attrName="homePhone"
          handleChange={handleGeneralDetailsChange}
          placeholder="123-456-7890"
          validateValue={validatePhone}
        />
        <EditClientInput
          label="Cell Phone #"
          value={selectedClientData?.generalDetails.cellPhone}
          attrName="cellPhone"
          handleChange={handleGeneralDetailsChange}
          validateValue={validatePhone}
          isRequired={true}
        />
        <EditClientInput
          label="Business Phone #"
          value={selectedClientData?.generalDetails.businessPhone}
          attrName="businessPhone"
          handleChange={handleGeneralDetailsChange}
          placeholder="123-456-7890"
          validateValue={validatePhone}
        />
        <EditClientInput
          label="Business Phone Ext."
          value={selectedClientData?.generalDetails.businessPhoneExt}
          attrName="businessPhoneExt"
          handleChange={handleGeneralDetailsChange}
        />

        <EditClientInput
          label="Email Address"
          value={selectedClientData?.generalDetails.email}
          attrName="email"
          handleChange={handleGeneralDetailsChange}
          validateValue={validateEmail}
          isRequired={true}
        />
        <EditClientSelect
          label="Citizenship"
          selectItems={ClientCitizenshipOptions}
          value={selectedClientData?.generalDetails.citizenship}
          attrName="citizenship"
          handleChange={handleGeneralDetailsChange}
          isRequired={true}
        />
        {selectedClientData?.generalDetails.citizenship == CitizenshipType.OTHER && (
          <EditClientInput
            label="Explain Citizenship"
            value={selectedClientData?.generalDetails.otherCitizenship}
            attrName="otherCitizenship"
            handleChange={handleGeneralDetailsChange}
            isRequired={true}
          />
        )}
        <EditClientInput
          label="Language of Correspondence"
          value={selectedClientData?.generalDetails.language}
          attrName="language"
          handleChange={handleGeneralDetailsChange}
        />
      </div>
      <button
        className="btn-nice-purple btn-save-client-details"
        onClick={() => handleSaveClientDetails(SECTION_ID)}
      >
        Save and Continue
      </button>
    </div>
  );
};

export default CreateClientGeneralDetails;
