import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetClientByIdQuery } from "Apis/mainApi";
import { Participant } from "types";
import withAuth from "HOC/withAuth";
import $ from "jquery";
import "../styles/createInvestmentAccount.scss";
import { WizardListItem } from "Components/Pages/CreateFullClient/Helpers/Wizard";
import { CreateInvestmentAccountDto } from "features/createInvestmentAccount/types/CreateInvestmentAccountDto";
import AccountDetails from "./AccountDetails";
import { InvestmentAccountSubType, InvestmentAccountType } from "constants/enums";
import {
  BeneficiaryTypeRequiredAccounts,
  JointTypeRequiredAccounts,
} from "assets/StaticData/InvestmentAccount";
import CreateInvestmentAccountRESPBeneficiaryDetails from "./CreateInvestmentAccountRESPBeneficiaryDetails";
import { EmptyInvestmentAccount } from "constants/emptyStates";
import CreateInvestmentAccountGeneralDetails from "./CreateInvestmentAccountGeneralDetails";
import CreateInvestmentAccountJointDetails from "./CreateInvestmentAccountJointDetails";
import CreateInvestmentAccountBeneficiaryDetails, {
  SetExistingHouseholdParticipants,
} from "./CreateInvestmentAccountBeneficiaryDetails";
import CreateInvestmentAccountEFTDetails from "./CreateInvestmentAccountEFTDetails";
import CreateInvestmentAccountTransferDetails from "./CreateInvestmentAccountTransferDetails";
import CreateInvestmentAccountPACDetails from "./CreateInvestmentAccountPACDetails";
import CreateInvestmentAccountITFDetails from "./CreateInvestmentAccountITFDetails";
import CreateInvestmentAccountSWPDetails from "./CreateInvestmentAccountSWPDetails";
import CreateInvestmentAccountReviewDetails from "./CreateInvestmentAccountReviewDetails";
import CreateInvestmentAccountSpouseContributerDetails from "./CreateInvestmentAccountSpouseContributerDetails";
import CreateInvestmentAccountIncomeFundDetails from "./CreateInvestmentAccountIncomeFundDetails";
import { SuccessorAnnuitantTypeRequiredAccounts } from "assets/StaticData/InvestmentAccount/AccountTypeRequiredData";
import CreateInvestmentAccountSuccessorDetails from "./CreateInvestmentAccountSuccessorDetails";
import { HouseholdState } from "stores/Redux/householdSlice";
import { useSelector } from "react-redux";
import { RootState } from "stores/store";

/**
 * Steps Required
 *
 * Bank Account(s) Must First Be Created
 *
 * Then, Investment Account(s) Must Be Created
 * @returns
 */

function CreateInvestmentAccount() {
  const navigate = useNavigate();
  const { participantId } = useParams();
  const [participantGetQuery, participantResult] = useLazyGetClientByIdQuery();
  const [selectedParticipant, setSelectedParticipant] = useState<Participant>();
  const [isCreatingAccount, setIsCreatingAccount] = useState<boolean>(false);
  const [newInvestmentAccount, setNewInvestmentAccount] =
    useState<CreateInvestmentAccountDto>(EmptyInvestmentAccount);
  const advisorHouseholds: HouseholdState = useSelector((state: RootState) => state.householdStore);
  const [householdParticipants, setHouseholdParticipants] = useState<Participant[]>([]);

  useEffect(() => {
    if (participantId) {
      participantGetQuery(participantId);
    }
  }, [participantId]);

  /**
   * Setting the participant data to the state variable as well as setting up the account holders for the account
   */
  useEffect(() => {
    if (participantResult.isSuccess) {
      const result: Participant = { ...participantResult.data.result }; // Makes a copy of the result and stores in the state variable
      setSelectedParticipant(result);

      // Setting up the account holder
      if (!newInvestmentAccount.accountHolders) {
        setNewInvestmentAccount({
          ...newInvestmentAccount,
          accountHolders: [result.id],
        });
      }

      // If the account holders array does not include the participant id, add it
      else if (!newInvestmentAccount.accountHolders.includes(result.id)) {
        setNewInvestmentAccount({
          ...newInvestmentAccount,
          accountHolders: [...newInvestmentAccount.accountHolders, result.id],
        });
      }
    }
  }, [participantResult]);

  useEffect(() => {
    if (advisorHouseholds && selectedParticipant) {
      SetExistingHouseholdParticipants(
        advisorHouseholds,
        selectedParticipant,
        setHouseholdParticipants
      );
      console.log("hello");
    }
  }, [advisorHouseholds, selectedParticipant]);

  /**
   * Legacy function used to handle the change of the step
   * when a user clicks on a list element in the select menu
   *
   * Going to be used in development for quicker changes between steps
   * @param e
   * @param target
   */
  const handleStepChangeWithClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    target: string
  ) => {
    //Remove the active class from the select menu
    const $selectItemActive = $(".edit-client-progress-item.active");
    $selectItemActive.removeClass("active");
    // Make the current item active
    // Because its possible to click the p element, we need to find the parent li element
    let $currentItem = $(e.target);
    if ($currentItem.is("p")) {
      $currentItem = $currentItem.parent();
    }
    $currentItem.addClass("active");
    const $target = $(`#${target}`);
    // Find the Active List Item;
    const $activeItem = $(".edit-client-wizard-item.active");
    //console.log($activeItem);
    // Remove the Active Status
    $($activeItem).removeClass("active");
    // Set the target to active
    $target.addClass("active");
  };

  const handleStepChangeWithoutClick = (target: string) => {
    //Remove the active class from the select menu
    const $selectItemActive = $(".edit-client-progress-item.active");
    $selectItemActive.removeClass("active");

    const $target = $(`#${target}`);
    // Find the Active List Item;
    const $activeItem = $(".edit-client-wizard-item.active");
    //console.log($activeItem);
    // Remove the Active Status
    $($activeItem).removeClass("active");
    // Set the target to active
    $target.addClass("active");
  };

  const handleGoForward = () => {
    if (selectedParticipant) {
      const $selectItems = $(".edit-client-progress-item");
      // From the list, find the items with the active class
      for (var i = 0; i < $selectItems.length; i++) {
        if ($($selectItems[i]).hasClass("active")) {
          // If the item has the active class, remove it
          $($selectItems[i]).removeClass("active");
          // Add the active class to the next item
          $($selectItems[i + 1]).addClass("active");
          break;
        }
      }

      // Find all the wizard items
      const $wizardItems = $(".edit-client-wizard-item");
      // From the list, find the items with the active class
      for (var i = 0; i < $wizardItems.length; i++) {
        if ($($wizardItems[i]).hasClass("active")) {
          // If the item has the active class, remove it
          $($wizardItems[i]).removeClass("active");
          // Add the active class to the next item
          $($wizardItems[i + 1]).addClass("active");
          break;
        }
      }
    }
  };

  const handleCancelAccountCreation = () => {
    const $selectItemActive = $(".edit-client-progress-item.active");
    $selectItemActive.removeClass("active");

    const $wizardItems = $(".edit-client-wizard-item.active");
    $wizardItems.removeClass("active");

    $("#edit-account-details").addClass("active");

    setNewInvestmentAccount(EmptyInvestmentAccount);
    setIsCreatingAccount(false);
  };

  return (
    <div className="edit-client-full-container">
      <div>
        <button>Go Back</button>
        <button onClick={() => console.log(newInvestmentAccount)}>Show Form</button>
        <p style={{ fontSize: "2rem", textAlign: "center" }}>Investment Account Application</p>
        {selectedParticipant && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p style={{ fontSize: "1.3rem" }}>
              {selectedParticipant.generalDetails.firstName}{" "}
              {selectedParticipant.generalDetails.lastName} ({selectedParticipant.id.slice(0, 8)}
              ...)
            </p>
          </div>
        )}
      </div>
      <div className="edit-client-container">
        <ul className="edit-client-progress">
          {!isCreatingAccount && (
            <WizardListItem
              label="Account Details"
              target="edit-account-details"
              handleStepChange={handleStepChangeWithClick}
              isActive={!isCreatingAccount}
            />
          )}
          {isCreatingAccount && (
            <>
              <WizardListItem
                label="General Details"
                target="edit-invest-account-general-details"
                handleStepChange={handleStepChangeWithClick}
                isActive={isCreatingAccount}
              />
              {
                // If the account is a joint account, we need to get the joint account details
                // Only allowed for RESP, Margin, or Cash Account
                JointTypeRequiredAccounts.includes(Number(newInvestmentAccount.type)) &&
                  newInvestmentAccount.isJointAccount && (
                    <WizardListItem
                      label="Joint Account Details"
                      target="edit-invest-account-joint-details"
                      handleStepChange={handleStepChangeWithClick}
                    />
                  )
              }
              {BeneficiaryTypeRequiredAccounts.includes(Number(newInvestmentAccount.type)) &&
                newInvestmentAccount.allowBeneficiarys && (
                  <WizardListItem
                    label="Beneficiary Details"
                    target="edit-invest-account-beneficiary-details"
                    handleStepChange={handleStepChangeWithClick}
                  />
                )}
              {SuccessorAnnuitantTypeRequiredAccounts.includes(Number(newInvestmentAccount.type)) &&
                newInvestmentAccount.allowSuccessorAnnuitants && (
                  <WizardListItem
                    label="Successor Annuitant Details"
                    target="edit-invest-account-successor-annuitant-details"
                    handleStepChange={handleStepChangeWithClick}
                  />
                )}
              {Number(newInvestmentAccount.type) == InvestmentAccountType.RESP && (
                <WizardListItem
                  label="RESP Beneficiary Details"
                  target="edit-invest-account-resp-beneficiary-details"
                  handleStepChange={handleStepChangeWithClick}
                />
              )}
              {Number(newInvestmentAccount.type) == InvestmentAccountType.RSP &&
                Number(newInvestmentAccount.subType) == InvestmentAccountSubType.SPOUSAL && (
                  <WizardListItem
                    label="Spousal Contributer Details"
                    target="edit-invest-spouse-contributer-details"
                    handleStepChange={handleStepChangeWithClick}
                  />
                )}
              {newInvestmentAccount.allowEFT && (
                <WizardListItem
                  label="EFT Details"
                  target="edit-invest-account-eft-details"
                  handleStepChange={handleStepChangeWithClick}
                />
              )}
              {newInvestmentAccount.allowTransfers && (
                <WizardListItem
                  label="Transfer Details"
                  target="edit-invest-account-transfer-details"
                  handleStepChange={handleStepChangeWithClick}
                />
              )}
              {newInvestmentAccount.allowPAC && (
                <WizardListItem
                  label="PAC Details"
                  target="edit-invest-account-pac-details"
                  handleStepChange={handleStepChangeWithClick}
                />
              )}
              {newInvestmentAccount.allowSWP && (
                <WizardListItem
                  label="SWP Details"
                  target="edit-invest-account-swt-details"
                  handleStepChange={handleStepChangeWithClick}
                />
              )}
              {newInvestmentAccount.type == InvestmentAccountType.CASH_IN_TRUST_FOR && (
                <WizardListItem
                  label="Trust Details"
                  target="edit-invest-account-itf-details"
                  handleStepChange={handleStepChangeWithClick}
                />
              )}
              {newInvestmentAccount.type == InvestmentAccountType.RIF && (
                <WizardListItem
                  label="Income Fund Details"
                  target="edit-invest-account-income-fund-details"
                  handleStepChange={handleStepChangeWithClick}
                />
              )}
              <WizardListItem
                label="Review Details"
                target="edit-invest-account-review-details"
                handleStepChange={handleStepChangeWithClick}
              />
            </>
          )}
        </ul>
        <div className="edit-client-wizard">
          <AccountDetails
            selectedClientData={selectedParticipant}
            setIsCreatingAccount={setIsCreatingAccount}
            handleStepChange={handleStepChangeWithoutClick}
          />
          <CreateInvestmentAccountGeneralDetails
            participant={selectedParticipant}
            account={newInvestmentAccount}
            setNewInvestmentAccount={setNewInvestmentAccount}
            handleGoNext={handleGoForward}
            handleCancelAccountCreate={handleCancelAccountCreation}
          />
          {JointTypeRequiredAccounts.includes(Number(newInvestmentAccount.type)) &&
            newInvestmentAccount.isJointAccount && (
              <CreateInvestmentAccountJointDetails
                participant={selectedParticipant}
                account={newInvestmentAccount}
                setNewInvestmentAccount={setNewInvestmentAccount}
                handleGoNext={handleGoForward}
              />
            )}
          {BeneficiaryTypeRequiredAccounts.includes(Number(newInvestmentAccount.type)) &&
            newInvestmentAccount.allowBeneficiarys && (
              <CreateInvestmentAccountBeneficiaryDetails
                participant={selectedParticipant}
                account={newInvestmentAccount}
                setNewInvestmentAccount={setNewInvestmentAccount}
                handleGoNext={handleGoForward}
              />
            )}
          {SuccessorAnnuitantTypeRequiredAccounts.includes(Number(newInvestmentAccount.type)) &&
            newInvestmentAccount.allowSuccessorAnnuitants && (
              <CreateInvestmentAccountSuccessorDetails
                participant={selectedParticipant}
                account={newInvestmentAccount}
                setNewInvestmentAccount={setNewInvestmentAccount}
                handleGoNext={handleGoForward}
              />
            )}
          {newInvestmentAccount.type == InvestmentAccountType.RESP && (
            <CreateInvestmentAccountRESPBeneficiaryDetails
              participant={selectedParticipant}
              account={newInvestmentAccount}
              setNewInvestmentAccount={setNewInvestmentAccount}
              handleGoNext={handleGoForward}
              householdParticipants={householdParticipants}
            />
          )}
          {Number(newInvestmentAccount.type) == InvestmentAccountType.RSP &&
            Number(newInvestmentAccount.subType) == InvestmentAccountSubType.SPOUSAL && (
              <CreateInvestmentAccountSpouseContributerDetails
                participant={selectedParticipant}
                account={newInvestmentAccount}
                setNewInvestmentAccount={setNewInvestmentAccount}
                handleGoNext={handleGoForward}
              />
            )}
          {newInvestmentAccount.allowEFT && (
            <CreateInvestmentAccountEFTDetails
              participant={selectedParticipant}
              account={newInvestmentAccount}
              setNewInvestmentAccount={setNewInvestmentAccount}
              handleGoNext={handleGoForward}
            />
          )}
          {newInvestmentAccount.allowTransfers && (
            <CreateInvestmentAccountTransferDetails
              participant={selectedParticipant}
              account={newInvestmentAccount}
              setNewInvestmentAccount={setNewInvestmentAccount}
              handleGoNext={handleGoForward}
            />
          )}

          {newInvestmentAccount.allowPAC && (
            <CreateInvestmentAccountPACDetails
              participant={selectedParticipant}
              account={newInvestmentAccount}
              setNewInvestmentAccount={setNewInvestmentAccount}
              handleGoNext={handleGoForward}
            />
          )}
          {newInvestmentAccount.allowSWP && (
            <CreateInvestmentAccountSWPDetails
              participant={selectedParticipant}
              account={newInvestmentAccount}
              setNewInvestmentAccount={setNewInvestmentAccount}
              handleGoNext={handleGoForward}
            />
          )}
          {newInvestmentAccount.type == InvestmentAccountType.CASH_IN_TRUST_FOR && (
            <CreateInvestmentAccountITFDetails
              participant={selectedParticipant}
              account={newInvestmentAccount}
              setNewInvestmentAccount={setNewInvestmentAccount}
              handleGoNext={handleGoForward}
            />
          )}
          {newInvestmentAccount.type == InvestmentAccountType.RIF && (
            <CreateInvestmentAccountIncomeFundDetails
              participant={selectedParticipant}
              account={newInvestmentAccount}
              setNewInvestmentAccount={setNewInvestmentAccount}
              handleGoNext={handleGoForward}
            />
          )}
          <CreateInvestmentAccountReviewDetails
            participant={selectedParticipant}
            account={newInvestmentAccount}
            setNewInvestmentAccount={setNewInvestmentAccount}
            handleGoNext={handleGoForward}
          />
        </div>
      </div>
    </div>
  );
}

export default withAuth(CreateInvestmentAccount);
