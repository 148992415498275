import React, { useEffect, useState } from "react";
import { InvestmentAccountDetailsProps } from "./CreateInvestmentAccountGeneralDetails";
import { RootState } from "stores/store";
import { HouseholdState } from "stores/Redux/householdSlice";
import { useSelector } from "react-redux";
import { CreateInvestmentAccountDto } from "types/InvestmentAccount";
import { Participant } from "types";
import { InvestmentAccountSubType, ParticipantRole } from "constants/enums";
import { SelectItem } from "types/Shared";
import { EditClientSelect } from "Components/Pages/CreateFullClient/Helpers/Inputs";
import { checkEmptyValue, notify } from "utils";
import AddNewParticipantWithRoleForm from "./AddNewParticipantWithRoleForm";
import { AddParticipantToAccountProps } from "../types";
import AddExistingParticipantWithRoleToAccount from "./AddExistingParticipantWithRoleToAccount";
import { CreateBeneficiaryDto } from "../types/CreateInvestmentAccountDto";

/**
 * Allows us to add an RESP Beneficiary to the account.
 * Occasionally, we may need to add a Trustee to the account in case something happens to the parents.
 * If the RESP is an individual account, then we can only have one beneficiary.
 * If the RESP is a family account, then we can have multiple beneficiaries.
 * @param param0
 * @returns
 */
function CreateInvestmentAccountRESPBeneficiaryDetails({
  account,
  participant,
  handleGoNext,
  setNewInvestmentAccount,
  householdParticipants,
}: InvestmentAccountDetailsProps) {
  const [isAddingBeneficiary, setIsAddingBeneficiary] = useState(false); // Lets us add an RESP Beneficiary
  const [canAddBeneficiary, setCanAddBeneficiary] = useState<boolean>(); // Lets us add an RESP Beneficiary

  useEffect(() => {
    // Tests if we can add a beneficiary
    const canAdd =
      !account.respBeneficiaries ||
      (account.respBeneficiaries &&
        account.subType == InvestmentAccountSubType.INDIVIDUAL &&
        account.respBeneficiaries.length < 1) ||
      account.subType == InvestmentAccountSubType.FAMILY;

    setCanAddBeneficiary(canAdd);
  }, [account.respBeneficiaries, account.subType]);

  return (
    <div className="edit-client-wizard-item" id="edit-invest-account-resp-beneficiary-details">
      <div className="edit-client-wizard-item-header">
        <p>RESP Beneficiaries Details</p>
      </div>
      <div className="edit-client-wizard-item-body">
        <div className="resp-beneficiaries-container">
          {account.respBeneficiaries && (
            <BeneficiaryDetails
              existingParticipants={householdParticipants!}
              account={account}
              setInvestmentAccount={setNewInvestmentAccount}
            />
          )}
        </div>
        {isAddingBeneficiary && (
          <AddRESPBeneficiaryForm
            account={account}
            participant={participant}
            setAccount={setNewInvestmentAccount}
            setIsAddingParticipant={setIsAddingBeneficiary}
            totalBeneficiaryAllocation={0}
          />
        )}
        {canAddBeneficiary && !isAddingBeneficiary && (
          <div>
            <button className="btn btn-success" onClick={() => setIsAddingBeneficiary(true)}>
              Add RESP Beneficiary
            </button>
          </div>
        )}

        {handleGoNext && (
          <button className="btn-nice-purple btn-save-client-details" onClick={handleGoNext}>
            Continue
          </button>
        )}
      </div>
    </div>
  );
}

/**
 * Component for the Beneficiary Details of the Investment Account, displays in a list
 * who the beneficiare for the account are.
 */
interface BeneficiaryDetailsProps {
  account: CreateInvestmentAccountDto;
  existingParticipants: Participant[];
  setInvestmentAccount: React.Dispatch<React.SetStateAction<CreateInvestmentAccountDto>>;
}

const BeneficiaryDetails = ({
  account,
  existingParticipants,
  setInvestmentAccount,
}: BeneficiaryDetailsProps) => {
  const totalAllocation = account.respBeneficiaries?.reduce((acc, beneficiary) => {
    return Math.round((acc + beneficiary.allocation) * 100) / 100;
  }, 0);

  const handleAutoBalance = () => {
    const tempData = { ...account };
    const beneficiaries = tempData.respBeneficiaries;
    if (beneficiaries) {
      const splitAllocation = 100 / beneficiaries.length;
      beneficiaries.forEach((beneficiary) => {
        beneficiary.allocation = splitAllocation;
      });
      setInvestmentAccount({ ...account, beneficiaries: beneficiaries });
    }
  };

  return (
    <div className="account-beneficiary-list-container">
      <p className="account-beneficiary-list-container-header">
        Total Beneficiary Allocation: {totalAllocation}%
      </p>
      <button onClick={handleAutoBalance}>Auto Balance</button>
      {account.respBeneficiaries && account.respBeneficiaries.length > 0 ? (
        <>
          {account.respBeneficiaries.map((beneficiary, index) => {
            // Function to find more infomation about the client from the existing client
            const participant = existingParticipants.find(
              (client) => client.id == beneficiary.participantId
            );
            if (participant) {
              return (
                <BeneficiaryDetail
                  participant={participant}
                  beneficiaryDetail={beneficiary}
                  index={index}
                  setInvestmentAccount={setInvestmentAccount}
                  account={account}
                />
              );
            }
          })}
        </>
      ) : (
        <p>
          There are no beneficiaries for this account. Please Add an Existing Client or Create a New
          One
        </p>
      )}
    </div>
  );
};

/**
 * Helper component for the Details of the Beneficiary
 */
interface BeneficiaryDetailProps {
  participant: Participant;
  beneficiaryDetail: CreateBeneficiaryDto;
  index: number;
  account: CreateInvestmentAccountDto;
  setInvestmentAccount: React.Dispatch<React.SetStateAction<CreateInvestmentAccountDto>>;
}

const BeneficiaryDetail = ({
  participant,
  beneficiaryDetail,
  index,
  account,
  setInvestmentAccount,
}: BeneficiaryDetailProps) => {
  const handleAllocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const tempData = { ...account };
    const beneficiaries = tempData.respBeneficiaries;
    if (beneficiaries) {
      // Check to see if the allocation is greater than 100
      const beneficiaryDetail = beneficiaries[index];
      const currentAllocation = beneficiaries.reduce((acc, beneficiary) => {
        return acc + beneficiary.allocation;
      }, 0);
      const allocationWithoutCurrent = currentAllocation - beneficiaryDetail.allocation;
      if (Number(val) + allocationWithoutCurrent > 100) {
        notify("Allocation cannot be greater than 100", "error");
        return;
      }

      beneficiaryDetail.allocation = Number(val);

      setInvestmentAccount({ ...account, beneficiaries: beneficiaries });
    }
  };

  const handleBeneficiaryRemove = () => {
    const tempData = { ...account };
    const beneficiaries = tempData.beneficiaries;
    if (beneficiaries) {
      beneficiaries.splice(index, 1);
      setInvestmentAccount({ ...account, beneficiaries: beneficiaries });
    }
  };

  return (
    <div className="beneficiary-detail-card">
      <div className="beneficiary-detail-card-header">
        <p className="beneficiary-detail-card-name">{`${participant.generalDetails.firstName} ${
          participant.generalDetails.lastName
        } (${participant.id.slice(0, 6)})`}</p>
        <div className="delete-benificary-detail" onClick={handleBeneficiaryRemove}>
          <i className="bi bi-trash-fill"></i>
        </div>
      </div>
      <div>
        <label style={{ marginRight: "0.5rem" }}>Allocation</label>
        <input
          value={beneficiaryDetail.allocation}
          type="number"
          style={{ padding: "0.25rem 0.5rem" }}
          onChange={handleAllocationChange}
          step="any"
        />
        <label>%</label>
      </div>
    </div>
  );
};

interface AddBeneficiaryProps extends AddParticipantToAccountProps {
  totalBeneficiaryAllocation: number | undefined;
}

const AddRESPBeneficiaryForm = ({
  participant,
  account,
  setAccount,
  setIsAddingParticipant,
}: AddBeneficiaryProps) => {
  // Here, there is an interesting rule about adding spouses as beneficiaries
  const handleBeneficiaryAdd = (participantId: string) => {
    if (checkEmptyValue(participantId)) {
      notify("Please enter valid beneficiary", "error");
    }

    // Check if the beneficiary is a spouse
    if (
      participant &&
      participant.spouseDetails &&
      participant.spouseDetails?.participantId &&
      participant.spouseDetails?.participantId == participantId
    ) {
      if (
        !window.confirm(
          "Are you sure you want to add the participant's spouse as an RESP beneficiary? Check with Primary Advisor of the Participant"
        )
      ) {
        return;
      }
    }

    const tempData = { ...account };
    // If there is nothing there, create an empty array with the participant id
    if (!tempData.respBeneficiaries) {
      tempData.respBeneficiaries = [{ participantId: participantId, allocation: 100 }];
    } else if (
      tempData.respBeneficiaries &&
      tempData.respBeneficiaries.find((beneficiary) => beneficiary.participantId == participantId)
    ) {
      notify("Beneficiary already added", "error");

      return;
    } else {
      // find the current allocation of the beneficiaries
      const splitAllocation = 100 / (tempData.respBeneficiaries.length + 1);

      tempData.respBeneficiaries = [
        ...tempData.respBeneficiaries,
        { participantId: participantId, allocation: 0 },
      ];

      // loop through the beneficiaries and set the allocation to the split allocation
      tempData.respBeneficiaries.forEach((beneficiary) => {
        beneficiary.allocation = splitAllocation;
      });
    }

    setAccount({ ...account, respBeneficiaries: tempData.respBeneficiaries });
    setIsAddingParticipant(false);
  };

  return (
    <div>
      <AddExistingParticipantWithRoleToAccount
        participant={participant!}
        roleLabel="RESP Beneficiary"
        role={ParticipantRole.BENEFICIARY}
        account={account}
        setAccount={setAccount}
        handleParticipantAdd={handleBeneficiaryAdd}
        setIsAddingParticipant={setIsAddingParticipant}
      />
      {participant && (
        <AddNewParticipantWithRoleForm
          role={ParticipantRole.BENEFICIARY}
          householdId={participant.householdId}
          createFormLabel="RESP Beneficiary"
          handleParticipantAdd={handleBeneficiaryAdd}
        />
      )}
    </div>
  );
};

export default CreateInvestmentAccountRESPBeneficiaryDetails;
