// Types of Investment Accounts that a client can open
// Config table can be found in PrivateWealthCore.dbo.FOClientInvestmentAccountType
export enum InvestmentAccountType {
  CASH = 1,
  CASH_IN_TRUST_FOR = 2,
  CASH_LEVERAGED = 3,
  MARGIN = 4,
  TFSA = 5,
  RESP = 6,
  RSP = 7,
  RIF = 8,
}
