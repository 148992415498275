import React, { useState } from "react";
import { useCreateHouseholdMutation } from "../../../Apis/mainApi";
import { inputHelper, notify } from "../../../utils";
import { ApiResponseModel } from "../../../types";

interface CreateNewHouseholdProps {
  advisorId: string;
  closeModal: () => void;
}

const emptyFormState = {
  fullName: "",
};

/**
 * Pass in the household and advisor id to send to the server
 * @returns
 */
const CreateHouseholdForm = ({ advisorId, closeModal }: CreateNewHouseholdProps) => {
  const [createNewHousehold] = useCreateHouseholdMutation();
  const [createHouseholdInputs, setCreateHouseholdInputs] = useState(emptyFormState);

  // Input Event Handlers
  const handleUserInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const tempData = inputHelper(e, createHouseholdInputs);
    setCreateHouseholdInputs(tempData);
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const createResult: ApiResponseModel = await createNewHousehold({
      fullName: createHouseholdInputs.fullName,
      advisorId,
    });

    if (createResult.data?.isSuccess) {
      notify("Created Household Successfully", "success");
      // Set the state to empty
      setCreateHouseholdInputs(emptyFormState);
      // Close the Modal
      closeModal();
    } else if (createResult.error) {
      console.log(createResult.error);
      notify("Error on Household Create", "error");
    }
  };

  return (
    <div className="create-new-client-form-container">
      <form onSubmit={handleFormSubmit}>
        <div className="create-new-client-form-control">
          <label>Full Household Name</label>
          <input
            name="fullName"
            value={createHouseholdInputs.fullName}
            onChange={handleUserInput}
            required
          ></input>
        </div>
        <div className="create-new-client-btn-control">
          <button className="btn btn-success" type="submit">
            Add Household
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateHouseholdForm;
