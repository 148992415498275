import React, { useEffect, useState } from "react";
import {
  ParticipantDetailsCompProps,
  ParticipantDetailsSectionProps,
  ParticipantProp,
} from "../types";
import $ from "jquery";
import "../styles/participantDetails.scss";
import { ClientCitizenshipOptions, MaritalStatusOptions } from "constants/selectOptions";
import { ApiResponseModel, Participant } from "types";
import { convertDateToString, notify } from "utils";
import { CitizenshipMappingById } from "constants/mappings";
import { CitizenshipType } from "constants/enums";
import { SelectItem } from "types/Shared";
import { useUpdateNewClientMutation } from "Apis/mainApi";
import { SpinningLoader } from "Components/Shared";
import { set } from "react-hook-form";
import { AddressTypes } from "assets/StaticData";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { EmploymentTypes } from "assets/StaticData/EditClientOptions";
import { Tooltip } from "react-tooltip";

type ParticipantInputProps = {
  label: string;
  value: string | number;
  type?: "text" | "number" | "date";
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
};

type ParticipantSelectProps = {
  label: string;
  value: string | number;
  options: SelectItem[];
  disabled?: boolean;
  country?: string;
  onChange?: (value: string) => void;
};

type RadioButtonContainerProps = {
  label: string;
  value: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
};

const ParticipantInput = ({
  label,
  value,
  type = "text",
  disabled = false,
  onChange,
}: ParticipantInputProps) => (
  <div className="participant-details">
    <label>{label}</label>
    <div className="input-container">
      <input
        value={value ? value : ""}
        type={type}
        disabled={disabled}
        onChange={(e) => onChange?.(e.target.value)}
      />
      <div className="underline"></div>
    </div>
  </div>
);

const ParticipantSelect = ({
  label,
  value,
  options,
  disabled = false,
  onChange,
}: ParticipantSelectProps) => (
  <div className="participant-details">
    <label>{label}</label>
    <div className="input-container">
      <select
        value={value ? value : ""}
        disabled={disabled}
        onChange={(e) => onChange?.(e.target.value)}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="underline"></div>
    </div>
  </div>
);

const RadioButtonContainer = ({
  label,
  value,
  disabled = false,
  onChange,
}: RadioButtonContainerProps) => {
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === "true";
    onChange?.(newValue);
  };

  return (
    <div className="participant-details">
      <label>{label}</label>
      <div className="radio-button-container">
        <input
          type="radio"
          id={`${label}-true`}
          name={label}
          value="true"
          disabled={disabled}
          checked={value}
          onChange={handleRadioChange}
        />
        <label htmlFor={`${label}-true`}>Yes</label>
        <input
          type="radio"
          id={`${label}-false`}
          name={label}
          value="false"
          disabled={disabled}
          checked={!value}
          onChange={handleRadioChange}
        />
        <label htmlFor={`${label}-false`}>No</label>
      </div>
    </div>
  );
};

interface ParticipantRegionSelectProps {
  label: string;
  value: string | number;
  disabled?: boolean;
  onChange?: (value: string) => void;
  country?: string;
}

const ParticipantRegionSelect = ({
  label,
  value,
  disabled = false,
  onChange,
  country,
}: ParticipantRegionSelectProps) => {
  return (
    <div className="participant-details">
      <label>{label}</label>
      <div className="input-container">
        <RegionDropdown
          country={country ? country : ""}
          value={value ? value.toString() : ""}
          onChange={(e) => onChange?.(e)}
          disabled={disabled}
        />
        <div className="underline"></div>
      </div>
    </div>
  );
};

interface ParticipantCountrySelectProps {
  label: string;
  value: string | number;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

const ParticipantCountrySelect = ({
  label,
  value,
  disabled = false,
  onChange,
}: ParticipantCountrySelectProps) => {
  return (
    <div className="participant-details">
      <label>{label}</label>
      <div className="input-container">
        <CountryDropdown
          value={value ? value.toString() : ""}
          onChange={(e) => onChange?.(e)}
          disabled={disabled}
        />
        <div className="underline"></div>
      </div>
    </div>
  );
};

/**
 * Component focused on handling the participant General Details
 * @param param0
 * @returns
 */
const ParticipantGeneralDetailsComp = ({
  participant,
  editMode,
  setParticipant,
  setEditButtonDisabled,
}: ParticipantDetailsCompProps) => {
  const handleInputChange = (val: number | string | boolean, attrName: string) => {
    let updatedGeneralDetails = { ...participant.generalDetails };
    // Check if attr exists in general details
    if (attrName in updatedGeneralDetails) {
      updatedGeneralDetails[attrName] = val;
    }

    setParticipant({ ...participant, generalDetails: updatedGeneralDetails });
  };

  // Edit button may be disabled for some reason
  useEffect(() => {
    setEditButtonDisabled(false);
  }, []);

  return (
    <div className="participant-details-section">
      <p className="participant-details-section-header">General Details</p>
      <RadioButtonContainer
        label="Is Individual"
        value={participant.generalDetails.isIndividual}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "isIndividual")}
      />
      <ParticipantInput
        label="Title"
        value={participant.generalDetails.title}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "title")}
      />
      <ParticipantSelect
        label="Marital Status"
        value={participant.generalDetails.maritalStatus}
        options={MaritalStatusOptions}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "maritalStatus")}
      />
      <ParticipantInput
        label="First Name"
        value={participant.generalDetails.firstName}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "firstName")}
      />
      <ParticipantInput
        label="Middle Initial"
        value={participant.generalDetails.middleInitial}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "middleInitial")}
      />
      <ParticipantInput
        label="Last Name"
        value={participant.generalDetails.lastName}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "lastName")}
      />
      <ParticipantInput
        label="Social Insurance Number"
        value={participant.generalDetails.sin}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "sin")}
      />
      <ParticipantInput
        label="Date of Birth"
        value={convertDateToString(participant.generalDetails.dateOfBirth)}
        type="date"
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "dateOfBirth")}
      />
      <ParticipantInput
        label="Number of Dependents"
        value={participant.generalDetails.numDependents}
        disabled={!editMode}
        type="number"
        onChange={(e) => handleInputChange(e, "numDependents")}
      />
      <ParticipantInput
        label="Home Phone #"
        value={participant.generalDetails.homePhone}
        placeholder={editMode ? "555-555-5555" : ""}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "homePhone")}
      />
      <ParticipantInput
        label="Business Phone #"
        value={participant.generalDetails.businessPhone}
        placeholder={editMode ? "555-555-5555" : ""}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "businessPhone")}
      />
      <ParticipantInput
        label="Business Phone Ext"
        value={participant.generalDetails.businessPhoneExt}
        disabled={!editMode}
        type="number"
        onChange={(e) => handleInputChange(e, "businessPhoneExt")}
      />
      <ParticipantInput
        label="Cell Phone #"
        value={participant.generalDetails.cellPhone}
        placeholder={editMode ? "555-555-5555" : ""}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "cellPhone")}
      />
      <ParticipantInput
        label="Email"
        value={participant.generalDetails.email}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "email")}
      />
      <ParticipantSelect
        label="Citizenship"
        value={participant.generalDetails.citizenship}
        options={ClientCitizenshipOptions}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "citizenship")}
      />
      {participant.generalDetails.citizenship === CitizenshipType.OTHER && (
        <ParticipantInput
          label="Other Citizenship"
          value={participant.generalDetails.otherCitizenship}
          disabled={!editMode}
          onChange={(e) => handleInputChange(e, "otherCitizenship")}
        />
      )}
      <ParticipantInput
        label="Language"
        value={participant.generalDetails.language}
        disabled={!editMode}
        onChange={(e) => handleInputChange(e, "language")}
      />
    </div>
  );
};

/**
 * Component focused on handling the participant Address Details
 * @param param0
 * @returns
 */
const ParticipantAddressDetailsComp = ({
  participant,
  editMode,
  setParticipant,
  setEditButtonDisabled,
}: ParticipantDetailsCompProps) => {
  const handlePermanentAddressChange = (val: any, attrName: string) => {
    let updatedPermanentAddressDetails = { ...participant.permanentAddressDetails };
    // Check if attr exists in general details
    if (attrName in updatedPermanentAddressDetails) {
      updatedPermanentAddressDetails[attrName] = val;
    }

    setParticipant({ ...participant, permanentAddressDetails: updatedPermanentAddressDetails });
  };

  const handleMailingAddressChange = (val: any, attrName: string) => {
    let updatedMailingAddress = { ...participant.mailingAddressDetails };
    updatedMailingAddress[attrName] = val;

    setParticipant({ ...participant, mailingAddressDetails: updatedMailingAddress });
  };

  const handleThirdPartyAddressChange = (val: any, attrName: string) => {
    let updatedThirdPartyAddressDetails = { ...participant.thirdPartyAddressDetails };
    updatedThirdPartyAddressDetails[attrName] = val;

    setParticipant({ ...participant, thirdPartyAddressDetails: updatedThirdPartyAddressDetails });
  };

  useEffect(() => {
    setEditButtonDisabled(false);
  }, []);

  return (
    <div className="participant-details-section">
      <p className="participant-details-section-header">Permanent Address Details</p>
      <ParticipantSelect
        label="Address Type"
        value={participant.permanentAddressDetails.addressType}
        options={AddressTypes}
        disabled={!editMode}
        onChange={(e) => handlePermanentAddressChange(e, "addressType")}
      />
      <ParticipantInput
        label="Apt / Unit / Suite #"
        value={participant.permanentAddressDetails.aptUnitSuiteNumber}
        type="number"
        disabled={!editMode}
        onChange={(e) => handlePermanentAddressChange(e, "aptUnitSuiteNumber")}
      />
      <ParticipantInput
        label="Street #"
        value={participant.permanentAddressDetails.streetNumber}
        type="number"
        disabled={!editMode}
        onChange={(e) => handlePermanentAddressChange(e, "streetNumber")}
      />
      <ParticipantInput
        label="Street Name"
        value={participant.permanentAddressDetails.streetName}
        disabled={!editMode}
        onChange={(e) => handlePermanentAddressChange(e, "streetName")}
      />
      <ParticipantInput
        label="City"
        value={participant.permanentAddressDetails.city}
        disabled={!editMode}
        onChange={(e) => handlePermanentAddressChange(e, "city")}
      />
      <ParticipantRegionSelect
        label="State/Province"
        value={participant.permanentAddressDetails.region}
        disabled={!editMode}
        onChange={(e) => handlePermanentAddressChange(e, "region")}
        country={participant.permanentAddressDetails.country}
      />
      <ParticipantCountrySelect
        label="Country"
        value={participant.permanentAddressDetails.country}
        disabled={!editMode}
        onChange={(e) => handlePermanentAddressChange(e, "country")}
      />
      <RadioButtonContainer
        label="Is Same as Mailing Address"
        value={participant.permanentAddressDetails.isSameAsMailingAddress}
        disabled={!editMode}
        onChange={(e) => handlePermanentAddressChange(e, "isSameAsMailingAddress")}
      />
      <RadioButtonContainer
        label="Has 3rd Party Address"
        value={participant.permanentAddressDetails.hasThirdPartyAddress}
        disabled={!editMode}
        onChange={(e) => handlePermanentAddressChange(e, "hasThirdPartyAddress")}
      />
      {!participant.permanentAddressDetails.isSameAsMailingAddress && (
        <>
          <p className="participant-details-section-header">Mailing Address Details</p>
          <ParticipantSelect
            label="Address Type"
            value={
              participant.mailingAddressDetails ? participant.mailingAddressDetails.addressType : ""
            }
            options={AddressTypes}
            disabled={!editMode}
            onChange={(e) => handleMailingAddressChange(e, "addressType")}
          />
          <ParticipantInput
            label="Apt / Unit / Suite #"
            value={
              participant.mailingAddressDetails
                ? participant.mailingAddressDetails.aptUnitSuiteNumber
                : ""
            }
            type="number"
            disabled={!editMode}
            onChange={(e) => handleMailingAddressChange(e, "aptUnitSuiteNumber")}
          />
          <ParticipantInput
            label="Street #"
            value={
              participant.mailingAddressDetails
                ? participant.mailingAddressDetails.streetNumber
                : ""
            }
            type="number"
            disabled={!editMode}
            onChange={(e) => handleMailingAddressChange(e, "streetNumber")}
          />
          <ParticipantInput
            label="Street Name"
            value={
              participant.mailingAddressDetails ? participant.mailingAddressDetails.streetName : ""
            }
            disabled={!editMode}
            onChange={(e) => handleMailingAddressChange(e, "streetName")}
          />
          <ParticipantInput
            label="City"
            value={participant.mailingAddressDetails ? participant.mailingAddressDetails.city : ""}
            disabled={!editMode}
            onChange={(e) => handleMailingAddressChange(e, "city")}
          />
          <ParticipantRegionSelect
            label="State/Province"
            value={
              participant.mailingAddressDetails ? participant.mailingAddressDetails.region : ""
            }
            disabled={!editMode}
            onChange={(e) => handleMailingAddressChange(e, "region")}
            country={participant.mailingAddressDetails.country}
          />
          <ParticipantCountrySelect
            label="Country"
            value={
              participant.mailingAddressDetails ? participant.mailingAddressDetails.country : ""
            }
            disabled={!editMode}
            onChange={(e) => handleMailingAddressChange(e, "country")}
          />
        </>
      )}
      {participant.permanentAddressDetails.hasThirdPartyAddress && (
        <>
          <p className="participant-details-section-header">3rd Party Address Details</p>
          <ParticipantSelect
            label="Address Type"
            value={
              participant.thirdPartyAddressDetails
                ? participant.thirdPartyAddressDetails.addressType
                : ""
            }
            options={AddressTypes}
            disabled={!editMode}
            onChange={(e) => handleThirdPartyAddressChange(e, "addressType")}
          />
          <ParticipantInput
            label="Apt / Unit / Suite #"
            value={
              participant.thirdPartyAddressDetails
                ? participant.thirdPartyAddressDetails.aptUnitSuiteNumber
                : ""
            }
            type="number"
            disabled={!editMode}
            onChange={(e) => handleThirdPartyAddressChange(e, "aptUnitSuiteNumber")}
          />
          <ParticipantInput
            label="Street #"
            value={
              participant.thirdPartyAddressDetails
                ? participant.thirdPartyAddressDetails.streetNumber
                : ""
            }
            type="number"
            disabled={!editMode}
            onChange={(e) => handleThirdPartyAddressChange(e, "streetNumber")}
          />
          <ParticipantInput
            label="Street Name"
            value={
              participant.thirdPartyAddressDetails
                ? participant.thirdPartyAddressDetails.streetName
                : ""
            }
            disabled={!editMode}
            onChange={(e) => handleThirdPartyAddressChange(e, "streetName")}
          />
          <ParticipantInput
            label="City"
            value={
              participant.thirdPartyAddressDetails ? participant.thirdPartyAddressDetails.city : ""
            }
            disabled={!editMode}
            onChange={(e) => handleThirdPartyAddressChange(e, "city")}
          />
          <ParticipantRegionSelect
            label="State/Province"
            value={
              participant.thirdPartyAddressDetails
                ? participant.thirdPartyAddressDetails.region
                : ""
            }
            disabled={!editMode}
            onChange={(e) => handleThirdPartyAddressChange(e, "region")}
            country={participant.thirdPartyAddressDetails.country}
          />
          <ParticipantCountrySelect
            label="Country"
            value={
              participant.thirdPartyAddressDetails
                ? participant.thirdPartyAddressDetails.country
                : ""
            }
            disabled={!editMode}
            onChange={(e) => handleThirdPartyAddressChange(e, "country")}
          />
        </>
      )}
    </div>
  );
};

/**
 * Compopnent focused on handling the participant Employment Details
 * @param param0
 * @returns
 */
const ParticipantEmploymentDetailsComp = ({
  participant,
  editMode,
  setParticipant,
  setEditButtonDisabled,
}: ParticipantDetailsCompProps) => {
  const handleEmploymentInputChange = (val: number | string | boolean, attrName: string) => {
    let updatedEmploymentDetails = { ...participant.employmentDetails };
    // Check if attr exists in general details
    if (attrName in updatedEmploymentDetails) {
      updatedEmploymentDetails[attrName] = val;
    }

    setParticipant({ ...participant, employmentDetails: updatedEmploymentDetails });
  };

  useEffect(() => {
    setEditButtonDisabled(false);
  }, []);

  return (
    <div className="participant-details-section">
      <p className="participant-details-section-header">Employment Details</p>
      <ParticipantSelect
        label="Employment Status"
        value={participant.employmentDetails.status}
        options={EmploymentTypes}
        disabled={!editMode}
        onChange={(e) => handleEmploymentInputChange(e, "status")}
      />
      <ParticipantInput
        label="Occupation"
        value={participant.employmentDetails.occupation}
        disabled={!editMode}
        onChange={(e) => handleEmploymentInputChange(e, "occupation")}
      />
      <ParticipantInput
        label="Employer Name"
        value={participant.employmentDetails.name}
        disabled={!editMode}
        onChange={(e) => handleEmploymentInputChange(e, "name")}
      />
      <ParticipantInput
        label="Type of Business"
        value={participant.employmentDetails.businessType}
        disabled={!editMode}
        onChange={(e) => handleEmploymentInputChange(e, "businessType")}
      />
      <ParticipantInput
        label="Length of Employment (Years)"
        value={participant.employmentDetails.lengthInYears}
        disabled={!editMode}
        type="number"
        onChange={(e) => handleEmploymentInputChange(e, "lengthInYears")}
      />
      <ParticipantInput
        label="Address of Employment"
        value={participant.employmentDetails.address}
        disabled={!editMode}
        onChange={(e) => handleEmploymentInputChange(e, "address")}
      />
      <ParticipantInput
        label="City"
        value={participant.employmentDetails.city}
        disabled={!editMode}
        onChange={(e) => handleEmploymentInputChange(e, "address")}
      />
      <ParticipantRegionSelect
        label="State/Province"
        value={participant.employmentDetails.region}
        country={participant.employmentDetails.country}
        onChange={(e) => handleEmploymentInputChange(e, "region")}
        disabled={!editMode}
      />
      <ParticipantCountrySelect
        label="Country"
        value={participant.employmentDetails.country}
        onChange={(e) => handleEmploymentInputChange(e, "country")}
        disabled={!editMode}
      />
      <ParticipantInput
        label="Postal Code"
        value={participant.employmentDetails.postalCode}
        disabled={!editMode}
        onChange={(e) => handleEmploymentInputChange(e, "postalCode")}
      />
    </div>
  );
};

/**
 * Component focused on handling the participant Spouse Details,
 * if the Spouse is a client themselves then they cannot edit their details
 * here, instead there will be a link to their profile.
 */
const ParticipantSpouseDetailsComp = ({
  participant,
  editMode,
  setEditMode,
  setParticipant,
  setEditButtonDisabled,
}: ParticipantDetailsCompProps) => {
  useEffect(() => {
    if (participant.spouseDetails.isClient) {
      setEditButtonDisabled(true);
      setEditMode(false);
    } else {
      setEditButtonDisabled(false);
    }
  }, []);

  return (
    <div className="participant-details-section">
      <p className="participant-details-section-header">Spouse Details</p>
    </div>
  );
};

const ParticipantDetailsComponents: React.ComponentType<ParticipantDetailsCompProps>[] = [
  ParticipantGeneralDetailsComp,
  ParticipantAddressDetailsComp,
  ParticipantEmploymentDetailsComp,
  ParticipantSpouseDetailsComp,
];

function ParticipantDetails({ participant }: ParticipantDetailsSectionProps) {
  // We need to maintain a copy of participant in state so that we can update it
  // and then pass it back to the parent component
  const [participantCopy, setParticipantCopy] = useState<Participant>({ ...participant });
  // Keep Track of Edit Mode
  const [editMode, setEditMode] = useState(false);
  // Track State of which component is being rendered
  const [currentComponent, setCurrentComponent] = useState(0);
  // Grab the current component
  const CurrentComponent = ParticipantDetailsComponents[currentComponent];
  // Update Participant Mutation
  const [updateParticipant] = useUpdateNewClientMutation();
  // Save Loading State
  const [saveLoading, setSaveLoading] = useState(false);
  // Edit button disabled state, this only applies when spouse details are being edited
  // When the spouse is a client, we need to disable the edit button, otherwise, edit away
  const [editBtnDisabled, setEditBtnDisabled] = useState(false);

  // Cancel Edit Functionality
  const handleCancelEdit = () => {
    // Reset the participant copy to the original participant
    setParticipantCopy({ ...participant });
    // Disable edit mode
    setEditMode(false);
  };

  // Save Functionality
  const handleSave = async () => {
    setSaveLoading(true);
    // Attempt to edit the participant
    const result: ApiResponseModel = await updateParticipant(participantCopy);
    // Check if the edit was successful
    if (result.data && result.data.isSuccess) {
      notify("Participant Updated Successfully", "success");
      // Disable edit mode
      setEditMode(false);
    } else if (result.error) {
      notify(result.error.message, "error");
    }

    setSaveLoading(false);
  };

  return (
    <div className="participant-details-container">
      <Tooltip id="details-tooltip" />
      <ParticipantDetailsNavBar setCurrentComponent={setCurrentComponent} />
      <div className="vertical-line"></div>
      <CurrentComponent
        participant={participantCopy}
        editMode={editMode}
        setEditMode={setEditMode}
        setParticipant={setParticipantCopy}
        setEditButtonDisabled={setEditBtnDisabled}
      />
      <div className="actions-container">
        <div className="actions-container-btns">
          {!editMode && (
            <a
              data-tooltip-id="details-tooltip"
              data-tooltip-content={
                editBtnDisabled ? "Spouse Details Cannot Be Edited When Spouse Is A Client" : ""
              }
            >
              <button
                onClick={() => setEditMode(true)}
                className="btn btn-warning"
                disabled={editBtnDisabled}
              >
                Enable
                <br /> Edit Mode
              </button>
            </a>
          )}
          {editMode && (
            <>
              <button className="btn btn-success" onClick={handleSave}>
                Save {saveLoading && <SpinningLoader />}
              </button>
              <button className="btn btn-danger" onClick={handleCancelEdit}>
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

interface ParticipantDetailsNavBarProps {
  setCurrentComponent: React.Dispatch<React.SetStateAction<number>>;
}

const ParticipantDetailsNavBar = ({ setCurrentComponent }: ParticipantDetailsNavBarProps) => {
  const handleTabClick = (e: React.MouseEvent<HTMLButtonElement>, index: number) => {
    // Remove the active class from all the tabs using jquery
    $(".participant-details-navbar-item").removeClass("active");
    // Add the active class to the component that was clicked
    $(e.currentTarget).parent().addClass("active");
    // Set the current component index
    setCurrentComponent(index);
  };

  return (
    <div className="participant-details-nav-bar">
      <div className="participant-details-navbar-item active">
        <button onClick={(e) => handleTabClick(e, 0)}>General Details</button>
      </div>
      <div className="participant-details-navbar-item">
        <button onClick={(e) => handleTabClick(e, 1)}>Address Details</button>
      </div>
      <div className="participant-details-navbar-item">
        <button onClick={(e) => handleTabClick(e, 2)}>Employment Details</button>
      </div>
      <div className="participant-details-navbar-item">
        <button onClick={(e) => handleTabClick(e, 3)}>Spouse Details</button>
      </div>
      <div className="participant-details-navbar-item">
        <button onClick={(e) => handleTabClick(e, 2)}>Net Worth Details</button>
      </div>
    </div>
  );
};

export default ParticipantDetails;
