import { SelectItem } from "../../types/Shared";
import { IntendedUseOfAccount } from "../../constants/enums";

export const IntendedUseOfAccounts: SelectItem[] = [
  {
    label: "Short-Term Savings",
    value: IntendedUseOfAccount.SHORT_TERM_SAVINGS,
  },
  {
    label: "Long-Term Savings",
    value: IntendedUseOfAccount.LONG_TERM_SAVINGS,
  },
  {
    label: "Investing For Specific Use",
    value: IntendedUseOfAccount.SPECIFIC_USE,
  },
  {
    label: "Speculation",
    value: IntendedUseOfAccount.SPECULATION,
  },
  {
    label: "Income",
    value: IntendedUseOfAccount.INCOME,
  },
  {
    label: "Retirement Savings",
    value: IntendedUseOfAccount.RETIREMENT_SAVINGS,
  },
  {
    label: "Other",
    value: IntendedUseOfAccount.OTHER,
  },
];
