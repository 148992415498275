import React, { useState } from "react";
import { InvestmentAccountDetailsProps } from "./CreateInvestmentAccountGeneralDetails";
import {
  EditClientInput,
  EditClientSelect,
} from "Components/Pages/CreateFullClient/Helpers/Inputs";
import { CurrencyOptions } from "assets/StaticData";
import { transferTypeOptions } from "constants/selectOptions";
import {
  Instruction,
  CreateInvestmentAccountDto,
  TransferDetail,
} from "features/createInvestmentAccount/types/CreateInvestmentAccountDto";
import "../styles/transferDetail.scss";
import { EmptyTransferDetail } from "constants/emptyStates";
import { checkEmptyValue, notify, validatePhone } from "utils";
import { CurrencyMapping } from "constants/mappings";

function CreateInvestmentAccountTransferDetails({
  account,
  handleGoNext,
  setNewInvestmentAccount,
}: InvestmentAccountDetailsProps) {
  const [addingTransferDetails, setAddingTransferDetails] = useState<boolean>(false);

  return (
    <div className="edit-client-wizard-item" id="edit-invest-account-transfer-details">
      <div className="edit-client-wizard-item-header">
        <p>Transfer Details</p>
      </div>
      <div className="edit-client-wizard-item-body">
        {account.transferDetails && account.transferDetails.length > 0 && (
          <div className="transfer-details-container">
            {account.transferDetails.map((detail, index) => {
              return (
                <TransferDetailCard
                  key={index}
                  account={account}
                  setAccount={setNewInvestmentAccount}
                  detail={detail}
                  index={index}
                />
              );
            })}
          </div>
        )}
        <div>
          {!addingTransferDetails ? (
            <button
              onClick={() => setAddingTransferDetails(!addingTransferDetails)}
              className="btn btn-success"
              style={{ marginTop: "2rem" }}
            >
              Add Transfer Detail
            </button>
          ) : (
            <button
              onClick={() => setAddingTransferDetails(!addingTransferDetails)}
              className="btn btn-danger"
              style={{ marginTop: "2rem" }}
            >
              Cancel
            </button>
          )}
        </div>
        {addingTransferDetails && (
          <AddTransferDetailForm
            account={account}
            setAccount={setNewInvestmentAccount}
            setAddingTransferDetails={setAddingTransferDetails}
          />
        )}
        {/* <p>Work in Progress</p> */}
        {handleGoNext && (
          <button className="btn-nice-purple btn-save-client-details" onClick={handleGoNext}>
            Continue
          </button>
        )}
      </div>
    </div>
  );
}

interface TransferDetailCardProps {
  account: CreateInvestmentAccountDto;
  setAccount: React.Dispatch<React.SetStateAction<CreateInvestmentAccountDto>>;
  detail: TransferDetail;
  index: number;
}

const TransferDetailCard = ({ account, setAccount, detail, index }: TransferDetailCardProps) => {
  const handleRemoveDetail = () => {
    if (window.confirm("Are you sure you want to remove this transfer detail?")) {
      let accountCopy = { ...account };
      accountCopy.transferDetails?.splice(index, 1);
      setAccount(accountCopy);
    }
  };

  return (
    <div className="transfer-detail-card">
      <div className="transfer-detail-card-header">
        <p>
          {detail.deliveringInstitution} <br /> {detail.institutionCountry},{" "}
          {detail.institutionAddress}
        </p>
        <i className="bi bi-dash-circle-fill text-danger" onClick={handleRemoveDetail}></i>
      </div>
      <div className="transfer-detail-card-body">
        {detail.instructions.map((instruction, index) => {
          return (
            <div>
              <p>
                <u>Instruction #{index + 1}</u>
              </p>
              <p>Account Number: {instruction.institutionAccountNumber}</p>
              <p>Currency: {CurrencyMapping[instruction.currency]}</p>
              <p>Type: {Number(instruction.transferType) == 1 ? "Cash" : "Kind"}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface AddTransferDetailFormProps {
  account: CreateInvestmentAccountDto;
  setAccount: React.Dispatch<React.SetStateAction<CreateInvestmentAccountDto>>;
  setAddingTransferDetails: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddTransferDetailForm = ({
  account,
  setAccount,
  setAddingTransferDetails,
}: AddTransferDetailFormProps) => {
  const [addingTransferInstruction, setAddingTransferInstruction] = useState<boolean>(false);
  const [transferDetail, setTransferDetail] = useState<TransferDetail>(EmptyTransferDetail);

  /**
   * Allows for the change of the transfer detail when it is being added to the account
   * @param e
   * @param attrName
   */
  const handleTransferDetailChange = (
    e: string | number | boolean,
    attrName: string | undefined
  ) => {
    if (attrName) {
      setTransferDetail((prevState) => ({
        ...prevState,
        [attrName]: e,
      }));
    }
  };

  /**
   * Adds an instruction to the transfer detail
   */
  const handleAddInstruction = () => {
    setTransferDetail((prevState) => ({
      ...prevState,
      instructions: [
        ...prevState.instructions,
        {
          currency: 0,
          institutionAccountNumber: "",
          transferType: 0,
        },
      ],
    }));
  };

  /**
   * Adds the detail to the investment account
   */
  const handleAddDetail = () => {
    // Validate the transfer detail
    if (validateTransferDetail(transferDetail)) {
      const accountCopy = { ...account };
      if (accountCopy.transferDetails) {
        accountCopy.transferDetails.push(transferDetail);
      } else {
        accountCopy.transferDetails = [transferDetail];
      }

      setAccount(accountCopy);
      setAddingTransferDetails(false);
    }
  };

  return (
    <div className="add-transfer-detail-form-container">
      <div className="add-transfer-detail-form">
        <h1 className="add-transfer-detail-form-header">Institution Information</h1>
        <EditClientInput
          label="Name"
          value={transferDetail.deliveringInstitution}
          attrName="deliveringInstitution"
          handleChange={handleTransferDetailChange}
        />
        <EditClientSelect
          label="Country"
          value={transferDetail.institutionCountry}
          attrName="institutionCountry"
          handleChange={handleTransferDetailChange}
          isCountry={true}
        />
        <EditClientInput
          label="Address"
          value={transferDetail.institutionAddress}
          attrName="institutionAddress"
          handleChange={handleTransferDetailChange}
        />
        <EditClientInput
          label="Business Phone"
          value={transferDetail.institutionBusinessPhone}
          attrName="institutionBusinessPhone"
          handleChange={handleTransferDetailChange}
        />
        <EditClientInput
          label="Business Ext"
          value={transferDetail.institutionBusinessPhoneExt}
          attrName="institutionBusinessPhoneExt"
          handleChange={handleTransferDetailChange}
        />
        <EditClientInput
          label="Dealer Number"
          value={transferDetail.institutionDealerNumber}
          attrName="institutionDealerNumber"
          handleChange={handleTransferDetailChange}
        />
        {transferDetail.instructions.map((instruction, index) => {
          return (
            <AddTransferInstruction
              detail={transferDetail}
              setTransferDetail={setTransferDetail}
              instruction={instruction}
              instructionNumber={index + 1}
            />
          );
        })}
        <button onClick={handleAddInstruction} style={{ marginTop: "2rem" }}>
          Add Instruction
        </button>{" "}
        <br />
        <div style={{ marginTop: "2rem" }}>
          <button className="btn btn-success" onClick={handleAddDetail}>
            Add Transfer Detail
          </button>
        </div>
      </div>
    </div>
  );
};

interface AddTransferInstructionProps {
  detail: TransferDetail;
  setTransferDetail: React.Dispatch<React.SetStateAction<TransferDetail>>;
  instruction: Instruction;
  instructionNumber: number;
}

const AddTransferInstruction = ({
  detail,
  setTransferDetail,
  instruction,
  instructionNumber,
}: AddTransferInstructionProps) => {
  const handleInstructionChange = (e: string | number | boolean, attrName: string | undefined) => {
    let detailCopy = { ...detail };
    let instructions = detailCopy.instructions;
    let instuctionCopy = instructions[instructionNumber - 1];
    if (attrName) {
      instuctionCopy[attrName] = e;
      instructions[instructionNumber - 1] = instuctionCopy;
      setTransferDetail((prevState) => ({
        ...prevState,
        instructions: instructions,
      }));
    }
  };

  const handleRemoveInstruction = () => {
    let detailCopy = { ...detail };
    let instructions = detailCopy.instructions;
    instructions.splice(instructionNumber - 1, 1);
    setTransferDetail((prevState) => ({
      ...prevState,
      instructions: instructions,
    }));
  };

  return (
    <div className="add-transfer-instruction-form-container">
      <div className="add-transfer-detail-form">
        <h1>
          Instruction #{instructionNumber}{" "}
          <i className="bi bi-dash-circle-fill text-danger" onClick={handleRemoveInstruction}></i>
        </h1>
        <EditClientInput
          label="Account Number"
          value={instruction.institutionAccountNumber}
          attrName="institutionAccountNumber"
          handleChange={handleInstructionChange}
        />
        <EditClientSelect
          label="Currency"
          value={instruction.currency}
          attrName="currency"
          handleChange={handleInstructionChange}
          selectItems={CurrencyOptions}
        />
        <EditClientSelect
          label="Transfer Type"
          attrName="transferType"
          value={instruction.transferType}
          handleChange={handleInstructionChange}
          selectItems={transferTypeOptions}
        />
      </div>
    </div>
  );
};

const validateTransferDetail = (transferDetail: TransferDetail) => {
  let isValid = true;
  if (checkEmptyValue(transferDetail.deliveringInstitution)) {
    notify("Please enter a delivering institution", "error");
    isValid = false;
  } else if (checkEmptyValue(transferDetail.institutionCountry)) {
    notify("Please enter an institution country", "error");
    isValid = false;
  } else if (checkEmptyValue(transferDetail.institutionAddress)) {
    notify("Please enter an institution address", "error");
    isValid = false;
  } else if (
    checkEmptyValue(transferDetail.institutionBusinessPhone) ||
    !validatePhone(transferDetail.institutionBusinessPhone)
  ) {
    notify("Please enter a valid business phone", "error");
    isValid = false;
  } else if (checkEmptyValue(transferDetail.institutionBusinessPhoneExt)) {
    notify("Please enter an institution business phone ext", "error");
    isValid = false;
  } else if (checkEmptyValue(transferDetail.institutionDealerNumber)) {
    notify("Please enter an institution dealer number", "error");
    isValid = false;
  }

  return isValid;
};

export default CreateInvestmentAccountTransferDetails;
