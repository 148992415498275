import { Tooltip } from "react-tooltip";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/createIPS.scss";
import { CreateIndividualPolicyStatementDto } from "../types";
import { useParams } from "react-router-dom";
import "react-step-progress/dist/index.css";
import withAuth from "HOC/withAuth";
import StepFormWizard from "./StepFormWIzard";
import { useCreateIPSMutation, useLazyGetClientByIdQuery } from "Apis/mainApi";
import { ApiResponseModel, Participant } from "types";
import { notify } from "utils";
import { CreateObjectiveDto } from "../types/CreateIndividualPolicyStatementDto";
import IPSClientSummary from "./IPSClientSummary";
import {
  validateClientSummary,
  validateInvestmentObjectives,
  validateLimits,
  validateTimeHorizon,
} from "../utils";
import IPSTimeHorizon from "./IPSTimeHorizon";
import IPSLimitsOnInvestment from "./IPSLimitsOnInvestments";
import IPSClientInvestmentObjectives from "./IPSInvestmentObjectives";
import IPSAccountFeeDescriptions from "./IPSAccountFeeDescriptions";
import IPSReviewDetails from "./IPSReviewDetails";
import IPSLegalRequirements from "./IPSLegalRequirements";
import IPSUniqueCircumstances from "./IPSUniqueCircumstances";
// Create Empty IPS

const emptyObjective: CreateObjectiveDto = {
  upperLimit: 0,
  lowerLimit: 0,
  target: 0,
};

const emptyIPS: CreateIndividualPolicyStatementDto = {
  participantId: "",
  clientSummary: "",
  legalRegulatoryConsiderations: "",
  uniqueCircumstances: "",
  timeHorizion: 0,
  incomeRequirements: false,
  maxCommericialPaperPercentage: 0,
  maxSecurityIssuerPercentage: 0,
  cashAndEquivalentsObjectives: emptyObjective,
  fixedIncomeObjectives: emptyObjective,
  equityObjectives: emptyObjective,
  alternativeInvestmentsObjectives: emptyObjective,
  accountFeeDescriptions: [],
};

/**
 * Main Component Page to Create IPS for a Client
 * @returns
 */
function CreateInvestmentPolicyStatement() {
  const [clientIPS, setClientIPS] = useState<CreateIndividualPolicyStatementDto>(emptyIPS); //
  const [participant, setParticipant] = useState<Participant>();
  const [clientQuery, clientResult] = useLazyGetClientByIdQuery();
  const { participantId } = useParams();
  const [createIPS] = useCreateIPSMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (participantId) {
      setClientIPS({ ...clientIPS, participantId: participantId });
      console.log(participantId);
      clientQuery(participantId);
    }
  }, [participantId]);

  useEffect(() => {
    if (clientResult.isSuccess && clientResult.data.result) {
      setParticipant(clientResult.data.result);
    }
  }, [clientResult]);

  const submitNewIPS = async () => {
    const result: ApiResponseModel = await createIPS(clientIPS);
    if (result.data?.isSuccess) {
      notify("Successfully Created IPS", "success");
      navigate(-1);
    } else {
      notify("Failed to Create IPS", "error");
    }
  };

  return (
    <div className="create-ips-full-container">
      <button onClick={() => console.log(clientIPS)}>Show IPS Data</button>
      <p className="ips-container-header">Create Investment Policy Statement</p>
      <p className="ips-container-subheader">
        Participant: {participant?.generalDetails.firstName} {participant?.generalDetails.lastName}
      </p>
      <StepFormWizard
        startIndex={0}
        steps={[
          {
            label: "Client Summary",
            subtitle: "",
            content: IPSClientSummary,
            validator: validateClientSummary,
          },
          {
            label: "Time Horizon & Income Requirements",
            subtitle: "",
            content: IPSTimeHorizon,
            validator: validateTimeHorizon,
          },
          {
            label: "Limits on Investment",
            subtitle: "",
            content: IPSLimitsOnInvestment,
            validator: validateLimits,
          },
          {
            label: "Legal & Regulatory Considerations",
            subtitle: "",
            content: IPSLegalRequirements,
          },
          {
            label: "Unique Circumstances",
            subtitle: "",
            content: IPSUniqueCircumstances,
          },
          {
            label: "Investment Objectives",
            subtitle: "",
            content: IPSClientInvestmentObjectives,
            validator: validateInvestmentObjectives,
          },
          {
            label: "Account Fee Descriptions",
            subtitle: "",
            content: IPSAccountFeeDescriptions,
          },
          {
            label: "Review",
            subtitle: "",
            content: IPSReviewDetails,
          },
        ]}
        formData={clientIPS}
        setFormData={setClientIPS}
        handleStepChange={setClientIPS}
        submitForm={submitNewIPS}
      />
    </div>
  );
}

export default withAuth(CreateInvestmentPolicyStatement);
