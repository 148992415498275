import { RiskToleranceFormData, RiskToleranceSection } from "../../../types";
import SIATable, { ColumnConfig } from "../../../Components/Shared/SIATable";
import "../styles/riskSection.scss";

interface RiskWizardSectionProps {
  formData: RiskToleranceFormData;
  section: RiskToleranceSection;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>, sectionId: number) => void;
  value?: number;
}

/**
 * This component focuses on rendering each section of the Risk Tolerance Form.
 * Each Section will contain a header, a question, and a select picker to choose an answer.
 * There are cases where a section may contain a table to depict another type of visualization.
 * @param param0
 * @returns
 */
const RiskWizardSection = ({ formData, section, onChange }: RiskWizardSectionProps) => {
  let columns: ColumnConfig[] = []; // used for a table
  let data: any[] = []; // used for a table

  // Set up a table if it's present
  if (section.isTable) {
    const tableData = section.tableData;
    if (tableData) {
      data = tableData;
      const columnKeys = Object.keys(tableData[0]);
      for (var key of columnKeys) {
        if (key != "id") {
          columns.push({ dataField: key, text: key });
        }
      }
    }
  }

  // Find the form value if it has been answered
  let sectionValue = 0;
  const sectionAnswer = formData.riskScoreSections.filter((x) => x.sectionId == section.id);
  if (sectionAnswer.length == 1) {
    sectionValue = sectionAnswer[0].answerScore;
  }

  return (
    <div className="risk-section-question-container">
      <p className="risk-section-question-header">
        {section.id}. {section.header}
      </p>

      <p className="risk-section-question">{section.question}</p>
      {section.isTable && (
        <div className="risk-section-table">
          <SIATable columns={columns} data={data} keyField="id"></SIATable>
        </div>
      )}
      <div className="risk-section-input-container">
        <select
          onChange={(e) => onChange(e, section.id)}
          className="risk"
          value={sectionValue}
          id={`risk-section-${section.id}`}
          style={sectionValue == 0 ? { border: "2px solid red" } : { border: "" }}
        >
          <option value={0}>Select Option</option>
          {section.possibleAnswers.map((answer, index) => {
            return (
              <option key={index} value={answer.score}>
                {answer.answer}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default RiskWizardSection;
