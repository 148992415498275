import { IPSSectionComponentProps } from "../types";

/**
 * Component for the Time Horizion Section and Income Requirements of the IPS
 * @param param0
 * @returns
 */
const IPSTimeHorizon = ({ formData, setFormData }: IPSSectionComponentProps) => {
  const handleTimeHorizonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = e.target.value;
    const temp = { ...formData, timeHorizion: Number(newVal) };
    setFormData(temp);
  };

  const handleIncomeRequirementsChange = (val: boolean) => {
    const temp = { ...formData, incomeRequirements: val };
    setFormData(temp);
  };

  return (
    <div className="ips-section-container">
      <div className="ips-section-header-container">
        <p className="ips-section-header">Time Horizion and Income Requirements</p>
      </div>
      <div className="ips-section-body-container">
        <div className="input-section">
          <label style={{ fontSize: "1.25rem" }}>
            What is the Client's Time Horizion in Years?
          </label>
          <br />
          <input
            value={formData.timeHorizion}
            type="number"
            onChange={handleTimeHorizonChange}
            style={{ fontSize: "1.25rem" }}
          />
        </div>
        <div className="input-section">
          <label style={{ fontSize: "1.25rem" }}>
            Does Client Require Income From Investment Accounts?
          </label>
          <div className="radio-group">
            <input
              type="radio"
              name="income-requirements"
              value="yes"
              checked={formData.incomeRequirements}
              onChange={() => handleIncomeRequirementsChange(true)}
            />{" "}
            <label>Yes</label>
            <input
              type="radio"
              name="income-requirements"
              value="no"
              checked={!formData.incomeRequirements}
              onChange={() => handleIncomeRequirementsChange(false)}
            />{" "}
            <label>No</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IPSTimeHorizon;
