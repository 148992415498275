function isValidSIN(sinNumber: string | number | undefined) {
  // Remove any non-numeric characters from the SIN
  if (sinNumber != undefined) {
    const cleanedSIN = sinNumber.toString().replace(/\D/g, "");
    // Check if the cleaned SIN is exactly nine digits
    if (cleanedSIN.length !== 9) {
      return false;
    }

    // Validate the SIN using a weighted check digit algorithm
    const sinDigits = cleanedSIN.split("").map(Number);
    let sum = 0;

    for (let i = 1; i < 9; i++) {
      let digit = sinDigits[i - 1];

      if (i % 2 === 0 && i != 0) {
        digit *= 2;

        if (digit > 9) {
          var digits = digit.toString().split("").map(Number);
          digit = digits[0] + digits[1];
        }
      }

      sum += digit;
    }

    // First Check: If the sum is divisible by 10, then we are good
    var firstCheck = sum % 10 == 0;
    if (firstCheck) {
      return firstCheck;
    }

    // Second Check: If the next value divided by 10 subtracted from the sum equals the check digit then we are good
    const lastDigit = sinDigits[8];

    const next10 = Number((sum + 9).toString()[0] + "0");
    return next10 - sum == lastDigit;
  }
  return false;
}

export default isValidSIN;
