import { Participant } from "../../../../../types";
import { InsiderDetail } from "../../../../../types/Participant/Participant";
import EditClientBoolean from "../Inputs/EditClientBoolean";
import EditClientInputWithArray from "../Inputs/EditClientInputWithArray";
import RegulatoryDataProps from "./RegulatoryDataProps";

const emptyInsiderDetail: InsiderDetail = {
  symbol: "",
  companyName: "",
};

const Is10PercentShareholderDetails = ({
  selectedClientData,
  handleDataChange,
  handleRegulatoryDetailChange,
}: RegulatoryDataProps) => {
  const handleShareholder10PAdd = () => {
    let tempData = { ...selectedClientData?.regulatoryDetails! };
    if (!tempData.shareholder10Details) {
      tempData.shareholder10Details = [];
    }

    tempData.shareholder10Details = [...tempData.shareholder10Details, { ...emptyInsiderDetail }];

    handleDataChange({ regulatoryDetails: tempData });
  };

  const handleShareholder10PChange = (
    val: number | string | boolean,
    attrName: string | undefined,
    index: number | undefined
  ) => {
    if (selectedClientData && attrName && index !== undefined) {
      let tempData = { ...selectedClientData?.regulatoryDetails! };
      if (!tempData.shareholder10Details) {
        tempData.shareholder10Details = [];
      }

      // Using spread operator to update an asset
      const updatedObject = {
        ...tempData.shareholder10Details[index],
        [attrName]: val,
      };

      tempData.shareholder10Details = [
        ...tempData.shareholder10Details.slice(0, index),
        updatedObject,
        ...tempData.shareholder10Details.slice(index + 1),
      ];

      handleDataChange({ regulatoryDetails: tempData });
    }
  };

  const handleShareholder10PDelete = (index: number) => {
    if (selectedClientData) {
      let tempData = { ...selectedClientData?.regulatoryDetails! };
      if (!tempData.shareholder10Details) {
        tempData.shareholder10Details = [];
      }

      tempData.shareholder10Details = [
        ...tempData.shareholder10Details.slice(0, index),
        ...tempData.shareholder10Details.slice(index + 1),
      ];

      handleDataChange({ regulatoryDetails: tempData });
    }
  };

  return (
    <>
      <div className="regulatory-list-section-header">10% Shareholder Details</div>
      <EditClientBoolean
        label="Is 10% Shareholder?"
        value={selectedClientData?.regulatoryDetails?.is10PercentShareholder}
        inputName="is10PercentShareholder"
        attrName="is10PercentShareholder"
        handleChange={handleRegulatoryDetailChange}
      />
      {selectedClientData?.regulatoryDetails?.is10PercentShareholder && (
        <div className="regulatory-list-container">
          {selectedClientData?.regulatoryDetails?.shareholder10Details?.map((detail, index) => {
            return (
              <div className="regulatory-list-item insider-detail">
                <EditClientInputWithArray
                  label={`10% Shareholder Symbol ${index + 1}`}
                  value={detail.symbol}
                  attrName="symbol"
                  handleDataChange={handleShareholder10PChange}
                  index={index}
                />
                <EditClientInputWithArray
                  label={`10% Shareholder Company ${index + 1}`}
                  value={detail.companyName}
                  attrName="companyName"
                  handleDataChange={handleShareholder10PChange}
                  index={index}
                />
                <div>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleShareholder10PDelete(index)}
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {selectedClientData?.regulatoryDetails?.is10PercentShareholder && (
        <div className="add-regulatory-detail-btn-cont">
          <button onClick={handleShareholder10PAdd}>Add 10% Shareholder Detail</button>
        </div>
      )}
    </>
  );
};

export default Is10PercentShareholderDetails;
