import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import withAuth from "HOC/withAuth";
import {
  useLazyDownloadUnsignedKYCDocumentQuery,
  useLazyGetClientByIdQuery,
} from "../../../Apis/mainApi";
import { ApiResponseModel, Participant } from "../../../types";
import "../styles/client.scss";
import $ from "jquery";
import ClientGeneralDetails from "./clientGeneralDetails";
import ClientAddressDetails from "./clientAddressDetails";
import ClientEmploymentDetails from "./clientEmploymentDetails";
import ClientNetWorthDetails from "./clientNetWorthDetails";
import ClientExternalInvestmentDetails from "./clientExternalInvestmentDetails";
import ClientSpouseDetails from "./clientSpouseDetails";
import { MainLoader } from "Components/Shared";

import { notify, sleep } from "utils";
import ClientRegulatoryDetails from "features/client/components/clientRegulatoryDetails";
import {
  useLazyDownloadSignedDocumentQuery,
  useLazyGetRiskToleranceResponseByClientQuery,
} from "Apis/riskToleranceApi";
import { useSendMagicLinkMutation } from "Apis/mainApi";
import { RiskToleranceDto } from "../../riskTolerance/types/RiskToleranceWizard";
import { ParticipantRole } from "constants/enums";
import { useLazyDownloadIPSUnsignedDocumentQuery } from "Apis/ipsApi";
/**
 * @summary Function that handles the client detail click
 * @param e - The Click Event
 * @param targetDetail - The Target Detail to show
 * @author Teddy K.
 */
const handleClientDetailClick = async (
  e: React.MouseEvent<HTMLLIElement, MouseEvent>,
  targetDetail: string
) => {
  const $target = $(e.target);

  // Find the Active List Item;
  const $activeItem = $(".client-detail-select-item.active");
  // Remove the Active Status

  $($activeItem).removeClass("active");
  // Set the target to active
  $target.addClass("active");

  // Find the Active Detail Container & Remove Active Status
  const $allDetails = $(".client-details");
  $allDetails.css("opacity", 0);

  // Fade Effect
  await sleep(100);

  const $activeDetails = $(".client-details.active");
  $activeDetails.removeClass("active");

  // Set the target details to active
  $(`#${targetDetail}`).addClass("active");

  // Fade Effect
  await sleep(100);
  $allDetails.css("opacity", 1);
};

function Client() {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [clientQuery, clientResult] = useLazyGetClientByIdQuery();
  const [selectedClientData, setSelectedClientData] = useState<Participant>();

  useEffect(() => {
    if (clientId) {
      clientQuery(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    if (clientResult.isSuccess) {
      const result: Participant = clientResult.data.result;
      console.log(result);
      setSelectedClientData(result);
    }
  }, [clientResult]);

  return (
    <div className="client-container">
      {selectedClientData ? (
        <>
          <div className="client-header-container">
            <button onClick={() => navigate(-1)}>Go Back</button>
            <p className="client-header">Client Information</p>
            <p className="client-header-name">
              {`${
                selectedClientData.generalDetails.title
                  ? selectedClientData.generalDetails.title + "."
                  : ""
              } ${selectedClientData.generalDetails.firstName} ${
                selectedClientData.generalDetails.lastName
              }`}
            </p>
          </div>
          <div className="client-details-and-select-container">
            <div className="client-detail-select-container">
              <ul className="client-detail-select-list">
                <ClientDetailsSelectItem
                  handleClientDetailClick={handleClientDetailClick}
                  labelName="General Details"
                  targetDetail="client-general-details"
                  isActive={true}
                />
                {selectedClientData.permanentAddressDetails && (
                  <ClientDetailsSelectItem
                    handleClientDetailClick={handleClientDetailClick}
                    labelName="Address Details"
                    targetDetail="client-address-details"
                  />
                )}
                {selectedClientData.employmentDetails && (
                  <ClientDetailsSelectItem
                    handleClientDetailClick={handleClientDetailClick}
                    labelName="Employment Details"
                    targetDetail="client-employment-details"
                  />
                )}
                {selectedClientData.spouseDetails && (
                  <ClientDetailsSelectItem
                    handleClientDetailClick={handleClientDetailClick}
                    labelName="Spouse Details"
                    targetDetail="client-spouse-details"
                  />
                )}
                {selectedClientData.netWorthDetails && (
                  <ClientDetailsSelectItem
                    handleClientDetailClick={handleClientDetailClick}
                    labelName="Net Worth Details"
                    targetDetail="client-net-worth-details"
                  />
                )}
                {selectedClientData.externalInvestmentDetails && (
                  <ClientDetailsSelectItem
                    handleClientDetailClick={handleClientDetailClick}
                    labelName="External Investment Details"
                    targetDetail="client-external-investment-details"
                  />
                )}
                {selectedClientData.regulatoryDetails && (
                  <ClientDetailsSelectItem
                    handleClientDetailClick={handleClientDetailClick}
                    labelName="Regulatory Details"
                    targetDetail="client-regulatory-details"
                  />
                )}
              </ul>
            </div>
            <div className="client-details-container">
              <ClientGeneralDetails selectedClientData={selectedClientData} />

              {selectedClientData.permanentAddressDetails && (
                <ClientAddressDetails selectedClientData={selectedClientData} />
              )}
              {selectedClientData.employmentDetails && (
                <ClientEmploymentDetails selectedClientData={selectedClientData} />
              )}
              {selectedClientData.spouseDetails && (
                <ClientSpouseDetails selectedClientData={selectedClientData} />
              )}
              {selectedClientData.netWorthDetails && (
                <ClientNetWorthDetails selectedClientData={selectedClientData} />
              )}
              {selectedClientData.externalInvestmentDetails && (
                <ClientExternalInvestmentDetails selectedClientData={selectedClientData} />
              )}
              {selectedClientData.regulatoryDetails && (
                <ClientRegulatoryDetails selectedClientData={selectedClientData} />
              )}
            </div>
          </div>
          {selectedClientData.roles.includes(ParticipantRole.ACCOUNT_HOLDER.toLowerCase()) && (
            <div className="actions-container">
              <div className="actions-container-header">
                <p>Account Holder Action Items</p>
              </div>
              <div className="action-items-container">
                <ClientRiskToleranceActionItem selectedClientData={selectedClientData} />

                {selectedClientData.completedRiskTolerance && (
                  <ClientKYCActionItem selectedClientData={selectedClientData} />
                )}

                {selectedClientData.completedKYC && selectedClientData.completedRiskTolerance && (
                  <ClientAccountOpeningActionItem selectedClientData={selectedClientData} />
                )}
                {selectedClientData.completedAccountOpening &&
                  selectedClientData.completedKYC &&
                  selectedClientData.completedRiskTolerance && (
                    <ClientIPSActionItem selectedClientData={selectedClientData} />
                  )}
              </div>
            </div>
          )}
        </>
      ) : (
        <MainLoader />
      )}
    </div>
  );
}

// Tabs in the Client Details
interface ClientDetailsSelectItemProps {
  handleClientDetailClick: (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    targetDetail: string
  ) => void;
  labelName: string;
  targetDetail: string;
  isActive?: boolean;
}

const ClientDetailsSelectItem = ({
  handleClientDetailClick,
  labelName,
  targetDetail,
  isActive,
}: ClientDetailsSelectItemProps) => {
  return (
    <li
      className={isActive ? "client-detail-select-item active" : "client-detail-select-item"}
      onClick={(e) => handleClientDetailClick(e, targetDetail)}
    >
      {labelName}
    </li>
  );
};

interface ActionItemProps {
  selectedClientData: Participant;
}

const ClientRiskToleranceActionItem = ({ selectedClientData }: ActionItemProps) => {
  const [statusMessage, setStatusMessage] = useState<string>("");
  const [documentStatus, setDocumentStatus] = useState<string>("");
  const navigate = useNavigate();
  const [sendMagicLink] = useSendMagicLinkMutation();
  const [riskToleranceQuery, riskToleranceResult] = useLazyGetRiskToleranceResponseByClientQuery(); // Get a Risk Tolerance by Client Id
  const [downloadSignedDocument, signedDocument] = useLazyDownloadSignedDocumentQuery();
  // This is how a use will send a link to a client to fill out a form
  const handleSendMagicLink = async () => {
    const params = {
      foClientId: selectedClientData.id,
    };
    try {
      const result: ApiResponseModel = await sendMagicLink(params);
      if (result.data && result.data.isSuccess) {
        notify("Risk Tolerance Link sent successfully", "success");
      } else {
        notify("Error sending Risk Tolerance Link", "error");
        console.log(result.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadSignedDocument = async () => {
    const result = await downloadSignedDocument(selectedClientData.id);
  };

  useEffect(() => {
    // If the client has completed the risk tolerance, get the risk tolerance details
    // This has the One Span API call to get the Risk Tolerance status
    if (selectedClientData.completedRiskTolerance) {
      riskToleranceQuery(selectedClientData.id);
    } else {
      setStatusMessage("Client Must Complete Tolerance Before Proceeding");
    }
  }, []);

  // Check the Risk Tolernace Result
  useEffect(() => {
    if (riskToleranceResult.isSuccess) {
      const result: RiskToleranceDto = riskToleranceResult.data.result;
      if (result.oneSpanPackage != undefined) {
        switch (result.oneSpanPackage.status) {
          case "SENT": {
            setStatusMessage("Risk Tolerance Has Been Sent For Signature");
            break;
          }
          case "COMPLETED": {
            setStatusMessage("Risk Tolerance Has Been Completed");
            setDocumentStatus("COMPLETED");
            break;
          }
          default: {
            break;
          }
        }
      } else {
        setStatusMessage("Client Has Completed Risk Tolerance");
      }
    }
  }, [riskToleranceResult]);

  return (
    <div
      className={`action-item ${
        selectedClientData.accountHolderWorkflow?.rTQCompletedByParticipant
          ? "completed-action"
          : "not-completed-action"
      }`}
    >
      <p className="action-item-header">
        Risk Tolerance{" "}
        {selectedClientData.completedRiskTolerance ? (
          <i className="bi bi-check2-square text-success"></i>
        ) : (
          <i className="bi bi-exclamation-diamond-fill text-warning"></i>
        )}
      </p>

      <p className="action-item-subheader">{statusMessage}</p>
      <div className="action-link-container">
        <button onClick={handleSendMagicLink}>Email Risk Tolerance Questionaire</button>
        <button onClick={() => navigate(`/riskToleranceForm/${selectedClientData.id}/`)}>
          Complete Form For Participant
        </button>
        {documentStatus === "COMPLETED" && (
          <button onClick={handleDownloadSignedDocument}>
            Download Signed RTQ Form <i className="bi bi-download"></i>
          </button>
        )}
      </div>
    </div>
  );
};

const ClientKYCActionItem = ({ selectedClientData }: ActionItemProps) => {
  const navigate = useNavigate();
  const [downloadUnsignedKYC, unsignedKYC] = useLazyDownloadUnsignedKYCDocumentQuery();

  const handleDownloadUnsignedKYC = async () => {
    const result = await downloadUnsignedKYC(selectedClientData.id);
  };

  return (
    <div
      className={`action-item ${
        selectedClientData.completedKYC ? "completed-action" : "not-completed-action"
      }`}
    >
      <p className="action-item-header">
        Front Office Client Application{" "}
        {selectedClientData.completedKYC ? (
          <i className="bi bi-check2-square text-success"></i>
        ) : (
          <i className="bi bi-exclamation-diamond-fill text-warning"></i>
        )}
      </p>
      <p className="action-item-subheader">
        {selectedClientData.completedKYC
          ? "Final Details for KYC Have Been Completed"
          : "Client or Advisor Must Complete KYC Before Proceeding"}
      </p>
      <div className="action-link-container">
        {selectedClientData.completedKYC ? (
          <button onClick={() => navigate(`/editClient/${selectedClientData.id}`)}>
            Edit Client Information
          </button>
        ) : (
          <button onClick={() => navigate(`/editClient/${selectedClientData.id}`)}>
            Create Client Application
          </button>
        )}
        {
          // If the client has completed the KYC and has an account open
          selectedClientData.completedKYC && selectedClientData.completedAccountOpening && (
            <button onClick={handleDownloadUnsignedKYC}>
              Download Unsigned KYC Form <i className="bi bi-download"></i>
            </button>
          )
        }
      </div>
    </div>
  );
};

const ClientAccountOpeningActionItem = ({ selectedClientData }: ActionItemProps) => {
  const navigate = useNavigate();
  return (
    <div
      className={`action-item ${
        selectedClientData.completedAccountOpening ? "completed-action" : "not-completed-action"
      }`}
    >
      <p className="action-item-header">
        Account Opening{" "}
        {selectedClientData.completedAccountOpening ? (
          <i className="bi bi-check2-square text-success"></i>
        ) : (
          <i className="bi bi-exclamation-diamond-fill text-warning"></i>
        )}
      </p>
      <p className="action-item-subheader">
        {selectedClientData.completedAccountOpening
          ? "Client Has Investment Account Created"
          : "An Investment Account(s) must be created before proceeding"}
      </p>
      <div className="action-link-container">
        {selectedClientData.completedAccountOpening ? (
          <button
            onClick={() => navigate(`/createClientInvestmentAccount/${selectedClientData.id}`)}
          >
            View Participant Accounts
          </button>
        ) : (
          <button
            onClick={() => navigate(`/createClientInvestmentAccount/${selectedClientData.id}`)}
          >
            Create Client Investment Account
          </button>
        )}
      </div>
    </div>
  );
};

const ClientIPSActionItem = ({ selectedClientData }: ActionItemProps) => {
  const navigate = useNavigate();
  const [downloadUnsignedIPS, unsignedIPS] = useLazyDownloadIPSUnsignedDocumentQuery();

  const handleDownloadUnsignedIPS = async () => {
    const result = await downloadUnsignedIPS(selectedClientData.id);
  };

  return (
    <div
      className={`action-item ${
        selectedClientData.completedIPS ? "completed-action" : "not-completed-action"
      }`}
    >
      <p className="action-item-header">
        Investment Policy Statement{" "}
        {selectedClientData.completedIPS ? (
          <i className="bi bi-check2-square text-success"></i>
        ) : (
          <i className="bi bi-exclamation-diamond-fill text-warning"></i>
        )}
      </p>
      <p className="action-item-subheader">
        {selectedClientData.completedIPS
          ? "IPS has been created for client"
          : "IPS must be created before proceeding"}
      </p>
      <div className="action-link-container">
        {selectedClientData.completedIPS ? (
          <>
            <button onClick={handleDownloadUnsignedIPS}>
              Download IPS Form <i className="bi bi-download"></i>
            </button>
            <button onClick={() => {}}>
              Edit IPS Form <i className="bi bi-download"></i>
            </button>
          </>
        ) : (
          <button onClick={() => navigate(`/createIPS/${selectedClientData.id}`)}>
            Create Client IPS
          </button>
        )}
      </div>
    </div>
  );
};

export default withAuth(Client);
