import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetClientByIdQuery, useUpdateNewClientMutation } from "../../Apis/mainApi";
import { ApiResponseModel, Participant } from "../../types";
import "./styles/editClient.scss";
import withAuth from "../../HOC/withAuth";
import { notify } from "../../utils";
import $ from "jquery";
import { ValidateAllDetails } from "../../Components/Pages/CreateFullClient/Validators";
import {
  CreateClientEmploymentDetails,
  CreateClientExternalInvestmentDetails,
  CreateClientGeneralDetails,
  CreateClientNetWorthDetails,
  CreateClientRegulatoryDetails,
  CreateClientSpouseDetails,
  CreateFullClientSummary,
} from "../../Components/Pages/CreateFullClient/Steps";
import CreateClientAddressDetails from "../../Components/Pages/CreateFullClient/Steps/CreateClientAddressDetails";
import "./styles/createFullClientSummary.scss";
import { WizardListItem } from "../../Components/Pages/CreateFullClient/Helpers/Wizard";
/**
 * This page is responsible for the create a FULL client
 * This means that the client will have all the details filled out for their entity int the database
 * This will focus on everything except bank accounts and investment accounts, we will handle this differently
 *
 * This page is made up of different components that focus on different types of information for the client, each of the following
 * GeneralDetails
 * AddressDetails
 * EmploymentDetails
 * SpouseDetails
 * NetWorthDetails
 * ExternalInvestmentDetails
 * RegulatoryDetails
 *
 * Each of these components will be responsible for their own data and will be passed in the client data and the function to handle the data change
 * Each component will call the handleDataChange function to update the client data in the parent component
 *
 * The parent component will be responsible for the saving of the client data and the navigation between the different components
 *
 * / BIGGG TODO, need to clean this shit up
 */

const spouseDetailTypes = ["Married", "Common Law"];

function CreateFullClient() {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const [clientQuery, clientResult] = useLazyGetClientByIdQuery();
  const [selectedClientData, setSelectedClientData] = useState<Participant>();
  const [completedForm, setCompletedForm] = useState<boolean>(false);

  const [updateClient] = useUpdateNewClientMutation();
  useEffect(() => {
    if (clientId) {
      clientQuery(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    if (clientResult.isSuccess) {
      const result: Participant = { ...clientResult.data.result }; // Makes a copy of the result and stores in the state variable
      setSelectedClientData(result);
    }
  }, [clientResult]);

  const handleDataChange = (data: Partial<Participant>): void => {
    setSelectedClientData((prevFormData) => {
      if (prevFormData != undefined) {
        return { ...prevFormData, ...data };
      }
    });
  };

  /**
   * Function that handles the going back of the wizard
   * Will change the active class of both the select menu and
   * the wizard item
   */
  const handleGoBack = () => {
    if (selectedClientData) {
      const $selectItems = $(".edit-client-progress-item");
      // From the list, find the items with the active class
      for (var i = 0; i < $selectItems.length; i++) {
        if ($($selectItems[i]).hasClass("active")) {
          // If the item has the active class, remove it
          $($selectItems[i]).removeClass("active");
          // Add the active class to the next item
          $($selectItems[i - 1]).addClass("active");
          break;
        }
      }

      // Find all the wizard items
      const $wizardItems = $(".edit-client-wizard-item");
      // From the list, find the items with the active class
      for (var i = 0; i < $wizardItems.length; i++) {
        if ($($wizardItems[i]).hasClass("active")) {
          // If the item has the active class, remove it
          $($wizardItems[i]).removeClass("active");
          // Add the active class to the next item
          $($wizardItems[i - 1]).addClass("active");
          break;
        }
      }
    }
  };

  /**
   * Function that handles saving the client details on the click
   * of the save button
   * @returns
   */
  const handleSaveClientDetails = async (sectionId: number, shouldNotSave?: boolean) => {
    if (selectedClientData) {
      // Check that all fields are valid and filled out
      // If not, return and notify the user
      if (!shouldNotSave) {
        if (!ValidateAllDetails(selectedClientData, sectionId)) {
          return;
        }
        const result: ApiResponseModel = await updateClient(selectedClientData);
        if (result.data?.isSuccess) {
          notify("Client Details Updated Successfully", "success");
          // Find all the select items
          moveForwardInWizard();
        } else {
          notify("Error Updating Client Details", "error");
        }
      } else {
        moveForwardInWizard();
      }
    }
  };

  function moveForwardInWizard() {
    const $selectItems = $(".edit-client-progress-item");
    // From the list, find the items with the active class
    for (var i = 0; i < $selectItems.length; i++) {
      if ($($selectItems[i]).hasClass("active")) {
        if (i === $selectItems.length - 1) {
          // If the item is the last item in the list, then we have completed the form
          break;
        }
        // If the item has the active class, remove it
        $($selectItems[i]).removeClass("active");
        // Add the active class to the next item
        $($selectItems[i + 1]).addClass("active");
        break;
      }
    }

    // Find all the wizard items
    const $wizardItems = $(".edit-client-wizard-item");
    // From the list, find the items with the active class
    for (var i = 0; i < $wizardItems.length; i++) {
      if ($($wizardItems[i]).hasClass("active")) {
        // If the item has the active class, remove it
        if (i === $wizardItems.length - 1) {
          // If the item is the last item in the list, then we have completed the form
          setCompletedForm(true);
          break;
        }

        // Remove the active class from the select menu
        $($wizardItems[i]).removeClass("active");

        // Add the active class to the next item
        $($wizardItems[i + 1]).addClass("active");
        break;
      }
    }
  }

  /**
   * Handles changing the flag to completed in the database
   */
  const finalSaveClientDetails = async () => {
    if (selectedClientData) {
      // if (!ValidateAllDetails(selectedClientData, 0)) {
      //   return;
      // }

      selectedClientData.completedKYC = true;
      const result: ApiResponseModel = await updateClient(selectedClientData);

      if (result.data?.isSuccess) {
        notify("Successfully Created Client!");
        navigate(-1);
      }
    }
  };

  /**
   * Legacy function used to handle the change of the step
   * when a user clicks on a list element in the select menu
   *
   * Going to be used in development for quicker changes between steps
   * @param e
   * @param target
   */
  const handleStepChange = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, target: string) => {
    //Remove the active class from the select menu
    const $selectItemActive = $(".edit-client-progress-item.active");
    $selectItemActive.removeClass("active");
    // Make the current item active
    // Because its possible to click the p element, we need to find the parent li element
    let $currentItem = $(e.target);
    if ($currentItem.is("p")) {
      $currentItem = $currentItem.parent();
    }
    $currentItem.addClass("active");
    const $target = $(`#${target}`);
    // Find the Active List Item;
    const $activeItem = $(".edit-client-wizard-item.active");
    //console.log($activeItem);
    // Remove the Active Status
    $($activeItem).removeClass("active");
    // Set the target to active
    $target.addClass("active");
  };

  // There is an issue with the clientResult where the result can come back as null
  // Need to ensure that we have an empty value where null values occur.

  return (
    <div className="edit-client-full-container">
      <div className="edit-client-header">
        <button onClick={() => navigate(-1)}>Go Back</button>
        <button onClick={() => console.log(selectedClientData)}>Show Form</button>
        <p>Create Front-Office Application</p>
      </div>
      <div className="edit-client-container">
        <ul className="edit-client-progress">
          <WizardListItem
            label="General Details"
            target="edit-client-general-details"
            isActive={true}
            handleStepChange={handleStepChange}
          />
          <WizardListItem
            label="Address Details"
            target="edit-client-address-details"
            handleStepChange={handleStepChange}
          />
          <WizardListItem
            label="Employment Details"
            target="edit-client-employment-details"
            handleStepChange={handleStepChange}
          />
          {selectedClientData?.generalDetails.maritalStatus &&
            spouseDetailTypes.includes(selectedClientData?.generalDetails.maritalStatus) && (
              <WizardListItem
                label="Spouse Details"
                target="edit-client-spouse-details"
                handleStepChange={handleStepChange}
              />
            )}
          <WizardListItem
            label="Net Worth Details"
            target="edit-client-net-worth-details"
            handleStepChange={handleStepChange}
          />
          <WizardListItem
            label="External Investments"
            target="edit-client-external-investment-details"
            handleStepChange={handleStepChange}
          />
          <WizardListItem
            label="Regulatory Details"
            target="edit-client-regulatory-details"
            handleStepChange={handleStepChange}
          />
          <WizardListItem
            label="Review"
            target="edit-client-summary-details"
            handleStepChange={handleStepChange}
          />
        </ul>
        <div className="edit-client-wizard">
          <CreateClientGeneralDetails
            selectedClientData={selectedClientData}
            handleDataChange={handleDataChange}
            handleSaveClientDetails={handleSaveClientDetails}
          />
          <CreateClientAddressDetails
            selectedClientData={selectedClientData}
            handleDataChange={handleDataChange}
            handleSaveClientDetails={handleSaveClientDetails}
            handleGoBack={handleGoBack}
          />
          <CreateClientEmploymentDetails
            selectedClientData={selectedClientData}
            handleDataChange={handleDataChange}
            handleSaveClientDetails={handleSaveClientDetails}
            handleGoBack={handleGoBack}
          />
          {selectedClientData?.generalDetails.maritalStatus &&
            spouseDetailTypes.includes(selectedClientData?.generalDetails.maritalStatus) && (
              <CreateClientSpouseDetails
                selectedClientData={selectedClientData}
                handleDataChange={handleDataChange}
                handleSaveClientDetails={handleSaveClientDetails}
                handleGoBack={handleGoBack}
              />
            )}
          <CreateClientNetWorthDetails
            selectedClientData={selectedClientData}
            handleDataChange={handleDataChange}
            handleSaveClientDetails={handleSaveClientDetails}
            handleGoBack={handleGoBack}
          />
          <CreateClientExternalInvestmentDetails
            selectedClientData={selectedClientData}
            handleDataChange={handleDataChange}
            handleSaveClientDetails={handleSaveClientDetails}
            handleGoBack={handleGoBack}
          />
          <CreateClientRegulatoryDetails
            selectedClientData={selectedClientData}
            handleDataChange={handleDataChange}
            handleSaveClientDetails={handleSaveClientDetails}
            handleGoBack={handleGoBack}
          />
          <CreateFullClientSummary
            client={selectedClientData}
            handleGoBack={handleGoBack}
            handleFinalSaveDetails={finalSaveClientDetails}
          />
        </div>
      </div>
    </div>
  );
}

export default withAuth(CreateFullClient);
