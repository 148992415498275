import { EmploymentTypes } from "../../../../assets/StaticData/EditClientOptions";
import { isValidUSZip, validatePostalCode } from "../../../../utils";
import { EditClientInput, EditClientSelect } from "../Helpers/Inputs";
import { EditClientWizardItemProps } from "./EditClientWizardItemProps";

const SECTION_ID = 3;
/**
 * Components that manages the change of employment details for a client
 * @param param0
 * @returns
 */
const CreateClientEmploymentDetails = ({
  selectedClientData,
  handleDataChange,
  handleSaveClientDetails,
  handleGoBack,
}: EditClientWizardItemProps) => {
  /**
   * Handles the change of the employment details given the value, attribute name and chekc if its a boolean
   * @param val The value of the change
   * @param attrName The attribute name of the change
   * @param check The boolean value of the change if it is a boolean
   */
  const handleEmploymentDetailsChange = (
    val: number | string | boolean,
    attrName: string | undefined,
    check?: boolean
  ) => {
    let value: any = "";
    if (check !== undefined) {
      value = check;
    } else {
      value = val;
    }

    if (selectedClientData && attrName) {
      let tempData = { ...selectedClientData.employmentDetails };
      tempData[attrName] = value;
      handleDataChange({ employmentDetails: tempData });
    }
  };

  return (
    <div
      className="edit-client-wizard-item"
      id="edit-client-employment-details"
    >
      <div className="edit-client-wizard-item-header">
        <button
          className="edit-client-wizard-item-back"
          onClick={handleGoBack}
          style={{ fontSize: "1rem" }}
        >
          <i className="bi bi-arrow-left-circle-fill"></i>
        </button>
        <p>Employment Details</p>
      </div>
      <div className="edit-client-wizard-item-body">
        <EditClientSelect
          label="Employment Status"
          value={selectedClientData?.employmentDetails?.status}
          attrName="status"
          handleChange={handleEmploymentDetailsChange}
          selectItems={EmploymentTypes}
        />
        <EditClientInput
          label="Occupation"
          value={selectedClientData?.employmentDetails?.occupation}
          attrName="occupation"
          handleChange={handleEmploymentDetailsChange}
        />
        <EditClientInput
          label="Company Name"
          value={selectedClientData?.employmentDetails?.name}
          attrName="name"
          handleChange={handleEmploymentDetailsChange}
        />
        <EditClientInput
          label="Type of Business"
          value={selectedClientData?.employmentDetails?.businessType}
          attrName="businessType"
          handleChange={handleEmploymentDetailsChange}
        />
        <EditClientInput
          label="Length Of Employment (Years)"
          value={selectedClientData?.employmentDetails?.lengthInYears}
          attrName="lengthInYears"
          isNumber={true}
          handleChange={handleEmploymentDetailsChange}
        />
        <EditClientInput
          label="City"
          value={selectedClientData?.employmentDetails?.city}
          attrName="city"
          handleChange={handleEmploymentDetailsChange}
        />
        <EditClientSelect
          label="Country"
          value={selectedClientData?.employmentDetails?.country}
          attrName="country"
          handleChange={handleEmploymentDetailsChange}
          isCountry={true}
        />
        <EditClientSelect
          label="Region"
          value={selectedClientData?.employmentDetails?.region}
          attrName="region"
          handleChange={handleEmploymentDetailsChange}
          isRegion={true}
          country={selectedClientData?.employmentDetails?.country}
        />
        <EditClientInput
          label="Address"
          value={selectedClientData?.employmentDetails?.address}
          attrName="address"
          handleChange={handleEmploymentDetailsChange}
        />

        {(selectedClientData?.employmentDetails?.country === "Canada" ||
          selectedClientData?.employmentDetails?.country ===
            "United States") && (
          <EditClientInput
            label={
              selectedClientData?.employmentDetails?.country === "Canada"
                ? "Postal Code"
                : "Zip Code"
            }
            value={selectedClientData?.employmentDetails?.postalCode}
            attrName="postalCode"
            handleChange={handleEmploymentDetailsChange}
            validateValue={
              selectedClientData?.employmentDetails?.country === "Canada"
                ? validatePostalCode
                : isValidUSZip
            }
          />
        )}
      </div>
      <button
        className="btn-nice-purple btn-save-client-details"
        onClick={() => handleSaveClientDetails(SECTION_ID)}
      >
        Save and Continue
      </button>
    </div>
  );
};

export default CreateClientEmploymentDetails;
