export const ParticipantRoleMapping: Record<string | number, string> = {
  "56b8bbce-4d6c-4af8-bf3d-40974c11af8d": "Account Holder",
  "6f3ee629-152a-45c9-81e4-1728e624bf69": "Beneficiary",
  "8fabf529-f2a3-4d27-a386-621e9e77a8ae": "Director",
  "15293291-f7c8-4c03-801b-7cfd595238aa": "Third Party",
  "ceb8a815-1fe9-4bd3-ac5c-9d849e5b4cd8": "Trustee",
  "59bfb474-617e-4ec6-b0c7-79d3f40b5495": "Power of Attorney",
  "7c5eb5fd-64bc-4cc6-b5c2-2993a5913876": "In Trust For Beneficiary",
  "587b86a3-76f2-4e8e-b2ba-915d8b9e6ae5": "RESP Beneficiary",
  "0c41e07d-bc9f-49c3-8e5e-32c7f9a2c054": "Spouse Contributer",
};
