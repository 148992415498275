import { useState } from "react";
import CreateClientSummarySectionProps from "./CreateClientSummarySectionProps";
import ClientDetail from "../../../../../features/client/components/clientDetail";

const CreateFullClientSummaryNetWorthDetails = ({ client }: CreateClientSummarySectionProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const maxHeight = "600px";

  return (
    <div className="create-client-summary-section" style={showMore ? { height: maxHeight } : {}}>
      <div className="create-client-summary-section-header" onClick={() => setShowMore(!showMore)}>
        <p>Net Worth Details</p>
        <button onClick={() => setShowMore(!showMore)}>
          {showMore ? (
            <i className="bi bi-caret-up-fill"></i>
          ) : (
            <i className="bi bi-caret-down-fill"></i>
          )}
        </button>
      </div>
      <div className="create-client-summary-section-header-underline"></div>

      {client?.netWorthDetails?.liquidAssets &&
        client?.netWorthDetails?.liquidAssets.map((asset, index) => (
          <div>
            <ClientDetail label={`Liquid Asset ${index + 1}`} value={asset.type} />
            <ClientDetail
              label={`Liquid Asset ${index + 1} Value`}
              value={asset.amount}
              isDollarAmount={true}
              isCommmas={true}
            />
          </div>
        ))}

      {client?.netWorthDetails?.fixedAssets &&
        client?.netWorthDetails?.fixedAssets.map((asset, index) => (
          <div>
            <ClientDetail label={`Fixed Asset ${index + 1}`} value={asset.type} />
            <ClientDetail
              label={`Fixed Asset ${index + 1} Value`}
              value={asset.amount}
              isDollarAmount={true}
              isCommmas={true}
            />
          </div>
        ))}

      {client?.netWorthDetails?.liabilities &&
        client?.netWorthDetails?.liabilities.map((asset, index) => (
          <div>
            <ClientDetail label={`Liability ${index + 1}`} value={asset.type} />
            <ClientDetail
              label={`Liability ${index + 1} Value`}
              value={asset.amount}
              isDollarAmount={true}
              isCommmas={true}
            />
          </div>
        ))}
    </div>
  );
};

export default CreateFullClientSummaryNetWorthDetails;
