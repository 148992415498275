import { useState } from "react";
import CreateClientSummarySectionProps from "./CreateClientSummarySectionProps";
import ClientDetail from "../../../../../features/client/components/clientDetail";

const CreateFullClientSummaryExternalInvestmentDetails = ({
  client,
}: CreateClientSummarySectionProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const maxHeight = "350px";

  return (
    <div className="create-client-summary-section" style={showMore ? { height: maxHeight } : {}}>
      <div className="create-client-summary-section-header" onClick={() => setShowMore(!showMore)}>
        <p>External Investment Details</p>
        <button onClick={() => setShowMore(!showMore)}>
          {showMore ? (
            <i className="bi bi-caret-up-fill"></i>
          ) : (
            <i className="bi bi-caret-down-fill"></i>
          )}
        </button>
      </div>
      <div className="create-client-summary-section-header-underline"></div>

      <ClientDetail
        label="Equities Value"
        value={client?.externalInvestmentDetails?.equitiesValue}
        isDollarAmount={true}
        isCommmas={true}
      />
      <ClientDetail
        label="Fixed Income Value"
        value={client?.externalInvestmentDetails?.fixedIncomeValue}
        isDollarAmount={true}
        isCommmas={true}
      />
      <ClientDetail
        label="Cash Or Equivalent Value"
        value={client?.externalInvestmentDetails?.cashEquivalentValue}
        isDollarAmount={true}
        isCommmas={true}
      />
      <ClientDetail
        label="Real Estate Value"
        value={client?.externalInvestmentDetails?.realEstateValue}
        isDollarAmount={true}
        isCommmas={true}
      />
      <ClientDetail
        label="Commodities Value"
        value={client?.externalInvestmentDetails?.commoditiesValue}
        isDollarAmount={true}
        isCommmas={true}
      />
      <ClientDetail
        label="Others Value"
        value={client?.externalInvestmentDetails?.otherValue}
        isDollarAmount={true}
        isCommmas={true}
      />
      <ClientDetail
        label="Others Description"
        value={client?.externalInvestmentDetails?.otherDescription}
      />
      <ClientDetail
        label="Annual Income"
        value={client?.externalInvestmentDetails?.annualIncome}
        isDollarAmount={true}
        isCommmas={true}
      />
    </div>
  );
};

export default CreateFullClientSummaryExternalInvestmentDetails;
