import { useGetAuthorizedUserQuery } from "../Apis/userApi";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setLoggedInUser, emptyUserState } from "../stores/Redux/userAuthSlice";

import { MainLoader } from "../Components/Shared";

const withAuth = (WrappedComponent: any) => {
  return (props: any) => {
    const dispatch = useDispatch();
    const { data, isLoading, isError, isSuccess, refetch } = useGetAuthorizedUserQuery(null);
    // Used to fetch the query every time a page loads
    useEffect(() => {
      refetch();
    }, [refetch]);

    // On Page Load, check for authorized user
    useEffect(() => {
      if (!isLoading) {
        if (isSuccess && data) {
          dispatch(setLoggedInUser(data.result));
        } else if (isError) {
          dispatch(setLoggedInUser(emptyUserState));
        }
      }
    }, [data, dispatch, isError, isSuccess]);

    // Loading Screen
    if (isLoading) {
      return <MainLoader />;
    }

    // If the user is authenticated and is on the login page -> Send to home
    if (isSuccess && data && window.location.pathname == "/login") {
      window.location.replace("/");
      return null;
    }

    // If the user is not authenticated and not on the login page -> send to login page
    if (isError && window.location.pathname != "/login") {
      window.location.replace("/login");
      return null;
    }

    //return <MainLoader />;
    // User is authenticated, let them go to the main page
    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
