import { SelectItem } from "types/Shared";

/**
 * Client Title Options for a Client
 * @author Teddy K.
 * @todo Make the values values of an enum,
 */
export const ClientTitleOptions: SelectItem[] = [
  {
    label: "Mr",
    value: "Mr",
  },
  {
    label: "Mrs",
    value: "Mrs",
  },
  {
    label: "Ms",
    value: "Ms",
  },
  {
    label: "Miss",
    value: "Miss",
  },
  {
    label: "Dr",
    value: "Dr",
  },
];
