import React, { useState } from "react";
import { InvestmentAccountDetailsProps } from "./CreateInvestmentAccountGeneralDetails";
import { AddParticipantToAccountProps } from "../types";
import { notify } from "utils";
import AddExistingParticipantWithRoleToAccount from "./AddExistingParticipantWithRoleToAccount";
import { ParticipantRole } from "constants/enums";
import AddNewParticipantWithRoleForm from "./AddNewParticipantWithRoleForm";

/**
 * Typically the successor of the account is the spouse
 * @param param0
 * @returns
 */
function CreateInvestmentAccountSuccessorDetails({
  account,
  participant,
  handleGoNext,
  setNewInvestmentAccount,
}: InvestmentAccountDetailsProps) {
  const [isAddingSuccessor, setIsAddingSuccessor] = useState(false);
  return (
    <div className="edit-client-wizard-item" id="edit-invest-account-successor-annuitant-details">
      <div className="edit-client-wizard-item-header">
        <p>Successor Annuitant Details</p>
      </div>
      <div className="edit-client-wizard-item-body">
        <div className="successor-container">
          {/* If a spouse has been found for the client, they will be automatically the successor annuitant */}
          <p>Successor has been automatically filled in as the Spouse</p>
          {account.successorAnnuitants &&
            account.successorAnnuitants.length > 0 &&
            account.successorAnnuitants.map((successor) => {
              return <div>Successor Id: {successor}</div>;
            })}
        </div>
        {account.successorAnnuitants && account.successorAnnuitants.length == 0 && (
          <div className="add-contributer-btn-container">
            {isAddingSuccessor ? (
              <button className="btn btn-danger" onClick={() => setIsAddingSuccessor(false)}>
                Cancel
              </button>
            ) : (
              <button className="btn btn-success" onClick={() => setIsAddingSuccessor(true)}>
                Add New Successor
              </button>
            )}
          </div>
        )}
        <div className="add-contributer-form-container">
          {isAddingSuccessor && (
            <AddSuccessorForm
              account={account}
              setAccount={setNewInvestmentAccount}
              participant={participant}
              setIsAddingParticipant={setIsAddingSuccessor}
            />
          )}
        </div>
        {handleGoNext && (
          <button className="btn-nice-purple btn-save-client-details" onClick={handleGoNext}>
            Continue
          </button>
        )}
      </div>
    </div>
  );
}

const AddSuccessorForm = ({
  participant,
  account,
  setAccount,
  setIsAddingParticipant,
}: AddParticipantToAccountProps) => {
  const handleParticipantAdd = (participantId: string) => {
    const tempAccount = { ...account };
    if (tempAccount.successorAnnuitants?.includes(participantId)) {
      notify("This Successor is already added", "error");
      return;
    }

    if (tempAccount.successorAnnuitants) {
      tempAccount.successorAnnuitants.push(participantId);
    } else {
      tempAccount.successorAnnuitants = [participantId];
    }

    setAccount(tempAccount);
    setIsAddingParticipant(false);
  };
  return (
    <div>
      <AddExistingParticipantWithRoleToAccount
        participant={participant!}
        roleLabel="Sucessor Annuitant"
        role={ParticipantRole.BENEFICIARY}
        account={account}
        setAccount={setAccount}
        handleParticipantAdd={handleParticipantAdd}
        setIsAddingParticipant={setIsAddingParticipant}
      />
      {participant && (
        <AddNewParticipantWithRoleForm
          createFormLabel="Sucessor Annuitant"
          role={ParticipantRole.BENEFICIARY}
          householdId={participant.householdId!}
          handleParticipantAdd={handleParticipantAdd}
        />
      )}
    </div>
  );
};

export default CreateInvestmentAccountSuccessorDetails;
