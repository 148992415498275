import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

/**
 * This endpoint uses the AuthController found in the .NET API
 */
const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api/auth`,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (userCredentials) => ({
        url: `login`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: userCredentials,
      }),
    }),
    expiredToken: builder.mutation({
      query: () => ({
        url: `expiredToken`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
      }),
    }),
    getQRCodeForTFASetup: builder.query({
      query: () => ({
        url: `tfa-setup`,
        method: "GET",
      }),
    }),
    postQRCodeForTFASetup: builder.mutation({
      query: (code) => ({
        url: `tfa-setup`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: code,
      }),
    }),
  }),
});

export const {
  useLoginUserMutation,
  useExpiredTokenMutation,
  useGetQRCodeForTFASetupQuery,
  usePostQRCodeForTFASetupMutation,
} = authApi;
export default authApi;
