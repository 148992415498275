import { useGetQRCodeForTFASetupQuery, usePostQRCodeForTFASetupMutation } from "Apis/authApi";
import withAuth from "HOC/withAuth";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { ApiResponseModel } from "types";
import { notify } from "utils";
import { onInputBlur, onInputFocus, onLoginLabelClick } from "../utils";
import { useNavigate } from "react-router-dom";
function SetupTFAPage() {
  /// Set State for QR Code
  const [qrCode, setQRCode] = useState<string>("CODE NOT FOUND");
  // Query for the QR code data
  const { data, isLoading, isSuccess, isError, status } = useGetQRCodeForTFASetupQuery(null);
  // State for the Code Input
  const [code, setCode] = useState<string>("");
  // Post Query for the Code Verficiation
  const [verifyCode] = usePostQRCodeForTFASetupMutation();
  // Navigation
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && isSuccess && data.isSuccess) {
      const { authenticatorKey, email, issuer } = data.result;
      setQRCode(`otpauth://totp/${issuer}:${email}?secret=${authenticatorKey}&issuer=${issuer}`);
    }
  }, [data]);

  const handleCodeSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    verifyCode({ code }).then((res: ApiResponseModel) => {
      if (res.data?.isSuccess) {
        notify("You have successfully set up your 2FA", "success");
        navigate("/");
      } else if (!res.data?.isSuccess) {
        if (res.data?.errorMessages) {
          notify(res.data?.errorMessages[0], "error");
        } else {
          notify("Something went wrong", "error");
        }
      }
    });
  };

  return (
    <div className="login-container-new">
      <div className="border-wrap-container">
        <div className="login-main-content-container">
          <div className="login-header-container">
            <p className="login-header">Welcome Back</p>
            <p className="login-subheader">Advisor Portal</p>
          </div>
          <div className="login-form-container has-qr-code">
            <form className="login-form " onSubmit={handleCodeSubmit}>
              <p>Please Set Up Your 2FA With Google Authenticator or Microsoft Authenticator</p>
              <QRCode value={qrCode} style={{ marginTop: "1rem" }} />
              <div className="login-form-input-cont">
                <label className="login-form-label" onClick={onLoginLabelClick}>
                  Code
                </label>
                <input
                  className="login-form-input"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  onFocus={onInputFocus}
                  onBlur={onInputBlur}
                />
              </div>
              <div className="login-form-btn-submit-cont">
                <button>Confirm</button>
              </div>
            </form>
          </div>
          <div className="login-footer">
            <p>Issues with SIA Account?</p>
            <p>Contact SIA Private Wealth Development Team</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetupTFAPage;
