export enum SourceOfFunds {
  RETIREMENT_INCOME = 1,
  INSURANCE_SETTLEMENT = 2,
  SALE_OF_PROPERTY = 3,
  SAVINGS = 4,
  GIFT_DONATION = 5,
  INVESTMENT_EARNINGS = 6,
  INHERITANCE = 7,
  OTHER = 8,
}
