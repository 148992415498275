/**
 * This Store will be used for the Logged In User
 * We can possibly implement an admin only functionality where we can use a different store for that
 */

import { AuthUser } from "../../features/auth/types";
import { createSlice } from "@reduxjs/toolkit";

export const emptyUserState: AuthUser = {
  user: {
    firstName: "",
    lastName: "",
    city: "",
    region: "",
    country: "",
    id: "",
  },
  roles: [],
  claims: [],
};

export const authAuthSlice = createSlice({
  name: "userAuth",
  initialState: emptyUserState,
  reducers: {
    setLoggedInUser: (state, action) => {
      state.user = action.payload.user;
      state.roles = action.payload.roles;
      state.claims = action.payload.claims;
    },
  },
});

export const { setLoggedInUser } = authAuthSlice.actions;
export const authSliceReducer = authAuthSlice.reducer;
