import { Participant } from "../../../../../types";
import { InsiderDetail } from "../../../../../types/Participant/Participant";
import EditClientBoolean from "../Inputs/EditClientBoolean";
import EditClientInputWithArray from "../Inputs/EditClientInputWithArray";
import RegulatoryDataProps from "./RegulatoryDataProps";
const emptyInsiderDetail: InsiderDetail = {
  symbol: "",
  companyName: "",
};

const Is20PercentShareholderDetails = ({
  selectedClientData,
  handleDataChange,
  handleRegulatoryDetailChange,
}: RegulatoryDataProps) => {
  const handleShareholder20PAdd = () => {
    let tempData = { ...selectedClientData?.regulatoryDetails! };
    if (!tempData.shareholder20Details) {
      tempData.shareholder20Details = [];
    }

    tempData.shareholder20Details = [...tempData.shareholder20Details, { ...emptyInsiderDetail }];

    handleDataChange({ regulatoryDetails: tempData });
  };

  const handleShareholder20PChange = (
    val: number | string | boolean,
    attrName: string | undefined,
    index: number | undefined
  ) => {
    if (selectedClientData && attrName && index !== undefined) {
      let tempData = { ...selectedClientData?.regulatoryDetails! };
      if (!tempData.shareholder20Details) {
        tempData.shareholder20Details = [];
      }

      // Using spread operator to update an asset
      const updatedObject = {
        ...tempData.shareholder20Details[index],
        [attrName]: val,
      };

      tempData.shareholder20Details = [
        ...tempData.shareholder20Details.slice(0, index),
        updatedObject,
        ...tempData.shareholder20Details.slice(index + 1),
      ];

      handleDataChange({ regulatoryDetails: tempData });
    }
  };

  const handleShareholder20PDelete = (index: number) => {
    if (selectedClientData) {
      let tempData = { ...selectedClientData?.regulatoryDetails! };
      if (!tempData.shareholder20Details) {
        tempData.shareholder20Details = [];
      }

      tempData.shareholder20Details = [
        ...tempData.shareholder20Details.slice(0, index),
        ...tempData.shareholder20Details.slice(index + 1),
      ];

      handleDataChange({ regulatoryDetails: tempData });
    }
  };

  return (
    <>
      <div className="regulatory-list-section-header">20% Shareholder Details</div>
      <EditClientBoolean
        label="Is 20% Shareholder?"
        value={selectedClientData?.regulatoryDetails?.is20PercentShareholder}
        inputName="is20PercentShareholder"
        attrName="is20PercentShareholder"
        handleChange={handleRegulatoryDetailChange}
      />
      {selectedClientData?.regulatoryDetails?.is20PercentShareholder && (
        <div className="regulatory-list-container">
          {selectedClientData?.regulatoryDetails?.shareholder20Details?.map((detail, index) => {
            return (
              <div className="regulatory-list-item insider-detail">
                <EditClientInputWithArray
                  label={`20% Shareholder Symbol ${index + 1}`}
                  value={detail.symbol}
                  attrName="symbol"
                  handleDataChange={handleShareholder20PChange}
                  index={index}
                />
                <EditClientInputWithArray
                  label={`20% Shareholder Company ${index + 1}`}
                  value={detail.companyName}
                  attrName="companyName"
                  handleDataChange={handleShareholder20PChange}
                  index={index}
                />
                <div>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleShareholder20PDelete(index)}
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {selectedClientData?.regulatoryDetails?.is20PercentShareholder && (
        <div className="add-regulatory-detail-btn-cont">
          <button onClick={handleShareholder20PAdd}>Add 20% Shareholder Detail</button>
        </div>
      )}
    </>
  );
};

export default Is20PercentShareholderDetails;
