import { IPSSectionComponentProps } from "../types";

/**
 * Component for the Client Summary Section of the IPS
 * @param param0
 * @returns
 */
const IPSUniqueCircumstances = ({ formData, setFormData }: IPSSectionComponentProps) => {
  const handleClientSummaryChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newVal = e.target.value;
    const temp = { ...formData, uniqueCircumstances: newVal };
    setFormData(temp);
  };

  return (
    <div className="ips-section-container">
      <div className="ips-section-header-container">
        <p className="ips-section-header">Unique Circumstances</p>
        <p>
          <i>
            This section of the IPS is meant to capture any specific constraint or requirements
            unique in regard to the construction and management of your portfolio.{" "}
          </i>
        </p>
      </div>
      <div className="ips-section-body-container">
        <textarea value={formData.uniqueCircumstances} onChange={handleClientSummaryChange} />
      </div>
    </div>
  );
};

export default IPSUniqueCircumstances;
