import { Participant } from "../../../../types";
import ValidateAddressDetailsDetails from "./ValidateClientAddressDetails";
import ValidateExternalInvestments from "./ValidateClientExternalInvestments";
import ValidateGeneralDetails from "./ValidateClientGeneralDetails";
import ValidateNetWorthDetails from "./ValidateClientNetWorthDetails";
import ValidateEmploymentDetails from "./ValidateEmploymentDetails";
import ValidateRegulatoryDetails from "./ValidateRegulatoryDetails";
import ValidateSpouseDetails from "./ValidateSpouseDetails";

// Function that validates all the details of a client
// We need to valid different detail by detail
// We will pass in a section id that is hard-coded

// 1 - General Details
// 2 - Address Details
// 3 - Employment Details
// 4 - Net Worth Details

const ValidateAllDetails = (client: Participant, sectionId: number) => {
  if (sectionId === 1) {
    return ValidateGeneralDetails(client);
  } else if (sectionId === 2) {
    return ValidateAddressDetailsDetails(client);
  } else if (sectionId === 3) {
    return ValidateEmploymentDetails(client);
  } else if (sectionId === 4) {
    return ValidateSpouseDetails(client);
  } else if (sectionId === 5) {
    return ValidateNetWorthDetails(client);
  } else if (sectionId === 6) {
    return ValidateExternalInvestments(client);
  } else if (sectionId === 7) {
    return ValidateRegulatoryDetails(client);
  } else {
    // Use all of the above validators and return the result
    return (
      ValidateGeneralDetails(client) &&
      ValidateAddressDetailsDetails(client) &&
      ValidateEmploymentDetails(client) &&
      ValidateSpouseDetails(client) &&
      ValidateNetWorthDetails(client) &&
      ValidateExternalInvestments(client) &&
      ValidateRegulatoryDetails(client)
    );
  }

  return false;
};

export default ValidateAllDetails;
