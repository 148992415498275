import { Route, Routes } from "react-router-dom";
import { CreateRiskTolerance, NotFound } from "../Pages";
import { Home } from "features/home";
import { Login, SetupTFAPage, VerifyCodePage } from "features/auth";
import { AdminManage } from "features/admin/components";
import { CreateFullClient, CreateInvestmentAccount } from "../Pages/Client";
import { Client } from "features/client";
import {
  ClientCreateRiskTolerance,
  CreateRiskToleranceLink,
} from "features/riskTolerance/components";
import TestForm from "features/testform/TestForm";
import { CreateInvestmentPolicyStatement } from "features/createInvestmentPolicyStatement";
import { HouseholdParticipants } from "features/householdParticipants";
import { ParticipantPage } from "features/participant";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/setup-tfa" element={<SetupTFAPage />} />
      <Route path="/verifyCode" element={<VerifyCodePage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/households" element={<Home />} />
      <Route path="/admin" element={<AdminManage />} />
      <Route path="/client/:clientId" element={<Client />} />
      <Route path="/participant/:participantId" element={<ParticipantPage />} />
      <Route path="/riskToleranceForm" element={<CreateRiskTolerance />} />
      <Route path="/riskToleranceForm/:clientId/" element={<CreateRiskTolerance />} />
      <Route path="clientRiskToleranceForm/:code" element={<ClientCreateRiskTolerance />} />
      <Route path="createRiskToleranceLink/:clientId/" element={<CreateRiskToleranceLink />} />
      <Route path="createRiskToleranceLink" element={<CreateRiskToleranceLink />} />
      <Route
        path="createClientInvestmentAccount/:participantId"
        element={<CreateInvestmentAccount />}
      />
      <Route path="editClient/:clientId" element={<CreateFullClient />} />
      <Route path="createIPS/:participantId" element={<CreateInvestmentPolicyStatement />} />
      <Route path="/test" element={<TestForm />} />
      <Route path="/householdParticipants/:householdId" element={<HouseholdParticipants />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
