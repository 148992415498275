import React from "react";
import { CitizenshipMappingById } from "constants/mappings";
import ClientDetail from "./clientDetail";
import { ClientDetailsProps } from "../types";

const ClientGeneralDetails = ({ selectedClientData }: ClientDetailsProps) => {
  return (
    <div className="client-details active" id="client-general-details">
      <ClientDetail
        label="Client"
        value={` ${selectedClientData?.generalDetails.title} 
        ${selectedClientData?.generalDetails.firstName} 
        ${selectedClientData?.generalDetails.middleInitial}
        ${selectedClientData?.generalDetails.lastName}`}
      />
      <ClientDetail label="Email" value={selectedClientData?.generalDetails.email} />

      <ClientDetail
        label="Marital Status"
        value={selectedClientData?.generalDetails.maritalStatus}
      />
      <ClientDetail label="DOB" value={selectedClientData?.generalDetails.dateOfBirth} />
      <ClientDetail label="Home Phone" value={selectedClientData?.generalDetails.homePhone} />
      <ClientDetail
        label="Business Phone"
        value={selectedClientData?.generalDetails.businessPhone}
      />
      <ClientDetail
        label="Business Ext"
        value={selectedClientData?.generalDetails.businessPhoneExt}
      />
      <ClientDetail label="Cell Phone" value={selectedClientData?.generalDetails.cellPhone} />
      <ClientDetail
        label="Citizenship"
        value={CitizenshipMappingById[selectedClientData?.generalDetails.citizenship]}
      />
      <ClientDetail
        label="Social Insurnace Number"
        value={selectedClientData?.generalDetails.sin}
        isSecure={true}
      />
      <ClientDetail label="Language" value={selectedClientData?.generalDetails.language} />
    </div>
  );
};

export default ClientGeneralDetails;
