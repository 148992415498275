import { useEffect, useState } from "react";
import withAuth from "../../../HOC/withAuth";
import "../styles/households.scss";
import "../styles/householdSection.scss";
import { Advisor } from "../../../types/Advisor/Advisor";
import { RootState } from "../../../stores/store";
import { useSelector } from "react-redux";
import { HouseholdState } from "../../../stores/Redux/householdSlice";
//import Modal from "../../../Components/Shared/Modal";
import { ApiResponseModel, Household } from "types";
import CreateHouseholdForm from "./createHouseholdForm";
import { emptyHousehold } from "../constants";
import { EditHouseholdProps, HouseholdSectionProps } from "../types";
import { useNavigate } from "react-router-dom";
import { inputHelper, notify } from "utils";
import { useUpdateHouseholdMutation } from "Apis/mainApi";
import Modal from "Components/Shared/Modal";

/**
 * This page focuses on the link between households and advisors
 * Also provides information on the accounts found in the household
 *
 * Big todo, is to rename this feature to `households`
 * @returns
 */
function Home() {
  const [searchText, setSearchText] = useState("");
  const advisor: Advisor = useSelector((state: RootState) => state.advisorStore);
  // Advisor Household coming from state
  const advisorHouseholds: HouseholdState = useSelector((state: RootState) => state.householdStore);
  const [viewHouseholds, setViewHouseholds] = useState<Household[]>([]);

  // UseStates for Creating a Household
  const [showModalBackdrop, setShowModalBackdrop] = useState(false);
  const [showCreateHousehold, setShowCreateHousehold] = useState(false);

  // No Results State
  const [noResults, setNoResults] = useState(false);

  // Set the viewHouseholds to the advisorHouseholds
  useEffect(() => {
    if (advisorHouseholds.households) {
      setViewHouseholds(advisorHouseholds.households);
      if (advisorHouseholds.households.length === 0) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }
    }
  }, [advisorHouseholds]);

  useEffect(() => {
    if (searchText.length > 0) {
      const filteredHouseholds = advisorHouseholds.households.filter((household) => {
        return household.fullName.toLowerCase().includes(searchText.toLowerCase());
      });
      setViewHouseholds(filteredHouseholds);
    } else {
      setViewHouseholds(advisorHouseholds.households);
    }
  }, [searchText]);

  useEffect(() => {
    console.log(noResults);
  }, [noResults]);

  const handleCreateNewHouseholdModalOpen = () => {
    setShowModalBackdrop(true);
    setShowCreateHousehold(true);
  };

  return (
    <div className="main-page-list-container">
      {showModalBackdrop && <div className="backdrop"></div>}
      <div className="households-header">
        <div className="household-header-texts">
          <p className="households-header-text">Households</p>
          <p className="households-subheader-text">
            Choose any of the households to view further details
          </p>
          <button className="add-new-btn" onClick={handleCreateNewHouseholdModalOpen}>
            Add New
          </button>
        </div>
        <div className="household-header-search">
          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search Households..."
          ></input>
        </div>
      </div>
      <div className="hr"></div>
      <div className="household-list-container">
        {viewHouseholds.length > 0 &&
          viewHouseholds.map((household) => {
            return <HouseholdRow household={household} />;
          })}
        {noResults && (
          <div className="household-row">
            <div className="household-row-information">
              <p className="household-row-id">No Households Found</p>
            </div>
          </div>
        )}
      </div>
      <Modal
        children={
          <CreateHouseholdForm
            advisorId={advisor.id}
            closeModal={() => {
              setShowCreateHousehold(false);
              setShowModalBackdrop(false);
            }}
          />
        }
        show={showCreateHousehold}
        modelTitle="Add New Household"
        setModalBackdrop={setShowModalBackdrop}
        setModelDisplay={setShowCreateHousehold}
      ></Modal>
    </div>
  );
  // const [showModalBackdrop, setShowModalBackdrop] = useState(false);
  // const [showCreateClientModal, setShowCreateClientModal] = useState(false);
  // const [householdForCreateClient, setHouseholdCreateClient] = useState("");
  // const [showCreateHouseholdModal, setShowCreateHouseholdModal] = useState(false);
  // const [showEditHouseholdModal, setShowEditHouseholdModal] = useState(false);
  // const [editingHousehold, setEditingHousehold] = useState<Household>(emptyHousehold);

  // const advisor: Advisor = useSelector((state: RootState) => state.advisorStore);
  // const advisorHouseholds: HouseholdState = useSelector((state: RootState) => state.householdStore);

  // const addNewHouseholdHandler = () => {
  //   setShowModalBackdrop(true);
  //   setShowCreateHouseholdModal(true);
  // };

  // const addNewClientHandler = (householdId: string) => {
  //   setHouseholdCreateClient(householdId);
  //   setShowModalBackdrop(true);
  //   setShowCreateClientModal(true);
  // };

  // const closeModalHandler = () => {
  //   setShowModalBackdrop(false);
  //   setShowCreateClientModal(false);
  //   setShowCreateHouseholdModal(false);
  //   setShowEditHouseholdModal(false);
  //   setHouseholdCreateClient("");
  // };

  // return (
  //   <div className="page-container">
  //     <div className="households-container">
  //       {showModalBackdrop && <div className="backdrop"></div>}
  //       <div className="households-container-header">
  //         <h1 className="container-header">My Households</h1>
  //         <button onClick={addNewHouseholdHandler} title="Add New Household">
  //           <i className="bi bi-house-add-fill"></i>
  //         </button>
  //       </div>
  //       <div className="households-content-container">
  //         {advisorHouseholds.households.length > 0 &&
  //           advisorHouseholds.households.map((household) => {
  //             return (
  //               <HouseholdSection
  //                 key={household.id}
  //                 household={household}
  //                 addNewClientHandler={addNewClientHandler}
  //                 setEditingHousehold={setEditingHousehold}
  //                 setShowEditingHousehold={setShowEditHouseholdModal}
  //                 setShowModalBackdrop={setShowModalBackdrop}
  //               />
  //             );
  //           })}
  //       </div>
  //       <Modal
  //         children={<CreateHouseholdForm advisorId={advisor.id} closeModal={closeModalHandler} />}
  //         show={showCreateHouseholdModal}
  //         modelTitle="Add Household"
  //         setModalBackdrop={setShowModalBackdrop}
  //         setModelDisplay={setShowCreateHouseholdModal}
  //       ></Modal>

  //       <Modal
  //         children={
  //           <CreateNewClientForm
  //             householdId={householdForCreateClient}
  //             advisorId={advisor.id}
  //             closeModal={closeModalHandler}
  //           />
  //         }
  //         show={showCreateClientModal}
  //         modelTitle="Add Participant"
  //         setModalBackdrop={setShowModalBackdrop}
  //         setModelDisplay={setShowCreateClientModal}
  //       ></Modal>
  //       <Modal
  //         children={
  //           <EditHousehold
  //             editingHousehold={editingHousehold}
  //             setEditingHousehold={setEditingHousehold}
  //             closeModal={closeModalHandler}
  //           />
  //         }
  //         show={showEditHouseholdModal}
  //         modelTitle="Edit Client"
  //         setModalBackdrop={setShowModalBackdrop}
  //         setModelDisplay={setShowEditHouseholdModal}
  //       ></Modal>
  //     </div>
  //   </div>
  // );
}

interface HouseholdRowProps {
  household: Household;
}

const HouseholdRow = ({ household }: HouseholdRowProps) => {
  const navigate = useNavigate();

  return (
    <div className="household-row">
      <div className="household-row-information">
        <p className="household-row-id">{household.id}</p>
        <p className="household-row-name">{household.fullName}</p>
        <p className="household-row-advisor-code">{household.advisor?.iaNumber}</p>
        <p className="household-row-status">SIA Only</p>
      </div>
      <div className="household-row-actions">
        <div className="household-view-actions-container">
          <button
            className="household-row-action-btn"
            onClick={() => navigate(`/householdParticipants/${household.id}`)}
          >
            <p>View Participants</p>
            <div className="icon-holder">
              <i className="bi bi-arrow-right-circle-fill"></i>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

/**
 * Each of the households on this home page will have a section
 *
 * @todo Make the client row a component. I'd like to put this in a separate file
 * @author Teddy K.
 */
const HouseholdSection = ({
  household,
  addNewClientHandler,
  setEditingHousehold,
  setShowEditingHousehold,
  setShowModalBackdrop,
}: HouseholdSectionProps) => {
  const navigate = useNavigate();
  const [showClients, setShowClients] = useState(false);

  const handleEditButton = () => {
    setShowEditingHousehold(true);
    setShowModalBackdrop(true);
    setEditingHousehold(household);
  };

  return (
    <div key={household.id} className="household-container">
      <div className="household-header">
        <button className="expand-btn" onClick={() => setShowClients(!showClients)}>
          {showClients ? (
            <i className="bi bi-caret-up-fill"></i>
          ) : (
            <i className="bi bi-caret-down-fill"></i>
          )}
        </button>
        <p className="household-name">{household.fullName}</p>
        <button
          onClick={() => addNewClientHandler(household.id)}
          title="Add New Client To Household"
          className="add-client-btn"
        >
          <i className="bi bi-person-plus-fill"></i>
        </button>
        <button onClick={() => handleEditButton()} className="edit-house-btn">
          <i className="bi bi-house-gear-fill"></i>
        </button>
      </div>
      {showClients && (
        <div className="household-clients">
          {household.newClients.map((client, index) => {
            console.log(client);
            return (
              <div className="household-client">
                <div>
                  <div>
                    <i className="bi bi-person-rolodex"></i>
                    {client.generalDetails.firstName} {client.generalDetails.lastName}
                  </div>
                  <div>{client.id}</div>

                  <div>
                    <button onClick={() => navigate(`/client/${client.id}`)}>
                      More Information
                    </button>
                  </div>
                </div>
                {/* <div className="household-client-information-status">
                  {!client.isConfirmed && (
                    <>
                      <div className="documention-completion house-client-risk-tolerance-completion">
                        <p>Risk Tolerance</p>
                        {client.completedRiskTolerance ? (
                          <i
                            className="bi bi-check-circle-fill text-success"
                            style={{ marginLeft: "0.5rem" }}
                          ></i>
                        ) : (
                          <i
                            className="bi bi-x-circle-fill text-danger"
                            style={{ marginLeft: "0.5rem" }}
                          ></i>
                        )}
                      </div>
                      <div className="documention-completion house-client-risk-tolerance-completion">
                        <p>Know Your Client</p>
                        <i
                          className="bi bi-x-circle-fill text-danger"
                          style={{ marginLeft: "0.5rem" }}
                        ></i>
                      </div>
                      <div className="documention-completion house-client-risk-tolerance-completion">
                        <p>Investment Policy Statement</p>
                        <i
                          className="bi bi-x-circle-fill text-danger"
                          style={{ marginLeft: "0.5rem" }}
                        ></i>
                      </div>
                    </>
                  )}
                </div> */}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

/**
 * Pass in the household and advisor id to send to the server. Same here as the create client form
 * @returns
 */
const EditHousehold = ({
  editingHousehold,
  setEditingHousehold,
  closeModal,
}: EditHouseholdProps) => {
  // Input Event Handlers
  const handleUserInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const tempData = inputHelper(e, editingHousehold);
    setEditingHousehold(tempData);
  };

  const [updateHousehold] = useUpdateHouseholdMutation();

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const createResult: ApiResponseModel = await updateHousehold(editingHousehold);

    if (createResult.data?.isSuccess) {
      notify("Updated Household Successfully", "success");
      // Set the state to empty
      setEditingHousehold(emptyHousehold);
      // Close the Modal
      closeModal();
    } else if (createResult.error) {
      console.log(createResult.error);
      notify("Error on Household Create", "error");
    }
  };

  return (
    <div className="create-new-client-form-container">
      <form onSubmit={handleFormSubmit}>
        <div className="create-new-client-form-control">
          <label>Full Household Name</label>
          <input
            name="fullName"
            value={editingHousehold.fullName}
            onChange={handleUserInput}
            required
          ></input>
        </div>
        <div className="create-new-client-btn-control">
          <button className="btn btn-success" type="submit">
            Update Client
          </button>
        </div>
      </form>
    </div>
  );
};

export default withAuth(Home);
