import "../styles/clientCompletedWizard.scss";
let logo = require("assets/images/sia-wealth-logo.png");

function ClientCompletedWizard() {
  return (
    <div className="client-completion-container">
      <p className="client-completion-header">Congratulations, you have finished!</p>
      <p>
        A copy of the document you completed will be sent to the email we have for you on file for
        signatures to verify everything that was mentioned is true. Please ensure that all fields
        are correct and correctly reflect your risk appetite
      </p>
      <br />
      <br />
      <p>If there are any issues, please contact your Advisor</p>
      <br />
      <br />
      <p className="client-completion-header">
        Thank you for taking the first step in becoming apart of the SIA family
      </p>
      {/* <Fireworks /> */}
      <div className="checkmark-container">
        <div>
          <img src={logo}></img>
        </div>
        <div>
          <i className="bi bi-check-circle-fill text-success"></i>
        </div>
      </div>
    </div>
  );
}

export default ClientCompletedWizard;
