import { checkEmptyValue, notify } from "../../../../utils";
import { Participant } from "../../../../types";

const ValidateRegulatoryDetails = (client: Participant) => {
  if (client.regulatoryDetails != null) {
    if (client.regulatoryDetails.hasPowerOfAttorney == (undefined || null)) {
      notify("Please specify if the client has a Power of Attorney", "error");
      return false;
    }

    // Insider Detail Validation
    if (client.regulatoryDetails.isInsider == (undefined || null)) {
      notify("Please confirm if the client is an Insider", "error");
      return false;
    }

    if (client.regulatoryDetails.isInsider) {
      if (
        client.regulatoryDetails.insiderDetails == null ||
        client.regulatoryDetails.insiderDetails.length == 0
      ) {
        notify("Please fill in the insider details", "error");
        return false;
      }

      for (var i = 0; i < client.regulatoryDetails.insiderDetails.length; i++) {
        if (checkEmptyValue(client.regulatoryDetails.insiderDetails[i].symbol)) {
          notify(`Please fill in the insider symbol ${i + 1}`, "error");
          return false;
        }
        if (checkEmptyValue(client.regulatoryDetails.insiderDetails[i].companyName)) {
          notify(`Please fill in the insider company ${i + 1}`, "error");
          return false;
        }
      }
    }

    if (client.regulatoryDetails.is10PercentShareholder == (undefined || null)) {
      notify("Please confirm if the client is a 10% Shareholder", "error");
      return false;
    }

    // 10% Shareholder Validation
    if (client.regulatoryDetails.is10PercentShareholder) {
      if (
        client.regulatoryDetails.shareholder10Details == null ||
        client.regulatoryDetails.shareholder10Details.length == 0
      ) {
        notify("Please fill in the 10% Shareholder details", "error");
        return false;
      }

      for (var i = 0; i < client.regulatoryDetails.shareholder10Details.length; i++) {
        if (checkEmptyValue(client.regulatoryDetails.shareholder10Details[i].symbol)) {
          notify(`Please fill in the 10% shareholder symbol ${i + 1}`, "error");
          return false;
        }
        if (checkEmptyValue(client.regulatoryDetails.shareholder10Details[i].companyName)) {
          notify(`Please fill in the 10% shareholder company ${i + 1}` + i, "error");
          return false;
        }
      }
    }

    if (client.regulatoryDetails.is20PercentShareholder == (undefined || null)) {
      notify("Please confirm if the client is a 20% Shareholder", "error");
      return false;
    }

    // 20% Shareholder Validation
    if (client.regulatoryDetails.is20PercentShareholder) {
      if (
        client.regulatoryDetails.shareholder20Details == null ||
        client.regulatoryDetails.shareholder20Details.length == 0
      ) {
        notify("Please fill in the 20% Shareholder details", "error");
        return false;
      }

      for (var i = 0; i < client.regulatoryDetails.shareholder20Details.length; i++) {
        if (checkEmptyValue(client.regulatoryDetails.shareholder20Details[i].symbol)) {
          notify(`Please fill in the 20% shareholder symbol ${i + 1}`, "error");
          return false;
        }
        if (checkEmptyValue(client.regulatoryDetails.shareholder20Details[i].companyName)) {
          notify(`Please fill in the 20% shareholder company ${i + 1}` + i, "error");
          return false;
        }
      }
    }

    if (client.regulatoryDetails.isIIROCMember == (undefined || null)) {
      notify("Please confirm if the client is an IIROC Member", "error");
      return false;
    }

    // IIROC Validation
    if (
      client.regulatoryDetails.isIIROCMember &&
      checkEmptyValue(client.regulatoryDetails?.iirocDetails?.nameOfFirm)
    ) {
      notify("Please fill in the IIROC Member details", "error");
      return false;
    }

    if (client.regulatoryDetails.isForeignPEP == (undefined || null)) {
      notify("Please confirm if the client is a Foreign PEP", "error");
      return false;
    }

    // Foreign PEP Details
    if (client.regulatoryDetails.isForeignPEP) {
      if (checkEmptyValue(client.regulatoryDetails?.foreignPEPDetails?.country)) {
        notify("Please fill in the Foreign PEP Country", "error");
        return false;
      }
      if (checkEmptyValue(client.regulatoryDetails?.foreignPEPDetails?.positionHeld)) {
        notify("Please fill in the Foreign PEP Position Held", "error");
        return false;
      }
    }

    if (client.regulatoryDetails.isAssociateForeignPEP == (undefined || null)) {
      notify("Please confirm if the client is an associate of a Foreign PEP", "error");
      return false;
    }

    // Foreign Associate PEP Details
    if (client.regulatoryDetails.isAssociateForeignPEP) {
      if (checkEmptyValue(client.regulatoryDetails?.foreignAssociatePEPDetails?.associateName)) {
        notify("Please fill in the Foreign Associate PEP Associate Name", "error");
        return false;
      }
      if (checkEmptyValue(client.regulatoryDetails?.foreignAssociatePEPDetails?.country)) {
        notify("Please fill in the Foreign Associate PEP Country", "error");
        return false;
      }
      if (checkEmptyValue(client.regulatoryDetails?.foreignAssociatePEPDetails?.organizationName)) {
        notify("Please fill in the Foreign Associate PEP Organization Name", "error");
        return false;
      }
      if (checkEmptyValue(client.regulatoryDetails?.foreignAssociatePEPDetails?.jobTitle)) {
        notify("Please fill in the Foreign Associate PEP Job Title", "error");
        return false;
      }
      if (checkEmptyValue(client.regulatoryDetails?.foreignAssociatePEPDetails?.relationship)) {
        notify("Please fill in the Foreign Associate PEP Relationship", "error");
        return false;
      }
      if (checkEmptyValue(client.regulatoryDetails?.foreignAssociatePEPDetails?.positionHeld)) {
        notify("Please fill in the Foreign Associate PEP Position Held", "error");
        return false;
      }
    }

    if (client.regulatoryDetails.isDomesticPEP == (undefined || null)) {
      notify("Please confirm if the client is a Domestic PEP", "error");
      return false;
    }
    // Domestic PEP Details
    if (client.regulatoryDetails.isDomesticPEP) {
      if (checkEmptyValue(client.regulatoryDetails?.domesticPEPDetails?.positionHeld)) {
        notify("Please fill in the Domestic PEP Position Held", "error");
        return false;
      }
    }

    if (client.regulatoryDetails.isAssociateDomesticPEP == (undefined || null)) {
      notify("Please confirm if the client is an Associate of Domestic PEP", "error");
      return false;
    }
    // Foreign Domestic PEP Details
    if (client.regulatoryDetails.isAssociateDomesticPEP) {
      if (checkEmptyValue(client.regulatoryDetails?.domesticAssociatePEPDetails?.associateName)) {
        notify("Please fill in the Domestic PEP Associate Name", "error");
        return false;
      }
      if (checkEmptyValue(client.regulatoryDetails?.domesticAssociatePEPDetails?.relationship)) {
        notify("Please fill in the Domestic PEP Relationship", "error");
        return false;
      }
      if (checkEmptyValue(client.regulatoryDetails?.domesticAssociatePEPDetails?.positionHeld)) {
        notify("Please fill in the Domestic PEP Position Held", "error");
        return false;
      }
      if (
        checkEmptyValue(client.regulatoryDetails?.domesticAssociatePEPDetails?.organizationName)
      ) {
        notify("Please fill in the Domestic PEP Organization Name", "error");
        return false;
      }
      if (checkEmptyValue(client.regulatoryDetails?.domesticAssociatePEPDetails?.jobTitle)) {
        notify("Please fill in the Domestic PEP Job Title", "error");
        return false;
      }
    }

    if (client.regulatoryDetails.isHIO == (undefined || null)) {
      notify("Please confirm if the client is an HIO", "error");
      return false;
    }
    // HIO Details
    if (client.regulatoryDetails.isHIO) {
      if (checkEmptyValue(client?.regulatoryDetails?.hioDetails?.jobTitle)) {
        notify("Please fill in the HIO Job Title", "error");
        return false;
      }
      if (checkEmptyValue(client?.regulatoryDetails?.hioDetails?.nameOfOrganization)) {
        notify("Please fill in the HIO Organization Name", "error");
        return false;
      }
      if (checkEmptyValue(client?.regulatoryDetails?.hioDetails?.country)) {
        notify("Please fill in the HIO Country", "error");
        return false;
      }
    }

    if (client.regulatoryDetails.isAssociateHIO == (undefined || null)) {
      notify("Please confirm if the client is an associate of an HIO", "error");
      return false;
    }
    // HIO Associate Details
    if (client.regulatoryDetails.isAssociateHIO) {
      console.log(client?.regulatoryDetails?.associateHIODetails);
      if (checkEmptyValue(client?.regulatoryDetails?.associateHIODetails?.nameOfAssociate)) {
        notify("Please fill in the HIO Associate Name", "error");
        return false;
      }
      if (checkEmptyValue(client?.regulatoryDetails?.associateHIODetails?.jobTitle)) {
        notify("Please fill in the HIO Job Title", "error");
        return false;
      }
      if (checkEmptyValue(client?.regulatoryDetails?.associateHIODetails?.country)) {
        notify("Please fill in the HIO Country", "error");
        return false;
      }
      if (checkEmptyValue(client?.regulatoryDetails?.associateHIODetails?.nameOfOrganization)) {
        notify("Please fill in the HIO Organization", "error");
        return false;
      }
    }

    if (client.regulatoryDetails.isSecuritiesFirm == (undefined || null)) {
      notify("Please confirm if the client is part of a Securities Firm", "error");
      return false;
    }
    // Security Firm Details
    if (client.regulatoryDetails.isSecuritiesFirm) {
      // console.log(client.regulatoryDetails?.securitiesFirmInformation);
      if (checkEmptyValue(client?.regulatoryDetails?.securitiesFirmInformation?.name)) {
        notify("Please fill in the Security Firm Associate's name", "error");
        return false;
      }

      if (checkEmptyValue(client?.regulatoryDetails?.securitiesFirmInformation?.relationship)) {
        notify("Please fill in the Security Firm Relationship", "error");
        return false;
      }

      if (checkEmptyValue(client?.regulatoryDetails?.securitiesFirmInformation?.companyName)) {
        notify("Please fill in the Security Firm Name", "error");
        return false;
      }

      if (checkEmptyValue(client?.regulatoryDetails?.securitiesFirmInformation?.companyPosition)) {
        notify("Please fill in the Security Firm Position Held", "error");
        return false;
      }
    }

    if (client.regulatoryDetails.isBrokerDealer == (undefined || null)) {
      notify("Please confirm if the client is part of a Broker/Dealer", "error");
      return false;
    }
    // Broker Dealer Details
    if (client.regulatoryDetails.isBrokerDealer) {
      if (checkEmptyValue(client?.regulatoryDetails?.brokerDealerInformation?.name)) {
        notify("Please fill in the Broker/Dealer Associate's name", "error");
        return false;
      }

      if (checkEmptyValue(client?.regulatoryDetails?.brokerDealerInformation?.relationship)) {
        notify("Please fill in the Broker/Dealer Relationship", "error");
        return false;
      }

      if (checkEmptyValue(client?.regulatoryDetails?.brokerDealerInformation?.companyName)) {
        notify("Please fill in the Broker/Dealer Name", "error");
        return false;
      }

      if (checkEmptyValue(client?.regulatoryDetails?.brokerDealerInformation?.companyPosition)) {
        notify("Please fill in the Dealer/Dealer Position Held", "error");
        return false;
      }
    }
  } else {
    notify("Please fill in the Regulatory Details", "error");
    return false;
  }
  return true;
};

export default ValidateRegulatoryDetails;
