import { checkEmptyValue, notify } from "../../../../utils";
import validateSIN from "../../../../utils/validateSIN";
import { Participant } from "../../../../types";

/**
 * Function that validates a client's general details
 * @param client
 * @returns
 **/

const ValidateNetWorthDetails = (client: Participant) => {
  // If liquid assets exist, check that the type is not empty and the value is proper
  if (client.netWorthDetails?.liquidAssets) {
    for (var i = 0; i < client.netWorthDetails.liquidAssets.length; i++) {
      if (checkEmptyValue(client.netWorthDetails.liquidAssets[i].type)) {
        notify(`Please Enter Type for Liquid Asset ${i + 1}`, "error");
        return false;
      }
      if (checkEmptyValue(client.netWorthDetails.liquidAssets[i].amount)) {
        notify(`Please Enter Amount for Liquid Asset ${i + 1}`, "error");
        return false;
      }
    }
  }
  // If fixed assets exist, check that the type is not empty and the value is proper
  if (client.netWorthDetails?.fixedAssets) {
    for (var i = 0; i < client.netWorthDetails.fixedAssets.length; i++) {
      if (checkEmptyValue(client.netWorthDetails.fixedAssets[i].type)) {
        notify(`Please Enter Type for Fixed Asset ${i + 1}`, "error");
        return false;
      }
      if (checkEmptyValue(client.netWorthDetails.fixedAssets[i].amount)) {
        notify(`Please Enter Amount for Fixed Asset ${i + 1}`, "error");
        return false;
      }
    }
  }

  // If liabilities exist, check that the type is not empty and the value is proper
  if (client.netWorthDetails?.liabilities) {
    for (var i = 0; i < client.netWorthDetails.liabilities.length; i++) {
      if (checkEmptyValue(client.netWorthDetails.liabilities[i].type)) {
        notify(`Please Enter Type For Liability ${i + 1}`, "error");
        return false;
      }
      if (checkEmptyValue(client.netWorthDetails.liabilities[i].amount)) {
        notify(`Please Enter Value for Liability ${i + 1}`, "error");
        return false;
      }
    }
  }

  return true;
};

export default ValidateNetWorthDetails;
