import { IPSSectionComponentProps } from "../types";

/**
 * Allows user to specify specific investment objectives for the client
 * @param param0
 * @returns
 */
const IPSClientInvestmentObjectives = ({ formData, setFormData }: IPSSectionComponentProps) => {
  return (
    <div className="ips-section-container">
      <div className="ips-section-header-container">
        <p className="ips-section-header">Investment Objectives</p>
      </div>
      <div className="ips-section-body-container">
        <div className="ips-objective-section">
          <p className="ips-section-body-container-header">Cash and Equivalent Objectives</p>
          <div className="ips-objective-inputs-section">
            <label style={{ marginRight: "0.8rem" }}>Upper Limit %</label>
            <input
              value={formData.cashAndEquivalentsObjectives.upperLimit}
              type="number"
              min={0}
              max={100}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  cashAndEquivalentsObjectives: {
                    ...formData.cashAndEquivalentsObjectives,
                    upperLimit: Number(e.target.value),
                  },
                })
              }
            />
            <label style={{ marginRight: "0.8rem" }}>Lower Limit %</label>
            <input
              value={formData.cashAndEquivalentsObjectives.lowerLimit}
              type="number"
              min={0}
              max={100}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  cashAndEquivalentsObjectives: {
                    ...formData.cashAndEquivalentsObjectives,
                    lowerLimit: Number(e.target.value),
                  },
                })
              }
            />
            <label style={{ marginRight: "0.8rem" }}>Target %</label>
            <input
              value={formData.cashAndEquivalentsObjectives.target}
              type="number"
              min={0}
              max={100}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  cashAndEquivalentsObjectives: {
                    ...formData.cashAndEquivalentsObjectives,
                    target: Number(e.target.value),
                  },
                })
              }
            />
          </div>
        </div>
        <div className="ips-objective-section">
          <p className="ips-section-body-container-header">Fixed Income Objectives</p>
          <div className="ips-objective-inputs-section">
            <label style={{ marginRight: "0.8rem" }}>Upper Limit %</label>
            <input
              value={formData.fixedIncomeObjectives.upperLimit}
              type="number"
              min={0}
              max={100}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  fixedIncomeObjectives: {
                    ...formData.fixedIncomeObjectives,
                    upperLimit: Number(e.target.value),
                  },
                })
              }
            />
            <label style={{ marginRight: "0.8rem" }}>Lower Limit %</label>
            <input
              value={formData.fixedIncomeObjectives.lowerLimit}
              type="number"
              min={0}
              max={100}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  fixedIncomeObjectives: {
                    ...formData.fixedIncomeObjectives,
                    lowerLimit: Number(e.target.value),
                  },
                })
              }
            />
            <label style={{ marginRight: "0.8rem" }}>Target %</label>
            <input
              value={formData.fixedIncomeObjectives.target}
              type="number"
              min={0}
              max={100}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  fixedIncomeObjectives: {
                    ...formData.fixedIncomeObjectives,
                    target: Number(e.target.value),
                  },
                })
              }
            />
          </div>
        </div>
        <div className="ips-objective-section">
          <p className="ips-section-body-container-header">Equity Objectives</p>
          <div className="ips-objective-inputs-section">
            <label style={{ marginRight: "0.8rem" }}>Upper Limit %</label>
            <input
              value={formData.equityObjectives.upperLimit}
              type="number"
              min={0}
              max={100}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  equityObjectives: {
                    ...formData.equityObjectives,
                    upperLimit: Number(e.target.value),
                  },
                })
              }
            />
            <label style={{ marginRight: "0.8rem" }}>Lower Limit %</label>
            <input
              value={formData.equityObjectives.lowerLimit}
              type="number"
              min={0}
              max={100}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  equityObjectives: {
                    ...formData.equityObjectives,
                    lowerLimit: Number(e.target.value),
                  },
                })
              }
            />
            <label style={{ marginRight: "0.8rem" }}>Target %</label>
            <input
              value={formData.equityObjectives.target}
              type="number"
              min={0}
              max={100}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  equityObjectives: {
                    ...formData.equityObjectives,
                    target: Number(e.target.value),
                  },
                })
              }
            />
          </div>
        </div>
        <div className="ips-objective-section">
          <p className="ips-section-body-container-header">Alternate Objectives</p>
          <div className="ips-objective-inputs-section">
            <label style={{ marginRight: "0.8rem" }}>Upper Limit %</label>
            <input
              value={formData.alternativeInvestmentsObjectives.upperLimit}
              type="number"
              min={0}
              max={100}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  alternativeInvestmentsObjectives: {
                    ...formData.alternativeInvestmentsObjectives,
                    upperLimit: Number(e.target.value),
                  },
                })
              }
            />
            <label style={{ marginRight: "0.8rem" }}>Lower Limit %</label>
            <input
              value={formData.alternativeInvestmentsObjectives.lowerLimit}
              type="number"
              min={0}
              max={100}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  alternativeInvestmentsObjectives: {
                    ...formData.alternativeInvestmentsObjectives,
                    lowerLimit: Number(e.target.value),
                  },
                })
              }
            />
            <label style={{ marginRight: "0.8rem" }}>Target %</label>
            <input
              value={formData.alternativeInvestmentsObjectives.target}
              type="number"
              min={0}
              max={100}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  alternativeInvestmentsObjectives: {
                    ...formData.alternativeInvestmentsObjectives,
                    target: Number(e.target.value),
                  },
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IPSClientInvestmentObjectives;
