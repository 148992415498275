import { notify } from "utils";
import { CreateIndividualPolicyStatementDto } from "../types";

const validateInvestmentObjectives = (formData: CreateIndividualPolicyStatementDto) => {
  // ensure that all objective percentages for upper, lower, and target are between 0 and 100
  const objectives = [
    formData.cashAndEquivalentsObjectives,
    formData.fixedIncomeObjectives,
    formData.equityObjectives,
    formData.alternativeInvestmentsObjectives,
  ];

  for (let i = 0; i < objectives.length; i++) {
    const objective = objectives[i];
    if (
      objective.upperLimit < 0 ||
      objective.upperLimit > 100 ||
      objective.lowerLimit < 0 ||
      objective.lowerLimit > 100 ||
      objective.target < 0 ||
      objective.target > 100
    ) {
      notify("Please provide valid investment objectives", "error");
      return false;
    }
  }
  return true;
};

export default validateInvestmentObjectives;
