import { useState } from "react";
import CreateClientSummarySectionProps from "./CreateClientSummarySectionProps";
import ClientDetail from "../../../../../features/client/components/clientDetail";
import { CitizenshipMappingById } from "../../../../../constants/mappings";
import { CitizenshipType } from "../../../../../constants/enums";

const CreateFullClientSummaryGeneralDetails = ({ client }: CreateClientSummarySectionProps) => {
  const [showMore, setShowMore] = useState<boolean>(true);
  const maxHeight = "600px";

  return (
    <div className="create-client-summary-section" style={showMore ? { height: maxHeight } : {}}>
      <div className="create-client-summary-section-header" onClick={() => setShowMore(!showMore)}>
        <p>General Details</p>
        <button onClick={() => setShowMore(!showMore)}>
          {showMore ? (
            <i className="bi bi-caret-up-fill"></i>
          ) : (
            <i className="bi bi-caret-down-fill"></i>
          )}
        </button>
      </div>
      <div className="create-client-summary-section-header-underline"></div>

      <>
        <ClientDetail label="Is Individual?" value={client?.generalDetails?.isIndividual} />
        <ClientDetail label="Marital Status" value={client?.generalDetails?.maritalStatus} />
        <ClientDetail label="Salutation" value={client?.generalDetails?.salutation} />
        <ClientDetail label="First Name" value={client?.generalDetails?.firstName} />
        <ClientDetail label="Middle Initials" value={client?.generalDetails?.middleInitial} />
        <ClientDetail label="Last Name" value={client?.generalDetails?.lastName} />
        <ClientDetail label="Social Insurance Number" value={client?.generalDetails?.sin} />
        <ClientDetail
          label="Date of Birth"
          value={client?.generalDetails?.dateOfBirth}
          isDate={true}
        />
        <ClientDetail label="# of Dependents" value={client?.generalDetails?.numDependents} />
        <ClientDetail label="Home Phone #" value={client?.generalDetails?.homePhone} />
        <ClientDetail label="Business Phone #" value={client?.generalDetails?.businessPhone} />
        <ClientDetail label="Business Ext" value={client?.generalDetails?.businessPhoneExt} />
        <ClientDetail label="Cell Phone #" value={client?.generalDetails?.cellPhone} />
        <ClientDetail label="Email" value={client?.generalDetails?.email} />
        <ClientDetail
          label="Citizenship"
          value={
            client?.generalDetails?.citizenship
              ? CitizenshipMappingById[client?.generalDetails?.citizenship]
              : ""
          }
        />
        {client?.generalDetails?.citizenship == CitizenshipType.OTHER && (
          <ClientDetail label="Other Citizenship" value={client?.generalDetails.otherCitizenship} />
        )}
        <ClientDetail label="Language Of Correspondence" value={client?.generalDetails?.language} />
      </>
    </div>
  );
};

export default CreateFullClientSummaryGeneralDetails;
