import { usePostQRCodeForTFASetupMutation } from "Apis/authApi";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiResponseModel } from "types";
import { notify } from "utils";
import $ from "jquery";
import { onInputBlur, onInputFocus, onLoginLabelClick } from "../utils";
function VerifyCodePage() {
  const [verificationCode, setVerificationCode] = useState("");
  const [verifyCode] = usePostQRCodeForTFASetupMutation();
  const navigate = useNavigate();
  const handleVerifySubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const inputs = {
      code: verificationCode,
    };

    const verifyResponse: ApiResponseModel = await verifyCode(inputs);
    if (verifyResponse.data?.isSuccess) {
      navigate("/");
    } else if (!verifyResponse.data?.isSuccess && verifyResponse.data?.errorMessages) {
      notify(verifyResponse.data?.errorMessages[0], "error");
    } else {
      console.log(verifyResponse);
      notify("Something went wrong", "error");
    }
  };

  return (
    <div className="login-container-new">
      <div className="border-wrap-container">
        <div className="login-main-content-container">
          <div className="login-header-container">
            <p className="login-header">Welcome Back</p>
            <p className="login-subheader">Advisor Portal</p>
          </div>
          <div className="login-form-container">
            <form className="login-form" onSubmit={handleVerifySubmit}>
              <p className="login-form-header">Please Enter Verification Code</p>
              <div className="login-form-input-cont">
                <label className="login-form-label" onClick={onLoginLabelClick}>
                  Code
                </label>
                <input
                  className="login-form-input"
                  onChange={(e) => setVerificationCode(e.target.value)}
                  value={verificationCode}
                  onFocus={onInputFocus}
                  onBlur={onInputBlur}
                />
              </div>
              {verificationCode && (
                <div className="login-form-btn-submit-cont">
                  <button>Verify</button>
                </div>
              )}
            </form>
          </div>
          <div className="login-footer">
            <p>Issues with SIA Account?</p>
            <p>Contact SIA Private Wealth Development Team</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyCodePage;
