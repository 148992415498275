import { RiskToleranceFormData } from "../../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";
import "../styles/clientIntroRiskStep.scss";
interface IntroStepProps {
  propData: RiskToleranceFormData;
  onChange: (propData: Partial<RiskToleranceFormData>) => void;
}

// Props not needed, but would like them to be compatible with the other components
function ClientIntroRiskStep(props: IntroStepProps) {
  const riskToleranceData = useSelector((state: RootState) => state.newClientRiskStore);

  return (
    <div className="client-intro-step-container">
      <p>
        Welcome <b>{riskToleranceData.clientFullName}</b>, to SIA Wealth Management!
        <br />
        <br /> We are thrilled to have you on board as we embark on this journey together to help
        you achieve your financial goals.
        <br />
        <br /> At our company, we understand that every investor has unique risk preferences and
        objectives. Hence, we have designed this application to tailor your investment strategy
        based on your risk tolerance. <br />
        <br /> By providing us with some essential information about your financial goals, time
        horizon, and risk appetite, we can create a personalized portfolio that aligns with your
        individual preferences. <br />
        <br />
        It is important to answer the questions thoughtfully and honestly, as your responses will
        significantly influence the outcomes and performance of your investment portfolio. <br />
        <br />
        Rest assured, our team of experts and advanced algorithms will ensure that your portfolio is
        optimized to strike the perfect balance between risk and potential returns, enabling you to
        build a prosperous financial future. <br />
        <br />
        So, let's get started on this exciting investment journey together!
      </p>
    </div>
  );
}

export default ClientIntroRiskStep;
