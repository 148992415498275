import { checkEmptyValue, notify, validatePostalCode } from "../../../../utils";
import validateSIN from "../../../../utils/validateSIN";
import { Participant } from "../../../../types";

/**
 * Function that validates a client's employment details
 * @param client
 * @returns
 **/

const ValidateEmploymentDetails = (client: Participant) => {
  // Check Details of General Information
  if (checkEmptyValue(client.employmentDetails.status)) {
    notify("Please Select an Employment Status", "error");
    return false;
  }

  if (checkEmptyValue(client.employmentDetails.occupation)) {
    notify("Please Enter an Occupation", "error");
    return false;
  }
  if (checkEmptyValue(client.employmentDetails.name)) {
    notify("Please Enter an Employer Name", "error");
    return false;
  }

  if (
    !checkEmptyValue(client.employmentDetails.postalCode) &&
    !validatePostalCode(client.employmentDetails.postalCode)
  ) {
    notify("Please Enter a Valid Postal Code", "error");
    return false;
  }

  return true;
};

export default ValidateEmploymentDetails;
