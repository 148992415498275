import BootstrapTable from "react-bootstrap-table-next";
import "./styles/SIATable.scss";
export interface ColumnConfig {
  dataField: string;
  text: string;

  formatter?: (cell: any, row: any) => React.ReactNode;
  classes?: (cell: any, row: any) => string;
}

interface TableProps {
  data: any[];
  columns: ColumnConfig[];
  keyField?: string;
  tableClass?: string;
}

function SIATable({ data, columns, keyField, tableClass }: TableProps) {
  return (
    <BootstrapTable
      keyField={keyField ? keyField : "id"}
      data={data}
      columns={columns}
      wrapperClasses={
        tableClass ? `generic-sia-table ${tableClass}` : "generic-sia-table"
      }
    />
  );
}

export default SIATable;
