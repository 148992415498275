import { useNavigate, useParams } from "react-router-dom";
import { useGetMagicLinkInfoMutation } from "../Apis/riskToleranceApi";
import { NotFound } from "../Pages";
import { Login } from "../features/auth";
import { useEffect, useState } from "react";
import { ApiResponseModel } from "../types";
import { useDispatch } from "react-redux";
import { setNewClientRiskState } from "../stores/Redux/newClientRiskSlice";
import { GuidValues } from "../constants/enums";
import isValidGUID from "../utils/isValidGuid";

/**
 * Higher Order Component used only for New Client Authentication for Risk Tolerance
 * @param WrappedComponent
 * @returns
 */
const withClientRiskCode = (WrappedComponent: any) => {
  return (props: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { code } = useParams();
    const [checkRiskCode] = useGetMagicLinkInfoMutation();
    const [checksValidated, setCheckValidated] = useState(true);

    //Check if Code is there and if it's valid
    if (code == undefined || !isValidGUID(code)) {
      return <NotFound />;
    }

    // Check the Risk Code in the API,
    // then set it as a slice for use in the Client Risk Tolerance Page
    useEffect(() => {
      const validateRiskCode = async () => {
        const result: ApiResponseModel = await checkRiskCode(code);
        return result;
      };

      validateRiskCode().then((res) => {
        if (res.data && res.data.isSuccess) {
          const validCodeResponse = res.data.result;
          dispatch(
            setNewClientRiskState({
              clientId: validCodeResponse.participantId,
              clientFullName:
                validCodeResponse.participant.generalDetails.firstName +
                " " +
                validCodeResponse.participant.generalDetails.lastName,
              responseId: validCodeResponse.participant.riskTolerance
                ? validCodeResponse.participant.riskTolerance.id
                : GuidValues.EMPTY_GUID,
              riskScoreSections: validCodeResponse.participant.riskTolerance
                ? validCodeResponse.participant.riskTolerance?.riskScoreSections
                : [],
            })
          );
          setCheckValidated(true); // Show the Component if all checks pass
        } else {
          navigate("*"); // navigate back to index
        }
      });
    }, []);

    //return <WrappedComponent {...props} />;
    return checksValidated ? <WrappedComponent {...props} /> : <NotFound />;
  };
};

export default withClientRiskCode;
