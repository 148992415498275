import React, { useState } from "react";
import { useCreateInitialNewParticipantMutation } from "../../../Apis/mainApi";
import "../styles/createNewClientForm.scss";
import { inputHelper, notify } from "../../../utils";
import { ApiResponseModel } from "../../../types";
import { ParticipantRole } from "constants/enums";
import { NewParticipantDto } from "types/Participant";
import $ from "jquery";
import { EmptyNewParticipant } from "constants/emptyStates";

interface CreateNewClientFormProps {
  householdId: string;
  closeModal: () => void;
}

/**
 * Pass in the household and advisor id to send to the server
 * @returns
 */
const CreateNewClientForm = ({ householdId, closeModal }: CreateNewClientFormProps) => {
  const [createNewParticipant] = useCreateInitialNewParticipantMutation();
  const [createNewClientInputs, setCreateNewClientInputs] = useState(EmptyNewParticipant);

  // Input Event Handlers
  const handleUserInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const tempData = inputHelper(e, createNewClientInputs);
    setCreateNewClientInputs(tempData);
  };

  // Handler function for the Role Radio Buttons
  const handleRoleChange = (e: React.MouseEvent<HTMLInputElement>) => {
    console.log(e.target);
    const roleId = $(e.target).val() as string;
    console.log(roleId);
    if (createNewClientInputs.roles.includes(roleId)) {
      const tempRoles = createNewClientInputs.roles.filter((role) => role !== roleId);
      setCreateNewClientInputs({ ...createNewClientInputs, roles: tempRoles });
    } else {
      setCreateNewClientInputs({
        ...createNewClientInputs,
        roles: [...createNewClientInputs.roles, roleId],
      });
    }
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createNewClientInputs["householdId"] = householdId;
    const createResult: ApiResponseModel = await createNewParticipant(createNewClientInputs);
    console.log(createNewClientInputs);
    if (createResult.data?.isSuccess) {
      notify("Created Client Successfully", "success");
      // Set the state to empty
      setCreateNewClientInputs(EmptyNewParticipant);
      // Close the Modal
      closeModal();
    } else if (createResult.error) {
      console.log(createResult.error);
      notify("Error on Client Client Create", "error");
    }
  };

  return (
    <div className="create-new-client-form-container">
      <form onSubmit={handleFormSubmit}>
        <div className="form-body-inputs">
          <div>
            <p className="new-participant-basic-info-header">Basic Information</p>
            <div className="create-new-client-form-control">
              <label>First Name</label>
              <input
                name="firstName"
                value={createNewClientInputs.firstName}
                onChange={handleUserInput}
                required
              ></input>
            </div>
            <div className="create-new-client-form-control">
              <label>Last Name</label>
              <input
                name="lastName"
                value={createNewClientInputs.lastName}
                onChange={handleUserInput}
                required
              ></input>
            </div>
            <div className="create-new-client-form-control">
              <label>Email</label>
              <input
                name="email"
                value={createNewClientInputs.email}
                onChange={handleUserInput}
                required
                placeholder="user@example.com"
              ></input>
            </div>
            <div className="create-new-client-form-control">
              <label>Phone #</label>
              <input
                name="phoneNumber"
                value={createNewClientInputs.phoneNumber}
                onChange={handleUserInput}
                required
                placeholder="111-111-1111"
              ></input>
            </div>
          </div>
          <div className="participant-role-create">
            <p className="participant-role-create-header">Participant Roles</p>
            <div>
              <input
                type="radio"
                id="role1"
                value={ParticipantRole.ACCOUNT_HOLDER}
                onClick={handleRoleChange}
                checked={createNewClientInputs.roles.includes(ParticipantRole.ACCOUNT_HOLDER)}
              />
              <label htmlFor="role1">Account Holder</label>
            </div>
            <div>
              <input
                type="radio"
                id="role2"
                value={ParticipantRole.BENEFICIARY}
                onClick={handleRoleChange}
                checked={createNewClientInputs.roles.includes(ParticipantRole.BENEFICIARY)}
              />
              <label htmlFor="role2">Beneficiary</label>
            </div>
            <div>
              <input
                type="radio"
                id="role3"
                value={ParticipantRole.DIRECTOR}
                onClick={handleRoleChange}
                checked={createNewClientInputs.roles.includes(ParticipantRole.DIRECTOR)}
              />
              <label htmlFor="role3">Director</label>
            </div>
            <div>
              <input
                type="radio"
                id="role4"
                value={ParticipantRole.THIRD_PARTY}
                onClick={handleRoleChange}
                checked={createNewClientInputs.roles.includes(ParticipantRole.THIRD_PARTY)}
              />
              <label htmlFor="role4">Third Party</label>
            </div>
            <div>
              <input
                type="radio"
                id="role5"
                value={ParticipantRole.TRUSTEE}
                onClick={handleRoleChange}
                checked={createNewClientInputs.roles.includes(ParticipantRole.TRUSTEE)}
              />
              <label htmlFor="role5">Trustee</label>
            </div>
            <div>
              <input
                type="radio"
                id="role6"
                value={ParticipantRole.POWER_OF_ATTORNEY}
                onClick={handleRoleChange}
                checked={createNewClientInputs.roles.includes(ParticipantRole.POWER_OF_ATTORNEY)}
              />
              <label htmlFor="role6">Power Of Attorney</label>
            </div>
          </div>
        </div>
        <div className="create-new-client-btn-control">
          <button className="btn btn-success" type="submit">
            Add Client
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateNewClientForm;
