import { checkEmptyValue } from "../../../../utils";
import { EditClientInput } from "../Helpers/Inputs";
import { EditClientWizardItemProps } from "./EditClientWizardItemProps";

const SECTION_ID = 6;

/**
 * This component is used to create or edit the general details of a client
 * Passes in a client as well as the handling of the client data changes from the parent component
 * that holds the full client data
 * @param param0
 * @returns
 */
const CreateClientExternalInvestmentDetails = ({
  selectedClientData,
  handleDataChange,
  handleSaveClientDetails,
  handleGoBack,
}: EditClientWizardItemProps) => {
  const handleExternalDetailsChange = (
    val: number | string | boolean,
    attrName: string | undefined,
    check?: boolean
  ) => {
    let value: any = "";
    if (check !== undefined) {
      value = check;
    } else {
      value = val;
    }

    if (selectedClientData && attrName) {
      let tempData = { ...selectedClientData.externalInvestmentDetails };
      tempData[attrName] = value;
      handleDataChange({ externalInvestmentDetails: tempData });
    }
  };

  return (
    <div
      className="edit-client-wizard-item"
      id="edit-client-external-investment-details"
    >
      <div className="edit-client-wizard-item-header">
        <button
          className="edit-client-wizard-item-back"
          onClick={handleGoBack}
          style={{ fontSize: "1rem" }}
        >
          <i className="bi bi-arrow-left-circle-fill"></i>
        </button>
        <p>External Details</p>
      </div>
      <div className="edit-client-wizard-item-body">
        <EditClientInput
          label="Equities Value"
          value={selectedClientData?.externalInvestmentDetails?.equitiesValue}
          attrName="equitiesValue"
          handleChange={handleExternalDetailsChange}
          isNumber={true}
          isDollarAmount={true}
        />
        <EditClientInput
          label="Fixed Income Value"
          value={
            selectedClientData?.externalInvestmentDetails?.fixedIncomeValue
          }
          attrName="fixedIncomeValue"
          handleChange={handleExternalDetailsChange}
          isNumber={true}
          isDollarAmount={true}
        />

        <EditClientInput
          label="Cash or Equivalent Value"
          value={
            selectedClientData?.externalInvestmentDetails?.cashEquivalentValue
          }
          attrName="cashEquivalentValue"
          handleChange={handleExternalDetailsChange}
          isNumber={true}
          isDollarAmount={true}
        />

        <EditClientInput
          label="Real Estate Value"
          value={selectedClientData?.externalInvestmentDetails?.realEstateValue}
          attrName="realEstateValue"
          handleChange={handleExternalDetailsChange}
          isNumber={true}
          isDollarAmount={true}
        />

        <EditClientInput
          label="Commodities Value"
          value={
            selectedClientData?.externalInvestmentDetails?.commoditiesValue
          }
          attrName="commoditiesValue"
          handleChange={handleExternalDetailsChange}
          isNumber={true}
          isDollarAmount={true}
        />
        <EditClientInput
          label="Others Value"
          value={selectedClientData?.externalInvestmentDetails?.otherValue}
          attrName="otherValue"
          handleChange={handleExternalDetailsChange}
          isNumber={true}
          isDollarAmount={true}
        />
        {!checkEmptyValue(
          selectedClientData?.externalInvestmentDetails?.otherValue
        ) && (
          <EditClientInput
            label="Others Description"
            value={
              selectedClientData?.externalInvestmentDetails?.otherDescription
            }
            attrName="otherDescription"
            handleChange={handleExternalDetailsChange}
          />
        )}
        <EditClientInput
          label="Annual Income Value"
          value={selectedClientData?.externalInvestmentDetails?.annualIncome}
          attrName="annualIncome"
          handleChange={handleExternalDetailsChange}
          isNumber={true}
          isDollarAmount={true}
        />
      </div>
      <button
        className="btn-nice-purple btn-save-client-details"
        onClick={() => handleSaveClientDetails(SECTION_ID)}
      >
        Save and Continue
      </button>
    </div>
  );
};

export default CreateClientExternalInvestmentDetails;
