import EditClientBoolean from "../Inputs/EditClientBoolean";
import EditClientInput from "../Inputs/EditClientInput";
import RegulatoryDataProps from "./RegulatoryDataProps";

const IsIIROCMemberDetails = ({
  selectedClientData,
  handleRegulatoryDetailChange,
  handleDataChange,
}: RegulatoryDataProps) => {
  const handleIIROCChange = (
    val: string | number | boolean,
    attrName: string | undefined
  ) => {
    if (selectedClientData && attrName) {
      let tempData = { ...selectedClientData.regulatoryDetails };
      let iirocDetails = { ...tempData.iirocDetails! };

      if (!iirocDetails) {
        iirocDetails = {
          nameOfFirm: "",
        };
      }

      iirocDetails[attrName] = val.toString();
      tempData.iirocDetails = iirocDetails;

      handleDataChange({ regulatoryDetails: tempData });
    }
  };

  return (
    <>
      <EditClientBoolean
        label="Is IIROC Member?"
        value={selectedClientData?.regulatoryDetails?.isIIROCMember}
        inputName="isIIROCMember"
        attrName="isIIROCMember"
        handleChange={handleRegulatoryDetailChange}
      />
      {selectedClientData?.regulatoryDetails?.isIIROCMember && (
        <>
          <EditClientInput
            label="Name of Firm"
            value={
              selectedClientData?.regulatoryDetails?.iirocDetails?.nameOfFirm
            }
            attrName="nameOfFirm"
            handleChange={handleIIROCChange}
          />
        </>
      )}
    </>
  );
};

export default IsIIROCMemberDetails;
