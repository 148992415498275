import { CreateInvestmentAccountDto } from "types/InvestmentAccount";

/**
 * Investment Account with no data set
 *
 * @author Teddy kalp
 */
export const EmptyInvestmentAccount: CreateInvestmentAccountDto = {
  type: 0,
  subType: 0,
  currency: 0,
  isJointAccount: null,
  allowBeneficiarys: null,
  beneficiaries: [],
  allowEFT: null,
  eftBankAccounts: [],
  allowTransfers: null,
  transferDetails: [],
  allowPAC: null,
  pacDetails: [],
  allowSWP: null,
  swtDetails: [],
  isBorrowingToInvest: null,
  intendedUseOfAccount: 0,
  intendedUseOfAccountOther: "",
  sourceOfFunds: 0,
  sourceOfFundsOther: "",
  itfBeneficiaries: [],
  incomeFundInformation: null,
  lifInformation: null,
  respBeneficiaries: [],
  accountHolders: [],
  trustees: [],
  spouseContributers: [],
  allowSuccessorAnnuitants: false,
  successorAnnuitants: [],
};
