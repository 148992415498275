import { useState } from "react";
import ClientDetail from "../../../../../features/client/components/clientDetail";
import CreateClientSummarySectionProps from "./CreateClientSummarySectionProps";

const CreateFullClientSummaryRegulatoryDetails = ({ client }: CreateClientSummarySectionProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const maxHeight = "800px";

  return (
    <div className="create-client-summary-section" style={showMore ? { height: maxHeight } : {}}>
      <div className="create-client-summary-section-header" onClick={() => setShowMore(!showMore)}>
        <p>Regulatory Details</p>
        <button onClick={() => setShowMore(!showMore)}>
          {showMore ? (
            <i className="bi bi-caret-up-fill"></i>
          ) : (
            <i className="bi bi-caret-down-fill"></i>
          )}
        </button>
      </div>
      <div className="create-client-summary-section-header-underline"></div>
      <ClientDetail
        label="Has Power of Attorney"
        value={client?.regulatoryDetails?.hasPowerOfAttorney}
      />
      <ClientDetail label="Is Insider?" value={client?.regulatoryDetails?.isInsider} />
      {
        // If the client is an insider, then show the insider details
        client?.regulatoryDetails?.isInsider &&
          client?.regulatoryDetails.insiderDetails &&
          client?.regulatoryDetails.insiderDetails.map((detail, index) => {
            return (
              <div>
                <ClientDetail label={`Insider Symbol ${index + 1}`} value={detail.symbol} />
                <ClientDetail label={`Insider Company ${index + 1}`} value={detail.companyName} />
              </div>
            );
          })
      }
      <ClientDetail
        label="Is 10% Shareholder?"
        value={client?.regulatoryDetails?.is10PercentShareholder}
      />
      {
        // If the client is an insider, then show the insider details
        client?.regulatoryDetails?.is10PercentShareholder &&
          client?.regulatoryDetails.shareholder10Details &&
          client?.regulatoryDetails.shareholder10Details.map((detail, index) => {
            return (
              <div>
                <ClientDetail label={`10% Shareholder Symbol ${index + 1}`} value={detail.symbol} />
                <ClientDetail
                  label={`10% Shareholder Company ${index + 1}`}
                  value={detail.companyName}
                />
              </div>
            );
          })
      }

      <ClientDetail
        label="Is 20% Shareholder?"
        value={client?.regulatoryDetails?.is20PercentShareholder}
      />
      {
        // If the client is an insider, then show the insider details
        client?.regulatoryDetails?.is20PercentShareholder &&
          client?.regulatoryDetails.shareholder20Details &&
          client?.regulatoryDetails.shareholder20Details.map((detail, index) => {
            return (
              <div>
                <ClientDetail label={`20% Shareholder Symbol ${index + 1}`} value={detail.symbol} />
                <ClientDetail
                  label={`20% Shareholder Company ${index + 1}`}
                  value={detail.companyName}
                />
              </div>
            );
          })
      }
      <ClientDetail label="Is IIROC Member?" value={client?.regulatoryDetails?.isIIROCMember} />
      {client?.regulatoryDetails?.isIIROCMember && (
        <ClientDetail
          label="IIROC Firm"
          value={client?.regulatoryDetails?.iirocDetails?.nameOfFirm}
        />
      )}

      <ClientDetail label="Is Foreign PEP?" value={client?.regulatoryDetails?.isForeignPEP} />

      {client?.regulatoryDetails?.isForeignPEP && (
        <>
          <ClientDetail
            label="Foreign PEP Country"
            value={client?.regulatoryDetails?.foreignPEPDetails?.country}
          />
          <ClientDetail
            label="Foreign PEP Position Held"
            value={client?.regulatoryDetails?.foreignPEPDetails?.positionHeld}
          />
        </>
      )}

      <ClientDetail
        label="Is Associate of Foreign PEP?"
        value={client?.regulatoryDetails?.isAssociateForeignPEP}
      />

      {client?.regulatoryDetails?.isAssociateForeignPEP && (
        <>
          <ClientDetail
            label="Foreign PEP Associate Name"
            value={client?.regulatoryDetails?.foreignAssociatePEPDetails?.associateName}
          />
          <ClientDetail
            label="Foreign PEP Associate Country"
            value={client?.regulatoryDetails?.foreignAssociatePEPDetails?.country}
          />

          <ClientDetail
            label="Foreign PEP Associate Organization"
            value={client?.regulatoryDetails?.foreignAssociatePEPDetails?.organizationName}
          />

          <ClientDetail
            label="Foreign PEP Associate Job Title"
            value={client?.regulatoryDetails?.foreignAssociatePEPDetails?.jobTitle}
          />

          <ClientDetail
            label="Foreign PEP Associate Relationship"
            value={client?.regulatoryDetails?.foreignAssociatePEPDetails?.relationship}
          />

          <ClientDetail
            label="Foreign PEP Associate Position"
            value={client?.regulatoryDetails?.foreignAssociatePEPDetails?.positionHeld}
          />
        </>
      )}

      <ClientDetail label="Is Domestic PEP?" value={client?.regulatoryDetails?.isDomesticPEP} />

      {client?.regulatoryDetails?.isDomesticPEP && (
        <>
          <ClientDetail
            label="Domestic PEP Position"
            value={client?.regulatoryDetails?.domesticPEPDetails?.positionHeld}
          />
        </>
      )}

      <ClientDetail
        label="Is Associate of Domestic PEP?"
        value={client?.regulatoryDetails?.isAssociateDomesticPEP}
      />

      {client?.regulatoryDetails?.isAssociateDomesticPEP && (
        <>
          <ClientDetail
            label="Domestic PEP Associate Name"
            value={client?.regulatoryDetails?.domesticAssociatePEPDetails?.associateName}
          />
          <ClientDetail
            label="Domestic PEP Associate Organization"
            value={client?.regulatoryDetails?.domesticAssociatePEPDetails?.organizationName}
          />

          <ClientDetail
            label="Domestic PEP Associate Job Title"
            value={client?.regulatoryDetails?.domesticAssociatePEPDetails?.jobTitle}
          />

          <ClientDetail
            label="Domestic PEP Associate Relationship"
            value={client?.regulatoryDetails?.domesticAssociatePEPDetails?.relationship}
          />

          <ClientDetail
            label="Domestic PEP Associate Position"
            value={client?.regulatoryDetails?.domesticAssociatePEPDetails?.positionHeld}
          />
        </>
      )}
      <ClientDetail label="Is HIO?" value={client?.regulatoryDetails?.isHIO} />

      {client?.regulatoryDetails?.isHIO && (
        <>
          <ClientDetail
            label="HIO Job Title"
            value={client?.regulatoryDetails?.hioDetails?.jobTitle}
          />
          <ClientDetail
            label="HIO Organization"
            value={client?.regulatoryDetails?.hioDetails?.nameOfOrganization}
          />
          <ClientDetail
            label="HIO Country"
            value={client?.regulatoryDetails?.hioDetails?.country}
          />
        </>
      )}

      <ClientDetail
        label="Is Associate of HIO?"
        value={client?.regulatoryDetails?.isAssociateHIO}
      />

      {client?.regulatoryDetails?.isAssociateHIO && (
        <>
          <ClientDetail
            label="HIO Associate Name"
            value={client?.regulatoryDetails?.associateHIODetails.nameOfAssociate}
          />
          <ClientDetail
            label="HIO Associate Job Title"
            value={client?.regulatoryDetails?.associateHIODetails.jobTitle}
          />
          <ClientDetail
            label="HIO Associate Organization"
            value={client?.regulatoryDetails?.associateHIODetails.nameOfOrganization}
          />
          <ClientDetail
            label="HIO Associate Country"
            value={client?.regulatoryDetails?.associateHIODetails.country}
          />
        </>
      )}

      <ClientDetail
        label="Knows someone in Security Firm?"
        value={client?.regulatoryDetails?.isSecuritiesFirm}
      />

      {client?.regulatoryDetails?.isSecuritiesFirm && (
        <>
          <ClientDetail
            label="Security Firm Associate Name"
            value={client?.regulatoryDetails?.securitiesFirmInformation?.name}
          />
          <ClientDetail
            label="Security Firm Associate Relationship"
            value={client?.regulatoryDetails?.securitiesFirmInformation?.relationship}
          />
          <ClientDetail
            label="Security Firm Name"
            value={client?.regulatoryDetails?.securitiesFirmInformation?.companyName}
          />
          <ClientDetail
            label="Security Firm Position"
            value={client?.regulatoryDetails?.securitiesFirmInformation?.companyPosition}
          />
        </>
      )}

      <ClientDetail
        label="Knows someone in Broker/Dealer?"
        value={client?.regulatoryDetails?.isBrokerDealer}
      />

      {client?.regulatoryDetails?.isBrokerDealer && (
        <>
          <ClientDetail
            label="Broker/Dealer Associate Name"
            value={client?.regulatoryDetails?.brokerDealerInformation?.name}
          />
          <ClientDetail
            label="Broker/Dealer Associate Relationship"
            value={client?.regulatoryDetails?.brokerDealerInformation?.relationship}
          />
          <ClientDetail
            label="Broker/Dealer Firm Name"
            value={client?.regulatoryDetails?.brokerDealerInformation?.companyName}
          />
          <ClientDetail
            label="Broker/Dealer Firm Position"
            value={client?.regulatoryDetails?.brokerDealerInformation?.companyPosition}
          />
        </>
      )}
    </div>
  );
};

export default CreateFullClientSummaryRegulatoryDetails;
