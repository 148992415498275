const convertDateToString = (val: string | undefined) => {
  if (!val) return "";
  const date = new Date(val);
  const year = date.getFullYear();

  // Get the month (from 0-11)
  let month: string | number = date.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }

  // Get the Day
  let day: string | number = date.getDate();
  if (day < 10) {
    day = `0${day}`;
  }
  const dateString = `${year}-${month}-${day}`;

  return dateString;
};

export default convertDateToString;
