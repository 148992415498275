interface EditClientBooleanProps {
  label: string;
  value: boolean | undefined | null;
  attrName?: string;
  inputName?: string;
  handleChange: (
    e: boolean | number | string,
    attrName: string | undefined,
    check?: boolean
  ) => void;
}

const EditClientBoolean = ({
  label,
  value,
  handleChange,
  inputName,
  attrName,
}: EditClientBooleanProps) => {
  let checked = false;
  // If the value is undefined, we don't want any check to occur
  if (value !== undefined && value !== null) {
    checked = value;
  }

  return (
    <div className="edit-client-wizard-item-input-control">
      <label>{label}</label>
      <div className="edit-client-wizard-item-input-control-radio">
        <input
          type="radio"
          name={inputName ? inputName : ""}
          checked={value !== undefined && value !== null ? value : false}
          onChange={(e) => handleChange(e.target.value, attrName, true)}
        />
        <label>Yes</label>
        <input
          type="radio"
          name={inputName ? inputName : ""}
          checked={value !== undefined && value !== null ? !value : false}
          onChange={(e) => handleChange(e.target.value, attrName, false)}
        />
        <label>No</label>
      </div>
    </div>
  );
};

export default EditClientBoolean;
