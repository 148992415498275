import { SelectItem } from "types/Shared";

/**
 * Marital Status for a Client
 * @author Teddy K.
 *
 * @todo Make the values values of an enum,
 * need to check with backend to ensure that integers are
 * being used instead of strings
 *  */
export const MaritalStatusOptions: SelectItem[] = [
  {
    label: "Married",
    value: "Married",
  },
  {
    label: "Common Law",
    value: "Common Law",
  },
  {
    label: "Divorced",
    value: "Divorced",
  },
  {
    label: "Seperated",
    value: "Seperated",
  },
  {
    label: "Widowed",
    value: "Widowed",
  },
  {
    label: "Single",
    value: "Single",
  },
];
