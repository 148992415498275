// function to get the masking the given string
function maskString(str: string | undefined, start: number, end: number) {
  if (!str) {
    return "";
  }

  if (
    !str ||
    start < 0 ||
    start >= str.length ||
    end < 0 ||
    end > str.length ||
    start >= end
  ) {
    return str;
  }
  const maskLength = end - start;
  const maskedStr =
    str.substring(0, start) + "*".repeat(maskLength) + str.substring(end);
  return maskedStr;
}

export default maskString;
