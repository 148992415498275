import { SelectItem } from "../../../types/Shared";
import { InvestmentAccountSubType } from "../../../constants/enums";
import { InvestmentAccountType } from "../../../constants/enums";

// Our object that maps the account type to the subtypes it can have
const DefaultInvestMentAccountSubTypes: { [key: number]: SelectItem[] } = {};

// Cash accounts can only be individual
DefaultInvestMentAccountSubTypes[InvestmentAccountType.CASH] = [
  {
    value: InvestmentAccountSubType.INDIVIDUAL,
    label: "Individual",
  },
];

// Margin accounts can only be individual
DefaultInvestMentAccountSubTypes[InvestmentAccountType.MARGIN] = [
  {
    value: InvestmentAccountSubType.INDIVIDUAL,
    label: "Individual",
  },
];

// In Trust For accounts can only be individual
DefaultInvestMentAccountSubTypes[InvestmentAccountType.CASH_IN_TRUST_FOR] = [
  {
    value: InvestmentAccountSubType.INDIVIDUAL,
    label: "Individual",
  },
];

// Cash Leveraged accounts can only be individual
DefaultInvestMentAccountSubTypes[InvestmentAccountType.CASH_LEVERAGED] = [
  {
    value: InvestmentAccountSubType.INDIVIDUAL,
    label: "Individual",
  },
];

// TFSA accounts can only be individual
DefaultInvestMentAccountSubTypes[InvestmentAccountType.TFSA] = [
  {
    value: InvestmentAccountSubType.INDIVIDUAL,
    label: "Individual",
  },
];

// RESP accounts can be individual or family
DefaultInvestMentAccountSubTypes[InvestmentAccountType.RESP] = [
  {
    value: InvestmentAccountSubType.INDIVIDUAL,
    label: "Individual",
  },
  {
    value: InvestmentAccountSubType.FAMILY,
    label: "Family",
  },
];

// RSP accounts can be individual, spousal, individual group, spousal group, or locked in
DefaultInvestMentAccountSubTypes[InvestmentAccountType.RSP] = [
  {
    value: InvestmentAccountSubType.INDIVIDUAL,
    label: "Individual",
  },
  {
    value: InvestmentAccountSubType.SPOUSAL,
    label: "Spousal",
  },
  // {
  //   value: InvestmentAccountSubType.INDIVIDUAL_GROUP,
  //   label: "Individual Group",
  // },
  // {
  //   value: InvestmentAccountSubType.SPOUSAL_GROUP,
  //   label: "Spousal Group",
  // },
  {
    value: InvestmentAccountSubType.LOCKED_IN,
    label: "Locked In",
  },
];

// RIF accounts can be individual, spousal, or locked in
DefaultInvestMentAccountSubTypes[InvestmentAccountType.RIF] = [
  { value: InvestmentAccountSubType.INDIVIDUAL, label: "Individual" },
  {
    value: InvestmentAccountSubType.SPOUSAL,
    label: "Spousal",
  },
  {
    value: InvestmentAccountSubType.LOCKED_IN,
    label: "Locked In",
  },
];

export default DefaultInvestMentAccountSubTypes;
