import { convertDateToString, numberWithCommas } from "../../../utils";
import maskString from "../../../utils/maskString";

interface ClientDetailProps {
  label: string;
  value: any;
  isSecure?: boolean;
  isDollarAmount?: boolean;
  isCommmas?: boolean;
  isDate?: boolean;
}

const ClientDetail = ({
  label,
  value,
  isSecure,
  isDollarAmount,
  isCommmas,
  isDate,
}: ClientDetailProps) => {
  if (isSecure && typeof value === "string") {
    value = maskString(value, 0, value.length - 3);
  }

  if (isDollarAmount) {
    value = `$${value}`;
  }

  if (isCommmas) {
    value = numberWithCommas(value);
  }
  if (typeof value === "boolean") {
    value = value ? "Yes" : "No";
  }
  if (isDate) {
    value = convertDateToString(value);
  }

  return (
    <div className="client-detail">
      <p className="client-detail-label">{`${label}:`}</p>{" "}
      <p className="client-detail-value">{value}</p>
    </div>
  );
};

export default ClientDetail;
