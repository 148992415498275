interface EditClientInputWithArrayProps {
  label: string;
  value: any;
  attrName: string;
  index: number;
  placeholder?: string;
  isNumber?: boolean;
  isDate?: boolean;
  isRequired?: boolean;
  isDollarAmount?: boolean;
  handleDataChange: (
    e: string | number | boolean,
    attrName: string | undefined,
    index: number | undefined
  ) => void;
}

const EditClientInputWithArray = ({
  label,
  value,
  attrName,
  index,
  placeholder,
  isNumber,
  isDate,
  isRequired,
  isDollarAmount,
  handleDataChange,
}: EditClientInputWithArrayProps) => {
  let inputType = "text";

  // If the input is a number, then set the input type to number
  // If the number is a date, then set the input type to date and check for null values
  if (isNumber) {
    inputType = "number";
    if (!value) {
      value = "0";
    }
  } else if (isDate) {
    if (value) {
      value = new Date(value).toISOString().split("T")[0];
      if (value == "0001-01-01") {
        value = new Date().toISOString().split("T")[0];
      }
    }

    inputType = "date";
  }

  return (
    <div className="edit-client-wizard-item-input-control">
      <label>
        {label}
        {isRequired && "*"}
      </label>
      {isDollarAmount ? "$" : ""}
      <input
        type={inputType}
        value={value ? value : ""}
        // Either handle the change normally, or use the index to handle the change with an array
        onChange={(e) => handleDataChange(e.target.value, attrName, index)}
        placeholder={placeholder}
        pattern={isNumber ? "[0-9]*" : undefined}
      />
    </div>
  );
};

export default EditClientInputWithArray;
