import React from "react";
import EditClientBoolean from "../Inputs/EditClientBoolean";
import EditClientInput from "../Inputs/EditClientInput";
import RegulatoryDataProps from "./RegulatoryDataProps";

function IsDomesticPEPDetails({
  selectedClientData,
  handleRegulatoryDetailChange,
  handleDataChange,
}: RegulatoryDataProps) {
  const handleDomesticPEPChange = (
    val: string | number | boolean,
    attrName: string | undefined
  ) => {
    if (selectedClientData && attrName) {
      let tempData = { ...selectedClientData.regulatoryDetails };
      let domesticPEPDetails = { ...tempData.domesticPEPDetails! };

      if (!domesticPEPDetails) {
        domesticPEPDetails = {
          positionHeld: "",
        };
      }

      domesticPEPDetails[attrName] = val.toString();
      tempData.domesticPEPDetails = domesticPEPDetails;
      handleDataChange({ regulatoryDetails: tempData });
    }
  };

  return (
    <>
      <EditClientBoolean
        label="Is Domestic PEP Member?"
        value={selectedClientData?.regulatoryDetails?.isDomesticPEP}
        inputName="isDomesticPEP"
        attrName="isDomesticPEP"
        handleChange={handleRegulatoryDetailChange}
      />
      {selectedClientData?.regulatoryDetails?.isDomesticPEP && (
        <div className="regulatory-list-item">
          <EditClientInput
            label="Position Held"
            value={
              selectedClientData?.regulatoryDetails?.domesticPEPDetails
                ?.positionHeld
            }
            attrName="positionHeld"
            handleChange={handleDomesticPEPChange}
          />
        </div>
      )}
    </>
  );
}

export default IsDomesticPEPDetails;
