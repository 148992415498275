import React from "react";
import "./styles/MainLoader.css";
import MiniLoader from "./MiniLoader";
function MainLoader() {
  return (
    <div className="body">
      <MiniLoader />
    </div>
  );
}

export default MainLoader;
