import $ from "jquery";

const onLoginLabelClick = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
  // Focus the contianer
  const loginLabel = $(e.target);
  const loginInputContainer = loginLabel.parent();
  $(loginInputContainer).addClass("focused");
  // Focus the input
  const loginInput = $(loginInputContainer).children("input");
  loginInput.trigger("focus");
};

const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
  const loginInput = $(e.target);
  const loginInputContainer = loginInput.parent();
  $(loginInputContainer).addClass("focused");
};

const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
  const loginInput = $(e.target);
  const loginInputContainer = loginInput.parent();
  $(loginInputContainer).removeClass("focused");

  if (loginInput.val() === "") {
    $(loginInputContainer).removeClass("filled");
  } else {
    $(loginInputContainer).addClass("filled");
  }
};

export { onLoginLabelClick, onInputFocus, onInputBlur };
