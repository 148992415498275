import { useState } from "react";
import ClientDetail from "../../../../../features/client/components/clientDetail";
import CreateClientSummarySectionProps from "./CreateClientSummarySectionProps";
import { EmploymentStatusMappingById } from "../../../../../constants/mappings/EmploymentStatusMappingById";
const CreateFullClientSummaryEmploymentDetails = ({ client }: CreateClientSummarySectionProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const maxHeight = "450px";

  return (
    <div className="create-client-summary-section" style={showMore ? { height: maxHeight } : {}}>
      <div className="create-client-summary-section-header" onClick={() => setShowMore(!showMore)}>
        <p>Employment Details</p>
        <button onClick={() => setShowMore(!showMore)}>
          {showMore ? (
            <i className="bi bi-caret-up-fill"></i>
          ) : (
            <i className="bi bi-caret-down-fill"></i>
          )}
        </button>
      </div>
      <div className="create-client-summary-section-header-underline"></div>
      <ClientDetail
        label="Employment Status"
        value={
          client?.employmentDetails?.status
            ? EmploymentStatusMappingById[client?.employmentDetails?.status]
            : ""
        }
      />
      <ClientDetail label="Occupation" value={client?.employmentDetails?.occupation} />
      <ClientDetail label="Company Name" value={client?.employmentDetails?.name} />
      <ClientDetail label="Type of Business" value={client?.employmentDetails?.businessType} />
      <ClientDetail label="Length of Employment" value={client?.employmentDetails?.lengthInYears} />
      <ClientDetail label="Address" value={client?.employmentDetails?.address} />
      <ClientDetail label="City" value={client?.employmentDetails?.city} />
      <ClientDetail label="Region" value={client?.employmentDetails?.region} />
      <ClientDetail label="Country" value={client?.employmentDetails?.country} />
      <ClientDetail label="Post Code" value={client?.employmentDetails?.postalCode} />
    </div>
  );
};

export default CreateFullClientSummaryEmploymentDetails;
