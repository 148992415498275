const DefaultInstitutionMapping: any = {
  1: "BMO",
  2: "ScotiaBank",
  3: "RBC",
  4: "TD",
  5: "NationalBank",
  6: "CIBC",
  7: "HSBC",
  8: "CandianWesternBank",
  9: "LaurentianBank",
  10: "ManulifeBank",
  11: "ATBFinancial",
  12: "AlbertaCreditUnion",
  13: "Other",
};

export default DefaultInstitutionMapping;
