import React from "react";
import RegulatoryDataProps from "./RegulatoryDataProps";
import EditClientBoolean from "../Inputs/EditClientBoolean";
import EditClientInput from "../Inputs/EditClientInput";
import EditClientSelect from "../Inputs/EditClientSelect";

function IsAssociateForeignPEP({
  selectedClientData,
  handleRegulatoryDetailChange,
  handleDataChange,
}: RegulatoryDataProps) {
  const handleAssociateForeignPEPChange = (
    val: string | number | boolean,
    attrName: string | undefined
  ) => {
    if (selectedClientData && attrName) {
      let tempData = { ...selectedClientData.regulatoryDetails };
      let foreignAssociatePEPDetails = {
        ...tempData.foreignAssociatePEPDetails!,
      };

      if (!foreignAssociatePEPDetails) {
        foreignAssociatePEPDetails = {
          associateName: "",
          country: "",
          organizationName: "",
          jobTitle: "",
          relationship: "",
          positionHeld: "",
        };
      }

      foreignAssociatePEPDetails[attrName] = val.toString();
      tempData.foreignAssociatePEPDetails = foreignAssociatePEPDetails;
      handleDataChange({ regulatoryDetails: tempData });
    }
  };

  return (
    <>
      <EditClientBoolean
        label="Is Associate of Foreign PEP Member?"
        value={selectedClientData?.regulatoryDetails?.isAssociateForeignPEP}
        inputName="isAssociateForeignPEP"
        attrName="isAssociateForeignPEP"
        handleChange={handleRegulatoryDetailChange}
      />
      {
        // If the client is a foreign PEP, then show the details
        selectedClientData?.regulatoryDetails?.isAssociateForeignPEP && (
          <div className="regulatory-list-item">
            <EditClientInput
              label="Name of Associate"
              value={
                selectedClientData?.regulatoryDetails
                  ?.foreignAssociatePEPDetails?.associateName
              }
              attrName="associateName"
              handleChange={handleAssociateForeignPEPChange}
            />
            <EditClientSelect
              label="Country"
              value={
                selectedClientData?.regulatoryDetails
                  ?.foreignAssociatePEPDetails?.country
              }
              attrName="country"
              isCountry={true}
              handleChange={handleAssociateForeignPEPChange}
            />
            <EditClientInput
              label="Organization Name"
              value={
                selectedClientData?.regulatoryDetails
                  ?.foreignAssociatePEPDetails?.organizationName
              }
              attrName="organizationName"
              handleChange={handleAssociateForeignPEPChange}
            />

            <EditClientInput
              label="Job Title"
              value={
                selectedClientData?.regulatoryDetails
                  ?.foreignAssociatePEPDetails?.jobTitle
              }
              attrName="jobTitle"
              handleChange={handleAssociateForeignPEPChange}
            />

            <EditClientInput
              label="Relationship"
              value={
                selectedClientData?.regulatoryDetails
                  ?.foreignAssociatePEPDetails?.relationship
              }
              attrName="relationship"
              handleChange={handleAssociateForeignPEPChange}
            />

            <EditClientInput
              label="Position Held"
              value={
                selectedClientData?.regulatoryDetails
                  ?.foreignAssociatePEPDetails?.positionHeld
              }
              attrName="positionHeld"
              handleChange={handleAssociateForeignPEPChange}
            />
          </div>
        )
      }
    </>
  );
}

export default IsAssociateForeignPEP;
