import React, { useEffect, useState } from "react";

import { ApiResponseModel, Participant } from "../../../types";

import { Advisor } from "../../../types/Advisor/Advisor";
import { RootState } from "../../../stores/store";
import { useSelector } from "react-redux";
import { HouseholdState } from "../../../stores/Redux/householdSlice";
import withAuth from "../../../HOC/withAuth";
import { useCreateMagicLinkMutation } from "../../../Apis/riskToleranceApi";
import { useParams } from "react-router-dom";
import { useLazyGetClientByIdQuery } from "../../../Apis/mainApi";
import { notify } from "../../../utils";

function CreateRiskToleranceLink() {
  const { clientId } = useParams();
  const [clientQuery, clientStatus] = useLazyGetClientByIdQuery();
  const [client, setClient] = useState<Participant>();
  const [code, setCode] = useState("");

  const advisor: Advisor = useSelector((state: RootState) => state.advisorStore);

  const advisorHouseholds: HouseholdState = useSelector((state: RootState) => state.householdStore);

  useEffect(() => {
    if (clientId) {
      clientQuery(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    if (clientStatus.isSuccess) {
      const result = clientStatus.data.result;
      setClient(result);
    }
  }, [clientStatus]);

  const [createMagicLink] = useCreateMagicLinkMutation();
  const host = window.location.host;
  const handleCreateButtonSubmit = async () => {
    const params = {
      foClientId: client?.id,
    };

    const createMagicLinkResult: ApiResponseModel = await createMagicLink(params);

    if (createMagicLinkResult.data && createMagicLinkResult.data.isSuccess) {
      const code = createMagicLinkResult.data.result.code;
      setCode(code);
    } else if (createMagicLinkResult.error) {
      notify("an error occured", "error");
    }
  };

  return (
    <>
      <div className="risk-step">
        <h2 className="risk-step-label">Create Risk Tolerance Questionaire Link</h2>
        <h2>{client?.generalDetails.firstName + " " + client?.generalDetails.lastName}</h2>
        <button onClick={handleCreateButtonSubmit}>Create</button>
      </div>
      {code && (
        <p>
          Client Link: {host}\clientRiskToleranceForm\{code}
        </p>
      )}
    </>
  );
}

export default withAuth(CreateRiskToleranceLink);
