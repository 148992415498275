import { useEffect, useState } from "react";
import { InvestmentAccountDetailsProps } from "./CreateInvestmentAccountGeneralDetails";
import {
  CreateInvestmentAccountDto,
  PacDetail,
} from "features/createInvestmentAccount/types/CreateInvestmentAccountDto";
import {
  EditClientInput,
  EditClientSelect,
} from "Components/Pages/CreateFullClient/Helpers/Inputs";
import { SelectItem } from "types/Shared";
import { ContributionFrequencies } from "assets/StaticData/EditClientOptions";
import { Participant } from "types";
import { ContributionFrequencyMapping } from "constants/mappings";
import { ContributionTypes, ContributionAccountOptions } from "constants/selectOptions";
import { EmptyPacDetail } from "constants/emptyStates";

/**
 * This component is the Pre-Authorized Contributions (PAC) Details component used in the Create Investment Account wizard.
 * This will be used to track if the account will have PACs or not.
 * @param param0
 * @returns
 */
function CreateInvestmentAccountPACDetails({
  account,
  participant,
  handleGoNext,
  setNewInvestmentAccount,
}: InvestmentAccountDetailsProps) {
  const [addingDetail, setIsAddingDetail] = useState(false);

  return (
    <div className="edit-client-wizard-item" id="edit-invest-account-pac-details">
      <div className="edit-client-wizard-item-header">
        <p>Pre-Authorized Contributions (PAC) Details</p>
      </div>
      <div className="edit-client-wizard-item-body">
        <div className="pac-details-container">
          {account.pacDetails &&
            account.pacDetails.length > 0 &&
            account.pacDetails.map((pac) => {
              return (
                <div
                  className="pac-detail-card"
                  style={{ border: "2px solid black", marginBottom: "1rem", padding: "1rem" }}
                >
                  <div className="pac-detail-card-header">
                    <p>Amount: ${pac.amount}</p>
                    <p>Frequency: {ContributionFrequencyMapping[pac.frequency]}</p>
                    <p>Bank Account Id: {pac.bankAccountId}</p>
                  </div>
                  <div className="pac-detail-card-body"></div>
                </div>
              );
            })}
        </div>
        <div className="add-pac-details-btn-container">
          {addingDetail ? (
            <button className="btn btn-danger" onClick={() => setIsAddingDetail(!addingDetail)}>
              Cancel
            </button>
          ) : (
            <button className="btn btn-success" onClick={() => setIsAddingDetail(!addingDetail)}>
              Add New PAC Detail
            </button>
          )}
        </div>
        {addingDetail && (
          <AddPACDetailForm
            account={account}
            setAccount={setNewInvestmentAccount}
            participant={participant}
            setIsAddingDetail={setIsAddingDetail}
          />
        )}
        {handleGoNext && (
          <button className="btn-nice-purple btn-save-client-details" onClick={handleGoNext}>
            Continue
          </button>
        )}
      </div>
    </div>
  );
}

export interface AddPACDetailFormProps {
  account: CreateInvestmentAccountDto;
  setAccount: React.Dispatch<React.SetStateAction<CreateInvestmentAccountDto>>;
  participant: Participant | undefined;
  setIsAddingDetail: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Form that allows you to add a new PAC Detail to the Investment Account.
 * @returns
 */
const AddPACDetailForm = ({
  account,
  setAccount,
  participant,
  setIsAddingDetail,
}: AddPACDetailFormProps) => {
  const [pacDetail, setPACDetail] = useState<PacDetail>(EmptyPacDetail);
  const [bankAccounts, setBankAccounts] = useState<SelectItem[]>([]);

  const handlePacDetailFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const tempAccount = { ...account };
    if (tempAccount.pacDetails) {
      tempAccount.pacDetails.push(pacDetail);
    } else {
      tempAccount.pacDetails = [pacDetail];
    }
    setAccount(tempAccount);
    setIsAddingDetail(false);
  };

  /**
   * Handling the change of the PAC Detail form.
   * @param e
   * @param attrName
   */
  const handlePacDetailChange = (e: string | number | boolean, attrName: string | undefined) => {
    if (attrName) {
      setPACDetail({ ...pacDetail, [attrName]: e });
    }
  };

  // Setting the bank accounts that can be chosen
  useEffect(() => {
    if (participant) {
      const eftBankAccounts = participant?.bankAccounts.filter((bankAccount) => {
        if (account.eftBankAccounts && account.eftBankAccounts.includes(bankAccount.id)) {
          return true;
        }

        return false;
      });

      const bankAccountSelectItems: SelectItem[] = eftBankAccounts.map((bankAccount) => {
        return {
          value: bankAccount.id,
          label: `${bankAccount.accountName} - ${bankAccount.accountNumber}`,
        };
      });

      setBankAccounts(bankAccountSelectItems);
    }
  }, []);

  return (
    <form className="add-pac-detail-form" onSubmit={handlePacDetailFormSubmit}>
      <EditClientSelect
        label="Type"
        selectItems={ContributionTypes}
        value={pacDetail.type}
        attrName="type"
        handleChange={handlePacDetailChange}
      />
      <EditClientInput
        label="Amount"
        value={pacDetail.amount}
        attrName="amount"
        handleChange={handlePacDetailChange}
        isDollarAmount={true}
        isNumber={true}
      />
      <EditClientSelect
        label="Frequency"
        selectItems={ContributionFrequencies}
        value={pacDetail.frequency}
        attrName="frequency"
        handleChange={handlePacDetailChange}
      />
      <EditClientInput
        label="Start Date"
        value={pacDetail.startDate}
        handleChange={handlePacDetailChange}
        attrName="startDate"
        isDate={true}
      />
      <EditClientSelect
        label="Account Option"
        selectItems={ContributionAccountOptions}
        value={pacDetail.accountOption}
        attrName="accountOption"
        handleChange={handlePacDetailChange}
      />
      <EditClientSelect
        label="Bank Account"
        selectItems={bankAccounts}
        value={pacDetail.bankAccountId}
        attrName="bankAccountId"
        handleChange={handlePacDetailChange}
      />
      <button className="btn btn-success">Add SWT</button>
    </form>
  );
};

export default CreateInvestmentAccountPACDetails;
