function validatePostalCode(pcode: string | number | undefined) {
  if (pcode && typeof pcode == "string") {
    var re = /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/;
    var cleanedPostal = pcode?.toString();
    return re.test(cleanedPostal);
  }

  return false;
}

export default validatePostalCode;
