import { SelectItem } from "../../types/Shared";
import { IntendedUseOfAccount, SourceOfFunds } from "../../constants/enums";

import { AddressType } from "../../constants/enums";
import { CitizenshipType } from "../../constants/enums";

import { ContributionFrequency } from "../../constants/enums";

import { EmploymentStatus } from "../../constants/enums";

export const AddressTypes: SelectItem[] = [
  {
    label: "Civic",
    value: AddressType.CIVIC,
  },
  {
    label: "Rural",
    value: AddressType.RURAL,
  },
  {
    label: "International",
    value: AddressType.INTERNATIONAL,
  },
];

export const EmploymentTypes: SelectItem[] = [
  {
    label: "Employed",
    value: EmploymentStatus.EMPLOYED,
  },
  {
    label: "Self-Employed",
    value: EmploymentStatus.SELF_EMPLOYED,
  },
  {
    label: "Retired",
    value: EmploymentStatus.RETIRED,
  },
  {
    label: "Student",
    value: EmploymentStatus.STUDENT,
  },
  {
    label: "Business Owner",
    value: EmploymentStatus.BUSINESS_OWNER,
  },
  {
    label: "Other",
    value: EmploymentStatus.OTHER,
  },
];

export const SourceOfFundsOptions: SelectItem[] = [
  {
    label: "Retirement Income",
    value: SourceOfFunds.RETIREMENT_INCOME,
  },
  {
    label: "Insurance Settlement",
    value: SourceOfFunds.INSURANCE_SETTLEMENT,
  },
  {
    label: "Sale of Property",
    value: SourceOfFunds.SALE_OF_PROPERTY,
  },
  {
    label: "Savings",
    value: SourceOfFunds.SALE_OF_PROPERTY,
  },
  {
    label: "Gift/Donation",
    value: SourceOfFunds.GIFT_DONATION,
  },
  {
    label: "Investment Earnings",
    value: SourceOfFunds.INVESTMENT_EARNINGS,
  },
  {
    label: "Inheritence",
    value: SourceOfFunds.INHERITANCE,
  },
  {
    label: "Other",
    value: SourceOfFunds.OTHER,
  },
];

export const ContributionFrequencies: SelectItem[] = [
  {
    label: "Weekly",
    value: ContributionFrequency.WEEKLY,
  },
  {
    label: "Bi-Weekly",
    value: ContributionFrequency.BI_WEEKLY,
  },
  {
    label: "Monthly",
    value: ContributionFrequency.MONTHLY,
  },
  {
    label: "Bi-Monthly",
    value: ContributionFrequency.BI_MONTHLY,
  },
  {
    label: "Quarterly",
    value: ContributionFrequency.QUARTERLY,
  },
  {
    label: "Semi-Annual",
    value: ContributionFrequency.SEMI_ANNUALLY,
  },
  {
    label: "Annual",
    value: ContributionFrequency.ANNUAL,
  },
];
