import { BankInstitution } from "../../../constants/enums";

const DefaultBankInstitutionNumbers: { [key: number]: string } = {};
DefaultBankInstitutionNumbers[BankInstitution.BMO] = "001";
DefaultBankInstitutionNumbers[BankInstitution.SCOTIA_BANK] = "002";
DefaultBankInstitutionNumbers[BankInstitution.RBC] = "003";
DefaultBankInstitutionNumbers[BankInstitution.TD] = "004";
DefaultBankInstitutionNumbers[BankInstitution.NATIONAL_BANK] = "006";
DefaultBankInstitutionNumbers[BankInstitution.CIBC] = "010";
DefaultBankInstitutionNumbers[BankInstitution.HSBC] = "016";
DefaultBankInstitutionNumbers[BankInstitution.CANADIAN_WESTERN_BANK] = "030";
DefaultBankInstitutionNumbers[BankInstitution.LAURENTIAN_BANK] = "039";
DefaultBankInstitutionNumbers[BankInstitution.MANULIFE_BANK] = "540";
DefaultBankInstitutionNumbers[BankInstitution.ATB_FINANCIAL] = "219";
DefaultBankInstitutionNumbers[BankInstitution.ALBERTA_CREDIT_UNION] = "899";

export default DefaultBankInstitutionNumbers;
