import { SelectItem } from "../../types/Shared";

export const CurrencyOptions: SelectItem[] = [
  {
    label: "CAD",
    value: 2,
  },
  {
    label: "USD",
    value: 1,
  },
];
