import { PacDetail } from "features/createInvestmentAccount/types/CreateInvestmentAccountDto";

const EmptyPacDetail: PacDetail = {
  type: 0,
  amount: 0,
  frequency: 0,
  startDate: "",
  accountOption: 0,
  bankAccountId: "",
};

export default EmptyPacDetail;
