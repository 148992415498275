import { notify } from "utils";
import { CreateIndividualPolicyStatementDto } from "../types";

const validateClientSummary = (formData: CreateIndividualPolicyStatementDto) => {
  if (formData.clientSummary.length > 0) {
    return true;
  }

  notify("Please provide a valid client summary", "error");
  return false;
};

export default validateClientSummary;
