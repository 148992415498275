import React from "react";
import RegulatoryDataProps from "./RegulatoryDataProps";
import EditClientBoolean from "../Inputs/EditClientBoolean";
import EditClientInput from "../Inputs/EditClientInput";
import EditClientSelect from "../Inputs/EditClientSelect";

function IsBrokerDealerDetails({
  selectedClientData,
  handleRegulatoryDetailChange,
  handleDataChange,
}: RegulatoryDataProps) {
  const handleSecurityFirmChange = (
    val: string | number | boolean,
    attrName: string | undefined
  ) => {
    if (selectedClientData && attrName) {
      let tempData = { ...selectedClientData.regulatoryDetails };
      let brokerDealerInformation = { ...tempData.brokerDealerInformation! };

      if (!brokerDealerInformation) {
        brokerDealerInformation = {
          name: "",
          relationship: "",
          companyName: "",
          companyPosition: "",
        };
      }

      brokerDealerInformation[attrName] = val.toString();
      tempData.brokerDealerInformation = brokerDealerInformation;
      handleDataChange({ regulatoryDetails: tempData });
    }
  };

  return (
    <>
      <EditClientBoolean
        label="Knows someone in Broker/Dealer?"
        value={selectedClientData?.regulatoryDetails?.isBrokerDealer}
        inputName="isBrokerDealer"
        attrName="isBrokerDealer"
        handleChange={handleRegulatoryDetailChange}
      />
      {
        // If the client is a foreign PEP, then show the details
        selectedClientData?.regulatoryDetails?.isBrokerDealer && (
          <div className="regulatory-list-item">
            <EditClientInput
              label="Name of Associate"
              value={
                selectedClientData?.regulatoryDetails?.brokerDealerInformation
                  ?.name
              }
              attrName="name"
              handleChange={handleSecurityFirmChange}
            />
            <EditClientInput
              label="Relationship"
              value={
                selectedClientData?.regulatoryDetails?.brokerDealerInformation
                  ?.relationship
              }
              attrName="relationship"
              handleChange={handleSecurityFirmChange}
            />
            <EditClientInput
              label="Broker / Dealer Name"
              value={
                selectedClientData?.regulatoryDetails?.brokerDealerInformation
                  ?.companyName
              }
              attrName="companyName"
              handleChange={handleSecurityFirmChange}
            />
            <EditClientInput
              label="Brokder / Dealer Position"
              value={
                selectedClientData?.regulatoryDetails?.brokerDealerInformation
                  ?.companyPosition
              }
              attrName="companyPosition"
              handleChange={handleSecurityFirmChange}
            />
          </div>
        )
      }
    </>
  );
}

export default IsBrokerDealerDetails;
