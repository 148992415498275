import { numberWithCommas } from "../../../utils";
import { AssetLiquidity } from "../../../types/Participant/Participant";

interface NetWorthTableProps {
  tableName: string;
  assets: AssetLiquidity[];
}
const ClientNetWorthTable = ({ tableName, assets }: NetWorthTableProps) => {
  let totalValue = 0;
  return (
    <div className="client-details-table-container">
      <p className="client-details-table-name">{tableName}</p>
      <table className="client-details-table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {assets.map((asset) => {
            totalValue += asset.amount;
            return (
              <tr>
                <td>{asset.type}</td>
                <td>{`$${numberWithCommas(asset.amount)}`}</td>
              </tr>
            );
          })}
          <tr>
            <td>Total</td>
            <td>{`$${numberWithCommas(totalValue)}`}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ClientNetWorthTable;
