// todo, put this in DB?
export enum BankInstitution {
  BMO = 1,
  SCOTIA_BANK = 2,
  RBC = 3,
  TD = 4,
  NATIONAL_BANK = 5,
  CIBC = 6,
  HSBC = 7,
  CANADIAN_WESTERN_BANK = 8,
  LAURENTIAN_BANK = 9,
  MANULIFE_BANK = 10,
  ATB_FINANCIAL = 11,
  ALBERTA_CREDIT_UNION = 12,
  OTHER = 13,
}
