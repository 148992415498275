import React from "react";
import RegulatoryDataProps from "./RegulatoryDataProps";
import EditClientBoolean from "../Inputs/EditClientBoolean";
import EditClientInput from "../Inputs/EditClientInput";
import EditClientSelect from "../Inputs/EditClientSelect";

function IsSecurityFirmDetails({
  selectedClientData,
  handleRegulatoryDetailChange,
  handleDataChange,
}: RegulatoryDataProps) {
  const handleSecurityFirmChange = (
    val: string | number | boolean,
    attrName: string | undefined
  ) => {
    if (selectedClientData && attrName) {
      let tempData = { ...selectedClientData.regulatoryDetails };
      let securityFirmDetails = { ...tempData.securitiesFirmInformation! };

      if (!securityFirmDetails) {
        securityFirmDetails = {
          name: "",
          relationship: "",
          companyName: "",
          companyPosition: "",
        };
      }

      securityFirmDetails[attrName] = val.toString();
      tempData.securitiesFirmInformation = securityFirmDetails;
      handleDataChange({ regulatoryDetails: tempData });
    }
  };

  return (
    <>
      <EditClientBoolean
        label="Knows someone in Security Firm?"
        value={selectedClientData?.regulatoryDetails?.isSecuritiesFirm}
        inputName="isSecuritiesFirm"
        attrName="isSecuritiesFirm"
        handleChange={handleRegulatoryDetailChange}
      />
      {
        // If the client is a foreign PEP, then show the details
        selectedClientData?.regulatoryDetails?.isSecuritiesFirm && (
          <div className="regulatory-list-item">
            <EditClientInput
              label="Name of Associate"
              value={
                selectedClientData?.regulatoryDetails?.securitiesFirmInformation
                  ?.name
              }
              attrName="name"
              handleChange={handleSecurityFirmChange}
            />
            <EditClientInput
              label="Relationship"
              value={
                selectedClientData?.regulatoryDetails?.securitiesFirmInformation
                  ?.relationship
              }
              attrName="relationship"
              handleChange={handleSecurityFirmChange}
            />
            <EditClientInput
              label="Security Firm Name"
              value={
                selectedClientData?.regulatoryDetails?.securitiesFirmInformation
                  ?.companyName
              }
              attrName="companyName"
              handleChange={handleSecurityFirmChange}
            />
            <EditClientInput
              label="Security Firm Position"
              value={
                selectedClientData?.regulatoryDetails?.securitiesFirmInformation
                  ?.companyPosition
              }
              attrName="companyPosition"
              handleChange={handleSecurityFirmChange}
            />
          </div>
        )
      }
    </>
  );
}

export default IsSecurityFirmDetails;
